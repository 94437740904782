import clsx from 'clsx'
import React, {FC} from 'react'
import {useDate} from './HeaderDate'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3 fs-4'

const Topbar: FC = () => {
   const {date, time} = useDate()
   return (
      <div className='d-flex align-items-stretch flex-shrink-0'>
         <div style={{textAlign: 'right'}} className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            {date}
            <br />
            {time}
         </div>
      </div>
   )
}

export {Topbar}
