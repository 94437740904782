import {MenuModifier} from './MenuModifier'
import {useEffect, useRef, useState, Dispatch, SetStateAction, FC, memo, useMemo} from 'react'
import {Accordion} from 'react-bootstrap'
import {MenuItemPlaceholderDark, MenuItemPlaceholderLight} from '../../../common/images'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {DerivedItemModGrp} from '../../../services/SelfOrderingDataSlice'
import {useAppAlerts} from '../../utilities/alerts'
import {ItemModifier} from '../../../common/schemas/SyncDataSchema'
import {useSelectedModifiers} from './ItemModifiersProvider'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {constants} from '../../../config/constants'
import {FormattedMessage} from 'react-intl'
import {useMenuSearch} from '../menu_components/MenuSearchProvider'
import Fuse from 'fuse.js'
import { ISelfOrderingCartItemModifier } from '../../../services/SelfOrderingCartSlice'
import { NestedModifierModal } from './NestedModifierModal'

interface ModifierGroupProps {
   title: string
   modInfo: DerivedItemModGrp
   setToShowNestedModifier: Dispatch<SetStateAction<ItemModifier|undefined>>
}

export const ModifierGroupAccordion: FC<ModifierGroupProps> = ({title, modInfo, setToShowNestedModifier}) => {
   let max = modInfo.max && modInfo.max > 0 ? modInfo.max : -1
   const [SelectedModLength, setSelectedModLength] = useState<number>(0)

   return (
      <>
         <Accordion defaultActiveKey={modInfo.name} className='mb-5 my-5 accordion-item-section'>
            <Accordion.Item eventKey={modInfo.name}>
               <Accordion.Header>
                  <h1> {title}</h1>{' '}
                  {SelectedModLength < modInfo.min && modInfo.min == modInfo.max && (
                     <span className={`ms-2 pb-2`}>
                        <i className='bi-exclamation-circle-fill text-danger'></i>
                        <span className='text-danger ms-2'>
                           <FormattedMessage id='olo.select_total_of' defaultMessage='Select a total of' />{' '}
                           {modInfo.min}
                        </span>
                     </span>
                  )}
                  {SelectedModLength < modInfo.min && modInfo.min != modInfo.max && (
                     <span className={`ms-2 pb-2`}>
                        <i className='bi-exclamation-circle-fill text-danger'></i>
                        <span className='text-danger ms-2'>
                           <FormattedMessage id='olo.select_between' defaultMessage='Select between' /> {modInfo.min}{' '}
                           <FormattedMessage id='olo.and' defaultMessage='and' /> {modInfo.max}
                        </span>
                     </span>
                  )}
                  {SelectedModLength > 0 && SelectedModLength >= modInfo.min && (
                     <span className={`ms-2 pb-2`}>
                        <span className='text-success ms-2'>
                           <FormattedMessage id='olo.completed' defaultMessage='Completed' />
                           <i className='bi-check-lg ms-2 text-success fs-3'></i>
                        </span>
                     </span>
                  )}
               </Accordion.Header>
               <Accordion.Body>
                  <ModGroupModifiers modInfo={modInfo} setSelectedModLength={setSelectedModLength} max={max} setToShowNestedModifier={setToShowNestedModifier} />
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </>
   )
}

export interface IModGroupMods {
   modInfo: DerivedItemModGrp
   max: number
   setSelectedModLength: Dispatch<SetStateAction<number>>
   setToShowNestedModifier: Dispatch<SetStateAction<ItemModifier|undefined>>
}

const ModGroupModifiers = memo((props: IModGroupMods) => {
   const {addSelectedModifier, removeSelectedModifier, removeAllNestedModifier, mod_groups} = useSelectedModifiers()
   const modGroup = mod_groups.find((modGroup) => modGroup.modifier_group_id == props.modInfo.modifier_group_id && modGroup.parent_modifier_group_id == props.modInfo.parent_modifier_group_id && modGroup.parent_modifier_id == props.modInfo.parent_modifier_id) 
   const modTotal = modGroup?.selected_modifiers.reduce((total: number, mod: ISelfOrderingCartItemModifier) => {
      return total + mod.quantity
   }, 0)                            
   props.setSelectedModLength(modTotal ?? 0)
   const {fireErrorAlert} = useAppAlerts()
   const {queryValue} = useMenuSearch()

   let memoizedModifiers = useMemo(() => {
      return modGroup?.modifiers
   }, [modGroup?.modifiers])

   const trimmedQuery = queryValue.trim()
   if (trimmedQuery != '' && trimmedQuery.length > 2) {
      const options = {
         keys: ['name'],
         includeScore: false,
         threshold: 0.3,
         location: 0,
         // distance: 100,
         ignoreLocation: true,
         findAllMatches: true,
      }
      if (memoizedModifiers) {
         const fuse = new Fuse(memoizedModifiers, options)
         let results = fuse.search(trimmedQuery)
         memoizedModifiers = []
         results.map((r) => {
            memoizedModifiers?.push(r.item)
         })
      }
   }

   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const APPEARANCE_STATUS = constants.status.location.appearance

   const placeholder =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? MenuItemPlaceholderLight : MenuItemPlaceholderDark

   const getModifierQuantity = (modifier:ItemModifier) => {
      let cartModifier:ISelfOrderingCartItemModifier|undefined = modGroup?.selected_modifiers.find((mod) => mod.id == modifier.id);
      if(modifier._embedded.mod_groups && modifier._embedded.mod_groups.length > 0){
         let groups = mod_groups.filter((group) => group.parent_modifier_id == modifier.id && group.parent_modifier_group_id == modGroup?.modifier_group_id)

         const modTotal = groups.reduce((total, group) => {
            return total + group.selected_modifiers.reduce((groupTotal, mod) => {
               return groupTotal + mod.quantity;
            }, 0);
         }, 0);

         return modTotal ?? 0                       
      }
      return cartModifier ? cartModifier.quantity : 0;
   }
 
   const isSelected = (modifier:ItemModifier) => {
      // Filter all groups that match the criteria
      let groups = mod_groups.filter((group) => group.parent_modifier_id == modifier.id && group.parent_modifier_group_id == modGroup?.modifier_group_id);
         // Check if the total quantity for each group meets the minimum requirement
         const allGroupsMeetMin = groups.every((group) => {
         const modTotal = group.selected_modifiers.reduce((total, mod) => {
            return total + mod.quantity;
         }, 0);
         return modTotal >= group.min;
      });

      return groups.length > 0 && allGroupsMeetMin;
   }
   const hasNestedModifier = (modifier:ItemModifier) => {
      return modifier._embedded?.mod_groups && modifier._embedded.mod_groups.length > 0
   } 
   return (
      <>
         <ul className={modGroup?.parent_modifier_id  ? `` : `ulGrid p-0 mb-0`}>
            {memoizedModifiers
               ?.slice()
               .sort((modA, modB) => (modA.name || '').localeCompare(modB.name || ''))
               .sort((modA, modB) => {
                  const idA = modA.id || 0
                  const idB = modB.id || 0
                        
                  // Check if the IDs are in the included_modifiers array
                  const isIncludedA = modGroup?.included_modifiers?.includes(idA)
                  const isIncludedB = modGroup?.included_modifiers?.includes(idB)

                  // If both are included or both are not included, use regular sorting
                  if (isIncludedA === isIncludedB) {
                     return (modA.name || '').localeCompare(modB.name || '')
                  }

                  // If A is included, it should come before B
                  return isIncludedA ? -1 : 1
               })

               ?.map((modifier, index: number) => (
                  <MenuModifier
                     key={index}
                     id={modifier.id}
                     img={modifier.image??''}
                     name={modifier.name ?? ''}
                     price={modifier.price}
                     quantity={getModifierQuantity(modifier)}
                     isSelected={(modGroup?.selected_modifiers.find((mod) => mod.id == modifier.id) !== undefined) || isSelected(modifier)}
                     onClick={(event, action) => {
                        event.stopPropagation();
                        if (action === 'select') {
                              const modTotal = modGroup!.selected_modifiers.reduce((total: number, mod: ISelfOrderingCartItemModifier) => {
                                 return total + mod.quantity
                              }, 0)                            
                              const canAddModifier =
                                 modGroup!.max <= 0 || modTotal < modGroup!.max
                              if (canAddModifier) {
                                 if(hasNestedModifier(modifier)){
                                    props.setToShowNestedModifier(modifier)
                                 }else{
                                    addSelectedModifier(props.modInfo.modifier_group_id, modifier, props.modInfo.parent_modifier_id, props.modInfo.parent_modifier_group_id)
                                 }
                              } else if (props.max == 1 && modTotal == 1) {
                                 removeSelectedModifier(
                                    props.modInfo.modifier_group_id,
                                    modGroup!.selected_modifiers[0]?.id,
                                    props.modInfo.parent_modifier_id, props.modInfo.parent_modifier_group_id
                                 )
                                 if(hasNestedModifier(modifier)){
                                    props.setToShowNestedModifier(modifier)
                                 }else{
                                    addSelectedModifier(props.modInfo.modifier_group_id, modifier, props.modInfo.parent_modifier_id, props.modInfo.parent_modifier_group_id)
                                 }
                              } else {
                                 fireErrorAlert(`Maximum ${props.max} allow`)
                              }
                           
                        } else {
                           if(hasNestedModifier(modifier)){
                              removeAllNestedModifier(modifier.id, props.modInfo.modifier_group_id)
                           }else{
                              removeSelectedModifier(props.modInfo.modifier_group_id, modifier.id, props.modInfo.parent_modifier_id, props.modInfo.parent_modifier_group_id)
                           }
                        }
                     }
                  }
                  ></MenuModifier>
               ))}
         </ul>
      </>
   )
})
