import {FC, CSSProperties, useState, useEffect} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {constants, KIOSK_GLOBAL_ON} from '../../../config/constants'
import {GetApiUrl, getAuth} from '../../auth'
import {useErrorAlert} from '../../utilities/alerts'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import axios from 'axios'
import {getSelectedLocation} from '../../../services/SelectedLocationSlice'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'
import {parseRequestError} from '../../../common/utilities'
import {StepConfigs} from '../TerminalConfigurationScreen'
import {fetchTerminal} from '../../../services/SelectedTerminalSlice'
import { useKeyboard } from '../../../services/hooks/useKeyboard'
interface CreateTerminalProps {
   handleNextStep: (stepData: StepConfigs) => void
}
//styles for input background in the READ ONLY FIELD
const styles: {[key: string]: CSSProperties} = {
   frostBackground: {
      textAlign: 'center',
      backgroundColor: '#323248',
      border: 'none',
   },
}
export const CreateTerminalStep: FC<CreateTerminalProps> = ({handleNextStep}) => {
   const intl = useIntl()
   const globalDispatch = useDispatch<AppDispatch>()
   const _keyboard = useSelector((state: RootState) => state.keyboard)
   const selectedLocation = useSelector(getSelectedLocation) as SoftPointLocation
   useTranslatedPageTitle(constants.staticPageTitle.create_terminal)
   const fireError = useErrorAlert()
   const {serial_number} = useSelector((state: RootState) => state.tizen_system)
   const [terminalSerialNumber, setTerminalSerialNumber] = useState<string>('')
   const [terminalName, setTerminalName] = useState<string>('')
   const {getInputValue, onChangeInput, setActiveInput} = useKeyboard()
   
   //AUTO FILL CREATE TERMINAL FIELDS
   useEffect(() => {
      if (serial_number !== null && serial_number !== '') {
         setTerminalSerialNumber(serial_number)
      }
   }, [serial_number])

   const SubmitNewTerminal = async () => {
      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${
         selectedLocation.location_id
      }/terminals?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
      const payload = {
         global_manufacturer_terminal_id: 36,
         global_terminal_mode_id: 4,
         global_tip_id: 2,
         serial_number: terminalSerialNumber,
         name: terminalName,
      }
      return await axios.post(url, payload)
   }

   const handleSubmit = () => {
      if (terminalSerialNumber === '') {
         fireError(intl.formatMessage({id: 'kiosk.terminal.enter_serial_number'}))
         return
      }
      if (terminalName === '') {
         fireError(intl.formatMessage({id: 'kiosk.terminal.enter_terminal_name'}))
         return
      }
      SubmitNewTerminal()
         .then((res) => {
            const terminalId = res.data.terminal?.id
            if (Number.isInteger(terminalId)) {
               globalDispatch(fetchTerminal(selectedLocation.location_id, terminalId)).then((result) => {
                  handleNextStep(null)
               })
            }
         })
         .catch((error) => {
            const standardError = parseRequestError(error)
            let errorMessage = standardError.message
            if (standardError.code < 0) {
               errorMessage = intl.formatMessage({id: standardError.message})
            }
            fireError(errorMessage)
         })
   }

   

   useEffect(()=>{
      if(_keyboard.inputName=="field2"){         
         setTerminalSerialNumber(getInputValue('field2').trim())
      }
   },[getInputValue('field2')])
   useEffect(()=>{      
      if(_keyboard.inputName=="field3"){
         setTerminalName(getInputValue('field3'))
      }
   },[getInputValue('field3')])

   return (
      <div className='d-flex justify-content-center p-8'>
         <div className='w-100 pt-5'>
            <div className='card card-stretch card-bordered px-8 flex-height-kiosk'>
               <div className='py-6'>
                  <h1 className='card-title fs-2tx'>
                     <FormattedMessage id='pagetitle.create_terminal' defaultMessage='Create Terminal' />
                  </h1>
                  <span className='text-gray-700 fs-2tx'>
                     <FormattedMessage
                        id='kiosk.step3.enter_terminal_info'
                        defaultMessage='Enter terminal information'
                     />
                     .
                  </span>
               </div>
               <div className='col'>
                  <div className='d-flex align-items-center'>
                     {/* READ ONLY FIELD */}
                     <input
                        style={styles.frostBackground}
                        className='form-control form-control-solid fs-2tx py-8 text-white'
                        type='text'
                        name='field1'
                        value={selectedLocation.name ?? ''}
                        id='field1'                        
                        placeholder={selectedLocation.name ?? ''}
                        readOnly
                     />
                  </div>
                  {/* EDITABLE FIELD */}
                  <div className='pt-5'>
                     <label className='form-label fs-2tx'>
                        <FormattedMessage id='kiosk.step3.serial_number' defaultMessage='Serial Number' />
                     </label>
                     <input
                        readOnly={true}
                        className={`form-control form-control-solid ps-3 fs-2tx py-8 text-white keyboard-input ${ _keyboard.inputName =="field2" && _keyboard.open?'keyboard-active':''} `}
                        type='text'
                        name='field2'
                        id='field2'
                        onFocus={() => setActiveInput('field2')}
                        value={terminalSerialNumber}
                        onChange={(e) => {
                           onChangeInput(e)
                        }}
                        placeholder={intl.formatMessage({id: 'kiosk.step3.serial_number'})}
                     />
                  </div>
                  {/* EDITABLE FIELD */}
                  <div className='pt-5'>
                     <label className='form-label fs-2tx'>
                        <FormattedMessage id='kiosk.step3.terminal_name' defaultMessage='Terminal Name' />
                     </label>
                     <input
                        readOnly={true}
                        className={`form-control form-control-solid ps-3 fs-2tx py-8 text-white ${ _keyboard.inputName =="field3" && _keyboard.open?'keyboard-active':''}`}
                        type='text'
                        name='field3'
                        id='field3'
                        value={terminalName}
                        onFocus={() => setActiveInput('field3')}
                        onChange={(e) => {
                           onChangeInput(e)                           
                        }}
                        placeholder={intl.formatMessage({id: 'kiosk.step3.terminal_name'})}
                     />
                  </div>
               </div>
               <div className='my-5 w-100'>
                  <button
                     onClick={handleSubmit}
                     type='button'
                     className='btn btn-lg btn-theme-primary w-100 py-8'
                     data-kt-stepper-action='next'
                  >
                     <span className='fs-2tx'>
                        <FormattedMessage id='kiosk.next_step' defaultMessage='Next Step' />
                     </span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   )
}
