export const camFeedStyles = {
   width: '100%',
   height: '100%',
}
export const buttonStyles = {
   backgroundColor: '#3398fa',
   width: '65%',
   height: '100%',
}
export const buttonStylesFull = {
   backgroundColor: '#3398fa',
   width: '95%',
   height: '100%',
   fontSize: '40px',
}
export const camFeedHolderStyles = {
   width: '100%',
   height: '60vh',
   background: 'gray',
   opacity: '.4',
}
