import {IBaseRequest} from '../../../common/api_interfaces/base'
import axios, {AxiosResponse} from 'axios'
import {GetApiUrl} from '../../auth'

export interface INextReservationResponse {
   id: number
   location_id: number
   status: number
   check_in: string
   check_out: null | string
   local_check_in: string
   local_check_out: string | null
   global_reservation_type_id: number | null
   pre_registration: number
   confirmation_number: string | null
   room_id: null | number
   rooms_type_id: null | number
   table_id: null | number
   revenue_center_id: null | number
   guest_count: number
   created_at: string | null
   _embedded: null | {
      guests?: IReservationGuests[] | null
      room?: {
         id: number
         location_id: number
         name: string | null
         number: number | null
         guests: number | null
      } | null
      room_type?: {
         id: number
         name: string | null
         guest: number
         description: string | null
      } | null
      revenue_center?: {
         id: number
         name: string | null
         seats: number | null
      } | null
      table?: {
         id: number
         location_id: number
         name: string | null
         number: number | null
         seats: number | null
      } | null
   }
}

export interface IGuestReservation extends INextReservationResponse {
   guest_id: number
}

export interface IReservationGuests {
   guest_id: number
   guest: string | null
   email: string | null
   phone: string
   global_country_id: number
   primary: number
   status_pre_checkin: number
   status_checkin: number
}

export interface IReservationReceipt {
   location_name: string
   guest_name: string
   reservation_code: string | null
   check_in: string
   check_out: null | string
   guest_count: number
}

export interface INextReservationRequest extends IBaseRequest {
   location_id: number | string
   guest_id: number | string
   with: string
}

export const fetchNextReservation = (payload: INextReservationRequest): Promise<AxiosResponse<any, any>> => {
   const url = `${GetApiUrl()}/locations/${payload.location_id}/guests/${payload.guest_id}/next_reservation`
   return axios.get(url, {params: payload})
}
