import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {getSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {GetApiUrl, getAuth} from '../../auth'
import {KIOSK_GLOBAL_ON} from '../../../config/constants'
import axios from 'axios'
import {
   CreateTicketResponse,
   CreateTicketSchema,
   IGuestData,
   PayWithCcResponse,
   TicketRequest,
} from '../models/use_pay_models'
import {ILoyaltyData} from './usePay'
import {generateUniqueId} from '../../../common/utilities'
import {TizenDebug} from '../../hotel/FaceAuth/TizenIntegration'
import {ISelfOrderingCartItem} from '../../../services/SelfOrderingCartSlice'

type flow = 'checkin' | 'self_ordering'

type OpenTicketRequest = {
   flow?: flow
   user_id: number
   employee_id: number
   global_on_id: number
   ticket: TicketRequest
   terminal_id: number
}

type CreateTicketRequest = {
   payment_id: number
   close_ticket: boolean
   loyalty?: {
      card_number: number
      amount: number
   }
} & OpenTicketRequest

export type CreateTicket = {user_data: IGuestData; loyalty_data?: ILoyaltyData; payment: {id: number}}

type OpenTicketContextArg = {
   flow: flow
   guest_id?: number
}
export type OpenTicketArgs = {
   ticketName: string
   guestCount: number
   guestPhone?: string
   context: OpenTicketContextArg
}

export const useTicketService = (cartItems: ISelfOrderingCartItem[] | []) => {
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const SelectedTerminal = useSelector(getSelectedTerminal) as Terminal
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const order_type = useSelector((state: RootState) => state.self_ordering_selections.order_type)
   const getOrderMetaData = () => {
      let employee_id = kiosk_settings.ordering_employee ?? 0
      let employee_external_id = kiosk_settings.ordering_employee_external_id
         ? kiosk_settings.ordering_employee_external_id
         : ''

      let order_type_id = kiosk_settings.order_type_id ?? 0
      let order_type_id_external = kiosk_settings.order_type_external_id ? kiosk_settings.order_type_external_id : ''
      if (order_type == 'dinein') {
         order_type_id = kiosk_settings.dine_in ?? 0
         order_type_id_external = kiosk_settings.order_type_dine_in_external_id
            ? kiosk_settings.order_type_dine_in_external_id
            : ''
      }
      let revenue_center_id = SelectedTerminal.revenue_center ?? 0
      let db_revenue_center = selfOrderData.Data_RevenueCenters.find(
         (revenueCenter) => revenueCenter.id == revenue_center_id
      )
      let revenue_center_id_external = db_revenue_center?.id_external ?? ''
      return {
         employee_id,
         employee_external_id,
         order_type_id,
         order_type_id_external,
         revenue_center_id,
         revenue_center_id_external,
      }
   }

   const CreateTicketWithProcessedPayment = async ({user_data, loyalty_data, payment}: CreateTicket) => {
      let user = getAuth()
      const user_id = user!.user_id
      const orderMetaData = getOrderMetaData()
      let ticketRequest: CreateTicketRequest = {
         user_id: user_id,
         employee_id: orderMetaData.employee_id,
         global_on_id: parseInt(KIOSK_GLOBAL_ON),
         terminal_id: SelectedTerminal.id,
         ticket: {
            terminal_id: SelectedTerminal.id,
            employee_id: orderMetaData.employee_id,
            employee_id_external: orderMetaData.employee_external_id,
            guest_count: 1,
            inclusive_tax: 0,
            name: user_data.name,
            phone: user_data.phone,
            order_type_id: orderMetaData.order_type_id,
            order_type_id_external: orderMetaData.order_type_id_external,
            revenue_center_id: orderMetaData.revenue_center_id,
            revenue_center_id_external: orderMetaData.revenue_center_id_external,
            ticket_number: generateUniqueId(),
            items: cartItems,
            sub_total: cartTotals.sub_total,
            tax: cartTotals.tax,
            total: cartTotals.total,
         },
         close_ticket: kiosk_settings.auto_close_ticket == 1,
         payment_id: payment.id,
      }
      if (loyalty_data) {
         ticketRequest.loyalty = {
            card_number: parseInt(loyalty_data.card_number),
            amount: loyalty_data.amount_to_redeem,
         }
      }
      const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/tickets`
      const ticketResponse = await axios.post(url, ticketRequest)
      const ticket = CreateTicketSchema.validateSync(ticketResponse.data) as CreateTicketResponse
      return {
         ...ticket,
         posted_payment: {
            id: payment.id,
         },
      } as PayWithCcResponse
   }

   /**
    * Creates an empty ticket
    */
   const OpenTicket = async ({ticketName, guestPhone, guestCount, context}: OpenTicketArgs) => {
      const user = getAuth()
      const user_id = user!.user_id
      const orderMetaData = getOrderMetaData()
      const ticketRequest: OpenTicketRequest = {
         flow: context.flow,
         user_id: user_id,
         employee_id: orderMetaData.employee_id,
         global_on_id: parseInt(KIOSK_GLOBAL_ON),
         terminal_id: SelectedTerminal.id,
         ticket: {
            terminal_id: SelectedTerminal.id,
            employee_id: orderMetaData.employee_id,
            employee_id_external: orderMetaData.employee_external_id,
            guest_count: guestCount,
            inclusive_tax: 0,
            name: ticketName,
            phone: guestPhone,
            order_type_id: orderMetaData.order_type_id,
            order_type_id_external: orderMetaData.order_type_id_external,
            revenue_center_id: orderMetaData.revenue_center_id,
            revenue_center_id_external: orderMetaData.revenue_center_id_external,
            ticket_number: generateUniqueId(),
            items: [],
            sub_total: 0,
            tax: 0,
            total: 0,
         },
      }

      if (context.guest_id) ticketRequest.ticket.guest_id = context.guest_id

      const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/tickets`
      const ticketResponse = await axios.post(url, ticketRequest)
      TizenDebug(`[OpenTicketResponse] ${JSON.stringify(ticketResponse.data)}`)
      return CreateTicketSchema.validateSync(ticketResponse.data) as CreateTicketResponse
   }

   return {
      CreateTicketWithProcessedPayment,
      OpenTicket,
   }
}
