import {MenuImage} from './MenuImage'
import {MenuItemUI} from '../../../services/SelfOrderingDataSlice'

export type MenuItemProps = MenuItemUI & {onSelect: (itemId: Number, categoryId: Number) => void}

export const MenuItem = ({id, display_name, price_per_unit, image, categoryId, onSelect}: MenuItemProps) => {
   return (
      <div
         className='card card-flush flex-row-fluid  mw-100 main-menu-item-div'
         onClick={() => {
            onSelect(id, categoryId)
         }}
      >
         <div className='card-body text-center d-flex flex-column mx-auto '>
            <MenuImage src={image} className='rounded-3 w-150px h-150px w-xxl-250px h-xxl-250px mx-auto' />
            <div className='justify-content-center'>
               <div className='product-text justify-content-center  d-flex align-items-center'>
                  <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-1 fs-xl-1'>
                     {display_name.substring(0, 30)}
                  </span>
               </div>
            </div>
            <span className='text-success fw-bold fs-2tx mb-1'>{`$${(price_per_unit / 100).toFixed(2)}`}</span>
         </div>
      </div>
   )
}
