import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {AppModeScreen} from '../modules/AppDirectory/AppModeScreen'
import {Settings} from '../modules/KioskSettings/Settings/Settings'
import {CartProvider} from '../services/hooks/useCart'
import {SelfOrderingRoutes} from './SelfOrderingRoutes'
import {FaceRecognitionMonitor} from '../modules/facial_recognition_services/FaceRecognitionMonitor'
import {HotelRoutes} from './HotelRoutes'
import OfflinePage from '../modules/errors/OfflinePage'

export const PublicRoutes = () => {
   return (
      <Routes>
         <Route element={<MasterLayout />}>
            <Route path='dashboard' element={<AppModeScreen />} />
            <Route path='settings' element={<Settings />} />
            <Route path='offline' element={<OfflinePage />} />
         </Route>
         <Route
            path='self_ordering/*'
            element={
               <CartProvider>
                  <SelfOrderingRoutes />
               </CartProvider>
            }
         />
         <Route
            path='checkin/*'
            element={
               <FaceRecognitionMonitor>
                  <HotelRoutes />
               </FaceRecognitionMonitor>
            }
         />
      </Routes>
   )
}
