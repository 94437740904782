import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import './SettingsStyle.css'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {Tizen} from '../../../common/tizen/tizen_services'
import {useLoadingAlert, useWarningConfirmAlert, useErrorAlert} from '../../utilities/alerts'
import Swal from 'sweetalert2'
import {FormattedMessage, useIntl} from 'react-intl'
import {Terminal} from '../../../common/schemas/TerminalSchema'

interface DeviceModalProps {
   toggleModal: () => void
   showChangeIpModal: () => void
}

export const DeviceDetailsModal: FC<DeviceModalProps> = ({toggleModal, showChangeIpModal}) => {
   const intl = useIntl()
   const BtnClrClass = useLocationBasedBtnColor()
   const fireLoadingAlert = useLoadingAlert()
   const fireConfirmAlert = useWarningConfirmAlert()
   const fireErrorAlert = useErrorAlert()

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
   }
   const closeBtnStyle = {
      position: 'absolute',
      top: '1rem',
      right: 0,
   } as React.CSSProperties

   const terminal_data = useSelector((state: RootState) => state.selected_terminal.value) as Terminal

   const handleApplicationUpdate = () => {
      fireConfirmAlert(
         `${intl.formatMessage({
            id: 'setting.device_detail.alert.updating_application_cause_reboot',
         })}.<br><br>${intl.formatMessage({id: 'setting.device_detail.alert.are_you_want_to_update'})}`
      ).then((result) => {
         if (result.isConfirmed) {
            toggleModal()
            fireLoadingAlert(intl.formatMessage({id: 'setting.device_detail.alert.updating_application'}))
            window.setTimeout(() => {
               Swal.close()
               fireErrorAlert(intl.formatMessage({id: 'setting.device_detail.alert.error_updating_application'})).then(
                  (result) => {}
               )
            }, 120000)
            Tizen.updateApplication()
         }
      })
   }

   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog-centered h-auto mw-50 px-10'
         show={true}
         onHide={toggleModal}
      >
         <div className='container-xxl px-10 py-5'>
            <div className='modal-dialog mw-100 p-0 m-0'>
               <div className='modal-content'>
                  <div className='text-end'>
                     <div
                        aria-label='Close'
                        data-bs-dismiss='modal'
                        onClick={toggleModal}
                        className='btn btn-icon btn-lg btn-active-light-primary'
                        style={closeBtnStyle}
                     >
                        <i className='bi bi-x-lg fs-2x svg-icon'></i>
                     </div>
                  </div>
                  {/* START::MODAL-BODY */}
                  <div className='tab-content'>
                     <div className=' h-xl-100 pb-10 pt-5'>
                        <div className='row theme-background py-3'>
                           <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12 mb-xl-12'>
                              <h1 className='fs-1'>
                                 <FormattedMessage
                                    id='setting.device_detail.terminal_details'
                                    defaultMessage='Terminal Details'
                                 />
                              </h1>
                              <p className='fs-3'></p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.device_detail.version' defaultMessage='Version' />
                              </h1>
                              <p className='fs-3'>{process.env.REACT_APP_VERSION ?? ''}</p>
                           </div>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <p className='fs-3'>
                                 <button
                                    type='button'
                                    style={buttonStyle}
                                    className={`btn btn-primary fs-5 ms-2 w-100 ${BtnClrClass}`}
                                    onClick={handleApplicationUpdate}
                                 >
                                    <FormattedMessage
                                       id='setting.device_detail.check_for_update'
                                       defaultMessage='Check For Update'
                                    />
                                 </button>
                              </p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage
                                    id='setting.device_detail.terminal_model'
                                    defaultMessage='Terminal Model'
                                 />
                              </h1>
                              <p className='fs-3'>{terminal_data.global_manufacturer_terminal}</p>
                           </div>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.device_detail.serial' defaultMessage='Serial #' />
                              </h1>
                              <p className='fs-3'>{terminal_data.serial_number}</p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage
                                    id='setting.device_detail.camera_ip'
                                    defaultMessage='Camera IP Address'
                                 />
                              </h1>
                              <p className='fs-3'>{terminal_data.local_echo_ip}</p>
                           </div>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'></h1>
                              <button
                                 type='button'
                                 style={buttonStyle}
                                 onClick={showChangeIpModal}
                                 className={`btn btn-primary fs-5 ms-2 w-100  ${BtnClrClass}`}
                              >
                                 <FormattedMessage
                                    id='setting.device_detail.change_camera_ip'
                                    defaultMessage='Change Camera IP'
                                 />
                              </button>
                           </div>
                        </div>

                        <div className='d-flex row justify-content-center'>
                           <button
                              type='button'
                              style={buttonStyle}
                              onClick={toggleModal}
                              className={`btn btn-bg-primary ms-2 w-100 ${BtnClrClass}`}
                           >
                              <FormattedMessage id='alert.ok_got_it' defaultMessage='OK, got it!' />
                           </button>
                        </div>
                     </div>
                  </div>
                  {/* END::MODAL-BODY */}
               </div>
            </div>
         </div>
      </Modal>
   )
}
