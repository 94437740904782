import {Dispatch, SetStateAction, useEffect, useState, FC, MouseEvent} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {KioskTerminalLocation} from '../KioskTerminalLocation'
import {useDispatch, useSelector} from 'react-redux'
import {StoreKioskData} from '../../../services/KioskSettingSlice'
import {getAuth} from '../../auth'
import {StoreHeaderPageTitle} from '../../../services/HeaderPageTitleSlice'
import {constants} from '../../../config/constants'
import {LocationProduct, SoftPointLocation} from '../../../common/schemas/LocationSchema'
import {fetchKioskSettings} from '../../../common/apis/kiosk_settings_request'
import {parseRequestError} from '../../../common/utilities'
import {useErrorAlert} from '../../utilities/alerts'
import {useNavigate} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'
import {useInternationalization} from '../../i18n/i18nProvider'
import {AppDispatch, RootState} from '../../../store'
import {getSelectedLocation, StoreSelectedLocation} from '../../../services/SelectedLocationSlice'
import {StepConfigs} from '../TerminalConfigurationScreen'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import { useKeyboard } from '../../../services/hooks/useKeyboard'

interface SelectLocationProps {
   handleNextStep: (stepData: StepConfigs) => void
}
export const LocationSelectionStep: FC<SelectLocationProps> = ({handleNextStep}) => {
   const intl = useIntl()
   const dispatch = useDispatch<AppDispatch>()
   const terminalLocations = useSelector((state: RootState) => state.auth_locations.value)
   const selectedLocation = useSelector(getSelectedLocation)
   let filteredLocationList: {[k: number]: any} = {}
   let arrayFilteredLocationList: Array<SoftPointLocation> = []
   let comparedLocations: {[k: string]: string} = {}
   const [ShowLocations, setShowLocations] = useState(arrayFilteredLocationList)
   const listLocationProducts: {locationID: number; embeddedLocationProducts: LocationProduct[]}[] = []
   const fireErrorAlert = useErrorAlert()
   const navigateTo = useNavigate()
   const {setLanguage, selectedLang} = useInternationalization()
   const {getInputValue, onChangeInput, setActiveInput} = useKeyboard()
   const _keyboard = useSelector((state: RootState) => state.keyboard)

   for (let location of terminalLocations) {
      let embeddedLocationProducts = location._embedded.location_products
      let locationID = location.location_id
      let locationObj = {
         locationID,
         embeddedLocationProducts,
      }
      listLocationProducts.push(locationObj)
   }

   if (listLocationProducts.length > 0) {
      let locationProductsID: string = ''

      listLocationProducts.map((locationProducts, index) => {
         locationProductsID = locationProducts.locationID.toString()
         if (
            locationProducts.embeddedLocationProducts[1].status == 1 ||
            (locationProducts.embeddedLocationProducts[5].status == 1 &&
               locationProducts.embeddedLocationProducts[1].status == 1)
         ) {
            comparedLocations[locationProductsID] = locationProductsID
         }
      })
   }

   const compareActiveKioskAndCheckinLocations = () => {
      terminalLocations.forEach((location, index) => {
         //console.log(location.location_id === comparedLocations[location.location_id], location)
         if (location.location_id.toString() === comparedLocations[location.location_id.toString()]) {
            filteredLocationList[index] = location
            arrayFilteredLocationList.push(location)
         }
      })
      if (ShowLocations.length == 0) {
         setShowLocations(arrayFilteredLocationList)
      }
      return
   }
   compareActiveKioskAndCheckinLocations()

   useEffect(() => {
      dispatch(StoreHeaderPageTitle(intl.formatMessage({id: constants.staticPageTitle.select_location})))
   }, [selectedLang])

   // handles selected terminal and its kiosk settings
   const HandleRowClick = (locationId: number, name: string) => {
      const location = terminalLocations.find((location: SoftPointLocation) => location.location_id === locationId)
      if (location) {
         dispatch(StoreSelectedLocation(location))
      }
      KioskSettings(locationId)
   }

   const KioskSettings = async (location_id: number) => {
      let user = getAuth()
      const user_id = user!.user_id
      fetchKioskSettings(location_id, user_id)
         .then((response) => {
            setLanguage(response._embedded.location_info.locale)
            dispatch(StoreKioskData(response))
            localStorage.setItem('kiosk_setting', JSON.stringify(response))
         })
         .catch((error) => {
            dispatch(StoreKioskData(null))
            localStorage.setItem('kiosk_setting', '')
            const standardError = parseRequestError(error)
            let translatedMessage = standardError.message
            if (standardError.code < 0) {
               translatedMessage = intl.formatMessage({id: standardError.message})
            }
            fireErrorAlert(translatedMessage).then((result) => {
               if (result.isConfirmed || result.isDismissed) {
                  navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
               }
            })
         })
   }

   useEffect(() => {
      if (getInputValue('search') !== '') {
         let sval = getInputValue('search').toLowerCase()
         let matches = arrayFilteredLocationList?.filter(function (s: any) {
            return (
               s.location_id > 0 &&
               (s.name?.toLowerCase().indexOf(sval) >= 0 || s.location_id.toString().indexOf(sval) >= 0)
            )
         })
         setShowLocations(matches)
      } else {
         setShowLocations(arrayFilteredLocationList)
      }
   }, [getInputValue('search')])

   return (
      <div className='d-flex justify-content-center p-8'>
         <div className='w-100 pt-5'>
            <div className='card card-stretch card-bordered px-8 flex-height-kiosk'>
               <div className='py-6'>
                  <h1 className='card-title fs-2tx'>
                     {intl.formatMessage({id: constants.staticPageTitle.select_location})}
                  </h1>
                  <span className='text-gray-700 fs-2tx'>
                     <FormattedMessage
                        id='kiosk.step1.select_location_to_associate_terminal'
                        defaultMessage='Select the location to associate terminal to.'
                     />
                  </span>
                  <div className={`input-group input-group-lg keyboard-input ${_keyboard.inputName=='search' && _keyboard.open?'keyboard-active':''} `}>
                     <span style={{border: 'none'}} className='input-group-text' id='inputGroup-sizing-lg'>
                        <KTSVG
                           path='/media/icons/duotune/general/gen021.svg'
                           className='svg-icon-4x svg-icon d-block ms-10 py-2'
                        />
                     </span>
                     <input
                        style={{border: 'none'}}
                        type='text'
                        name='search'
                        id='searchTerminal'
                        placeholder={intl.formatMessage({id: 'kiosk.step1.search'})}
                        className={`form-control form-control-solid ps-9 fs-2tx py-8 keyboard-input`}
                        aria-describedby='inputGroup-sizing-lg'
                        value={getInputValue('search')}
                        onFocus={() => setActiveInput('search')}
                        onChange={onChangeInput}
                        readOnly={true}
                     />
                  </div>
               </div>
               <div style={{overflow: 'auto', maxHeight: '1340px'}} className='col'>
                  {ShowLocations.map((location, i) => {
                     return (
                        <KioskTerminalLocation
                           key={i}
                           name={`${location.name} (ID: ${location.location_id})`}
                           value={location.location_id}
                           POS={location.pos_name}
                           logo={location.logo ?? ''}
                           HandleRowClick={HandleRowClick}
                           selectedTerminalLocation={selectedLocation?.location_id ?? 0}
                        />
                     )
                  })}
               </div>
               <div className='my-5 w-100'>
                  <button
                     onClick={() => handleNextStep(null)}
                     type='button'
                     className='btn btn-lg w-100 mb-5 fs-2tx py-8 btn-theme-primary'
                     data-kt-stepper-action='next'
                  >
                     <span className='fs-2tx'>
                        <FormattedMessage id='kiosk.next_step' defaultMessage='Next Step' />
                     </span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   )
}
