/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthScreen`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {SelfOrderingRoutes} from './SelfOrderingRoutes'
import {AuthScreen} from '../modules/auth'
import {App} from '../App'
import SplashScreen from '../modules/IdleMode/SplashScreen'
import {HotelRoutes} from './HotelRoutes'
import {AppModeScreen} from '../modules/AppDirectory/AppModeScreen'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Settings} from '../modules/KioskSettings/Settings/Settings'
import {useDispatch} from 'react-redux'
import {
   ITizenNetworkStatus,
   setAlertDisplayed,
   setSystemSettings,
   updateNetworkStatus,
} from '../services/TizenSystemSlice'
import {TizenSystem} from '../common/tizen/helpers'
import {TizenDebug} from '../modules/hotel/FaceAuth/TizenIntegration'
import {refreshToken} from '../modules/auth/core/_requests'
import {FaceRecognitionMonitor} from '../modules/facial_recognition_services/FaceRecognitionMonitor'
import {AutoLoginController} from '../modules/auth/AutoLoginController'
import {AutoLoginProvider} from '../modules/auth/AutoLoginProvider'
import {CartProvider} from '../services/hooks/useCart'
import {TerminalConfigurationScreen} from '../modules/KioskSettings/TerminalConfigurationScreen'
import {PublicRoutes} from './PublicRoutes'
import {QuickPointProvider} from '../common/quick_point/QuickPointProvider'

const AppRoutes: FC = () => {
   const dispatch = useDispatch()

   const onChange = function (netState: any) {

      //@ts-ignore
      var activeConnectionType = webapis.network.getActiveConnectionType();
      TizenDebug(`[activeConnectionType]: ${activeConnectionType}`)

      try {
         const onlineStatus: ITizenNetworkStatus | null = TizenSystem.getNetworkStatus()
         TizenDebug(`onlineStatus = ${onlineStatus ? JSON.stringify(onlineStatus) : 'null'}`)
         dispatch(updateNetworkStatus(onlineStatus))
         dispatch(setAlertDisplayed(false))
      } catch (e: any) {
         TizenDebug('From Tizen OS')
         TizenDebug('An error occurred while retrieving the new network status')
         TizenDebug(`[Error code] ${e.code}`)
         TizenDebug(`[Message] = ${e.message}`)
      }
   }

   useEffect(() => {
      try {
         //@ts-ignore
         webapis.network.addNetworkStateChangeListener(onChange)
      } catch (e: any) {
         TizenDebug('addNetworkStateChangeListener exception [' + e.code + '] name: ' + e.name + ' message: ' + e.message)
      }

      const tizenSettings = TizenSystem.getSystemSettings()
      dispatch(setSystemSettings(tizenSettings))
   }, [])

   //REFRESH TOKEN HOOK
   useEffect(() => {
      const timeoutId = setInterval(() => {
         refreshToken()
      }, 300000)
      return () => clearInterval(timeoutId)
   }, [])

   return (
      <BrowserRouter>
         <Routes>
            <Route element={<App />}>
               <Route
                  index
                  element={
                     <AutoLoginProvider>
                        <AutoLoginController />
                     </AutoLoginProvider>
                  }
               />
               <Route
                  path='index.html'
                  element={
                     <AutoLoginProvider>
                        <AutoLoginController />
                     </AutoLoginProvider>
                  }
               />
               <Route path='login/*' element={<AuthScreen />} />
               <Route path='idle' element={<SplashScreen />} />
               <Route path='kiosk_terminal' element={<TerminalConfigurationScreen />} />
               <Route
                  path='app/*'
                  element={
                     <QuickPointProvider>
                        <PublicRoutes />
                     </QuickPointProvider>
                  }
               />
               <Route path='*' element={<AuthScreen />} />
            </Route>
         </Routes>
      </BrowserRouter>
   )
}

export {AppRoutes}
