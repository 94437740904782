/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useLocation, useMatch} from 'react-router-dom'
import {SettingModal} from '../../../app/modules/KioskSettings/Settings/SettingModal'
import {toAbsoluteUrl} from '../../helpers'
import {useLayout} from '../core'
import {clientIsTizen} from '../../../app/common/tizen/helpers'
import {Tizen} from '../../../app/common/tizen/tizen_services'
import { FormattedMessage } from 'react-intl'
import {MODULE_ROUTE_PATHS} from '../../../app/routing/RoutePaths'
import {RootState} from '../../../app/store'
import {useSelector} from 'react-redux'
import { useCacheImg } from '../../../app/common/hooks/useCacheImg'


const Footer: FC = () => {
   const LOGIN_ROUTE_PATTER = MODULE_ROUTE_PATHS.app_auth.index
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   const location = useLocation()
   const routeLocation = useLocation()
   const loginPathMatch = useMatch(LOGIN_ROUTE_PATTER)
   const offlinePathMatch = useMatch(MODULE_ROUTE_PATHS.offline.index)
   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/SP_LOGO_BLACK.webp'
         : '../media/logos/SP_LOGO_WHITE.webp'

   let holdDelay = 3000
   let isHold = false
   const [showSettingModal, setShowSettingModal] = useState<boolean>(false)

   let activeHoldTimeoutId: any = null

   const toggleSettingModal = () => {
      if (!tizenSystem.network.available && clientIsTizen()) {
         console.log('network not available');
      }else{
         setShowSettingModal(!showSettingModal)
      }
   }

   const mouseDown = () => {
      if (location.pathname !== '/settings') {
         activeHoldTimeoutId = setTimeout(() => {
            isHold = true
            if (isHold) {
               toggleSettingModal()
            }
         }, holdDelay)
      }
   }

   const mouseUp = () => {
      if (location.pathname !== '/settings') {
         isHold = false

         clearTimeout(activeHoldTimeoutId)
      }
   }
   const {cachedImage} = useCacheImg({imageUrl: null, placeholderUrl: toAbsoluteUrl(logoPath)})
   return (
      <div style={{backgroundColor: 'inherit'}} className='footer d-flex flex-lg-column' id='kt_footer'>
         <div className='row px-8'>
            <div className='fs-3 text-dark col d-flex align-items-center'><FormattedMessage id='footer.version' defaultMessage='Version'/>: {process.env.REACT_APP_VERSION}</div>
            <div className='col d-flex flex-wrap justify-content-center'>
               {!loginPathMatch && !offlinePathMatch && (
                  <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
                     <li
                        className='menu-item'
                        onMouseDown={mouseDown}
                        onMouseUp={mouseUp}
                        onTouchStart={mouseDown}
                        onTouchEnd={mouseUp}
                     >
                        <h1>
                        <FormattedMessage id='footer.poweredby' defaultMessage='Powered By'/>{' '}
                           <img
                              style={{imageRendering: 'auto'}}
                              src={cachedImage}
                              className='w-100px h-35px'
                              alt=''
                           />
                        </h1>
                     </li>
                  </ul>
               )}
            </div>
            <div className='col'></div>
         </div>
         {showSettingModal ? <SettingModal toggleSettingModal={toggleSettingModal} /> : <></>}
      </div>
   )
}

export {Footer}
