import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {constants, KIOSK_GLOBAL_ON} from '../../config/constants'
import {useEffect} from 'react'
import Swal from 'sweetalert2'
import {setFlow} from '../../services/FlowSlice'
import {GetApiUrl, getAuth} from '../auth'
import moment from 'moment'
import axios from 'axios'
import {StoreKioskData} from '../../services/KioskSettingSlice'
import {useErrorAlert} from '../utilities/alerts'
import {RootState} from '../../store'
import {integrationHasKiosk, parseRequestError} from '../../common/utilities'
import {KioskSettings, KioskSettingsSchema} from '../../common/schemas/KioskSettingsSchema'
import {FormattedMessage, useIntl} from 'react-intl'
import {useInternationalization} from '../i18n/i18nProvider'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import {getCheckInPaths, getOrderingPaths} from '../../routing/RoutePaths'
import {clientIsTizen} from '../../common/tizen/helpers'

export const AppModeScreen = () => {
   const intl = useIntl()
   let navigateTo = useNavigate()
   const {setLanguage} = useInternationalization()
   const fireError = useErrorAlert()
   const dispatch = useDispatch<any>()
   const settings = useSelector((state: RootState) => state.kiosk_settings)
   const orderingIntegration = settings.derived_values.selfOrderingIntegration
   let kiosk_settings = settings.value as KioskSettings
   const selectedLocation = useSelector((state: RootState) => state.location_data)
   const locationProductStatuses = selectedLocation.derived_values.product_statuses
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   let location_data = selectedLocation.value
   let check_in_len: number | undefined = 0
   const selfOrderingEnabled = locationProductStatuses.selfOrderingEnabled && integrationHasKiosk(orderingIntegration)
   const orderingPaths = getOrderingPaths()
   const checkInPaths = getCheckInPaths()
   if (!location_data || Object.keys(location_data).length == 0) {
      location_data = localStorage.getItem('location_data')
         ? JSON.parse(localStorage.getItem('location_data') as any)
         : null
   }
   check_in_len = location_data?._embedded?.location_products?.filter((e) => {
      return e.name == 'Check In' && e.status == 1
   }).length

   if (check_in_len === 0 && !selfOrderingEnabled) {
      fireError(intl.formatMessage({id: 'appmode.you_do_not_have_access'}))
   }

   useTranslatedPageTitle(constants.staticPageTitle.dashboard)
   
   useEffect(() => {
      if (!tizenSystem.network.available && clientIsTizen()) {
         console.log('network not available');
      }else{
         if (check_in_len == 0 || kiosk_settings?._embedded?.locations_config_contactless?.status == 0) {
            if (selfOrderingEnabled && kiosk_settings?.status == 1) {
               dispatch(setFlow(constants.flows.ordering))
               checkLatestKioskSettingsAndNavigateToPath(orderingPaths.ORDER_HOME)
            }
         }      
      }
   }, [])

   const showPayLoader = () => {
      Swal.fire({
         html: `<h1 class="swal2-html-container fs-2x mx-0">${intl.formatMessage({id: 'appmode.loading'})}</h1>`,
         didOpen(popup: HTMLElement) {
            Swal.showLoading()
         },
      })
   }
   const checkLatestKioskSettingsAndNavigateToPath = async (path: any) => {
      let user = getAuth()
      const token = user?.api_token
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${
         kiosk_settings.location_id
      }/settings/kiosk?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
      const bearerToken = `Bearer ${token}`
      const data: any = {
         token: user?.api_token,
         url: url,
      }
      // const result = dispatch()
      await axios
         .get(`${data.url}`, {
            headers: {
               Authorization: `Basic ${data.token}`,
            },
         })
         .then((response) => {
            Swal.close()
            const cleanData = KioskSettingsSchema.validateSync(response.data)
            setLanguage(cleanData._embedded.location_info.locale)
            dispatch(StoreKioskData(cleanData))
            localStorage.setItem('kiosk_setting', JSON.stringify(cleanData))
            NavigationCheck(path, cleanData)
         })
         .catch((error) => {
            Swal.close()
            const standardError = parseRequestError(error)
            if (standardError.code != -2) {
               NavigationCheck(path, kiosk_settings)
            }
            fireError(intl.formatMessage({id: standardError.message}))
         })
   }

   function NavigationCheck(path: any, kiosk_settings: any) {
      if (path == checkInPaths.check_in_home && kiosk_settings?._embedded?.locations_config_contactless?.status == 0) {
         fireError(intl.formatMessage({id: 'appmode.alert.service_unavailable'}))
      } else if (path == orderingPaths.ORDER_HOME && kiosk_settings?.status == 0) {
         fireError(intl.formatMessage({id: 'appmode.alert.service_unavailable'}))
      } else {
         navigateTo(path)
      }
   }
   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softPointLoginLogo.svg'
         : '../media/logos/softpoint_logo_app-white.svg'

   const handleRedirect = (redirect_to: string) => {
      if (!tizenSystem.network.available && clientIsTizen()) {
         console.log('network not available');
      }else{
         if (redirect_to == 'checkin') {
            dispatch(setFlow(constants.flows.checkin))
            checkLatestKioskSettingsAndNavigateToPath(checkInPaths.check_in_home)
         } else if (redirect_to == 'order') {
            // if (!settings?.value?.dine_in && settings?.value?.order_type_id) {
            //    dispatch(setOrderType('dinein'))
            //    navigateTo(orderingPaths.MENU)
            // } else if (settings?.value?.dine_in && !settings?.value?.order_type_id) {
            //    dispatch(setOrderType('takeout'))
            //    navigateTo(orderingPaths.MENU)
            // } else {
               dispatch(setFlow(constants.flows.ordering))
               checkLatestKioskSettingsAndNavigateToPath(orderingPaths.ORDER_HOME)
            // }
         }
      }
   }

   const checkKioskIsInOperation = () => {
      const dayName = moment().format('dddd').toLowerCase()
      // const start = kiosk_settings[`${dayName}_start`]
      // const end = kiosk_settings[`${dayName}_end`]
      // const startTime = moment(start, 'HH:mm:ss')
      // const endTime = moment(end, 'HH:mm:ss')
      // const currentTime = moment()

      /* TODO: Need to check after API fix
        if (kiosk_settings[dayName] == 1 && currentTime >= startTime && currentTime <= endTime)
            return true;
        return false;
        */
      return true
   }

   return (
      <>
         <div className='mtp-40'>
            <div>
               <h1 className='text-center mt-10 mb-0 fs-lg-4x'>
                  <FormattedMessage id='common.welcome_to' defaultMessage='Welcome to' />
               </h1>
            </div>
            <div className='row d-flex justify-content-center my-14'>
               {kiosk_settings?._embedded?.location_info?.logo ? (
                  <img
                     src={`${process.env.REACT_APP_API_URL}/download-file?path=${
                        kiosk_settings?._embedded?.location_info?.logo
                     }&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`}
                     className='rounded-3 m-0 p-0 w-250px h-250px w-xxl-250px h-xxl-250px'
                     onError={({currentTarget}) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = toAbsoluteUrl(logoPath)
                     }}
                     alt=''
                  />
               ) : (
                  <h1 className='text-center mt-3'>{location_data?.name ?? ''}</h1>
               )}
               {/* {
                    selectedLocationData && selectedLocationData?.logo ? `<img src={toAbsoluteUrl(${selectedLocationData.logo})} className="rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px" alt="" />` : <h1 className='text-center mt-3'>{selectedLocationData.name}</h1>
                } */}
            </div>

            <div className='row d-flex justify-content-center mb-12'>
               <h2 className='text-center fs-lg-4x'>
                  {' '}
                  <FormattedMessage
                     id='setting.what_would_you_like_to_do'
                     defaultMessage='What would you like to do?'
                  />
               </h2>
            </div>

            <div className='row justify-content-center'>
               <div className='clear'></div>

               <div className='col-xl-3 col-md-2 col-sm-1'></div>
               {check_in_len && check_in_len > 0 ? (
                  <div className='col-xl-3 col-md-4 col-sm-5'>
                     <div className='card card-flush pb-5 mw-100'>
                        <div
                           onClick={() => {
                              handleRedirect('checkin')
                           }}
                        >
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <KTSVG
                                    path='/media/icons/duotune/general/gen063.svg' //might use this icon communication/com005
                                    className='svg-icon-fluid svg-icon-primary d-block p-3'
                                 />
                              </div>
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-2tx fs-xl-1'>
                                       <FormattedMessage
                                          id='appmode.option.checkin'
                                          defaultMessage='Check In / Return'
                                       />
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ) : (
                  ''
               )}
               {selfOrderingEnabled && checkKioskIsInOperation() ? (
                  <div className='col-xl-3 col-md-4 col-sm-5'>
                     <div className='card card-flush pb-5 mw-100 '>
                        <div
                           onClick={() => {
                              handleRedirect('order')
                           }}
                        >
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <KTSVG
                                    path='/media/icons/duotune/ecommerce/ecm012.svg' //might use this icon communication/com005
                                    className='svg-icon-fluid svg-icon-primary d-block p-3'
                                 />
                              </div>
                              {/* <img style={{backgroundColor:"#ecedf2",padding:"30px"}} src={toAbsoluteUrl('media/icons/duotune/ecommerce/ecm012.svg')} className="rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px" alt="" />                                 */}
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-2tx fs-xl-1'>
                                       <FormattedMessage id='appmode.option.order' defaultMessage='Order' />
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ) : (
                  ''
               )}
               <div className='col-xl-3 col-md-2 col-sm-1'></div>
            </div>
         </div>
      </>
   )
}
