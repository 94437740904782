import React, {SyntheticEvent, useRef} from "react";

export const Debugger = () => {
    const clearBtnRef = useRef<null | HTMLButtonElement>(null)
    const messageListRef = useRef<null|HTMLUListElement>(null)
    const toggleDisplay = (event:SyntheticEvent) => {
        event.preventDefault()
        const debugBox = document.getElementById('debug_box')
        const debugConsole = document.querySelector('.debugger-console')

        if(debugConsole !== null && debugBox !== null && clearBtnRef.current !== null){
            const consoleDimensions = debugConsole.getBoundingClientRect()
            if(consoleDimensions.left == 0){
                clearBtnRef.current.style.opacity = '0'
                clearBtnRef.current.disabled = true
                debugBox.style.transform = `translate(-${consoleDimensions.width}px, 0px)`
            }
            else{
                debugBox.style.transform = `translate(0px, 0px)`
                clearBtnRef.current.style.opacity = '1'
                clearBtnRef.current.disabled = false
            }

        }
    }

    const clearConsole = (event:SyntheticEvent) => {
        event.preventDefault()
        if(messageListRef.current !== null){
            messageListRef.current.innerHTML = ''
        }
    }

    return(
        <div id='debug_box' className='position-absolute text-wrap debugger-box'>
            <div className='debugger-console'>
                <ul id='debug_messages' ref={messageListRef}/>
            </div>
            <div className='debugger-controls'>
                <div className='pb-2'>
                    <button type='button'
                            className='debugger-btn debug-yellow btn'
                            onClick={toggleDisplay}>Debugger
                    </button>
                </div>
                <div>
                    <button ref={clearBtnRef} type='button'
                            className='debugger-btn btn btn-danger'
                            onClick={clearConsole}>Clear
                    </button>
                </div>
            </div>
        </div>
    )
}

// export function Debug(message:any){
//     const messages = document.getElementById('debug_messages')
//     const debugBox = document.getElementById('debug_box')
//     if(debugBox !== null && messages !== null){
//         if(debugBox.style.display === 'none'){
//             debugBox.style.display = 'block'
//         }
//         const text = document.createTextNode(`${message}`)
//         const listItem = document.createElement('li')
//         listItem.appendChild(text)
//         messages.appendChild(listItem)
//     }
// }