import {array, boolean, InferType, mixed, number, object, string} from 'yup'
import {LANGUAGE_CODES, SupportedLanguages} from '../../modules/i18n/i18nProvider'

export const LocationsConfigContactlessSchema = object({
   id: number(),
   registration_minimum_age: number().required(),
   registrations: number(),
   reservation_banner: string().nullable().defined(),
   status: number(),
   bartab: number().required(),
})
export type LocationsConfigContactless = InferType<typeof LocationsConfigContactlessSchema>

export const LocationInfoSchema = object({
   logo: string().nullable().defined(),
   locale: mixed<SupportedLanguages>().oneOf(LANGUAGE_CODES).required(),
})
export type LocationInfo = InferType<typeof LocationInfoSchema>

export const IntegrationSchema = object({
   id: number().required(),
   global_integrations_id: number(),
   global_integrations_types_id: number(),
   name: string(),
   checkin: number(),
   kiosk: number(),
   biometrics_match_percent: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'string') {
            if (originalValue.trim() == '') return null
            return parseInt(originalValue)
         } else {
            return Number(originalValue)
         }
      })
      .nullable()
      .defined(),
   biometrics_min_percent: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'string') {
            if (originalValue.trim() == '') return null
            return parseInt(originalValue)
         } else {
            return Number(originalValue)
         }
      })
      .nullable()
      .defined(),
})
export type Integration = InferType<typeof IntegrationSchema>

export const DonationSchema = object({
   id: number(),
   global_tender_types_id: number().nullable().defined(),
   last_datetime: string(),
})

export type Donation = InferType<typeof DonationSchema>

export const EmbeddedSchema = object({
   integrations: array(IntegrationSchema).required(),
   location_info: LocationInfoSchema.required(),
   locations_config_contactless: LocationsConfigContactlessSchema.required(),
   donation: DonationSchema.nullable().defined(),
})
export type Embedded = InferType<typeof EmbeddedSchema>

export const KioskSettingsSchema = object({
   appearance: number().required(),
   id: number().required(),
   location_id: number().required(),
   check_in_walk_up: number().required(),
   header_banner: string().nullable().defined(),
   status: number().required(),
   icon: string().nullable().defined(),
   print_confirmation: number().required(),
   tips: number().required(),
   tip_percentage: string().nullable().defined(),
   global_tips_types_id: number().nullable().defined(),
   auto_print_receipt: number().required(),
   auto_close_ticket: number().required(),
   receipt_text: boolean().required(),
   receipt_email: boolean().required(),
   receipt_print: boolean().required(),
   primary_color: string().required(),
   background_color: string().required(),
   checkout_name_required: number().required(),
   checkout_phone_number_required: number().required(),
   _embedded: EmbeddedSchema.required(),
   ordering_employee: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'number' && originalValue === 0) {
            return null
         }
         return originalValue
      })
      .nullable()
      .defined(),
   ordering_employee_external_id: string().nullable().defined(),
   order_type_id: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'number' && originalValue === 0) {
            return null
         }
         return originalValue
      })
      .nullable()
      .defined(),
   order_type_external_id: string().nullable().defined(),
   order_type_dine_in_external_id: string().nullable().defined(),
   revenue_center: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'number' && originalValue === 0) {
            return null
         }
         return originalValue
      })
      .nullable()
      .defined(),
   revenue_center_external_id: string().nullable().defined(),
   bartab: number().required(),
   pay_by_card: number().nullable().defined(),
   pay_in_store: number().nullable().defined(),
   pay_by_gift_card: number().nullable().defined(),
   pay_by_loyalty: number().nullable().defined(),
   location_current_datetime: string().required(),
   dine_in: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'number' && originalValue === 0) {
            return null
         }
         return originalValue
      })
      .nullable()
      .defined(),
   quick_selfie: number().required(),
   selfie_timer: number().required(),
   idle_image: string().nullable().defined(),
   idle_video: string().nullable().defined(),
})

export type KioskSettings = InferType<typeof KioskSettingsSchema>
