import {useEffect} from 'react'
import {Outlet, useMatch} from 'react-router-dom'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {useLocation} from 'react-router-dom'
import {ThemeModeProvider} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../app/store'
import {ThemeModeComponent} from '../../_metronic/assets/ts/layout'
import {ThemeModeType, useThemeMode} from '../../_metronic/partials'
import {getAuth} from '../../app/modules/auth'
import {StoreCatSync} from '../../app/services/SelfOrderingDataSlice'
import {useKioskProducts} from '../../app/common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS} from '../../app/routing/RoutePaths'

const MasterLayout = () => {
   const dispatch = useDispatch()
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {updateMode, updateMenuMode} = useThemeMode()
   const themeModeLSKey = 'kt_theme_mode_value'
   const themeMenuModeLSKey = 'kt_theme_mode_menu'
   const location = useLocation()
   const isOnSelfieAuthScreen = useMatch(MODULE_ROUTE_PATHS.checkIn.biometric_auth)
   const {orderingEnabled, checkInEnabled, DineinAndTakeOutBothEnable} = useKioskProducts()
   const headerIconRoute =
      orderingEnabled && checkInEnabled
         ? MODULE_ROUTE_PATHS.dashboard.index
         : checkInEnabled
         ? MODULE_ROUTE_PATHS.checkIn.check_in_home
         : orderingEnabled
         ? DineinAndTakeOutBothEnable
            ? MODULE_ROUTE_PATHS.ordering.ORDER_HOME
            : MODULE_ROUTE_PATHS.ordering.MENU
         : MODULE_ROUTE_PATHS.dashboard.index

   const selfOrder = useSelector((state: RootState) => state.location_menu_data.values)
   if (!selfOrder || !selfOrder.Data_Categories) {
      dispatch(StoreCatSync(false))
   }

   if (kiosk_settings) {
      let _mode: ThemeModeType = 'dark'
      if (kiosk_settings.appearance == 1) {
         _mode = 'light'
      }
      if (localStorage) {
         localStorage.setItem(themeModeLSKey, _mode)
         localStorage.setItem(themeMenuModeLSKey, _mode)
         document.documentElement.setAttribute('data-theme', _mode)
         ThemeModeComponent.init()
      }
      updateMenuMode(_mode)
      updateMode(_mode)
   }

   useEffect(() => {
      setTimeout(() => {
         MenuComponent.reinitialization()
      }, 500)
   }, [])

   useEffect(() => {
      setTimeout(() => {
         MenuComponent.reinitialization()
      }, 500)
   }, [location.key])

   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softpoint_small_black.svg'
         : '../media/logos/softpoint_small.svg'

   let LogoFullPath = false

   if (kiosk_settings?.icon) {
      const user_id = getAuth()?.user_id
      LogoFullPath = true
      logoPath = `${process.env.REACT_APP_API_URL}/download-file?path=${kiosk_settings?.icon}&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${user_id}`
   }

   if (kiosk_settings) {
      if (kiosk_settings?.primary_color) {
         const root = document.documentElement
         root.style.setProperty('--kt-primary-active', kiosk_settings?.primary_color)
         root.style.setProperty('--kt-primary', kiosk_settings?.primary_color)
         root.style.setProperty('--kt-text-primary', kiosk_settings?.primary_color)
      }
   }

   const FooterExcludedRoutes = [
      '/',
      MODULE_ROUTE_PATHS.ordering.MENU,
      MODULE_ROUTE_PATHS.ordering.ITEMS,
      MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT,
   ]

   return (
      <ThemeModeProvider>
         <div className='page d-flex flex-row flex-column-fluid'>
            <div className='d-flex flex-column flex-row-fluid' id='kt_wrapper'>
               <HeaderWrapper
                  to={headerIconRoute}
                  useSVG={false}
                  viewDateTime={true}
                  styles={{}}
                  imgClassName={'h-50px rounded-1'}
                  pathname={location.pathname}
                  LogoFullPath={LogoFullPath}
                  imgURL={logoPath}
               />
               <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                  <div className='px-5 h-100' style={{marginTop: isOnSelfieAuthScreen !== null ? '35px' : '50px'}}>
                     <Outlet />
                  </div>
               </div>
               {!FooterExcludedRoutes.includes(window.location.pathname) ? <Footer /> : null}
            </div>
         </div>
      </ThemeModeProvider>
   )
}

export {MasterLayout}
