import {ReactNode, useEffect, useRef} from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import {useInternationalization} from '../modules/i18n/i18nProvider'
import {current} from '@reduxjs/toolkit'

export const AxiosConfigProvider = ({children}: {children: ReactNode}) => {
   const {selectedLang} = useInternationalization()
   const interceptorRef = useRef<number>()

   useEffect(() => {
      if (interceptorRef.current === undefined) {
         interceptorRef.current = axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
               config.params = {
                  ...config.params,
                  i18n: selectedLang,
               }
               return config
            },
            (err: any) => Promise.reject(err)
         )
      } else {
         axios.interceptors.request.eject(interceptorRef.current)
         interceptorRef.current = axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
               config.params = {
                  ...config.params,
                  i18n: selectedLang,
               }
               return config
            },
            (err: any) => Promise.reject(err)
         )
      }
   }, [selectedLang])

   return <>{children}</>
}
