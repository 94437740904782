import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

import {Terminal, TerminalValidationSchema} from '../common/schemas/TerminalSchema'
import {AppDispatch, RootState} from '../store'
import {GetApiUrl, getAuth} from '../modules/auth'
import axios from 'axios'
import {KIOSK_GLOBAL_ON} from '../config/constants'
import {IStandardError} from '../common/models/SoftPointAPIs'
import {stat} from 'fs'
import {parseRequestError} from '../common/utilities'

interface ITerminal {
   value: Terminal | null
   request: {
      status: 'IDLE' | 'IN_PROGRESS' | 'REPLIED'
      error: IStandardError | null
   }
}

const initialState: ITerminal = {
   value: null,
   request: {
      status: 'IDLE',
      error: null,
   },
}

export const SelectedTerminalSlice = createSlice({
   name: 'SelectedTerminal',
   initialState,
   reducers: {
      StoreSelectedTerminal: (state, action: PayloadAction<Terminal | null>) => {
         state.value = action.payload
      },
      SaveLocalEchoIP: (state, action: PayloadAction<string | null>) => {
         if(state.value)
            state.value.local_echo_ip = action.payload
      },
      SetRequestError: (state, action: PayloadAction<null | IStandardError>) => {
         state.request.error = action.payload
      },
   },
})

// Action creators are generated for each case reducer function
export const {StoreSelectedTerminal, SaveLocalEchoIP, SetRequestError} = SelectedTerminalSlice.actions

export default SelectedTerminalSlice.reducer

export const getSelectedTerminal = (state: RootState): Terminal | null => state.selected_terminal.value

// THUNKS
export const fetchTerminal = (locationId: number | string, terminalId: number | string) => {
   let user = getAuth()
   const user_id = user?.user_id
   const url = `${GetApiUrl()}/locations/${locationId}/terminals/${terminalId}?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
   return async (dispatch: AppDispatch) => {
      try {
         const response = await axios.get(url)
         const cleanData = TerminalValidationSchema.validateSync(response.data.terminals)
         dispatch(StoreSelectedTerminal(cleanData))
      } catch (e) {
         dispatch(SetRequestError(parseRequestError(e)))
      }
   }
}
