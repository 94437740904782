import {KIOSK_GLOBAL_ON} from '../../../config/constants'
import {GetApiUrl, getAuth} from '../../auth'
import axios from 'axios'
import {parseRequestError} from '../../../common/utilities'
import {useErrorAlert, useLoadingAlert} from '../../utilities/alerts'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
import {getSelectedLocation} from '../../../services/SelectedLocationSlice'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'

export type QuickPointPrintRequest = {
   ticket_id: number
   terminal_id: number
   device_id: number
   payment_id: number
}

export type QpPrintResponse = {
   response: 'SUCCESS' | 'FAIL'
}

export const useQuickPointPrint = () => {
   const fireError = useErrorAlert()
   const fireLoadingAlert = useLoadingAlert()
   const user = getAuth()
   const user_id = user?.user_id
   const location = useSelector(getSelectedLocation) as SoftPointLocation
   const queryParams = {
      global_on_id: KIOSK_GLOBAL_ON,
      user_id: user_id,
   }
   const url = `${GetApiUrl()}/locations/${location.location_id}/webhook/print`

   return (payload: QuickPointPrintRequest, callback?: () => void) => {
      fireLoadingAlert('Processing...')
      axios
         .post(url, payload, {params: queryParams})
         .then((response) => {
            const content = response.data as QpPrintResponse
            Swal.close()
            if (callback) {
               callback()
            }
         })
         .catch((error) => {
            const standardError = parseRequestError(error)
            fireError(standardError.message)
            Swal.close()
         })
   }
}
