import {KioskSettings} from '../schemas/KioskSettingsSchema'
import {Terminal} from '../schemas/TerminalSchema'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {getSelectedTerminal} from '../../services/SelectedTerminalSlice'

/**
 * The hook returns the location's order configuration data which is required by most of our ticket creation APIs
 */
export const useOrderConfigData = () => {
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const SelectedTerminal = useSelector(getSelectedTerminal) as Terminal
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const order_type = useSelector((state: RootState) => state.self_ordering_selections.order_type)
   return (type: 'bar_tab' | 'self_order') => {
      let employee_id = kiosk_settings.ordering_employee ?? 0
      let employee_external_id = kiosk_settings.ordering_employee_external_id
         ? kiosk_settings.ordering_employee_external_id
         : ''

      let order_type_id = 0
      let order_type_id_external = ''
      if (type === 'bar_tab') {
         order_type_id = kiosk_settings.dine_in ?? 0
         order_type_id_external = kiosk_settings.order_type_dine_in_external_id
            ? kiosk_settings.order_type_dine_in_external_id
            : ''
      }
      if (type === 'self_order') {
         order_type_id = kiosk_settings.order_type_id ?? 0
         order_type_id_external = kiosk_settings.order_type_external_id ? kiosk_settings.order_type_external_id : ''
         if (order_type == 'dinein') {
            order_type_id = kiosk_settings.dine_in ?? 0
            order_type_id_external = kiosk_settings.order_type_dine_in_external_id
               ? kiosk_settings.order_type_dine_in_external_id
               : ''
         }
      }

      let revenue_center_id = SelectedTerminal.revenue_center ?? 0
      let db_revenue_center = selfOrderData.Data_RevenueCenters.find(
         (revenueCenter) => revenueCenter.id == revenue_center_id
      )
      let revenue_center_id_external = db_revenue_center?.id_external ?? ''
      return {
         employee_id,
         employee_external_id,
         order_type_id,
         order_type_id_external,
         revenue_center_id,
         revenue_center_id_external,
      }
   }
}
