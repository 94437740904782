import {IReservationGuests, IReservationReceipt, IGuestReservation} from '../../modules/hotel/_requests/reservations'
import moment from 'moment'
import {constants} from '../../config/constants'
import {SupportedLanguages} from '../../modules/i18n/i18nProvider'

export const generateReservationReceipt = (
   locationName: string = '',
   guestReservation: IGuestReservation,
   language: SupportedLanguages = 'en'
) => {
   const statuses = constants.status
   const reservationGuests: IReservationGuests[] | undefined | null = guestReservation._embedded?.guests
   let reservationReceipt: IReservationReceipt = {
      location_name: locationName,
      guest_name: '',
      reservation_code: '',
      check_in: '',
      check_out: '',
      guest_count: 0,
   }
   if (reservationGuests !== undefined && reservationGuests !== null) {
      // const primaryGuestQuery = reservationGuests.filter((guest, key) => {
      //     return guest.primary === statuses.YES
      // })

      const secondaryGuestsQuery = reservationGuests.filter((guest) => {
         return guest.primary !== statuses.NO
      })

      const checkedInGuestQuery = reservationGuests.filter((guest) => {
         return guest.guest_id === guestReservation.guest_id
      })

      // const primaryGuest = primaryGuestQuery[0]
      const checkedInGuest = checkedInGuestQuery[0]
      const formattedCheckIn = moment(guestReservation.local_check_in).format('ddd, MMM DD [at] LT')
      const formattedCheckOut =
         guestReservation.local_check_out != null && guestReservation.local_check_out !== ''
            ? moment(guestReservation.local_check_out).format('ddd, MMM DD [at] LT')
            : null

      reservationReceipt.guest_name = checkedInGuest.guest ?? ''
      reservationReceipt.reservation_code = guestReservation.confirmation_number ?? ''
      reservationReceipt.check_in = formattedCheckIn ?? ''
      reservationReceipt.check_out = formattedCheckOut ?? ''
      reservationReceipt.guest_count = secondaryGuestsQuery.length ?? 0
   }

   const currentTimeStamp = moment().format('LT')
   const translator = new Translator()

   const receiptTranslations = {
      en: {
         approved: 'Approved',
         time_stamp: 'Time Stamp',
         guest_name: 'Guest Name',
         reservation_code: 'Reservation Code',
         check_in: 'Check In',
         check_out: 'Check Out',
         additional_guests: 'Additional Guests',
      },
      es: {
         approved: 'Aprobado',
         time_stamp: 'Marca De Tiempo',
         guest_name: 'Nombre De Invitado',
         reservation_code: 'Codigo De Reservacion',
         check_in: 'Entrada',
         check_out: 'Salida',
         additional_guests: 'Invitados Adicionales',
      },
   }

   const translations = receiptTranslations[language]

   return (
      `<c><2>${translator.toEnglish(reservationReceipt.location_name)}</></><e><e><e>` +
      `<l><2><u>${translations.approved}</></></><e>` +
      `<l><b>${translations.time_stamp}: ${currentTimeStamp}</></><e>` +
      `<1><b>${translations.guest_name}: ${translator.toEnglish(reservationReceipt.guest_name)}</></><e><e>` +
      `<1><b>${translations.reservation_code}: #${reservationReceipt.reservation_code}</></><e><e>` +
      `<1><b>${translations.check_in}: ${reservationReceipt.check_in}</></><e><e>` +
      `<1><b>${translations.check_out}: ${reservationReceipt.check_out}</></><e><e>` +
      `<1><b>${translations.additional_guests}: +${reservationReceipt.guest_count}</></><e><e>`
   )
}

export const generateAdmissionReceipt = (
   locationName: string,
   guestName: string,
   language: SupportedLanguages = 'en'
) => {
   const currentTimeStamp = moment().format('LT')
   const translator = new Translator()
   const engLocationName = translator.toEnglish(locationName)
   const engGuestName = translator.toEnglish(guestName)

   const admissionTranslations = {
      en: {
         approved: 'Approved',
         time_stamp: 'Time Stamp',
         guest_name: 'Guest Name',
      },
      es: {
         approved: 'Aprobado',
         time_stamp: 'Marca De Tiempo',
         guest_name: 'Nombre De Invitado',
      },
   }

   const translations = admissionTranslations[language]

   return (
      `<c><2>${engLocationName}</></><e><e><e>` +
      `<l><2><u>${translations.approved}</></></><e>` +
      `<l><b>${translations.time_stamp}: ${currentTimeStamp}</></><e>` +
      `<1><b>${translations.guest_name}: ${engGuestName}</></><e><e><e>`
   )
}

class Translator {
   private spanishLetters = new Map<string, string>()

   constructor() {
      const lowerSpanishN = 'F1'
      const lowerSpanishA = 'E1'
      const lowerSpanishE = 'E9'
      const lowerSpanishI = 'ED'
      const lowerSpanishO = 'F3'
      const lowerSpanishU = 'FA'

      const upperSpanishN = 'D1'
      const upperSpanishA = 'C1'
      const upperSpanishE = 'C9'
      const upperSpanishI = 'CD'
      const upperSpanishO = 'D3'
      const upperSpanishU = 'DA'

      this.spanishLetters.set(lowerSpanishN, 'n')
      this.spanishLetters.set(lowerSpanishA, 'a')
      this.spanishLetters.set(lowerSpanishE, 'e')
      this.spanishLetters.set(lowerSpanishI, 'i')
      this.spanishLetters.set(lowerSpanishO, 'o')
      this.spanishLetters.set(lowerSpanishU, 'u')

      this.spanishLetters.set(upperSpanishA, 'A')
      this.spanishLetters.set(upperSpanishU, 'U')
      this.spanishLetters.set(upperSpanishE, 'E')
      this.spanishLetters.set(upperSpanishI, 'I')
      this.spanishLetters.set(upperSpanishO, 'O')
      this.spanishLetters.set(upperSpanishN, 'N')
   }

   toEnglish(text: string) {
      let newText: string = ''
      for (let ii = 0; ii < text.length; ii++) {
         const hexCode = text.charCodeAt(ii).toString(16)
         const englishLetter = this.spanishLetters.get(hexCode.toUpperCase())
         if (englishLetter) {
            newText += englishLetter
         } else {
            newText += text[ii]
         }
      }
      return newText
   }
}
