import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Swal, {SweetAlertResult} from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {StoreHeaderPageTitle} from '../../../services/HeaderPageTitleSlice'
import {constants} from '../../../config/constants'
import {useState} from 'react'
import {PosRefreshModal} from './PosRefreshModal'
import {HelpModal} from './HelpModal'
import {DeviceDetailsModal} from './DeviceDetailsModal'
import {ConnectionDetailsModal} from './ConnectionDetailsModal'
import {DeviceIpChangeModal} from './DeviceIpChangeModal'
import {getAuth} from '../../auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'

export const Settings = () => {
   const intl = useIntl()
   let kiosk_settings: any = null
   const dispatch = useDispatch<any>()
   const [show, setShow] = useState<boolean>(false)
   const [CallFullRefresh, setCallFullRefresh] = useState<boolean>(false)

   const toggleModal = () => {
      setShow(!show)
      setCallFullRefresh(false)
   }
   const showChangeIpModal = () => {
      setShow(true)
      setSelectedOption('DeviceIpChange')
   }
   const forcePosFullRefresh = () => {
      setShow(true)
      setSelectedOption('pos')
      setCallFullRefresh(true)
   }

   useTranslatedPageTitle(constants.staticPageTitle.settings)

   let location_data = useSelector((state: any) => state.location_data.value)
   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softPointLoginLogo.svg'
         : '../media/logos/softpoint_logo_app-white.svg'

   kiosk_settings = localStorage.getItem('kiosk_setting')
      ? JSON.parse(localStorage.getItem('kiosk_setting') as any)
      : null

   if (!location_data || Object.keys(location_data).length == 0) {
      location_data = localStorage.getItem('location_data')
         ? JSON.parse(localStorage.getItem('location_data') as any)
         : null
   }
   const [selectedOption, setSelectedOption] = useState('')

   return (
      <>
         <div className='mtp-40'>
            <div>
               <h1 className='text-center mt-10 mb-0 fs-lg-4x invisible'>
                  <FormattedMessage id='common.welcome_to' defaultMessage='Welcome to' />
               </h1>
            </div>
            <div className='row d-flex justify-content-center my-14'>
               {kiosk_settings?._embedded?.location_info?.logo ? (
                  <img
                     src={`${process.env.REACT_APP_API_URL}/download-file?path=${
                        kiosk_settings?._embedded?.location_info?.logo
                     }&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`}
                     className='rounded-3 m-0 p-0 w-250px h-250px w-xxl-250px h-xxl-250px'
                     onError={({currentTarget}) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = toAbsoluteUrl(logoPath)
                     }}
                     alt=''
                  />
               ) : (
                  <h1 className='text-center mt-3'>{location_data.name}</h1>
               )}
               {/* {
                    selectedLocationData && selectedLocationData?.logo ? `<img src={toAbsoluteUrl(${selectedLocationData.logo})} className="rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px" alt="" />` : <h1 className='text-center mt-3'>{selectedLocationData.name}</h1>
                } */}
            </div>

            <div className='row d-flex justify-content-center mb-12'>
               <h2 className='text-center fs-lg-4x'>
                  {' '}
                  <FormattedMessage
                     id='setting.what_would_you_like_to_do'
                     defaultMessage='What would you like to do?'
                  />
               </h2>
            </div>

            <div className='row justify-content-center'>
               <div className='clear'></div>

               <div className='col-xl-3 col-md-6 col-sm-5'>
                  <div className='card card-flush pb-5 mw-100 '>
                     <div
                        onClick={() => {
                           setSelectedOption('pos')
                           setShow(true)
                        }}
                     >
                        <div className='card-body text-center '>
                           <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                              <KTSVG
                                 path='/media/icons/duotune/coding/cod005.svg' //might use this icon communication/com005
                                 className='svg-icon-fluid svg-icon-primary d-block p-3'
                              />
                           </div>
                           <div className='mt-6'>
                              <div className='text-center'>
                                 <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                    <FormattedMessage id='setting.pos_refresh' defaultMessage='POS Refresh' />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='col-xl-3 col-md-6 col-sm-5'>
                  <div className='card card-flush pb-5 mw-100 '>
                     <div
                        onClick={() => {
                           setSelectedOption('connection')
                           setShow(true)
                        }}
                     >
                        <div className='card-body text-center'>
                           <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                              <KTSVG
                                 path='/media/icons/duotune/electronics/elc007.svg' //might use this icon communication/com005
                                 className='svg-icon-fluid svg-icon-primary d-block p-3'
                              />
                           </div>
                           <div className='mt-6'>
                              <div className='text-center'>
                                 <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                    <FormattedMessage id='setting.connection' defaultMessage='Connection' />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='col-xl-3 col-md-6 col-sm-5'>
                  <div className='card card-flush pb-5 mw-100 mt-md-5  mt-xl-0'>
                     <div
                        onClick={() => {
                           setSelectedOption('device_details')
                           setShow(true)
                        }}
                     >
                        <div className='card-body text-center'>
                           <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                              <KTSVG
                                 path='/media/icons/duotune/electronics/elc003.svg' //might use this icon communication/com005
                                 className='svg-icon-fluid svg-icon-primary d-block p-3'
                              />
                           </div>
                           <div className='mt-6'>
                              <div className='text-center'>
                                 <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                    <FormattedMessage id='setting.terminal_details' defaultMessage='Terminal Details' />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='col-xl-3 col-md-6 col-sm-5'>
                  <div className='card card-flush pb-5 mw-100 mt-md-5 mt-xl-0'>
                     <div
                        onClick={() => {
                           setSelectedOption('help')
                           setShow(true)
                        }}
                     >
                        <div className='card-body text-center'>
                           <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                              <KTSVG
                                 path='/media/icons/duotune/communication/com003.svg' //might use this icon communication/com005
                                 className='svg-icon-fluid svg-icon-primary d-block p-3'
                              />
                           </div>
                           <div className='mt-6'>
                              <div className='text-center'>
                                 <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                    <FormattedMessage id='setting.get_help' defaultMessage='Get Help' />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='col-xl-3 col-md-2 col-sm-1'></div>
            </div>
         </div>

         {show && selectedOption == 'pos' ? (
            <PosRefreshModal toggleModal={toggleModal} stepTracker={0} CallFullRefresh={CallFullRefresh} />
         ) : (
            <></>
         )}
         {show && selectedOption == 'help' ? <HelpModal toggleModal={toggleModal} /> : <></>}
         {show && selectedOption == 'device_details' ? (
            <DeviceDetailsModal showChangeIpModal={showChangeIpModal} toggleModal={toggleModal} />
         ) : (
            <></>
         )}
         {show && selectedOption == 'connection' ? <ConnectionDetailsModal toggleModal={toggleModal} /> : <></>}
         {show && selectedOption == 'DeviceIpChange' ? (
            <DeviceIpChangeModal forcePosFullRefresh={forcePosFullRefresh} toggleModal={toggleModal} />
         ) : (
            <></>
         )}
      </>
   )
}
