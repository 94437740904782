import {
   FC,
   useState,
   ReactNode,
   useEffect,
   SetStateAction,
   Dispatch,
   useRef,
   SyntheticEvent,
   KeyboardEvent,
} from 'react'
import {Modal} from 'react-bootstrap'
import '../assets/CheckoutStyle.css'
import {OrderCompletedModalBody} from './OrderCompletedModalBody'
import {ReceiptsModalBody} from './ReceiptsModalBody'
import {Formik, ErrorMessage, Form} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {
   fireErrorMessage,
   useErrorAlert,
   useLoadingAlert,
   useReverseConfirmAlert,
   useTimedSuccessAlert,
   useWarningConfirmAlert,
} from '../../utilities/alerts'
import {ILoyaltyData, PaymentTimeoutError, usePay} from '../hooks/usePay'
import Swal from 'sweetalert2'
import {parseRequestError, sleep} from '../../../common/utilities'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useCart} from '../../../services/hooks/useCart'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {constants, KIOSK_GLOBAL_ON} from '../../../config/constants'
import {CardModalBody} from './CardModalBody'
import {GiftCardModalBody} from './GiftCardModalBody'
import {LoyaltyModalBody} from './LoyaltyModalBody'
import Inputmask from 'inputmask'
import {getSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {useQuickPointPrint} from '../requests/receipts'
import {TipSelector} from './TipsSelector'
import {useKioskProducts} from '../../../common/hooks/useKioskProducts'
import {useKeyboard} from '../../../services/hooks/useKeyboard'
import {CreatingTicketModalBody} from './CreatingTicketModalBody'
import {CreateTicket, useTicketService} from '../hooks/useTicketService'
import axios from 'axios'
import {GetApiUrl, getAuth} from '../../auth'
import {getSaleSession, updateTotalsWithNotTip} from '../../../services/SelfOrderingCartSlice'
import {KeyboardField} from '../../../common/components/KeyboardField'
import {useTipSelectorMachine} from '../hooks/useTipSelectorMachine'
import {useFetchKioskSettings} from '../../../common/apis/kiosk_settings_request'

interface IPayForm {
   firstName: string
   lastName: string
   phoneNumber: string
}

interface PaymentModalProps {
   toggleModal: () => void
   orderForm: IPayForm
   setOrderForm: Dispatch<SetStateAction<IPayForm>>
}

export type PaymentType = 'card' | 'cash' | 'gift_card' | ''
export type NewOrder = {
   ticket_id: number | null
   ticket_number: string
   payment_id: number | null
}
const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ //ensures valid format with regex

type ProcessedTicket = {
   posted_payment?: {id: number}
   id: number
   ticket_number: string
}
type ModalBodyView = 'default' | 'card' | 'receipt' | 'order_completed' | 'gift_card' | 'loyalty' | 'creating_ticket'

const PAYMENT_FORM_FIELDS = {
   firstName: 'firstName',
   lastName: 'lastName',
   phoneNumber: 'phoneNumber',
   customTipInput: 'custom-tip-input',
} as const
const PAYMENT_FORM_FIELD_NAMES = Object.values(PAYMENT_FORM_FIELDS)

export const PaymentModalController: FC<PaymentModalProps> = ({toggleModal, orderForm, setOrderForm}) => {
   const STATUS = constants.status
   const CREATE_TICKET_ERRORS = constants.errors.create_ticket
   const COMMON_ERRORS = constants.errors.common
   const intl = useIntl()
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)
   const terminal = useSelector(getSelectedTerminal) as Terminal
   const saleSession = useSelector(getSaleSession)
   const submitBtnFlag = useRef(false)
   const navigate = useNavigate()
   const fireLoadingAlert = useLoadingAlert()
   const fireErrorAlert = useErrorAlert()
   const fireReverseConfirm = useReverseConfirmAlert()
   const fireConfirmAlert = useWarningConfirmAlert()
   const fireTimedSuccess = useTimedSuccessAlert(false)
   const {payInStore, payWithCC, payWithLoyalty} = usePay()
   const {ResetCart, GetItems} = useCart()
   const {CreateTicketWithProcessedPayment} = useTicketService(GetItems())
   const dispatch = useDispatch<AppDispatch>()
   const PrintPaperReceipt = useQuickPointPrint()
   const [modalViewType, setModalView] = useState<ModalBodyView>('default')
   const {loyaltyEnabled} = useKioskProducts()

   const [formPaymentType, setFormPaymentType] = useState<PaymentType>('')
   const [createdOrder, setCreatedOrder] = useState<NewOrder>({
      ticket_id: null,
      ticket_number: '',
      payment_id: null,
   })
   const [phoneNumber, setPhoneNumber] = useState<string>('')

   const posSVGPath = getPosLogo()
   const [ReceiptType, setReceiptType] = useState<'' | 'Phone' | 'Email'>('')

   const [activeTipOption, dispatchNewTip] = useTipSelectorMachine(PAYMENT_FORM_FIELDS.customTipInput)
   const navigateTo = useNavigate()
   const fetchKioskSettings = useFetchKioskSettings()

   const hideLoader = () => {
      Swal.close()
   }

   /* Keyboard Code */
   const {clearInputs, onChangeInput, setActiveInput} = useKeyboard()

   const handleChildInputFocus = () => {
      console.log('Input field in child component focused')
      // Do something else
   }
   /* Keyboard Code end */

   /**
    * A reducer method that sets the ID and number of a newly created ticket
    * @param ticket
    */
   const setTicket = (ticket: {id: number | null; number: string}) => {
      setCreatedOrder((prevState) => ({...prevState, ticket_id: ticket.id, ticket_number: ticket.number}))
   }

   const validatePayForm = (values: IPayForm) => {
      const errors = getFormErrors(values, kiosk_settings)
      const errorMessages = Object.values(errors)
      if (errorMessages.length > 0 && submitBtnFlag.current) {
         fireErrorAlert(getValidationAlertMessage(errors))
      }
      submitBtnFlag.current = false
      return errors
   }

   const processLoyaltyDiscount = (card_number: string, userInfo: {name: string; phone: string}) => {
      fireLoadingAlert('Processing...')
      let loyalty_data = {
         card_number: card_number,
         amount_to_redeem: cartTotals.due,
      }
      payWithLoyalty(loyalty_data, userInfo)
         .then((ticket) => {
            hideLoader()
            showOrderCompleted({
               id: ticket.id,
               ticket_number: `${ticket.ticket_number}`,
               posted_payment: ticket.posted_payment ?? undefined,
            })
         })
         .catch((error) => {
            hideLoader()

            const standardError = parseRequestError(error)
            if (standardError.code == constants.errors.create_ticket.loyalty_balance_not_sufficient) {
               let loyalty_current_balance = standardError.details?.balance
               if (loyalty_current_balance == undefined) {
                  processOrder(userInfo)
               } else {
                  //Ask if guest want to use that partial
                  let error_msg =
                     `${standardError.message} <br/><br/> Balance: $` + (loyalty_current_balance / 100).toFixed(2)
                  fireConfirmAlert(error_msg, 'Yes, use my balance', 'No, Thank you', 'modal-full-width').then(
                     (result) => {
                        if (result.isConfirmed) {
                           //Pay using card fully
                           processOrder(userInfo)
                        } else {
                           //Pay using card + partial loyalty
                           loyalty_data.amount_to_redeem = loyalty_current_balance
                           processOrder(userInfo, loyalty_data)
                        }
                     }
                  )
               }
            } else {
               if (
                  standardError.details &&
                  standardError.details['loyalty.card_number'] &&
                  standardError.details['loyalty.card_number'][0]
               ) {
                  fireErrorAlert(standardError.details['loyalty.card_number'][0])
               } else {
                  fireErrorAlert(standardError.message)
               }
               //processOrder(userInfo)
            }
         })
   }

   /**
    * Changes modal view to order_completed, sets prop data, and auto prints receipt if configured
    * @param ticket
    */
   const showOrderCompleted = (ticket: ProcessedTicket) => {
      setModalView('order_completed')

      let posted_payment_id = 0

      if (ticket?.posted_payment && ticket.posted_payment?.id) {
         posted_payment_id = ticket.posted_payment.id
      }

      setCreatedOrder({
         ticket_id: ticket.id,
         ticket_number: `${ticket.ticket_number}`,
         payment_id: posted_payment_id,
      })
      if (kiosk_settings.auto_print_receipt === STATUS.YES) {
         if (terminal.paired_terminal_id) {
            const payload = {
               ticket_id: ticket.id,
               terminal_id: terminal.id,
               device_id: terminal.paired_terminal_id,
               payment_id: posted_payment_id,
            }
            PrintPaperReceipt(payload)
         }
      }
   }

   const checkKioskSettings = async () => {
      const userSession = getAuth()
      return fetchKioskSettings(kiosk_settings.location_id, userSession!.user_id)
         .then((settings: KioskSettings) => {
            if (settings.status == 0) {
               fireErrorAlert(intl.formatMessage({id: 'appmode.alert.service_unavailable'}))
               navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
               return false
            } else {
               return true
            }
         })
         .catch((error) => {
            const standardError = parseRequestError(error)
            let translatedMessage = standardError.message
            if (standardError.code < 0) {
               translatedMessage = intl.formatMessage({id: standardError.message})
            }
            fireErrorAlert(translatedMessage)
            return false
         })
   }

   const processOrder = async (userInfo: {name: string; phone: string}, loyalty_data?: ILoyaltyData) => {
      setModalView('card')
      try {
         const processedPayment = await payWithCC(userInfo, loyalty_data)
         const payload: CreateTicket = {
            user_data: userInfo,
            loyalty_data,
            payment: {
               id: processedPayment.id,
            },
         }
         await fireTimedSuccess('Payment Successful!', 1)
         setModalView('creating_ticket')
         const delayedRequest = await Promise.all([CreateTicketWithProcessedPayment(payload), sleep(2000)])
         const processedTicket = delayedRequest[0]
         showOrderCompleted({
            id: processedTicket.id,
            ticket_number: `${processedTicket.ticket_number}`,
            posted_payment: processedTicket.posted_payment,
         })
      } catch (error) {
         const standardError = parseRequestError(error)
         let errorMessage = standardError.message
         const detailKeys = standardError.details == null ? [] : Object.keys(standardError.details)
         const isRevenueCenterError = detailKeys.includes('revenue_center')

         if (standardError.code < 0) {
            errorMessage = intl.formatMessage({id: standardError.message})
         }
         if (
            error instanceof PaymentTimeoutError ||
            standardError.code === CREATE_TICKET_ERRORS.sock_connection_error
         ) {
            errorMessage = 'We had some troubles communicating with the payment terminal. Would you like to try again?'
         }
         if (standardError.code === CREATE_TICKET_ERRORS.webhook_timeout) {
            errorMessage = 'We had some troubles processing your payment. Would you like to try again?'
         }
         if (standardError.code === CREATE_TICKET_ERRORS.failed_payment_match) {
            fireErrorAlert(errorMessage)
            const ticketId = standardError.details?.ticket_id
            const paymentId = standardError.details?.payment_id
            if (ticketId && paymentId && typeof ticketId == 'number' && typeof paymentId == 'number') {
               PrintPaperReceipt({
                  ticket_id: ticketId,
                  terminal_id: terminal.id,
                  device_id: terminal.paired_terminal_id ?? 0,
                  payment_id: paymentId,
               })
            }
            return
         }
         if (standardError.code === COMMON_ERRORS.missing_required_params && isRevenueCenterError) {
            errorMessage = constants.errors.static.missing_revenue_center
         }
         fireReverseConfirm(errorMessage, 'Try Again', 'Ok, got it!').then((userInput) => {
            if (userInput.isDenied) {
               processOrder(userInfo, loyalty_data)
            } else {
               setModalView('default')
            }
         })
      }
   }

   const handleSkipLoyalty = () => {
      if (formPaymentType == 'card') {
         const userInfo = {name: `${orderForm.firstName} ${orderForm.lastName}`, phone: orderForm.phoneNumber}
         processOrder(userInfo)
      }
   }

   const handleSubmitLoyalty = (cardNumber: string) => {
      if (cardNumber.trim() == '') {
         fireErrorAlert('Please enter a valid loyalty card number.')
         return false
      }
      if (formPaymentType == 'card') {
         const userInfo = {name: `${orderForm.firstName} ${orderForm.lastName}`, phone: orderForm.phoneNumber}
         processLoyaltyDiscount(cardNumber, userInfo)
      }
   }

   const handleSubmit = (values: IPayForm) => {
      checkKioskSettings().then((success) => {
         if (success) {
            setOrderForm(values)
            const userInfo = {name: `${values.firstName} ${values.lastName}`, phone: values.phoneNumber}
            setPhoneNumber(values.phoneNumber)
            if (formPaymentType == 'card') {
               if (loyaltyEnabled) {
                  setModalView('loyalty')
               } else {
                  processOrder(userInfo)
               }
            }
            if (formPaymentType == 'cash') {
               fireLoadingAlert('Processing...')
               if (loyaltyEnabled) {
                  setModalView('loyalty')
               }
               payInStore(userInfo)
                  .then((response) => {
                     hideLoader()
                     setTicket({id: response.id, number: response.ticket_number})
                     setModalView('order_completed')
                     ResetCart()
                  })
                  .catch((err) => {
                     console.log('[payInStore Error]', err)
                     hideLoader()
                     const standardError = parseRequestError(err)
                     let error_message = standardError.message
                     if (standardError.code < 0) {
                        error_message = intl.formatMessage({id: standardError.message})
                     }
                     fireErrorMessage(error_message)
                  })
            }
         }
      })
   }

   const handleCancelCCPayment = (event: SyntheticEvent<HTMLButtonElement>) => {
      const cancelButton = event.target as HTMLButtonElement
      cancelButton.disabled = true
      const auth = getAuth()
      const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/webhook/cancel`
      const payload = {
         global_on_id: KIOSK_GLOBAL_ON,
         user_id: auth?.user_id ?? 0,
         terminal_id: terminal.id,
         payment_terminal_id: terminal.paired_terminal_id,
         sale_session_id: saleSession.data?.id ?? 0,
      }
      axios
         .post(url, payload)
         .then((response) => {})
         .catch((error) => {
            const standardError = parseRequestError(error)
            let errorMessage = standardError.message
            if (standardError.code < 0) {
               errorMessage = intl.formatMessage({id: standardError.message})
            }
            fireErrorAlert(errorMessage)
         })
         .finally(() => {
            // Add delay to prevent button spamming
            sleep(2000).finally(() => (cancelButton.disabled = false))
         })
   }

   const startNewOrder = () => {
      navigate(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
      ResetCart()
   }

   useEffect(() => {
      if (modalViewType == 'default') {
         Inputmask({
            mask: '(999) 999-9999',
         }).mask('#phoneNumber')
      }
   }, [modalViewType])

   useEffect(() => {
      // Payment form clean up
      return () => {
         dispatch(updateTotalsWithNotTip())
         clearInputs(PAYMENT_FORM_FIELD_NAMES)
         setActiveInput('')
      }
   }, [])

   const HandleCloseModal = () => {
      toggleModal()
   }

   function onKeyDown(keyEvent: KeyboardEvent<HTMLFormElement>) {
      if (keyEvent.key === 'Enter') {
         keyEvent.preventDefault()
         const activeElement = document.activeElement as HTMLInputElement | null
         activeElement?.blur()
      }
   }

   const modalOnCloseHandler = () => {
      switch (modalViewType) {
         case 'order_completed':
         case 'receipt':
            startNewOrder()
            break
         default:
            HandleCloseModal()
      }
   }

   const getModalTitle = () => {
      switch (modalViewType) {
         case 'default':
            return 'Checkout'
         case 'receipt':
            if (ReceiptType == '') {
               return 'Thank You'
            } else {
               return undefined
            }
         case 'creating_ticket':
            return undefined
         default:
            return undefined
      }
   }

   return (
      <>
         <Modal
            tabIndex={-1}
            aria-hidden='true'
            show={true}
            onHide={() => {
               if (modalViewType == 'order_completed' || modalViewType == 'receipt') {
                  startNewOrder()
               } else if (modalViewType != 'card') {
                  toggleModal()
               }
            }}
            style={modalViewType == 'order_completed' ? {background: 'rgba(0,0,0,0.7)'} : {}}
            centered
            size='xl'
         >
            <div className='px-10 py-10 m-0'>
               <div>
                  <PaymentModalHeader
                     modalViewType={modalViewType}
                     ReceiptType={ReceiptType}
                     onClose={modalOnCloseHandler}
                     title={getModalTitle() ?? undefined}
                     children={
                        modalViewType != 'default' ? undefined : (
                           <div className='d-flex flex-equal gap-5 px-0 row align-items-center' data-kt-buttons='true'>
                              <div className='col d-flex justify-content-center'>
                                 <div
                                    className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center'
                                    style={{
                                       padding: '0px',
                                       minWidth: '280px',
                                       minHeight: '280px',
                                       maxWidth: '280px',
                                       maxHeight: '280px',
                                    }}
                                 >
                                    <div className=' my-auto mx-auto'>
                                       <span className='d-block fs-lg-2x fw-bold'>Order Total</span>
                                       <span className='d-block fs-lg-5x text-primary fw-bold'>
                                          ${getDisplayValue(cartTotals?.total)}
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )
                     }
                  />
                  {modalViewType == 'default' && (
                     <div className='modal-body'>
                        <div className='w-100 mb-5'>
                           <Formik
                              initialValues={orderForm}
                              validate={validatePayForm}
                              onSubmit={(values, error) => {
                                 handleSubmit(values)
                              }}
                           >
                              {({errors, touched}) => (
                                 <Form className='p-0 w-100' id='checkout-form' onKeyDown={onKeyDown}>
                                    <div className='row'>
                                       <div className='col-12 col-md-6 col-lg-6'>
                                          <div className='card p-1'>
                                             <label
                                                className={`fs-5 fw-bold mb-2 ${
                                                   kiosk_settings.checkout_name_required == STATUS.YES ? 'required' : ''
                                                }`}
                                             >
                                                First Name
                                             </label>

                                             <div className='d-flex p-0 m-0 ' id='basic-addon1'>
                                                <KeyboardField
                                                   id='firstName'
                                                   name={PAYMENT_FORM_FIELDS.firstName}
                                                   placeholder='First Name'
                                                   setActiveInput={setActiveInput}
                                                />
                                             </div>
                                          </div>
                                          <div className='text-danger ps-1'>
                                             <ErrorMessage name='firstName' />
                                          </div>
                                       </div>
                                       <div className='col-12 col-md-6 col-lg-6'>
                                          <div className='card p-1'>
                                             <label
                                                className={`fs-5 fw-bold mb-2 ${
                                                   kiosk_settings.checkout_name_required == STATUS.YES ? 'required' : ''
                                                }`}
                                             >
                                                Last Initial
                                             </label>

                                             <div className='d-flex p-0 m-0 ' id='basic-addon1'>
                                                <KeyboardField
                                                   id='lastName'
                                                   name={PAYMENT_FORM_FIELDS.lastName}
                                                   placeholder='Last Initial'
                                                   setActiveInput={setActiveInput}
                                                />
                                             </div>
                                          </div>
                                          <div className='text-danger ps-1'>
                                             <ErrorMessage name='lastName' />
                                          </div>
                                       </div>
                                    </div>
                                    <div className='card p-1 mt-3'>
                                       <label
                                          className={`fs-5 fw-bold mb-2 ${
                                             kiosk_settings.checkout_phone_number_required == STATUS.YES
                                                ? 'required'
                                                : ''
                                          }`}
                                       >
                                          Phone Number
                                       </label>

                                       <div className='d-flex p-0 m-0' id='basic-addon1'>
                                          <KeyboardField
                                             id='phoneNumber'
                                             name={PAYMENT_FORM_FIELDS.phoneNumber}
                                             placeholder='(000) 000-0000'
                                             setActiveInput={setActiveInput}
                                          />
                                       </div>
                                    </div>
                                    <div className='text-danger ps-1 mb-3'>
                                       <ErrorMessage name='phoneNumber' />
                                    </div>
                                 </Form>
                              )}
                           </Formik>
                        </div>
                        <h1 className='pb-10 m-0 text-center fs-lg-3x'>How would you like to pay?</h1>
                        {kiosk_settings.global_tips_types_id === 2 && (
                           <div className='w-100'>
                              <TipSelector
                                 activeTipOption={activeTipOption}
                                 dispatchTipSelection={dispatchNewTip}
                                 customTipFieldName={PAYMENT_FORM_FIELDS.customTipInput}
                              />
                           </div>
                        )}
                        <div
                           className='d-flex flex-equal gap-5 px-0 py-0 row align-items-center'
                           data-kt-buttons='true'
                           data-kt-buttons-target='[data-kt-button]'
                        >
                           {kiosk_settings?.pay_in_store == 1 && (
                              <div className='col d-flex justify-content-center'>
                                 <button
                                    data-kt-button='true'
                                    type='submit'
                                    form='checkout-form'
                                    className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center'
                                    style={{
                                       padding: '0px',
                                       minWidth: '180px',
                                       minHeight: '180px',
                                       maxWidth: '180px',
                                       maxHeight: '180px',
                                    }}
                                    onClick={() => {
                                       // setSelectedPaymentType('cash')
                                       fireErrorAlert('Service Coming Soon.')
                                       return false
                                    }}
                                 >
                                    <div className=' my-auto mx-auto'>
                                       <input className='btn-check' type='radio' name='method' value={0} />
                                       <i className='bi bi-cash-coin fs-2hx my-1 pe-0'></i>
                                       <span className='d-block fs-3 fw-bold'>Pay at Counter</span>
                                    </div>
                                 </button>
                              </div>
                           )}
                           {kiosk_settings?.pay_by_gift_card == 1 && (
                              <div className='col d-flex justify-content-center'>
                                 <button
                                    data-kt-button='true'
                                    type='submit'
                                    form='checkout-form'
                                    className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center '
                                    style={{
                                       padding: '0px',
                                       minWidth: '180px',
                                       minHeight: '180px',
                                       maxWidth: '180px',
                                       maxHeight: '180px',
                                    }}
                                    onClick={() => {
                                       setFormPaymentType('gift_card')
                                       fireErrorAlert('Service Coming Soon.')
                                    }}
                                 >
                                    <div className=' my-auto mx-auto'>
                                       <input className='btn-check' type='radio' name='method' value={1} />
                                       <i className='bi bi-gift fs-2hx mb-2 pe-0'></i>
                                       <span className='d-block fs-3 fw-bold'>Pay with Gift Card</span>
                                    </div>
                                 </button>
                              </div>
                           )}
                           {kiosk_settings?.pay_by_card == 1 && (
                              <div className='col d-flex justify-content-center'>
                                 <button
                                    data-kt-button='true'
                                    type='submit'
                                    form='checkout-form'
                                    className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center'
                                    style={{
                                       padding: '0px',
                                       minWidth: '180px',
                                       minHeight: '180px',
                                       maxWidth: '180px',
                                       maxHeight: '180px',
                                    }}
                                    onClick={() => {
                                       if (kiosk_settings.status === constants.status.OFF) {
                                          fireErrorAlert('Card Payments are currently offline')
                                       } else {
                                          setFormPaymentType('card')
                                          submitBtnFlag.current = true
                                       }
                                    }}
                                 >
                                    <div className=' my-auto mx-auto'>
                                       <input className='btn-check' type='radio' name='method' value={1} />
                                       <i className='bi bi-credit-card fs-2hx mb-2 pe-0'></i>
                                       <span className='d-block fs-3 fw-bold'>Pay with Card</span>
                                    </div>
                                 </button>
                              </div>
                           )}
                        </div>
                     </div>
                  )}
                  {modalViewType == 'card' && (
                     <CardModalBody imgPath={posSVGPath} onCancelPayment={handleCancelCCPayment} />
                  )}
                  {modalViewType == 'loyalty' && (
                     <LoyaltyModalBody onSubmit={handleSubmitLoyalty} onSkipLoyalty={handleSkipLoyalty} />
                  )}
                  {modalViewType == 'receipt' && (
                     <ReceiptsModalBody
                        setReceiptType={setReceiptType}
                        TicketId={createdOrder.ticket_id}
                        paymentId={createdOrder.payment_id}
                        phonePreview={phoneNumber}
                     />
                  )}
                  {modalViewType == 'order_completed' && (
                     <OrderCompletedModalBody
                        orderNumber={createdOrder.ticket_number}
                        paymentType={formPaymentType}
                        toggleReceiptModal={() => setModalView('receipt')}
                     />
                  )}
                  {modalViewType == 'gift_card' && (
                     <GiftCardModalBody
                        cartTotal={cartTotals?.total ? cartTotals.total / 100 : 0}
                        toggleGiftCardModal={() => setModalView('gift_card')}
                     />
                  )}
                  {modalViewType == 'creating_ticket' && <CreatingTicketModalBody />}
               </div>
            </div>
         </Modal>
      </>
   )
}

function getPosLogo() {
   return localStorage.getItem('kt_theme_mode_value') === 'light'
      ? 'media/misc/pos-light.gif'
      : 'media/misc/pos-modal-dark.gif'
}

/**
 * Validates the payment form fields
 * @param values
 * @param kiosk_settings
 */
function getFormErrors(values: IPayForm, kiosk_settings: KioskSettings) {
   let errors: {[key: string]: string} = {}

   if (kiosk_settings.checkout_name_required == constants.status.YES) {
      if (values.firstName == '') {
         errors.firstName = 'Required!'
      }
      if (values.lastName == '') {
         errors.lastName = 'Required!'
      }
   }
   if (kiosk_settings.checkout_phone_number_required == constants.status.YES) {
      if (values.phoneNumber == '') {
         errors.phoneNumber = 'Required!'
      }
   }

   if (values.phoneNumber !== '' && !PHONE_REGEX.test(values.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number.'
   }

   if (values.firstName !== '') {
      if (values.firstName.length < 2) {
         errors.firstName = 'First name is too short.'
      }
      if (values.firstName.length > 20) {
         errors.firstName = 'First name is too long.'
      }
   }
   return errors
}

type PaymentModalHeaderProps = {
   onClose: () => void
   title?: string | undefined
   children?: ReactNode
   modalViewType: ModalBodyView
   ReceiptType: '' | 'Phone' | 'Email'
}

/**
 * Title section of the payment modal
 * @param onClose
 * @param title
 * @param children
 * @param modalViewType
 * @param ReceiptType
 * @constructor
 */
function PaymentModalHeader({onClose, title, children, modalViewType, ReceiptType}: PaymentModalHeaderProps) {
   const viewsWithNoCloseBtn = ['card', 'creating_ticket']
   return (
      <>
         <div className='row'>
            <div className='col-11' style={{paddingLeft: '4rem'}}>
               {modalViewType == 'receipt' && ReceiptType == 'Phone' && (
                  <div className='text-center'>
                     <h1 className='text-center'>Enter your phone number</h1>
                     <span className='fs-4'>We'll send you a link of your receipt once your order is completed</span>
                  </div>
               )}
               {modalViewType == 'receipt' && ReceiptType == 'Email' && (
                  <div className='text-center'>
                     <h1 className='text-center'>Enter your email address</h1>
                     <span className='fs-4'>We'll email you your receipt once your order is completed</span>
                  </div>
               )}
               {title && (
                  <div className='text-center'>
                     <h1 className={`modal-title ${modalViewType != 'receipt' ? 'pb-10' : ''} fs-lg-3x`}>{title}</h1>
                  </div>
               )}
            </div>
            {!viewsWithNoCloseBtn.includes(modalViewType) && (
               <div className='col-1 text-end'>
                  <div
                     aria-label='Close'
                     data-bs-dismiss='modal'
                     onClick={onClose}
                     className='btn btn-icon btn-lg btn-active-light-primary me-4'
                  >
                     <i className='bi bi-x-lg fs-2x svg-icon'></i>
                  </div>
               </div>
            )}
         </div>
         {children && children}
      </>
   )
}

/**
 * Formats cent values to {Dollar}.{Cents} format
 * @param cents
 */
function getDisplayValue(cents?: number) {
   return cents ? (cents / 100).toFixed(2) : '0'
}

/**
 * Returns a custom error message to be displayed on an alert pop-up
 * @param errors
 */
function getValidationAlertMessage(errors: {[p: string]: string}): string {
   const keys = Object.keys(errors)
   if (keys.includes('firstName') || keys.includes('lastName')) {
      return 'Please enter your name.'
   }
   if (keys.includes('phoneNumber')) {
      return 'Please enter your phone number.'
   }
   return ''
}
