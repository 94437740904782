import axios from 'axios'
import {useEffect} from 'react'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {StoreKioskData} from '../../../services/KioskSettingSlice'
import {GetApiUrl, getAuth} from '../../auth'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {DownloadSettingsStep} from '../components/DownloadSettingsStep'
import './SettingsStyle.css'
import {TizenDebug} from '../../hotel/FaceAuth/TizenIntegration'
import {RootState} from '../../../store'
import {FormattedMessage, useIntl} from 'react-intl'
import {KioskSettingsSchema} from '../../../common/schemas/KioskSettingsSchema'
import {useInternationalization} from '../../i18n/i18nProvider'
import {parseRequestError} from '../../../common/utilities'
import {useErrorAlert} from '../../utilities/alerts'
import {KIOSK_GLOBAL_ON, constants} from '../../../config/constants'
import {LocationSchema} from '../../../common/schemas/LocationSchema'
import {StoreSelectedLocation} from '../../../services/SelectedLocationSlice'
interface PosRefreshModalProps {
   toggleModal: () => void
   stepTracker: number
   CallFullRefresh: boolean
}

export const PosRefreshModal: FC<PosRefreshModalProps> = ({toggleModal, stepTracker, CallFullRefresh}) => {
   const intl = useIntl()
   const dispatch = useDispatch<any>()
   let kiosk_settings = useSelector((state: any) => state.kiosk_settings.value)
   const terminalData = useSelector((state: RootState) => state.selected_terminal.value)
   const BtnClrClass = useLocationBasedBtnColor()
   const {setLanguage} = useInternationalization()
   const [refreshType, setRefreshType] = useState('')
   const [loading, setLoading] = useState(false)
   const [fullRefreshModal, setFullRefreshModal] = useState(false)
   const [quickRefreshModal, setQuickRefreshModal] = useState(false)
   const [CallSync, setCallSync] = useState<boolean>(false)
   const fireError = useErrorAlert()
   if (!kiosk_settings) {
      kiosk_settings = localStorage.getItem('kiosk_setting')
         ? JSON.parse(localStorage.getItem('kiosk_setting') as any)
         : null
   }

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      maxWidth: '300px',
      minWidth: '300px',
      height: '70px',
   }

   const handleProcess = () => {
      setLoading(true)

      if (refreshType == 'full_refresh') {
         KioskSettings('full_refresh')
         setFullRefreshModal(true)
      } else if (refreshType == 'quick_refresh') {
         KioskSettings('quick_refresh')
         setQuickRefreshModal(true)
      }
   }

   useEffect(() => {
      if (CallFullRefresh) {
         setLoading(true)
         KioskSettings('full_refresh')
         setFullRefreshModal(true)
      }
   }, [CallFullRefresh])

   useEffect(() => {
      if (CallSync) {
        let alertDetails;
        if (refreshType === 'full_refresh') {
          alertDetails = {
            titleId: 'setting.full_refresh.complete',
            defaultMessage: 'Full Refresh Successful',
          };
        } else if (refreshType === 'quick_refresh') {
          alertDetails = {
            titleId: 'setting.quick_refresh.complete',
            defaultMessage: 'Quick Refresh Successful',
          };
        }
   
        if (alertDetails) {
          const translatedTitle = intl.formatMessage({
            id: alertDetails.titleId,
            defaultMessage: alertDetails.defaultMessage,
          });
    
          Swal.fire({
            icon: 'success',
            title: translatedTitle,
            confirmButtonText: intl.formatMessage({ id: 'alert.ok_got_it' }),
            customClass: {
              confirmButton: `btn btn-primary btn-lg fs-1 px-10 py-8 ${BtnClrClass}`,
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              toggleModal();
            }
          });
        }
      }
   }, [CallSync])

   const NavigateToOrdering = () => {
      setCallSync(true)
      handleHideFullRefreshModal()
   }

   const handleHideFullRefreshModal = () => {
      setLoading(false)
      setFullRefreshModal(false)
      setQuickRefreshModal(false)
   }

   /**
    * API  call to /locations/{location_id}/settings/kiosk
    * @param dtype
    * @constructor
    */
   const KioskSettings = async (dtype: string) => {
      let startTime = performance.now()
      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${
         kiosk_settings.location_id
      }/settings/kiosk?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`

      try {
         const response = await axios.get(url)
         const cleanData = KioskSettingsSchema.validateSync(response.data)
         setLanguage(cleanData._embedded.location_info.locale)
         dispatch(StoreKioskData(cleanData))
         localStorage.setItem('kiosk_setting', JSON.stringify(cleanData))

         const locationDataEndpoint = `${GetApiUrl()}/location_data/${
            kiosk_settings.location_id
         }?with=products&global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}&has_products[]=${
            constants.global_ids.products.CHECK_IN
         }&has_products[]=${constants.global_ids.products.SELF_ORDERING}`
         const locationResponse = await axios.get(locationDataEndpoint)
         const location = LocationSchema.validateSync(locationResponse.data)
         dispatch(StoreSelectedLocation(location))

         if (dtype == 'full_refresh') {
            return true
         }
         let endTime = performance.now()
         let quicSyncCompletetionTime = endTime - startTime
         const translatedTitle = intl.formatMessage(
            {id: 'setting.pos_refresh.quick_refresh.complete', defaultMessage: 'Sync returned in {milliseconds} ms'},
            {milliseconds: quicSyncCompletetionTime.toFixed(1)}
         )
         const result = await Swal.fire({
            icon: 'success',
            title: translatedTitle,
            confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
            customClass: {
               confirmButton: `btn btn-primary btn-lg fs-1 px-10 h-70px mt-0 ${BtnClrClass}`,
            },
            buttonsStyling: false,
         })
         if (result.isConfirmed) {
            toggleModal()
         }
         setLoading(false)
         return cleanData
      } catch (err: any) {
         const standardError = parseRequestError(err)
         if (dtype == 'full_refresh' && standardError.code != -2) {
            return true
         }

         if (dtype == 'quick_refresh' && standardError.code != -2) {
            return true
         }
         console.log(err.response.status)
         if (typeof err.response !== 'undefined' && err.response.status == 401) {
            Swal.fire({
               icon: 'error',
               title: err.response.statusText,
               text: 'User token expired',
               confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
               customClass: {
                  confirmButton: `btn btn-primary ${BtnClrClass}`,
               },
               buttonsStyling: false,
            })
         } else {
            let translatedMessage = standardError.message
            if (standardError.code < 0) {
               translatedMessage = intl.formatMessage({id: standardError.message})
            }
            fireError(translatedMessage)
         }
         dispatch(StoreKioskData(null))
         localStorage.setItem('kiosk_setting', '')
      }
   }

   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog-centered mw-100 h-auto px-10'
         show={true}
         onHide={toggleModal}
      >
         {!fullRefreshModal && !quickRefreshModal ? (
            <div className='container-xxl px-10 py-5'>
               <div className='modal-dialog mw-100 p-0 m-0'>
                  <div className='modal-content'>
                     <div className='modal-header'>
                        <h5 className='modal-title fs-1 p-0'>
                           <FormattedMessage id='setting.pos_refresh' defaultMessage='POS Refresh' />
                        </h5>
                        <div className='text-end'>
                           <div
                              aria-label='Close'
                              data-bs-dismiss='modal'
                              onClick={toggleModal}
                              className='btn btn-icon btn-lg btn-active-light-primary'
                           >
                              <i className='bi bi-x-lg fs-2x svg-icon'></i>
                           </div>
                        </div>
                     </div>
                     {/* START::MODAL-BODY */}
                     <div className='tab-content'>
                        <div className='d-flex flex-row flex-column-fluid h-xl-100 pb-10 pt-5'>
                           <div className='d-flex flex-row-fluid flex-center'>
                              <div className='row theme-background py-3'>
                                 {
                                    <div
                                       onChange={(e: any) => {
                                          setRefreshType((e.target as HTMLButtonElement).value)
                                       }}
                                    >
                                       <label className='d-flex flex-stack cursor-pointer mb-10'>
                                          <span className='d-flex align-items-center me-2'>
                                             <span className='d-flex flex-column'>
                                                <span className='fw-bold fw-bold fs-1 mb-3'>
                                                   <FormattedMessage
                                                      id='setting.pos_refresh.quick_refresh'
                                                      defaultMessage='Quick Refresh'
                                                   />
                                                </span>
                                                <span className='fs-3 fw-bold text-muted'>
                                                   <FormattedMessage
                                                      id='setting.pos_refresh.check_recent_updates'
                                                      defaultMessage='Check for any recent updates done through the
                                                   dashboard.'
                                                   />
                                                </span>
                                             </span>
                                          </span>
                                          <span className='form-check form-check-custom form-check-solid'>
                                             <input
                                                className='form-check-input'
                                                type='radio'
                                                name='refresh_type'
                                                value='quick_refresh'
                                             />
                                          </span>
                                       </label>
                                       <label className='d-flex flex-stack cursor-pointer'>
                                          <span className='d-flex align-items-center me-2'>
                                             <span className='d-flex flex-column'>
                                                <span className='fw-bold fs-1 mb-3'>
                                                   <FormattedMessage
                                                      id='setting.full_refresh'
                                                      defaultMessage='Full Refresh'
                                                   />
                                                </span>
                                                <span className='fs-3 fw-bold text-muted'>
                                                   <FormattedMessage
                                                      id='setting.pos_refresh.resync_all_data'
                                                      defaultMessage='Resync all data from cloud. Please note this
                                                   device will not be accessible during this time
                                                   and it may take a few minutes. Once completed,
                                                   any additional terminal on the property would be
                                                   auto updated after 20 minutes or you can manually
                                                   run a POS Refresh'
                                                   />{' '}
                                                   &gt;{' '}
                                                   <FormattedMessage
                                                      id='setting.pos_refresh.quick_refresh'
                                                      defaultMessage='Quick Refresh'
                                                   />
                                                </span>
                                             </span>
                                          </span>
                                          <span className='form-check form-check-custom form-check-solid'>
                                             <input
                                                className='form-check-input'
                                                type='radio'
                                                name='refresh_type'
                                                value='full_refresh'
                                             />
                                          </span>
                                       </label>
                                    </div>
                                 }
                                 <div className='clear'></div>
                                 <div className='col-xl-12 col-md-12 col-sm-12 text-center mt-10'>
                                    <div className='d-flex  justify-content-between'>
                                       <button
                                          type='button'
                                          style={buttonStyle}
                                          className='btn btn-bg-secondary me-2 w-100'
                                          onClick={() => toggleModal()}
                                       >
                                          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
                                       </button>
                                       <button
                                          type='button'
                                          style={buttonStyle}
                                          disabled={!refreshType || loading ? true : false}
                                          className={`btn btn-bg-primary ms-2 w-100 ${BtnClrClass}`}
                                          onClick={handleProcess}
                                       >
                                          {loading
                                             ? `${intl.formatMessage({id: 'common.please_wait'})}...`
                                             : intl.formatMessage({id: 'common.process'})}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* END::MODAL-BODY */}
                  </div>
               </div>
            </div>
         ) : (
            //  : <FullRefresh setCallSync={setCallSync} handleHideFullRefreshModal={handleHideFullRefreshModal}/>

         <DownloadSettingsStep CallSync={true} onSettingsDownloaded={NavigateToOrdering} fullRefresh={fullRefreshModal} />
         )}
      </Modal>
   )
}
