/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {removeAuth, setAuth} from '../core/AuthHelpers'
import {login, CheckSSLRequest} from '../core/_requests'
import {ThemeModeComponent} from '../../../../_metronic/assets/ts/layout'
import Swal from 'sweetalert2'
import {LocationSchema} from '../../../common/schemas/LocationSchema'
import {array, ValidationError} from 'yup'
import {useErrorAlert} from '../../utilities/alerts'
import {constants} from '../../../config/constants'
import {parseRequestError} from '../../../common/utilities'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../../store'
import {setAuthLocations} from '../../../services/AuthLocationsSlice'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {useKeyboard} from '../../../services/hooks/useKeyboard'
import {RootState} from '../../../store'

export type ThemeModeType = 'dark' | 'light' | 'system'

const loginSchema = Yup.object().shape({
   email: Yup.string()
      .email('auth.validation.wrong_format')
      .min(3, 'auth.validation.minimum_3_symobols')
      .max(50, 'auth.validation.maximum_50_symobols')
      .required('auth.validation.email_required'),
   password: Yup.string()
      .min(3, 'auth.validation.minimum_3_symobols')
      .max(50, 'auth.validation.maximum_50_symobols')
      .required('auth.validation.password_required'),
})

const themeModeLSKey = 'kt_theme_mode_value'
const themeMenuModeLSKey = 'kt_theme_mode_menu'
const switchMode = (_mode: ThemeModeType) => {
   if (localStorage) {
      localStorage.setItem(themeModeLSKey, _mode)
      localStorage.setItem(themeMenuModeLSKey, _mode)
      document.documentElement.setAttribute('data-theme', _mode)
      ThemeModeComponent.init()
   }
}

const appEnv = process.env.REACT_APP_ENVIRONMENT
const envInDev = appEnv != undefined && (appEnv == 'development' || appEnv == 'local')
const initialValues = {
   email: envInDev ? 'morales@softpointcloud.com' : '',
   password: envInDev ? '123456!' : '',
}

interface loginResponseState {
   hasError: boolean
   errorMsg: string
}

const initLoginResponseState = {hasError: false, errorMsg: ''}

export const Login = () => {
   const appDispatch = useDispatch<AppDispatch>()
   const intl = useIntl()
   const [passShow, setPassShow] = useState(false)
   const fireAlert = useErrorAlert()
   const {getInputValue, onChangeInput, setActiveInput} = useKeyboard()
   const handlePassHideShow = () => {
      setPassShow(!passShow)
   }
   const _keyboard = useSelector((state: RootState) => state.keyboard)

   useEffect(() => {
      CheckSSLRequest()
      switchMode('dark')
   }, [])

   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()
   const [loginResponse, showError] = useState<loginResponseState>(initLoginResponseState)

   const formik = useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, {setSubmitting}) => {
         showError(initLoginResponseState)
         setLoading(true)
         login(values.email, values.password)
            .then(function (response) {
               const data = response.data
               const userToken = data.user.access_token as string
               const user_id = data.user.id as number
               setAuth({api_token: userToken, user_id: user_id})
               const locationsSchema = array(LocationSchema).required()
               locationsSchema
                  .validate(response.data.locations)
                  .then((locations) => {
                     appDispatch(setAuthLocations(locations))
                     navigate(MODULE_ROUTE_PATHS.terminal_configuration.index)
                  })
                  .catch((err: ValidationError) => {
                     setSubmitting(false)
                     setLoading(false)
                     console.log('validation error ', err.errors)
                     fireAlert(constants.errors.static.vital_parse_error).then((result) => {})
                  })
            })
            .catch(function (error) {
               const standardError = parseRequestError(error)

               let error_msg = standardError.message
               if ('message' in error && error.message === 'Network Error') {
                  error_msg = ` ${intl.formatMessage({id: 'header.alert.let_staff_know_terminal_is_offline'})}<br/><br/>
                  ${intl.formatMessage({id: 'header.alert.check_network_or_wifi'})}<br/>
                  ${intl.formatMessage({id: 'header.alert.check_other_device_are_online'})}`
               }

               removeAuth()
               setSubmitting(false)
               setLoading(false)
               Swal.fire({
                  icon: 'error',
                  title: error_msg,
                  confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
                  customClass: {
                     confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
               })

               showError({hasError: true, errorMsg: error_msg})
            })
      },
   })

   useEffect(() => {
      if (_keyboard.inputName == 'email') {
         formik.setFieldValue('email', getInputValue('email'))
      } else {
         formik.setFieldValue('email', initialValues.email)
      }
   }, [getInputValue('email')])

   useEffect(() => {
      if (_keyboard.inputName == 'password') {
         formik.setFieldValue('password', getInputValue('password'))
      } else {
         formik.setFieldValue('password', initialValues.password)
      }
   }, [getInputValue('password')])

   return (
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
         {/* begin::Form group */}
         <div className='fv-row mb-10'>
            {/* <label className='form-label fs-2tx fw-bolder text-dark'>Email</label> */}
            <input
               placeholder={intl.formatMessage({id: 'auth.email'})}
               {...formik.getFieldProps('email')}
               readOnly={true}
               className={clsx(
                  `form-control form-control-lg form-control-solid fs-2tx py-8 keyboard-input ${
                     _keyboard.inputName == 'email' && _keyboard.open ? 'keyboard-active' : ''
                  }`,
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                     'is-valid': formik.touched.email && !formik.errors.email,
                  }
               )}
               type='email'
               name='email'
               autoComplete='off'
               onFocus={() => setActiveInput('email')}
               onChange={onChangeInput}
            />
            {formik.touched.email && formik.errors.email && (
               <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                     <span role='alert'>{intl.formatMessage({id: formik.errors.email})}</span>
                  </div>
               </div>
            )}
         </div>
         {/* end::Form group */}

         {/* begin::Form group */}
         <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
               <div className='d-flex flex-stack mb-2'>
                  {/* <label className='form-label fw-bolder text-dark fs-2tx mb-0'>Password</label> */}
               </div>
            </div>
            <div className='position-relative col-xl-12 col-md-12 col-sm-12 flex-center mb-5 '>
               <input
                  readOnly={true}
                  type={passShow ? 'text' : 'password'}
                  placeholder={intl.formatMessage({id: 'auth.password'})}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                     `form-control form-control-lg form-control-solid fs-2tx py-8 keyboard-input ${
                        _keyboard.inputName == 'password' && _keyboard.open ? 'keyboard-active' : ''
                     }`,
                     {
                        'is-invalid': formik.touched.password && formik.errors.password,
                     },
                     {
                        'is-valid': formik.touched.password && !formik.errors.password,
                     }
                  )}
                  onFocus={() => setActiveInput('password')}
                  onChange={onChangeInput}
               />
            </div>
            {formik.touched.password && formik.errors.password && (
               <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                     <span role='alert'>{intl.formatMessage({id: formik.errors.password})}</span>
                  </div>
               </div>
            )}
         </div>

         {/* Login BTN */}
         <div className='text-center'>
            <button
               style={styles.primaryButtonColor}
               type='submit'
               id='kt_sign_in_submit'
               className='btn btn-lg w-100 mb-5 fs-2tx py-8'
               disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  formik.getFieldProps('email').value == '' ||
                  formik.getFieldProps('password').value == ''
               }
            >
               {!loading && (
                  <span className='indicator-label'>
                     <FormattedMessage id='pagetitle.login' defaultMessage='Log In' />
                  </span>
               )}
               {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                     <FormattedMessage id='common.please_wait' />
                     ...
                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
               )}
            </button>
         </div>
      </form>
   )
}
const styles = {
   primaryButtonColor: {
      backgroundColor: '#3398fa', //lighter blue color
   },
}
