import React, {ChangeEvent, FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import '../assets/CheckoutStyle.css'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'
import Inputmask from 'inputmask'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {NumberKeyPad} from './NumberKeyPad'
import {getAuth} from '../../../../app/modules/auth'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'

interface LoyaltyUiModalProps {
   onSubmit: (cardNumber: string) => void
   onSkipLoyalty: () => void
}

export const LoyaltyModalBody: FC<LoyaltyUiModalProps> = ({onSkipLoyalty, onSubmit}) => {
   const location_data = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings

   useEffect(() => {
      Inputmask({
         mask: '(999) 999-9999',
         oncomplete: function () {
            return
         },
      }).mask('#phone_num')
   }, [])

   const [cardNumber, setCardNumber] = useState<string>('')

   const handleNumberClick = (number: number) => {
      setCardNumber(cardNumber + number)
   }

   const handleBackspaceClick = () => {
      setCardNumber(cardNumber.slice(0, -1))
   }

   const handleSubmit = () => {
      onSubmit(cardNumber)
   }
   let logoPath = ''
   if (kiosk_settings?.icon) {
      const user_id = getAuth()?.user_id
      logoPath = `${process.env.REACT_APP_API_URL}/download-file?path=${kiosk_settings?.icon}&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${user_id}`
   }

   return (
      <div className='modal-body'>
         <div className='text-center'>
            {logoPath != '' ? (
               <img
                  alt='Logo'
                  src={logoPath}
                  className='h-200px w-200px rounded-1'
                  onError={({currentTarget}) => {
                     currentTarget.onerror = null
                     logoPath = ''
                  }}
               />
            ) : (
               <div className='card-title fw-bolder text-dark'>
                  <h1 style={{fontSize: 'calc(4.3rem + 0.6vw)'}} className='mb-3'>
                     {location_data?.name}
                  </h1>
               </div>
            )}
            <h1 style={{fontSize: 'calc(5.3rem + 0.6vw)'}} className='mb-3'>
               Loyalty Member
            </h1>
         </div>
         <div className=''>
            <div className='fs-1 text-center'>Are you a card member?</div>
            <div className='fs-2 text-center'>Earn points by entering your {location_data?.name} Rewards Phone.</div>
            <div className='w-100'>
               <div className='mt-10 w-100'>
                  <div className='keypad_input'>
                     <form className='p-0 w-100' id='loyalty-form' onSubmit={(event) => event.preventDefault()}>
                        <div className='card p-1 m-3'>
                           <div className='d-flex p-0 m-0' id='basic-addon1'>
                              <input
                                 id='cardNumber'
                                 type='text'
                                 value={cardNumber}
                                 onChange={() => {}}
                                 name='cardNumber'
                                 placeholder='Enter Card Number'
                                 className='form-control form-control-lg text-center'
                                 style={{height: '88px', fontSize: '2rem'}}
                              />
                           </div>
                        </div>
                     </form>
                  </div>

                  <NumberKeyPad onNumberClick={handleNumberClick} onDeleteClick={handleBackspaceClick} />
                  <div className='row justify-content-center'>
                     <div className='col-xl-3 col-md-4 col-sm-5'>
                        <div className='card card-flush pb-5 mw-100'>
                           <div onClick={handleSubmit}>
                              <div className='card-body text-center'>
                                 <div className='rounded-3 mb-4 w-200px h-200px w-xxl-200px h-xxl-200px mx-auto'>
                                    <img
                                       style={{backgroundColor: '#ecedf2', padding: '30px'}}
                                       src={'/media/icons/duotune/arrows/arr023.svg'}
                                       className='rounded-3 mb-4 w-200px h-200px w-xxl-200px h-xxl-200px'
                                       alt=''
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='col-xl-3 col-md-4 col-sm-5'>
                        <div className='card card-flush pb-5 mw-100'>
                           <div onClick={onSkipLoyalty}>
                              <div className='card-body text-center'>
                                 <div className='rounded-3 mb-4 w-200px h-200px w-xxl-200px h-xxl-200px mx-auto'>
                                    <img
                                       style={{backgroundColor: '#ecedf2', padding: '30px'}}
                                       src={'/media/icons/duotune/arrows/arr088.svg'}
                                       className='rounded-3 mb-4 w-200px h-200px w-xxl-200px h-xxl-200px'
                                       alt=''
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
