import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {constants} from '../config/constants'
import {SoftPointLocation} from '../common/schemas/LocationSchema'
import {boolean} from 'yup'
import {RootState} from '../store'

const globalProductIds = constants.global_ids.products
const statuses = constants.status

type ProductStatuses = {
   selfOrderingEnabled: boolean
   checkInIsEnabled: boolean
   reservationsEnabled: boolean
   qrCodeEnabled: boolean
   biometricsEnabled: boolean
   loyaltyEnabled: boolean
}

type DerivedLocationValues = {
   hasMultipleProducts: boolean
   product_statuses: ProductStatuses
}

export interface SelectedLocation {
   value: SoftPointLocation | null
   derived_values: DerivedLocationValues
}

const initialState: SelectedLocation = {
   value: null,
   derived_values: {
      hasMultipleProducts: false,
      product_statuses: {
         selfOrderingEnabled: false,
         checkInIsEnabled: false,
         reservationsEnabled: false,
         qrCodeEnabled: false,
         biometricsEnabled: false,
         loyaltyEnabled: false,
      },
   },
}

export const SelectedLocationSlice = createSlice({
   name: 'SelectedLocation',
   initialState,
   reducers: {
      StoreSelectedLocation: (state, action: PayloadAction<SoftPointLocation | null>) => {
         state.value = action.payload
         const locationData = action.payload
         if (locationData != null) {
            const locationProducts = locationData._embedded.location_products

            const checkInProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.CHECK_IN &&
                  product.status == statuses.ON &&
                  product.global_product_status == statuses.ON
               )
            })

            const selfOrderProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.SELF_ORDERING &&
                  product.status == statuses.ON &&
                  product.global_product_status == statuses.ON
               )
            })

            const reservationProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.RESERVATIONS &&
                  product.status == constants.status.ON &&
                  product.global_product_status == constants.status.ON
               )
            })

            const qrProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.QR_CODE &&
                  product.status == constants.status.ON &&
                  product.global_product_status == constants.status.ON
               )
            })

            const biometricProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.BIOMETRICS &&
                  product.status == constants.status.ON &&
                  product.global_product_status == constants.status.ON
               )
            })

            const loyaltyProduct = locationProducts.find((product) => {
               return (
                  product.global_product_id == globalProductIds.LOYALTY &&
                  product.status == constants.status.ON &&
                  product.global_product_status == constants.status.ON
               )
            })

            state.derived_values.product_statuses.checkInIsEnabled = checkInProduct != undefined
            state.derived_values.hasMultipleProducts = checkInProduct != undefined && selfOrderProduct != undefined
            state.derived_values.product_statuses.reservationsEnabled = reservationProduct != undefined
            state.derived_values.product_statuses.qrCodeEnabled = qrProduct != undefined
            state.derived_values.product_statuses.biometricsEnabled = biometricProduct != undefined
            state.derived_values.product_statuses.selfOrderingEnabled = selfOrderProduct != undefined
            state.derived_values.product_statuses.loyaltyEnabled = loyaltyProduct != undefined
         } else {
            state.derived_values.product_statuses.checkInIsEnabled = false
            state.derived_values.hasMultipleProducts = false
            state.derived_values.product_statuses.reservationsEnabled = false
            state.derived_values.product_statuses.qrCodeEnabled = false
            state.derived_values.product_statuses.biometricsEnabled = false
            state.derived_values.product_statuses.selfOrderingEnabled = false
            state.derived_values.product_statuses.loyaltyEnabled = false
         }
      },
   },
})

// Action creators are generated for each case reducer function
export const {StoreSelectedLocation} = SelectedLocationSlice.actions

export default SelectedLocationSlice.reducer

export const getSelectedLocation = (state: RootState): SoftPointLocation | null => state.location_data.value
export const getLocationProductStatus = (state: RootState): ProductStatuses =>
   state.location_data.derived_values.product_statuses
