import {Modal} from 'react-bootstrap'
import {
   ES_COUNTRIES,
   LANGUAGE_CODES,
   LANGUAGE_FLAGS,
   LANGUAGE_NAMES,
   SupportedLanguages,
   useInternationalization,
} from '../i18n/i18nProvider'
import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'
import {useLocationBasedBtnColor} from '../../common/hooks/buttons'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export type LanguageModalProps = {
   showModal: boolean
   setShowModal: Dispatch<SetStateAction<boolean>>
}

export const LanguageSettingsModal = ({showModal, setShowModal}: LanguageModalProps) => {
   const {setLanguage, selectedLang} = useInternationalization()
   const BtnClrClass = useLocationBasedBtnColor()
   const [activeLang, updateActiveLang] = useState<SupportedLanguages>(selectedLang)
   const [saveBtnIsDisabled, setDisableBtn] = useState(false)
   const selectedLocation = useSelector((state: RootState) => state.location_data.value)

   const handleAppLanguageSave = () => {
      setDisableBtn(true)
      setLanguage(activeLang)
      setShowModal(false)
   }

   useEffect(() => {
      return () => {
         if (!showModal) {
            setDisableBtn(false)
         }
      }
   }, [showModal])

   const getLocationCodes = () => {
      let languageCodes = [...LANGUAGE_CODES];
      if(selectedLocation?.global_country_id && ES_COUNTRIES.includes(selectedLocation.global_country_id)){
         if (languageCodes.indexOf('es') > 0) {
            languageCodes = languageCodes.filter(code => code != 'es');
            languageCodes.unshift('es');
        }        
      }
      return languageCodes;
   }

   return (
      <Modal show={showModal} size='sm' aria-labelledby='contained-modal-title-vcenter' centered>
         <Modal.Header onHide={() => setShowModal(false)} closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
               <FormattedMessage id='setting.language_modal.title' defaultMessage='Language Settings' />
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <h4>
               <FormattedMessage id='setting.language_modal.body_title' defaultMessage='Supported Languages' />
            </h4>
            <div className='overflow-y-auto border rounded bg-secondary' style={{height: '20em', overflowY: 'auto'}}>
               <div className='d-flex flex-column py-2'>
                  {getLocationCodes().map((langCode, index) => {
                     const highlight = activeLang === langCode ? ' rounded-theme-highlight' : ''
                     return (
                        <div
                           key={index + 1}
                           className={`py-3 px-2 d-flex${highlight} align-items-center justify-content-between`}
                           onClick={() => updateActiveLang(langCode)}
                        >
                           <div className='fs-3'>{LANGUAGE_NAMES[langCode]}</div>
                           <div>
                              <img src={LANGUAGE_FLAGS[langCode]} alt='' style={{maxWidth:'30px', height:'auto'}} width='30px'/>
                           </div>
                        </div>
                     )
                  })}
               </div>
            </div>
            <div className='pt-5 d-flex justify-content-between'>
               <button type='button' className='btn btn-bg-secondary' onClick={() => setShowModal(false)}>
                  <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
               </button>
               <button
                  type='button'
                  className={`btn btn-primary ${BtnClrClass}`}
                  onClick={handleAppLanguageSave}
                  disabled={saveBtnIsDisabled}
               >
                  <FormattedMessage id='alert.confirm' defaultMessage='Confirm' />
               </button>
            </div>
         </Modal.Body>
      </Modal>
   )
}
