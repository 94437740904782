import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useCart} from '../../../services/hooks/useCart'
import {RootState} from '../../../store'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {useWarningConfirmAlert} from '../../utilities/alerts'
import {useKioskProducts} from '../../../common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {setOrderType} from '../../../services/SelfOrderingUserSlice'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'

interface ProductCheckoutFooterProps {
   // total: number;
}
export const CheckoutFooter: React.FC<ProductCheckoutFooterProps> = (
   {
      // total
   }
) => {
   const homePaths = {
      dashboard: MODULE_ROUTE_PATHS.dashboard.index,
      ordering: MODULE_ROUTE_PATHS.ordering.ORDER_HOME,
      checkIn: MODULE_ROUTE_PATHS.checkIn.check_in_home,
   }

   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings

   const fireConfirmAlert = useWarningConfirmAlert()
   const dispatch = useDispatch()
   const navigateTo = useNavigate()
   const BtnClrClass = useLocationBasedBtnColor()
   const {checkInEnabled, orderingEnabled, DineinAndTakeOutBothEnable} = useKioskProducts()
   const {GetItems, ResetCart, ShowAddToCartSuccessModal} = useCart()
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)
   const [cancelDisabled, updateCancelBtn] = useState(false)

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      maxWidth: '300px',
      minWidth: '300px',
      height: '70px',
   }
   const NavigateToMenuPage = () => {
      navigateTo(homePaths.ordering)
   }
   const navigateUrl = () => {
      updateCancelBtn(true)
      fireConfirmAlert('Are you sure you want to cancel your order?', 'No', 'Yes').then((result) => {
         if (result.isConfirmed) {
            ResetCart()
            orderingEnabled && checkInEnabled
               ? navigateTo(homePaths.dashboard)
               : checkInEnabled
               ? navigateTo(homePaths.checkIn)
               : orderingEnabled
               ? NavigateToMenuPage()
               : navigateTo(homePaths.dashboard)
         } else {
            updateCancelBtn(false)
         }
      })
   }

   const cartItemsCount = () => {
      const count = GetItems().reduce((quantity, item) => {
         return quantity + item.quantity
      }, 0)
      return count == 0 ? '' : `(${count})`
   }

   return (
      <>
         <div
            className='container-fluid d-flex align-items-stretch justify-content-between fixed-bottom bg-light border-top footer-bg-color'
            style={{height: '100px', zIndex: '101'}}
         >
            <div className='col-5 my-auto'>
               <button
                  type='button'
                  className='btn btn-bg-secondary me-2'
                  style={buttonStyle}
                  onClick={navigateUrl}
                  disabled={cancelDisabled}
               >
                  Cancel
               </button>
            </div>
            <div className='col-7 my-auto text-right d-flex justify-content-end '>
               <h1 className='me-10  my-auto'>TOTAL: ${(cartTotals.total / 100).toFixed(2)}</h1>
               {ShowAddToCartSuccessModal ? (
                  <button
                     type='button'
                     className={`ms-3 btn btn-success btn-lg ${BtnClrClass}`}
                     style={buttonStyle}
                     onClick={() => {
                        if (GetItems().length > 0) {
                           navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT)
                        }
                     }}
                     disabled={GetItems().length <= 0}
                  >
                     <i style={{float: 'left', fontSize: '20px'}} className={`bi py-3 ps-4 pe-0 bi-check-lg`}></i>
                     Item added to cart.
                  </button>
               ) : (
                  <button
                     type='button'
                     className={`ms-3 btn btn-primary btn-lg ${BtnClrClass}`}
                     style={buttonStyle}
                     onClick={() => {
                        if (GetItems().length > 0) {
                           navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_CHECKOUT)
                        }
                     }}
                     disabled={GetItems().length <= 0}
                  >
                     View My Order {cartItemsCount()}
                  </button>
               )}
            </div>
         </div>
      </>
   )
}
