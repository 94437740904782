export const MOBILE_URL = process.env.REACT_APP_MOBILE_URL
export const KIOSK_GLOBAL_ON: string = process.env.REACT_APP_KIOSK_GLOBAL_ON ?? '3'

export const API_KEY = process.env.REACT_APP_API_KEY ?? ''

export const PAYMENT_TIMEOUT = process.env.REACT_APP_PAYMENT_SERVICE_TIMEOUT
   ? parseInt(process.env.REACT_APP_PAYMENT_SERVICE_TIMEOUT)
   : 125

export const constants = {
   staticPageTitle: {
      login: 'pagetitle.login',
      select_location: 'pagetitle.select_location',
      select_terminal: 'pagetitle.select_terminal',
      create_terminal: 'pagetitle.create_terminal',
      sync: 'pagetitle.sync',
      dashboard: 'pagetitle.dashboard',
      checkin: 'pagetitle.checkin',
      signin: 'pagetitle.signin',
      confirmation: 'pagetitle.confirmation',
      order: 'pagetitle.order',
      emp_login: 'pagetitle.emp_login',
      checkout: 'pagetitle.checkout',
      cart: 'pagetitle.cart',
      order_type: 'pagetitle.order_type',
      confirm: 'pagetitle.confirm',
      settings: 'pagetitle.setttings',
   } as const,
   flows: {
      checkin: 'checkin',
      ordering: 'ordering',
      checkin_age_gate: 'age_gate',
      checkin_reservation: 'reservation',
   },
   theme: {
      lightMode: '#fffff',
      darkMode: '#151521',
      warning: '#FABB05',
      danger: '#d9214e',
   },
   status: {
      location: {
         appearance: {
            lightMode: 1,
            darkMode: 0,
         },
      },
      ON: 1,
      OFF: 0,
      YES: 1,
      NO: 0,
      complete: 1,
      incomplete: 0,
   },
   integrations: {
      biometrics: {
         global_integration_id: 2,
      },
      qr_code: {
         global_integration_id: 26,
      },
      self_ordering: {
         global_integration_id: 28,
      },
      check_in: {
         softpoint_global_id: 24,
         vidanta_global_id: 41,
      },
      loyalty: {
         global_integration_type_id: 9,
      },
   },
   global_biometrics: {
      service_type: {
         auth: {
            id: 1,
            type: 'AUTH',
         },
         compare: {
            id: 2,
            type: 'COMPARE',
         },
         phone_callback: {
            id: 3,
            type: 'PHONE_FALLBACK',
         },
      },
      data_types: {
         tfv5: {
            id: 1,
         },
         id: {
            id: 2,
         },
      },
   },
   errors: {
      common: {
         external_api_error: 10146,
         missing_required_params: 10109,
      },
      biometrics: {
         guest_registration_not_found: 10814,
         guest_biometrics_out_of_sync: 10899,
         biometrics_many_guests_found: 10904,
         biometrics_offline_error: 10416,
         no_tf_guest_match_found: 10675,
         no_guest_registration_redirecting_phone: 10690,
         underage: 10985,
         no_face_match_redirect_qr: 10998,
         false_positive_threshold_guard: 11060,
         missing_biometric_registration_step: 19132,
         missing_id_verification_registration_step: 19133,
         missing_cof_registration_step: 19134,
      },
      reservations: {
         guest_without_pre_check_in: 10894,
         precheckin_not_complete: 10898,
      },
      create_auth_code: {
         verification_code_error: 10772,
      },
      static: {
         unexpected_contact_support: 'error.alert.unexpected_contact_support',
         unexpected_redirect: 'error.alert.unexpected_redirect',
         default_error: 'error.alert.default_error',
         biometric_auth_timeout: 'error.alert.biometric_auth_timeout',
         vital_parse_error: 'error.alert.vital_parse_error',
         missing_vital_config_error: 'error.alert.missing_vital_config_error',
         missing_revenue_center: 'Missing revenue center configuration.',
      },
      create_ticket: {
         payment_declined: 11078,
         loyalty_balance_not_sufficient: 11081,
         webhook_timeout: 10646,
         sock_connection_error: 10644,
         failed_payment_match: 11096,
      },
      pos_check: {
         not_external_pos: 10659,
      },
   },
   global_ids: {
      products: {
         SELF_ORDERING: 2,
         CHECK_IN: 6,
         RESERVATIONS: 9,
         QR_CODE: 4,
         BIOMETRICS: 7,
         LOYALTY: 11,
      },
      manufacturer_terminals: {
         clover: [1, 2, 3, 4, 5],
         pax: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 44],
      },
      reservation_types: {
         RESTAURANT: 1,
         ROOM: 2,
      },
   },
   global_tip_types: {
      tip_before: 2,
   },
}

type PageTitleKeys = keyof typeof constants.staticPageTitle
export type PageTitles = typeof constants.staticPageTitle[PageTitleKeys]
