import {camFeedHolderStyles} from './styles'
import {LoadingEllipses} from '../../../auth/components/LoadingEllipses'

interface props {
   message: string
}

export const CamFeedPlaceholder = ({message}: props) => {
   return (
      <div className='d-flex justify-content-center align-items-center' style={camFeedHolderStyles}>
         <span style={{fontSize: '4rem'}}>{message}</span>
      </div>
   )
}
