/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLocation} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {HeaderWrapper} from '../../../_metronic/layout/components/header/HeaderWrapper'
import {StoreHeaderPageTitle} from '../../services/HeaderPageTitleSlice'
import {useDispatch} from 'react-redux'
import {constants} from '../../config/constants'
import {useEffect} from 'react'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {FormattedMessage, useIntl} from 'react-intl'
import {AppDispatch} from '../../store'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'


const AuthScreen = () => {
   const intl = useIntl()
   let location = useLocation()
   const dispatch = useDispatch<AppDispatch>()
   useEffect(() => {
      dispatch(StoreHeaderPageTitle(intl.formatMessage({id: constants.staticPageTitle.login})))
      localStorage.setItem('kt_theme_mode_value', 'dark')
   }, [])

   // login screen always in dark mode
   let logoPath = '../media/logos/softpoint_small.svg'
   let fullLogoPath = '../media/logos/softpoint_logo_app_white.svg'

   return (
      <div className='d-flex flex-column pt-5' style={{height: '100vh'}}>
         <HeaderWrapper
            to={MODULE_ROUTE_PATHS.app_auth.index}
            useSVG={true}
            viewDateTime={false}
            styles={{height: 'auto'}}
            imgClassName={'svg-icon-2tx'}
            pathname={location.pathname}
            LogoFullPath={false}
            imgURL={logoPath}
         />
         <div className='flex-grow-1 d-flex flex-column justify-content-center p-8 pt-20 mt-20'>
            <div className='card container-fluid h-100 justify-content-center'>
               <div className='d-flex flex-column'>
                  <div className='text-center'>
                     <a href='#' className='mb-12'>
                        <img alt='Logo' src={toAbsoluteUrl(fullLogoPath)} className='h-150px mt-5' />
                     </a>
                     <h2 className='fs-2tx py-10'>
                        <FormattedMessage id='auth.login_to_softpoint' defaultMessage='Log In to SoftPoint' />
                     </h2>
                  </div>
                  <Login />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export {AuthScreen}
