import Swal from "sweetalert2";
import {GetApiUrl} from "../../auth";
 
export function captureNSend(camIP:string, fileInputID:string, userID:number|undefined){
    // The supported resolutions by Axis can be found in AxisIntegration.ts file
    const imgResolution = {
        width: 960,
        height: 1280
    }

    const listeners = {
        /* When the download progresses (interval is platform-dependent) */
        onprogress: function (id:any, receivedSize:any, totalSize:any) {
            TizenDebug('Received with id: ' + id + ', ' + receivedSize + '/' + totalSize);
            console.log('Received with id: ' + id + ', ' + receivedSize + '/' + totalSize);
        },

        /* When the user pauses the download */
        onpaused: function (id:any) {
            // Debug('Paused with id: ' + id);
            console.log('Paused with id: ' + id);
        },

        /* When the user cancels the download */
        oncanceled: function (id:any) {
            // Debug('Canceled with id: ' + id);
            console.log('Canceled with id: ' + id);
            Swal.close()
        },

        /* When the download is completed */
        oncompleted: function (id:any, fullPath:any) {
            // console.log('Completed with id: ' + id + ', full path: ' + fullPath);
            // sendMessage('Completed with id: ' + id + ', full path: ' + fullPath);
            const selfieEle = new Image()
            //@ts-ignore
            const MIMEType = tizen.download.getMIMEType(id);
            TizenDebug('The MIME type of the download is: ' + MIMEType);
            selfieEle.src = fullPath;
            selfieEle.onload = function () {
                try {
                    // const content = document.getElementById('CameraPage')
                    // if(content !== null){
                    //     content.appendChild(selfieEle)
                    //     selfieEle.style.position = 'absolute'
                    //     selfieEle.style.zIndex = '50'
                    //     selfieEle.style.left = '40%'
                    // }

                    const canvas = document.createElement('canvas');
                    canvas.width = imgResolution.width
                    canvas.height = imgResolution.height
                    const ctx = canvas.getContext('2d');
                    if(ctx == null){
                        throw new Error('Unable To Create Context Element')
                    }
                    //@ts-ignore
                    ctx.drawImage(selfieEle, 0, 0);

                    const formData = new FormData();
                    const dataURL = canvas.toDataURL('image/jpeg');

                    const myFile = dataURLtoFile(dataURL, 'myFile-11-11-2022');
                    formData.append('file', myFile);

                    // if (formData.get('file') != null) {
                    //     Debug('File loaded into form.');
                    // } else {
                    //     Debug('Failed to load file into form.');
                    // }

                    const request = new XMLHttpRequest();
                    request.open('POST', `${GetApiUrl()}/global/upload-file?user_id=${userID}&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}`);

                    request.onload = function () {
                        const fileInputNode = document.getElementById(fileInputID) as HTMLInputElement
                        TizenDebug(request.status);
                        TizenDebug(request.responseText);
                        if(fileInputNode !== null){
                            // fileInputNode.value = request.responseText;
                            // fileInputNode.dispatchEvent(new Event('change'));
                            // Debug(`Event dispatched on ${fileInputID}`)
                            dispatchSyntheticEvent(fileInputID, request.responseText)
                        }

                    };

                    request.onerror = function () {
                        TizenDebug('An error occurred while uploading the image.');
                        const fileInputNode = document.getElementById(fileInputID) as HTMLInputElement
                        if(fileInputNode !== null){
                            fileInputNode.value = JSON.stringify({error:{code:-1, message:'An error occurred while uploading the image.', details:null}});
                            fileInputNode.dispatchEvent(new Event('change'));
                        }
                        Swal.close()
                    }

                    request.send(formData);
                } catch (err:any) {
                    const fileInputNode = document.getElementById(fileInputID) as HTMLInputElement
                    if(fileInputNode !== null){
                        fileInputNode.value = JSON.stringify({error:{code:-1, message:err.message, details:null}});
                        fileInputNode.dispatchEvent(new Event('change'));
                    }
                    Swal.close()
                }
            }
        },

        /* When the download fails */
        onfailed: function (id:any, error:any) {
            TizenDebug('Failed with id: ' + id + ', error name: ' + error.name);
            Swal.close()
        }
    }

    const snapShotQuery = `resolution=${imgResolution.height}x${imgResolution.width}&camera=1&rotation=270`
    //@ts-ignore
    let downloadReq = new tizen.DownloadRequest(`http://${camIP}/axis-cgi/jpg/image.cgi?${snapShotQuery}`, 'downloads');
    if(downloadReq != null){
        //@ts-ignore
        const downloadID = tizen.download.start(downloadReq, listeners);
    }


}

function dataURLtoFile(data_url:any, filename:any){
    const arr = data_url.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
}


export function TizenDebug(message:any){
    const messages = document.getElementById('debug_messages')
    const debugBox = document.getElementById('debug_box')
    if(debugBox !== null && messages !== null){
        if(debugBox.style.display === 'none'){
            debugBox.style.display = 'block'
        }
        const text = document.createTextNode(`${message}`)
        const listItem = document.createElement('li')
        listItem.appendChild(text)
        messages.appendChild(listItem)
    }
}

function dispatchSyntheticEvent(elementID:string, value:string){
    try{
        const uploadedFile = document.getElementById(elementID) as any
        const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set
        valueSetter!.call(uploadedFile, value)
        const myEvent = new Event('input', {bubbles: true})
        uploadedFile.dispatchEvent(myEvent)
    }
    catch (error:any){
        TizenDebug(`${error.message}`)
    }
}