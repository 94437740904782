import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NoWifiSVG } from "../../../_metronic/assets/ts/components/NoWifiSVG";
import { useKioskProducts } from "../../common/hooks/useKioskProducts";
import { clientIsTizen } from "../../common/tizen/helpers";
import { MODULE_ROUTE_PATHS } from "../../routing/RoutePaths";
import { StoreHeaderPageTitle } from "../../services/HeaderPageTitleSlice";
import { AppDispatch, RootState } from "../../store";


const OfflinePage = () => {
    const intl = useIntl()
    let navigateTo = useNavigate()
    const tizenSystem = useSelector((state: RootState) => state.tizen_system)
    const {orderingEnabled, checkInEnabled} = useKioskProducts()
    const dispatch = useDispatch<AppDispatch>()
    dispatch(StoreHeaderPageTitle(intl.formatMessage({id: "error.offline"})))

    useEffect(() => {
        if(tizenSystem.network.available && clientIsTizen()){
            if(orderingEnabled && checkInEnabled){
                navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
             }else if(orderingEnabled){
                navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
             }else if(checkInEnabled){
                navigateTo(MODULE_ROUTE_PATHS.checkIn.check_in_home)
             }else{
                navigateTo(MODULE_ROUTE_PATHS.app_auth.index)                           
             }
        }
     }, [tizenSystem.network.available])
    
    return (
        <div className='h-100 d-flex justify-content-center'>
            <div className='h-100 d-flex flex-column justify-content-center'>
                <div className='d-flex flex-column align-items-center'>
                    <NoWifiSVG fill="black" width={150} height={150} />
                </div>
                <h1 className='fs-md-2x pe-5 text-center'>
                    <FormattedMessage id='header.alert.check_network_or_wifi' defaultMessage='Check your network cable or WiFi connection.'/></h1> 
            </div>
        </div>
    )
};

export default OfflinePage