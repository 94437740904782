import { useEffect } from 'react'
import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useWarningAlert } from '../../utilities/alerts'
import { useLocationBasedBtnColor } from '../../../common/hooks/buttons'
import { ModGroupsUI, useSelectedModifiers } from './ItemModifiersProvider'
import { ItemModifier } from '../../../common/schemas/SyncDataSchema'
import { getModDictionary, getModGroupDictionary } from '../../../services/SelfOrderingDataSlice'
import { ModifierGroupAccordion } from './ModifierGroupAccordion'
import { ISelfOrderingCartItemModifier } from '../../../services/SelfOrderingCartSlice'

interface NestedModifierModalProps {
   toggleModal: () => void
   modifier: ItemModifier
   modifier_group_id: number | undefined
}

export const NestedModifierModal: FC<NestedModifierModalProps> = ({ toggleModal, modifier, modifier_group_id }) => {
   const intl = useIntl()
   const BtnClrClass = useLocationBasedBtnColor()
   const { initModGroups, addModGroups, mod_groups } = useSelectedModifiers()
   const modGroupDictionary = useSelector(getModGroupDictionary)
   const modDictionary = useSelector(getModDictionary)
   var [cloneModGroups, setCloneModeGroups] = useState<Array<ModGroupsUI>>([]);
   const fireWarningAlert = useWarningAlert()

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      maxWidth: '300px',
      minWidth: '300px',
      height: '70px',
   }

   useEffect(() => {
      if (mod_groups && cloneModGroups.length == 0) {
         setCloneModeGroups(JSON.parse(JSON.stringify(mod_groups)));
      }
   }, [mod_groups])

   useEffect(() => {
      if (modifier && modifier_group_id)
         fetchItem()
   }, [])

   const getDisplayName = (item: { name_info: string | null; name: string | null }) => {
      if (item.name_info && item.name_info != '') {
         return item.name_info
      }
      if (item.name && item.name != '') {
         return item.name
      }
      return ''
   }

   const fetchItem = () => {
      if (modifier && modDictionary && modGroupDictionary) {
         const selectedModGroups: ModGroupsUI[] = []

         modifier?._embedded?.mod_groups?.forEach((group) => {
            if (!getNestedModeGroups().find((existingGroup) => existingGroup.modifier_group_id == group.id && existingGroup.id_external == group.id_external)) {
               const modGroupDB = modGroupDictionary[group.id]
               if (!modGroupDB) return
               if (!modGroupDB._embedded.mods) return
               const modifiers: ItemModifier[] = []
               modGroupDB._embedded.mods.forEach((mod) => {
                  modifiers.push(modDictionary[mod.id])
               })

               if (modifiers.length) {
                  let required = group?.required == 1 ? group.required : 0
                  selectedModGroups.push({
                     id_external: group.id_external ?? '',
                     name: getDisplayName({ name_info: modGroupDB?.name_info, name: modGroupDB?.name }),
                     modifiers: modifiers,
                     min: group.min ?? 0,
                     max: group.max ?? 0,
                     modifier_group_id: group.id ?? 0,
                     required: required ?? 0,
                     selected_modifiers: [],
                     removed_included_modifiers: [],
                     included_modifiers: [],
                     parent_modifier_id: modifier.id,
                     parent_modifier_group_id: modifier_group_id
                  })
               }
            }
         })
         addModGroups(selectedModGroups)
      }
   }

   function getNestedModeGroups() {
      return mod_groups.filter((group) => group.parent_modifier_id == modifier.id && group.parent_modifier_group_id == modifier_group_id);
   }

   const cancelSelection = () => {
      initModGroups(cloneModGroups);
      toggleModal();
   }

   const continueWithSelection = () => {
      const nested_mod_groups = getNestedModeGroups();
      const groupedModifiers = nested_mod_groups.map((modGroup) => modGroup.selected_modifiers)
      const modifiers = groupedModifiers.reduce((accum, mods) => {
         return accum.concat(mods)
      }, [])

      let validated = true
      getNestedModeGroups().some((modgroup, index: number) => {
         let min = modgroup.min ? modgroup.min : 0
         let max = modgroup.max ? modgroup.max : 0
         const modCount = modifiers.filter((e) => e.modgroup_id == modgroup.modifier_group_id).reduce((total: number, mod: ISelfOrderingCartItemModifier) => {
            return total + mod.quantity
         }, 0)

         //let modCount = modifiers.hasOwnProperty(modgroup.id) ? Object.keys(modifiers[modgroup.id]).length : 0
         if (modgroup.required == 1 && min > 0 && modCount == 0) {
            fireWarningAlert('Please select minimum required modifiers from each required Group.')
            validated = false
            return true
         } else if (modgroup.required == 1 && min > 0 && modCount < min) {
            fireWarningAlert(`${modgroup.name} requires ${min} modifiers, please select them to proceed.`)
            validated = false
            return true
         } else if (modCount > 0 && min > 0 && modCount < min) {
            fireWarningAlert(`${modgroup.name} requires ${min} modifiers, please select them to proceed.`)
            validated = false
            return true
         }
      })
      if (!validated) return
      toggleModal();
   }

   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         dialogClassName='modal-dialog-centered mw-80 px-10'
         show={true}
         backdrop="static"
         onHide={toggleModal}
      >
         <div className='container-xxl px-10 py-5'>
            <div className='modal-dialog mw-100 p-0 m-0'>
               <div className='modal-content'>
                  <div className='modal-header'>
                     <h5 className='modal-title fs-1 p-0'>
                        {modifier.name}
                     </h5>
                  </div>
                  <div className='modal-body' style={{ minHeight:'800px', maxHeight: 'calc(100vh - 500px)', overflowY: 'auto' }}>
                     <div className='w-100' >
                        {getNestedModeGroups().map((mod, index: number) => (
                           <ModifierGroupAccordion title={mod.name} modInfo={mod} key={index} setToShowNestedModifier={() => { }} />
                        ))}
                     </div>
                  </div>
                  <div className="modal-footer">
                     <div className='d-flex flex-row-fluid flex-center'>
                        <button
                           type='button'
                           style={buttonStyle}
                           className='btn btn-bg-secondary me-2 w-100'
                           onClick={cancelSelection}
                        >
                           <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
                        </button>
                        <button
                           type='button'
                           style={buttonStyle}
                           className={`btn btn-bg-primary ms-2 w-100 ${BtnClrClass}`}
                           onClick={continueWithSelection}
                        >
                           {intl.formatMessage({ id: 'common.continue' })}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   )
}
