import {SweetAlertSpinner} from '../../../common/components/SweetAlertSpinner'
export const CreatingTicketModalBody = () => {
   return (
      <div className='modal-body text-center'>
         <div>
            <h1 className='text-center fs-lg-3x'>Creating your ticket, please wait.</h1>
         </div>
         <div className='d-flex justify-content-center align-items-center' style={{height: '500px'}}>
            <SweetAlertSpinner size={'med'} />
         </div>
      </div>
   )
}
