import {array, InferType, number, object, string} from 'yup'

export const ItemModGrpSchema = object({
   id_external: string().nullable().defined(),
   max: number().nullable().defined(),
   min: number().nullable().defined(),
   required: number(),
   option: number(),
   modifier_group_id: number().required(),
   included_modifier: array(
      object({
         modifier_id: number().required(),
      })
   )
      .nullable()
      .defined(),
})
export const ItemPrinterSchema = object({
   // TODO: Try to remove this, is empty in /app/menu/categories
   id: number().required(),
   status: number().required(),
   location_id: number().required(),
   global_printer_id: number(),
   name: string().nullable().defined(),
   internal_printer: number(),
   serial_number: string().nullable().defined(),
   ip: number().nullable().defined(),
   cash_drawer: number(),
   backup_printer: number(),
})
export const ItemEmbeddedSchema = object({
   mod_groups: array(ItemModGrpSchema).nullable().defined(),
   tax_rate: object({
      id: number().required(),
      name: string().nullable().defined(),
   })
      .nullable()
      .defined(),
})
export const ItemsSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   name_info: string().nullable().defined(),
   description: string().nullable().defined(),
   price_per_unit: number().nullable().defined(),
   open: number().required(),
   kiosk: number().required(),
   status: number().required(),
   image: string().nullable().defined(),
   _embedded: ItemEmbeddedSchema.required(),
   categoryId: number().nullable(),
   weakUuid: number().nullable(),
})
export type CategoryItem = InferType<typeof ItemsSchema>

export const MenuEmbeddedSchema = object({
   items: array(ItemsSchema).required(),
})
export const MenuCategorySchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   background_color: string().nullable(),
   start_time: string().nullable().defined(),
   end_time: string().nullable().defined(),
   available_dow: string().nullable(),
   image: string().nullable().defined(),
   _embedded: MenuEmbeddedSchema.required(),
})

export type MenuCategory = InferType<typeof MenuCategorySchema>

export const MenuCategoriesSchema = array(MenuCategorySchema).required()

export const RevenueCenterSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   image: string().nullable().defined(),
   x: number(),
   y: number(),
   default: number(),
})

export type RevenueCenter = InferType<typeof RevenueCenterSchema>

export const RevenueCentersSchema = array(RevenueCenterSchema).required()

export const TaxRateSchema = object({
   id: number().required(),
   status: number().required(),
   name: string().nullable().defined(),
   exclusive: number().nullable().defined(),
   rate: number().nullable().defined(),
   default: number().nullable().defined(),
})

export type ItemTaxRate = InferType<typeof TaxRateSchema>

export const TaxRatesSchema = array(TaxRateSchema).required()

const ItemModifierGroupSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   name_info: string().nullable().defined(),
   modgroup_type: object({}).nullable(),
   _embedded: object({
      mods: array(
         object({
            id: number().required(),
         })
      ).required(),
   }).required(),
})

export type ItemModifierGroup = InferType<typeof ItemModifierGroupSchema>

export const ItemModifierGroupsSchema = array(ItemModifierGroupSchema).required()

const AppMenuModifiersModGroupSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   max: number().nullable().defined(),
   min: number().nullable().defined(),
   required: number(),
})

const ModifierSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   price: number()
      .transform((value, originalValue) => {
         const transformedValue = Number(originalValue)
         return isNaN(transformedValue) ? 0 : transformedValue
      })
      .required(),
   open: number().required(),
   image: string().nullable().defined(),
   _embedded: object({
      printers: array(ItemPrinterSchema).nullable().defined(),
      mod_groups: array(AppMenuModifiersModGroupSchema).nullable().defined(),
   }).required(),
})

export type ItemModifier = InferType<typeof ModifierSchema>

export const ModifiersSchema = array(ModifierSchema).required()

export const Sync_ItemOrderModes_Schema = array(
   object({
      id: number().required(),
      id_external: string().nullable().defined(),
      name: string().nullable().defined(),
      available: number().required(),
      default: number().required(),
   })
)
