import {array, InferType, number, object, string} from 'yup'
import {ISelfOrderingCartItem} from '../../../services/SelfOrderingCartSlice'

export const CreateTicketAndPaySchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   ticket_number: string().required(),
   open: number().required(),
   _embedded: object({
      employee: object({
         employee_id: number(),
         id_external: string().nullable().defined(),
         first_name: string().nullable().defined(),
         last_name: string().nullable().defined(),
         check_name: string().nullable().defined(),
      }).required(),
      order_type: object({
         order_type_id: number().required(),
         name: string().nullable().defined(),
      }).required(),
      payments: array(
         object({
            id: number().required(),
         })
      ).nullable(),
   }).required(),
   posted_payment: object({
      id: number().required(),
   }).nullable(),
})

export type CreateTicketAndPayResponse = InferType<typeof CreateTicketAndPaySchema>

export const WebhookSaleSchema = object({
   id: number().required(),
   status_text: string().required(),
   webhook_id: number().required(),
})
export type WebhookSaleResponse = InferType<typeof WebhookSaleSchema>

export const CreateTicketSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   name: string().nullable().defined(),
   ticket_number: number().required(),
})
export type CreateTicketResponse = InferType<typeof CreateTicketSchema>

export type PayWithCcResponse = {
   posted_payment: {
      id: number
   }
} & CreateTicketResponse

export interface IGuestData {
   name: string
   phone?: string
}

export type TicketRequest = {
   ticket_number: string
   terminal_id?: number
   terminal_id_external?: string
   employee_id: number
   employee_id_external?: string
   order_type_id: number
   order_type_id_external: string
   revenue_center_id: number
   revenue_center_id_external: string
   table_id?: number
   name?: string
   table_id_external?: string
   items: ISelfOrderingCartItem[]
   guest_count?: number
   inclusive_tax?: number
   phone?: string
   sub_total: number
   tax: number
   total: number
   tip?: number
   guest_id?: number
}
