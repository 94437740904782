import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {constants, KIOSK_GLOBAL_ON} from '../../config/constants'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import {useMenuSearch} from './menu_components/MenuSearchProvider'
import {KTSVG} from '../../../_metronic/helpers'
import {MenuCategories} from './menu_components/MenuCategories'
import './assets/MenuCategories.css'
import {AppDispatch, RootState} from '../../../app/store'
import {GetApiUrl, getAuth} from '../../../app/modules/auth'
import {KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {SoftPointLocation} from '../../common/schemas/LocationSchema'
import {MenuItems} from './MenuItems'
import {CheckoutFooter} from './checkout_components/CheckoutFooter'
import {useMenuView} from './menu_components/MenuViewProvider'
import {useErrorAlert} from '../utilities/alerts'
import {useNavigate} from 'react-router-dom'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {updatePosCheckRequest} from '../../services/SelfOrderingDataSlice'
import {useKeyboard} from '../../services/hooks/useKeyboard'

export const MainMenuView = () => {
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const selectedLocation = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const posMonitor = useSelector((state: RootState) => state.location_menu_data.pos)
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const navigateTo = useNavigate()
   const fireErrorAlert = useErrorAlert()
   const dispatch = useDispatch<AppDispatch>()
   const {updateQuery, queryValue} = useMenuSearch()
   const {activeCategory, setActiveCategory} = useMenuView()
   const {getInputValue, onChangeInput, setActiveInput, clearInput} = useKeyboard()
   useTranslatedPageTitle(constants.staticPageTitle.order)
   const _keyboard = useSelector((state: RootState) => state.keyboard)

   useEffect(() => {
      if (posMonitor.request_status === 'done') {
         if (posMonitor.data?.status === 'offline') {
            fireErrorAlert('Sorry the network if offline, please try again later.').then((result) => {
               navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
            })
         }
         dispatch(updatePosCheckRequest('idle'))
      }
   }, [])

   const removeSearchSelection = () => {
      updateQuery('')
      clearInput('searchMenu')
   }
   let categories = selfOrderData.Data_Categories

   useEffect(() => {
      const cat = categories[0]
      setActiveCategory({
         id: cat.id,
         name: cat.name ?? '',
      })
      return () => {
         updateQuery('')
         clearInput('searchMenu')
      }
   }, [])

   useEffect(() => {
      updateQuery(getInputValue('searchMenu'))
   }, [getInputValue('searchMenu')])

   let logoPath = ''
   if (kiosk_settings.icon) {
      const user_id = getAuth()?.user_id
      logoPath = `${GetApiUrl()}/download-file?path=${
         kiosk_settings?.icon
      }&global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
   }
   return (
      <div className='row g-5 g-xl-8'>
         <div className='container'>
            <div
               style={{paddingLeft: '0px'}}
               className='card-header align-items-center border-0 mt-4 row mt-5 mb-5 bg-light footer-bg-color h-100px'
            >
               <div className='contain-fluid col-2 text-center'>
                  {logoPath != '' ? (
                     <img
                        alt='Logo'
                        src={logoPath}
                        className='h-100px w-100px rounded-1'
                        onError={({currentTarget}) => {
                           currentTarget.onerror = null
                           logoPath = ''
                        }}
                     />
                  ) : (
                     <div className='card-title fw-bolder text-dark'>
                        <h1 className='card-label fs-1' style={{color: '#181C32 !important'}}>
                           {selectedLocation?.name}
                        </h1>
                     </div>
                  )}
               </div>
               <div className='contain-fluid col-5'>
                  <div className='card-title fw-bold text-dark'>
                     <span style={{fontSize: '2.5em'}}>{activeCategory?.name ?? ''}</span>
                  </div>
               </div>
               <div className='contain-fluid col-5'>
                  <div
                     className={`input-group input-group-lg keyboard-input ${
                        _keyboard.inputName == 'searchMenu' && _keyboard.open ? 'keyboard-active' : ''
                     } `}
                  >
                     <span style={{border: 'none'}} className='input-group-text h-70px' id='inputGroup-sizing-lg'>
                        <KTSVG
                           path='/media/icons/duotune/general/gen021.svg'
                           className='svg-icon-4x svg-icon d-block py-2'
                        />
                     </span>
                     <input
                        style={{border: 'none', paddingRight: '50px'}}
                        type='text'
                        name='searchMenu'
                        id='searchMenu'
                        placeholder='Search'
                        value={queryValue}
                        className='form-control form-control-solid ps-5 fs-1 search-input h-70px keyboard-input'
                        aria-describedby='inputGroup-sizing-lg'
                        onFocus={() => setActiveInput('searchMenu')}
                        onChange={onChangeInput}
                        readOnly={true}
                     />

                     {queryValue && (
                        <span
                           style={{border: 'none', top: '30%', right: '16px', zIndex: 10}}
                           className='position-absolute'
                           id='inputGroup-sizing-lg'
                        >
                           <i className='bi bi-x-lg fs-2x svg-icon cursor-pointer' onClick={removeSearchSelection}></i>
                        </span>
                     )}
                  </div>
               </div>
            </div>
            <div className='row'>
               <div className='app-blank card contain-fluid col-2 h-100' style={{zIndex: 101}}>
                  <MenuCategories />
               </div>
               <MenuItems />
               <CheckoutFooter />
            </div>
         </div>
      </div>
   )
}
