import {ReactElement} from 'react'
import Swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {constants} from '../../config/constants'
import {useLocationBasedBtnColor} from '../../common/hooks/buttons'
import {useIntl} from 'react-intl'

//setting custom class for error message in modal
const swalWithBootstrapButtons = Swal.mixin({
   customClass: {
      confirmButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
   },
   buttonsStyling: false,
   icon: 'error',
   background: '#151521',
   confirmButtonText: 'OK, got it!',
})
//handles the error messages
export const fireErrorMessage = async (_errorMessage: string): Promise<SweetAlertResult<ReactElement<any, any>>> =>
   swalWithBootstrapButtons.fire({
      html: `<h1 class="swal2-html-container fs-2x mx-0">${_errorMessage}</h1>`,
   })

export const errorAlertHtmlWithBtn = Swal.mixin({
   customClass: {
      confirmButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
      htmlContainer: 'mh-100',
   },
   buttonsStyling: false,
   icon: 'error',
})

export const errorAlertWithBtn = Swal.mixin({
   customClass: {
      confirmButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
   },
   buttonsStyling: false,
   icon: 'error',
   width: '29rem',
   heightAuto: false,
})
export const errorAlertWithBlackBtn = Swal.mixin({
   customClass: {
      confirmButton: 'btn btn-white btn-sm fs-5 my-0',
   },
   buttonsStyling: false,
   icon: 'error',
   width: '29rem',
   heightAuto: false,
})

const successAlertWithBtn = Swal.mixin({
   buttonsStyling: false,
   icon: 'success',
   width: '29rem',
   heightAuto: false,
})

const confirmAlertWithBtn = Swal.mixin({
   buttonsStyling: false,
   icon: 'error',
   width: '29rem',
   heightAuto: false,
   showDenyButton: true,
})

const reverseConfirmAlertWithBtn = Swal.mixin({
   buttonsStyling: false,
   icon: 'error',
   width: '29rem',
   heightAuto: false,
   showDenyButton: true,
   reverseButtons: true,
})

const confirmWarningAlertWithBtn = Swal.mixin({
   buttonsStyling: false,
   icon: 'warning',
   width: '29rem',
   heightAuto: false,
   showDenyButton: true,
   allowOutsideClick: false,
})

const timerAlert = Swal.mixin({
   icon: 'warning',
   timerProgressBar: true,
   allowOutsideClick: false,
   showDenyButton: true,
})

const warningAlert = Swal.mixin({
   buttonsStyling: false,
   icon: 'warning',
   width: '29rem',
   heightAuto: false,
   customClass: {
      confirmButton: 'btn btn-primary btn-lg fs-1 px-10 py-8',
   },
})

const timedErrorAlert = Swal.mixin({
   icon: 'error',
   timerProgressBar: false,
   allowOutsideClick: false,
   customClass: {
      confirmButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
   },
   buttonsStyling: false,
   width: '29rem',
   heightAuto: false,
})

const timedSuccessAlert = Swal.mixin({
   icon: 'success',
   timerProgressBar: false,
   allowOutsideClick: false,
   buttonsStyling: false,
   width: '29rem',
   heightAuto: false,
})

export const useErrorHtmlAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)

   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   return (
      errorMsg: any,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
   ): Promise<SweetAlertResult<any>> => {
      return errorAlertHtmlWithBtn.fire({
         html: errorMsg,
         confirmButtonText: confirmText,
         background: alertBackground,
         heightAuto: false,
      })
   }
}

export const useErrorAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   return (
      errorMsg: string,
      bigWidth: boolean = false,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'}),
      footer: string = ''
   ): Promise<SweetAlertResult<any>> => {
      let message = errorMsg
      if (Object.values(constants.errors.static).includes(message)) message = intl.formatMessage({id: message})
      const options = {
         html: `<h1 class="fs-2x ${alertTextColor}">${message}</h1>`,
         confirmButtonText: confirmText,
         background: alertBackground,
         heightAuto: false,
         footer: footer,
      }

      return errorAlertWithBtn.fire(bigWidth ? {...options, width: '50%'} : options)
   }
}

export const useTimedErrorAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   return (
      errorMsg: string,
      seconds: number = 30,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
   ): Promise<SweetAlertResult<any>> => {
      return timedErrorAlert.fire({
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         confirmButtonText: confirmText,
         background: alertBackground,
         timer: seconds * 1000,
      })
   }
}

export const useTimedSuccessAlert = (showConfirm: boolean = true) => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const dynamicBtnColor = useLocationBasedBtnColor()
   return (
      errorMsg: string,
      seconds: number = 5,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
   ): Promise<SweetAlertResult> => {
      let options: SweetAlertOptions = {
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         showConfirmButton: showConfirm,
         background: alertBackground,
         timer: seconds * 1000,
      }

      if (showConfirm) {
         options.confirmButtonText = confirmText
         options.customClass = {
            confirmButton: `btn ${dynamicBtnColor} btn-primary btn-lg fs-1 px-10 py-8`,
         }
      }
      return successAlertWithBtn.fire(options)
   }
}

export const useTimerAlert = () => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const dynamicBtnColor = useLocationBasedBtnColor()
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const progressBarColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'bg-black' : 'bg-white'
   return (
      errorMsg: string,
      milliseconds: number = 30000,
      confirmText: string = 'Ok',
      denyText: string = 'No'
   ): Promise<SweetAlertResult<any>> => {
      return timerAlert.fire({
         html: `<h1 class="fs-2x my-0 ${alertTextColor}">${errorMsg}</h1>`,
         background: alertBackground,
         heightAuto: false,
         timer: milliseconds,
         denyButtonText: denyText,
         confirmButtonText: confirmText,
         customClass: {
            confirmButton: `btn ${dynamicBtnColor} btn-lg fs-1 px-10 py-8`,
            denyButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
            timerProgressBar: `${progressBarColor}`,
         },
      })
   }
}

export const useSuccessAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   if (!kioskLocSettings || Object.keys(kioskLocSettings).length === 0) {
      throw new Error('Location Settings Not Loaded!')
   }
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const dynamicBtnColor = useLocationBasedBtnColor()
   return (
      errorMsg: string,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
   ): Promise<SweetAlertResult> => {
      return successAlertWithBtn.fire({
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         confirmButtonText: confirmText,
         background: alertBackground,
         heightAuto: false,
         customClass: {
            confirmButton: `btn ${dynamicBtnColor} btn-primary btn-lg fs-1 px-10 py-8`,
         },
      })
   }
}

export const useWarningAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   return (
      alertMsg: string,
      bigWidth: boolean = false,
      confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
   ): Promise<SweetAlertResult<any>> => {
      const options = {
         html: `<h1 class="fs-2x ${alertTextColor}">${alertMsg}</h1>`,
         confirmButtonText: confirmText,
         background: alertBackground,
      }

      return warningAlert.fire(bigWidth ? {...options, width: '50%'} : options)
   }
}

export const useReverseConfirmAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)

   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const alertConfirmTextColor =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.darkMode && kioskLocSettings.primary_color === '#ffffff'
         ? 'text-black'
         : ''
   const dynamicBtnColor = useLocationBasedBtnColor()
   return (
      errorMsg: string,
      denyText: string = intl.formatMessage({id: 'alert.no_thank_you'}),
      confirmText: string = intl.formatMessage({id: 'alert.proceed'}),
      footer: string = ''
   ): Promise<SweetAlertResult> => {
      return reverseConfirmAlertWithBtn.fire({
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         denyButtonText: denyText,
         confirmButtonText: confirmText,
         customClass: {
            confirmButton: `btn ${dynamicBtnColor} ${alertConfirmTextColor} btn-primary btn-lg fs-1 px-10 py-8`,
            denyButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
            footer: 'border border-0',
         },
         background: alertBackground,
         footer,
      })
   }
}

export const useConfirmAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const alertConfirmTextColor =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.darkMode && kioskLocSettings.primary_color === '#ffffff'
         ? 'text-black'
         : ''
   const dynamicBtnColor = useLocationBasedBtnColor()
   return (
      errorMsg: string,
      denyText: string = intl.formatMessage({id: 'alert.no_thank_you'}),
      confirmText: string = intl.formatMessage({id: 'alert.proceed'}),
      footer: string = ''
   ): Promise<SweetAlertResult<any>> => {
      return confirmAlertWithBtn.fire({
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         denyButtonText: denyText,
         confirmButtonText: confirmText,
         customClass: {
            confirmButton: `btn ${dynamicBtnColor} ${alertConfirmTextColor} btn-primary btn-lg fs-1 px-10 py-8`,
            denyButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
            footer: 'border border-0',
         },
         background: alertBackground,
         heightAuto: false,
         footer,
      })
   }
}

export const useWarningConfirmAlert = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const alertConfirmTextColor =
      kioskLocSettings?.appearance === APPEARANCE_STATUS.darkMode && kioskLocSettings?.primary_color === '#ffffff'
         ? 'text-black'
         : ''
   const dynamicBtnColor = useLocationBasedBtnColor()
   return (
      errorMsg: string,
      denyText: string = intl.formatMessage({id: 'alert.no_thank_you'}),
      confirmText: string = intl.formatMessage({id: 'alert.proceed'}),
      containerClass: string = ''
   ): Promise<SweetAlertResult<any>> => {
      return confirmWarningAlertWithBtn.fire({
         html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
         denyButtonText: denyText,
         confirmButtonText: confirmText,
         customClass: {
            confirmButton: `btn ${dynamicBtnColor} ${alertConfirmTextColor} btn-primary btn-lg fs-1 px-10 py-8`,
            denyButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
            container: containerClass ? containerClass : '',
         },
         background: alertBackground,
         heightAuto: false,
      })
   }
}

export const useLoadingAlert = () => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   if (!kioskLocSettings || Object.keys(kioskLocSettings).length === 0) {
      throw new Error('Location Settings Not Loaded!')
   }
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   return (message: string) => {
      return Swal.fire({
         icon: 'warning',
         html: `<h1 class="fs-2x my-0 ${alertTextColor}">${message}</h1>`,
         didOpen(popup: HTMLElement) {
            Swal.showLoading()
         },
         background: alertBackground,
         heightAuto: false,
         allowOutsideClick: false,
      })
   }
}

export const useAppAlerts = () => {
   const intl = useIntl()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   if (!kioskLocSettings || Object.keys(kioskLocSettings).length === 0) {
      throw new Error('Location Settings Not Loaded!')
   }
   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const alertBackground =
      kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? theme.lightMode : theme.darkMode
   const alertTextColor = kioskLocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
   const alertConfirmTextColor =
      kioskLocSettings.appearance === APPEARANCE_STATUS.darkMode && kioskLocSettings.primary_color === '#ffffff'
         ? 'text-black'
         : ''
   const dynamicBtnColor = useLocationBasedBtnColor()
   return {
      fireSuccessAlert: (
         errorMsg: string,
         confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
      ): Promise<SweetAlertResult> => {
         return successAlertWithBtn.fire({
            html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
            customClass: {
               confirmButton: `btn ${dynamicBtnColor} btn-primary btn-lg fs-1 px-10 py-8`,
            },
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
         })
      },
      fireErrorAlert: (
         errorMsg: string,
         confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
      ): Promise<SweetAlertResult<any>> => {
         return errorAlertWithBtn.fire({
            html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
         })
      },
      fireStaticColorAlert: (
         errorMsg: string,
         confirmText: string = intl.formatMessage({id: 'alert.ok_got_it'})
      ): Promise<SweetAlertResult<any>> => {
         return errorAlertWithBlackBtn.fire({
            html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
            customClass: {
               confirmButton: `btn ${alertConfirmTextColor} btn-primary btn-lg fs-1 px-10 py-8`,
            },
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
         })
      },
      fireLoadingAlert: (message: string) => {
         return Swal.fire({
            icon: 'warning',
            html: `<h1 class="fs-2x my-0 ${alertTextColor}">${message}</h1>`,
            didOpen(popup: HTMLElement) {
               Swal.showLoading()
            },
            background: alertBackground,
            heightAuto: false,
         })
      },
      fireTimeoutAlert: (message: string, callBack: (timeLeft: number) => void) => {
         let timeLeft: number = 31
         let timerId = setInterval(countdown, 1000)

         function countdown() {
            if (timeLeft == 1) {
               callBack(timeLeft)
               clearTimeout(timerId)
               return
            } else {
               timeLeft--
               const timeLeftDiv = Swal.getHtmlContainer()!.querySelector('div')
               if (!timeLeftDiv) return
               timeLeftDiv!.innerText = timeLeft.toString()
            }
         }

         return Swal.fire({
            //timer: 31,
            icon: 'warning',
            html: `<h1 class="fs-2x my-0 ${alertTextColor}"> <div id="timeLeft">${timeLeft}</div> <br/> ${message}</h1>`,
            customClass: {
               confirmButton: `btn ${dynamicBtnColor} btn-primary btn-lg fs-1 px-10 py-8`,
            },
            background: alertBackground,
            heightAuto: false,
            confirmButtonText: intl.formatMessage({id: 'alert.confirm'}),
            didOpen: () => {
               countdown()
            },
            willClose: () => {
               clearTimeout(timerId)
            },
         })
      },
      fireConfirmAlert: (
         errorMsg: string,
         denyText: string = intl.formatMessage({id: 'alert.no_thank_you'}),
         confirmText: string = intl.formatMessage({id: 'alert.proceed'})
      ): Promise<SweetAlertResult<any>> => {
         return confirmAlertWithBtn.fire({
            html: `<h1 class="fs-2x ${alertTextColor}">${errorMsg}</h1>`,
            customClass: {
               confirmButton: `btn ${alertConfirmTextColor} btn-primary btn-lg fs-1 px-10 py-8`,
               denyButton: 'btn btn-danger btn-lg fs-1 px-10 py-8',
            },
            denyButtonText: denyText,
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
         })
      },
   }
}
