import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {INextReservationResponse, IReservationGuests} from '../modules/hotel/_requests/reservations'
import {stat} from 'fs'
import {RootState} from '../store'

export interface IGGuestReservationSlice {
   data: INextReservationResponse | null
   requested_guest_id: number | string | null // The guest id used to call on the GET Next Reservation API
   biometrics_sync_id: string | null
   derived_values: {
      primary_guest: IReservationGuests | undefined
      current_guest: IReservationGuests | undefined
      non_primary_guests: IReservationGuests[] | []
   }
}
const initialState: IGGuestReservationSlice = {
   data: null,
   requested_guest_id: null,
   biometrics_sync_id: null,
   derived_values: {
      primary_guest: undefined,
      current_guest: undefined,
      non_primary_guests: [],
   },
}

export const GuestReservationSlice = createSlice({
   name: 'GuestReservation',
   initialState,
   reducers: {
      setGuestReservation: (
         state: IGGuestReservationSlice,
         action: PayloadAction<{guestID: number; reservation: INextReservationResponse}>
      ) => {
         state.data = action.payload.reservation
         state.requested_guest_id = action.payload.guestID

         state.derived_values.primary_guest = action.payload.reservation._embedded?.guests?.find(
            (guest) => guest.primary == 1
         )

         state.derived_values.current_guest = action.payload.reservation._embedded?.guests?.find(
            (guest) => guest.guest_id == action.payload.guestID
         )

         state.derived_values.non_primary_guests =
            action.payload.reservation._embedded?.guests?.filter((guest) => guest.primary != 1) ?? []
      },
      setBiometricsSyncId: (state: IGGuestReservationSlice, action: PayloadAction<string | null>) => {
         state.biometrics_sync_id = action.payload
      },
   },
})

export const {setGuestReservation, setBiometricsSyncId} = GuestReservationSlice.actions

export default GuestReservationSlice.reducer

// SELECTORS
export const getGuestReservationStore = (state: RootState): IGGuestReservationSlice => state.guest_reservation
