const supportedResolutions = [
    '1920x1080',
    '1280x960',
    '1280x720',
    '1024x768',
    '1024x576',
    '800x600',
    '640x480',
    '640x360',
    '352x240',
    '320x240'
]

const supportedRotations = {ninety:90, one_eighty:180, two_seventy:270}

export const SUPPORTED_VALUES = {
    resolutions: supportedResolutions,
    rotations: supportedRotations
}


export interface IAxisOptions {
    resolution?:string,
    camera:number,
    compression?:number,
    rotation?:number,
    duration?:number,
    fps?:number
}

export const getVideoURL = (ip:string, options:IAxisOptions) => {
    const iterableOptions = Object.entries(options)
    const optionsLen = iterableOptions.length
    let URL = `http://${ip}/axis-cgi/mjpg/video.cgi?`
    let index = 0
    for(const [option, value] of iterableOptions){
        const keyValuePair = `${option}=${value}`
        URL += (index == optionsLen - 1) ? `${keyValuePair}` : `${keyValuePair}&`

        index += 1
    }
    return URL
}
