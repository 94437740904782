import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type LayoutType = 'default' | 'shift' | 'phone' | 'money'

type KeyboardInputFields = {[key: string]: string}

export type IKeyboardStatus = {
   open: boolean
   layoutName: LayoutType
   inputs: KeyboardInputFields
   inputName: string
}

const initialState: IKeyboardStatus = {
   open: false,
   layoutName: 'default',
   inputs: {},
   inputName: '',
}

export const KeyboardSlice = createSlice({
   name: 'Keyboard',
   initialState,
   reducers: {
      closeKeyboard: (state) => {
         state.open = false
      },
      displayKeyboard: (state, action: PayloadAction<LayoutType | null>) => {
         state.open = true
         state.layoutName = action.payload ?? 'default'
      },
      setInput: (state, action: PayloadAction<KeyboardInputFields>) => {
         state.inputs = action.payload
      },
      setInputName: (state, action: PayloadAction<string>) => {
         state.inputName = action.payload
      },
      setLayoutName: (state, action: PayloadAction<LayoutType>) => {
         state.layoutName = action.payload
      },
   },
})

export const {closeKeyboard, displayKeyboard, setInput, setInputName, setLayoutName} = KeyboardSlice.actions

export default KeyboardSlice.reducer
