import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {constants, KIOSK_GLOBAL_ON} from '../../config/constants'
import {useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getAuth, GetApiUrl} from '../auth'
import {setOrderType} from '../../services/SelfOrderingUserSlice'
import {AppDispatch, RootState} from '../../store'
import {KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {SoftPointLocation} from '../../common/schemas/LocationSchema'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import {useErrorAlert, useLoadingAlert, useReverseConfirmAlert} from '../utilities/alerts'
import {Terminal} from '../../common/schemas/TerminalSchema'
import {FormattedMessage} from 'react-intl'
import axios from 'axios'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {clientIsTizen} from '../../common/tizen/helpers'
import {getPosStatus} from '../../services/SelfOrderingDataSlice'
import {useCacheImg} from '../../../app/common/hooks/useCacheImg'

export const OrderTypeView = () => {
   let navigateTo = useNavigate()
   const fireError = useErrorAlert()
   const dispatch = useDispatch<AppDispatch>()
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const location_data = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const SelectedTerminal = useSelector((state: RootState) => state.selected_terminal.value) as Terminal
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   const modulePaths = MODULE_ROUTE_PATHS
   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softPointLoginLogo.svg'
         : '../media/logos/softpoint_logo_app-white.svg'

   useTranslatedPageTitle(constants.staticPageTitle.order_type)

   const selfOrderingNotConfigured = () => {
      let db_revenue_center = selfOrderData.Data_RevenueCenters.find(
         (revenueCenter) => revenueCenter.id == SelectedTerminal.revenue_center
      )
      const revenueIsNotConfigured =
         db_revenue_center === undefined ||
         SelectedTerminal.revenue_center === null ||
         db_revenue_center.id_external === null ||
         db_revenue_center.id_external === ''
      const employeeIsNotConfigured =
         kiosk_settings.ordering_employee === null ||
         kiosk_settings.ordering_employee_external_id === null ||
         kiosk_settings.ordering_employee_external_id === ''
      return revenueIsNotConfigured || employeeIsNotConfigured
   }

   const SelectOrderType = (type: 'dinein' | 'takeout') => {
      dispatch(getPosStatus(kiosk_settings.location_id)).then((result) => {
         if (!tizenSystem.network.available && clientIsTizen()) {
            console.log('network not available')
         } else {
            if (selfOrderingNotConfigured()) {
               CreateTerminalLog(
                  'OrderTypeVeiw',
                  'Navigate to order types',
                  SelectedTerminal.revenue_center === null
                     ? 'Revenue center is not configured for this terminal.'
                     : 'Ordering Employee is not configured for this terminal.'
               )
               fireError(constants.errors.static.missing_vital_config_error).then((result) => {
                  navigateTo(modulePaths.app_auth.index)
               })
               return
            }
            dispatch(setOrderType(type))
            navigateTo(MODULE_ROUTE_PATHS.ordering.MENU)
         }
      })
   }

   const CreateTerminalLog = (
      screen: 'OrderTypeVeiw' | 'MenuView',
      action: 'Navigate to order types' | 'Navigate to Menu View',
      details: string
   ) => {
      let locationId = kiosk_settings.location_id
      let user = getAuth()
      const user_id = user?.user_id
      const employee_id = kiosk_settings?.ordering_employee ? kiosk_settings.ordering_employee : ''
      const url = `${GetApiUrl()}/locations/${locationId}/terminals/${SelectedTerminal.id}/logs?`
      const queryString = {
         global_on_id: KIOSK_GLOBAL_ON,
         user_id: user_id,
         employee_id: employee_id,
         device_type: 'Tizen',
         terminal_id: SelectedTerminal.id,
         application: 'KioskPointWeb',
         app_version: process.env.REACT_APP_VERSION,
      }
      let data = {
         logs: [
            {
               action: action,
               details: details,
               employee_id: employee_id,
               screen: screen,
               ticket_id: 0,
            },
         ],
      }
      try {
         const response = axios.post(url, data, {params: queryString})
      } catch (e) {}
   }

   const {cachedImage} = useCacheImg({
      imageUrl: kiosk_settings._embedded.location_info.logo,
      placeholderUrl: toAbsoluteUrl(logoPath),
   })

   return (
      <>
         <div className='mtp-40'>
            <div>
               <h1 className='text-center mt-10 mb-0 fs-lg-4x invisible'>Welcome to</h1>
            </div>
            <div className='row d-flex justify-content-center my-14'>
               {kiosk_settings._embedded.location_info.logo ? (
                  <img
                     src={cachedImage}
                     className='rounded-3 m-0 p-0 w-250px h-250px w-xxl-250px h-xxl-250px'
                     onError={({currentTarget}) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = toAbsoluteUrl(logoPath)
                     }}
                     alt=''
                  />
               ) : (
                  <h1 className='text-center mt-3'>{location_data.name}</h1>
               )}
            </div>

            {kiosk_settings.dine_in && kiosk_settings.order_type_id && (
               <div className='row d-flex justify-content-center mb-12'>
                  <h2 className='text-center fs-lg-4x'>
                     <FormattedMessage id='order_type.view_title' defaultMessage='Where will you be eating today?' />
                  </h2>
               </div>
            )}

            <div className='row justify-content-center'>
               {kiosk_settings.dine_in && (
                  <div className='col-xl-3 col-md-4 col-sm-5'>
                     <div className='card card-flush pb-5 mw-100'>
                        <div onClick={() => SelectOrderType('dinein')}>
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <img
                                    style={{backgroundColor: '#ecedf2', padding: '30px'}}
                                    src={'/media/svg/dinein/dinein.svg'}
                                    className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px'
                                    alt=''
                                 />
                              </div>
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-2tx fs-xl-1'>
                                    {kiosk_settings.order_type_id && (
                                       <FormattedMessage id='order_type.Dine_In' defaultMessage='Dine In' />
                                    )}
                                    {!kiosk_settings.order_type_id && (
                                       <FormattedMessage id='order_type.start_your_order' defaultMessage='Start Your Order' />
                                    )}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
               {kiosk_settings.order_type_id && (
                  <div className='col-xl-3 col-md-4 col-sm-5'>
                     <div className='card card-flush pb-5 mw-100 '>
                        <div onClick={() => SelectOrderType('takeout')}>
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <img
                                    style={{backgroundColor: '#ecedf2', padding: '30px'}}
                                    src={'/media/svg/takeout/takeout.svg'}
                                    className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px'
                                    alt=''
                                 />
                              </div>
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-2tx fs-xl-1'>
                                    {kiosk_settings.dine_in && (
                                       <FormattedMessage id='order_type.Take_Out' defaultMessage='Take Out' />
                                    )}
                                    {!kiosk_settings.dine_in && (
                                       <FormattedMessage id='order_type.start_your_order' defaultMessage='Start Your Order' />
                                       )}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>

            {kiosk_settings.pay_in_store == 1 && (
               <div className='w-100 '>
                  <h5 className='fs-1 mt-10 text-center'>
                     <FormattedMessage
                        id='order_type.Paying_Cash'
                        defaultMessage='Paying Cash? Place your order here and proceed to counter for payment.'
                     />
                  </h5>
               </div>
            )}
            {kiosk_settings.pay_in_store == 0 && kiosk_settings.pay_by_card == 1 && (
               <div className='w-100 '>
                  <h5 className='fs-1 mt-10 text-center'>
                     <FormattedMessage
                        id='order_type.Paying_Card_only'
                        defaultMessage='We only accept card payment here. Pay in cash? Please order at counter.'
                     />
                  </h5>
               </div>
            )}
         </div>
      </>
   )
}
