import {GetApiUrl} from '../../modules/auth'
import axios from 'axios'
import {KIOSK_GLOBAL_ON} from '../../config/constants'
import {KioskSettings, KioskSettingsSchema} from '../schemas/KioskSettingsSchema'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../store'
import {useInternationalization} from '../../modules/i18n/i18nProvider'
import {StoreKioskData} from '../../services/KioskSettingSlice'
import {ThemeModeType, useThemeMode} from '../../../_metronic/partials'
import {ThemeModeComponent} from '../../../_metronic/assets/ts/layout'

export const fetchKioskSettings = (locationId: string | number, userId: string | number) => {
   return new Promise<KioskSettings>(async (resolve, reject) => {
      const queryString = {
         global_on_id: KIOSK_GLOBAL_ON,
         user_id: userId,
      }
      const URL = `${GetApiUrl()}/locations/${locationId}/settings/kiosk`
      try {
         const response = await axios.get(URL, {params: queryString})
         const cleanResponse = await KioskSettingsSchema.validate(response.data)
         return resolve(cleanResponse)
      } catch (error) {
         return reject(error)
      }
   })
}

export const useFetchKioskSettings = () => {
   const dispatch = useDispatch<AppDispatch>()
   const {setLanguage} = useInternationalization()
   const themeModeLSKey = 'kt_theme_mode_value'
   const themeMenuModeLSKey = 'kt_theme_mode_menu'
   const {updateMode, updateMenuMode} = useThemeMode()
   const switchMode = (_mode: ThemeModeType) => {
      if (localStorage) {
         localStorage.setItem(themeModeLSKey, _mode)
         localStorage.setItem(themeMenuModeLSKey, _mode)
         document.documentElement.setAttribute('data-theme', _mode)
         ThemeModeComponent.init()
      }
      updateMenuMode(_mode)
      updateMode(_mode)
   }

   return (locationId: string | number, userId: string | number) => {
      return new Promise<KioskSettings>(async (resolve, reject) => {
         const queryString = {
            global_on_id: KIOSK_GLOBAL_ON,
            user_id: userId,
         }
         const URL = `${GetApiUrl()}/locations/${locationId}/settings/kiosk`
         try {
            const response = await axios.get(URL, {params: queryString})
            const cleanResponse = await KioskSettingsSchema.validate(response.data)
            dispatch(StoreKioskData(cleanResponse))
            setLanguage(cleanResponse._embedded.location_info.locale)
            if (cleanResponse.appearance == 1) {
               switchMode('light')
            } else {
               switchMode('dark')
            }
            return resolve(cleanResponse)
         } catch (error) {
            return reject(error)
         }
      })
   }
}
