import {getVideoURL, IAxisOptions} from "../AxisIntegration";
import {camFeedStyles} from "./styles";

export interface ICamFeed{
    uri_options:IAxisOptions,
    ip:string
}


export const CamFeed = ({ip, uri_options}:ICamFeed) => {
    return (
        <img id='cam_feed'
             src={getVideoURL(ip, uri_options)}
             alt="selfie video feed"
             style={camFeedStyles}/>
    )
}
