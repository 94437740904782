import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {constants} from '../../config/constants'
import {useAppAlerts} from '../utilities/alerts'
import {KTSVG} from '../../../_metronic/helpers'
import {getAuth} from '../auth'
import {AppDispatch, RootState} from '../../store'
import {usePrinterPaperCallback} from '../../common/hooks/PrinterPaperCallback'
import {NoPortOpenError, PAPER_QUERY_RESULTS, PrinterService} from '../../common/printing/printing_service'
import {TizenDebug} from './FaceAuth/TizenIntegration'
import {Integration, KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {SoftPointLocation} from '../../common/schemas/LocationSchema'
import {CHECK_INS} from './types'
import {clientIsTizen} from '../../common/tizen/helpers'
import {FormattedMessage, MessageDescriptor, useIntl} from 'react-intl'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {useOrderConfigData} from '../../common/hooks/useOrderConfigData'
import {setOrderType} from '../../services/SelfOrderingUserSlice'

const integrationHasCheckIn = (locationIntegration: Integration | undefined) => {
   if (!locationIntegration) return false
   return locationIntegration.checkin == constants.status.ON
}

export const CheckinMethodScreen = () => {
   useTranslatedPageTitle(constants.staticPageTitle.checkin)
   const intl = useIntl()
   const appDispatch = useDispatch<AppDispatch>()
   let navigateTo = useNavigate()
   let kioskSettingsStore = useSelector((state: RootState) => state.kiosk_settings)
   let kiosk_settings = kioskSettingsStore.value as KioskSettings
   let selectedLocation = useSelector((state: RootState) => state.location_data)
   let location_data = selectedLocation.value as SoftPointLocation
   const {fireErrorAlert} = useAppAlerts()
   const checkPaperCallback = usePrinterPaperCallback()
   const getLocationOrderConfigs = useOrderConfigData()
   const facialRecognitionService = useSelector((state: RootState) => state.faceRecognitionServices)
   const checkinPaths = MODULE_ROUTE_PATHS.checkIn
   const biometricsEnabled =
      integrationHasCheckIn(kioskSettingsStore.derived_values.biometricIntegration) &&
      selectedLocation.derived_values.product_statuses.biometricsEnabled

   if (!kiosk_settings) {
      kiosk_settings = localStorage.getItem('kiosk_setting')
         ? JSON.parse(localStorage.getItem('kiosk_setting') as any)
         : null
   }

   if (!location_data || Object.keys(location_data).length == 0) {
      location_data = localStorage.getItem('location_data')
         ? JSON.parse(localStorage.getItem('location_data') as any)
         : null
   }
   const user_id = getAuth()?.user_id
   let reservation_banner = ''
   if (kiosk_settings._embedded.locations_config_contactless.reservation_banner) {
      reservation_banner = `${process.env.REACT_APP_API_URL}/download-file?path=${kiosk_settings._embedded.locations_config_contactless?.reservation_banner}&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${user_id}`
   }

   const checkPrinterPaperLevel = () => {
      const printerManger = new PrinterService({
         onPortPrint: checkPaperCallback,
      })
      printerManger.openSerialPort()
      return printerManger.checkPaperStatus()
   }

   async function handleRouteNavigation(checkin_type: string) {
      if (kiosk_settings.print_confirmation == constants.status.YES && clientIsTizen()) {
         try {
            const result = await checkPrinterPaperLevel()
            TizenDebug('[NavigationCheck]' + JSON.stringify(result))
            let paperMessage: string | null = null
            if (result.data == PAPER_QUERY_RESULTS.NO_ROLL || result.data == PAPER_QUERY_RESULTS.OUT_OF_PAPER) {
               paperMessage = intl.formatMessage({id: 'checkin.alert.printer_out_of_paper'})
            }
            if (paperMessage) {
               fireErrorAlert(paperMessage)
            }
         } catch (error) {
            let message = constants.errors.static.unexpected_contact_support
            if (error instanceof NoPortOpenError) {
               message = error.message
            }
            fireErrorAlert(message)
            return
         }
      }

      if (kiosk_settings._embedded.locations_config_contactless.bartab == constants.status.YES) {
         const configs = getLocationOrderConfigs('bar_tab')
         // Need to set order type to dinein so that the correct order type is chosen for the bar-tab
         if (checkin_type == CHECK_INS.RESERVATION) appDispatch(setOrderType('dinein'))
         if (configs.revenue_center_id == 0 || configs.revenue_center_id_external == '') {
            TizenDebug(`${configs.revenue_center_id == 0 ? 'revenue_center_id' : 'revenue_center_id_external'}`)
            const descriptor: MessageDescriptor = {
               id: 'bar_tabs.missing_configs.revenue_center',
               defaultMessage: 'Bar Tabs require a revenue center, please configure one.',
            }
            const message = intl.formatMessage(descriptor)
            fireErrorAlert(message)
            return
         }

         if (configs.order_type_id == 0 || configs.order_type_id_external == '') {
            TizenDebug(`${configs.order_type_id == 0 ? 'order_type_id' : 'order_type_id_external'}`)
            const descriptor: MessageDescriptor = {
               id: 'bar_tabs.missing_configs.order_type',
               defaultMessage: 'Bar Tabs require an order type, please configure one.',
            }
            const message = intl.formatMessage(descriptor)
            fireErrorAlert(message)
            return
         }

         if (configs.employee_id == 0 || configs.employee_external_id == '') {
            TizenDebug(`${configs.employee_id == 0 ? 'employee_id' : 'employee_external_id'}`)
            const descriptor: MessageDescriptor = {
               id: 'bar_tabs.missing_configs.employee',
               defaultMessage: 'Bar Tabs require an employee, please configure one.',
            }
            const message = intl.formatMessage(descriptor)
            fireErrorAlert(message)
            return
         }
      }

      if (biometricsEnabled && facialRecognitionService.is_online) {
         navigateTo(checkinPaths.biometric_auth, {state: {checkin_type}})
      } else {
         navigateTo(checkinPaths.qr_auth, {state: {checkin_type}})
      }
   }

   return (
      <>
         <div className='tab-content image-text-container'>
            <div
               className='card flex-grow-1 bgi-no-repeat bgi-size-auto bgi-position-x-end h-xl-100 pb-20 pt-20'
               style={{backgroundSize: 'cover', backgroundImage: `url(${reservation_banner})`}}
            >
               <div className='card-body d-flex justify-content-between flex-column ps-xl-18'>
                  <div className='mb-20 pt-xl-13' style={{marginLeft: '2%'}}>
                     <h3 className='fw-bold text-white fs-4x mb-5 ms-n1'>
                        <FormattedMessage id='checkin.reservation.confirmation.welcome' defaultMessage='Welcome' />
                     </h3>
                     <span className='fw-bold text-white fs-2x mb-8 d-block lh-0'>
                        <FormattedMessage id='checkin.reservation.confirmation.to' defaultMessage='to' />{' '}
                        {location_data.name}
                     </span>
                     <div className='d-flex align-items-center'>
                        {/* FIXME: The div below can not be removed because it will break the image card container above */}
                        <div className='d-flex align-items-center me-6 pb-20 pt-20'></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='row mb-5 mb-xl-10 mt-15 mtp-32'>
            <div className='col-xl-12 col-md-12 col-sm-12'>
               <h3 className='fw-bold fs-4x mb-15 ms-n1 text-center'>
                  <FormattedMessage
                     id='checkin.options.what_would_you_like_to_do'
                     defaultMessage='What would you like to do?'
                  />
               </h3>
            </div>
            <div className='row justify-content-center'>
               <div className='clear' />
               {/* <div className='col-xl-3 col-md-4 col-sm-12'></div> */}
               {selectedLocation.derived_values.product_statuses.reservationsEnabled && (
                  <div className='col-xl-4 col-md-4 col-sm-4'>
                     <div className='card card-flush pb-5 mw-100'>
                        <div
                           onClick={() => {
                              handleRouteNavigation(CHECK_INS.RESERVATION)
                           }}
                        >
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <KTSVG
                                    path='media/icons/duotune/general/calendar_3.svg'
                                    className='svg-icon-fluid svg-icon-primary d-block p-3'
                                 />
                              </div>
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                       <FormattedMessage
                                          id='checkin.options.reservation_checkin'
                                          defaultMessage='Reservations Check In'
                                       />
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
               {kiosk_settings.check_in_walk_up == 1 && (
                  <div className='col-xl-4 col-md-4 col-sm-4'>
                     <div className='card card-flush pb-5 mw-100'>
                        <div
                           onClick={() => {
                              handleRouteNavigation(CHECK_INS.AGE_GATE)
                           }}
                        >
                           <div className='card-body text-center'>
                              <div className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px mx-auto'>
                                 <KTSVG
                                    path='media/icons/duotune/maps/pin_map_fill.svg'
                                    className='svg-icon-fluid svg-icon-primary d-block p-3'
                                 />
                              </div>
                              <div className='mt-6'>
                                 <div className='text-center'>
                                    <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                                       <FormattedMessage
                                          id='checkin.options.checkin'
                                          defaultMessage='Check In / Return'
                                       />
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </>
   )
}
