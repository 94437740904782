import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface FacialRecognitionServices{
    is_online:boolean,
}

const initialState:FacialRecognitionServices = {
    is_online:true,
}


export const FacialRecognitionServicesSlice = createSlice({
    name:'FacialRecognitionServices',
    initialState,
    reducers:{
        setFaceRecognitionStatus:(state, action:PayloadAction<boolean>) => {
            state.is_online = action.payload
        }
    }
})


export const {
    setFaceRecognitionStatus} = FacialRecognitionServicesSlice.actions

export default FacialRecognitionServicesSlice.reducer
