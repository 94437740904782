import { ReactNode, createContext, useContext, useState } from "react"

type CategoryUI = {
    id:number,
    name:string
}

type MenuViewContext = {
    activeCategory: CategoryUI | null,
    setActiveCategory:(category:CategoryUI) => void
}

const MainMenuViewContext = createContext<MenuViewContext|undefined>(undefined)

export const MenuViewProvider = ({children}:{children:ReactNode}) => {
    const [activeCategory, setActiveCategory] = useState<CategoryUI|null>(null)
    return(
        <MainMenuViewContext.Provider value={{activeCategory, setActiveCategory}}>
            {children}
        </MainMenuViewContext.Provider>
    )
}

export const useMenuView = () => {
    const context = useContext(MainMenuViewContext)
    if(!context) throw new Error('[useMenuView] needs to be used within [MenuViewProvider]')
    return context
}