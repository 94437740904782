import React from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {useCacheImg} from '../../common/hooks/useCacheImg'
import {MenuItemPlaceholderDark, MenuItemPlaceholderLight} from '../../common/images'
import {constants} from '../../config/constants'
import {useCacheVideo} from '../../common/hooks/useCacheVideo'
import {KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {getKioskSettings} from '../../services/KioskSettingSlice'
import { useKioskProducts } from '../../common/hooks/useKioskProducts'
import ContentLoader from 'react-content-loader'

const SplashScreen = () => {
   const navigateTo = useNavigate()
   const kioskSettings = useSelector(getKioskSettings) as KioskSettings
   const APPEARANCE_STATUS = constants.status.location.appearance
   const placeholderMedia =
      kioskSettings.appearance === APPEARANCE_STATUS.lightMode ? MenuItemPlaceholderLight : MenuItemPlaceholderDark
   const imageURL = kioskSettings.idle_image
   const videoURL = kioskSettings.idle_video
   const {cachedImage, isLoading} = useCacheImg({imageUrl: imageURL, placeholderUrl: placeholderMedia})
   const cachedVideo = useCacheVideo({videoUrl: videoURL})
   const {orderingEnabled, checkInEnabled} = useKioskProducts()

   const showHome = () => {
    if (checkInEnabled && orderingEnabled) {
       navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
    } else if (checkInEnabled) {
       navigateTo(MODULE_ROUTE_PATHS.checkIn.check_in_home)
    } else if (orderingEnabled) {
       navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
    } 
   }

   const showImage = () => {
    if (isLoading)
        return (<ContentLoader
          animateBegin="0.1s"                
          height={500}
          backgroundColor="#f5f5f5"
          foregroundColor="#dbdbdb"
          style={{ width: '100%' }}
          foregroundOpacity={0.5}
      >
          <rect x="255" y="0" rx="0" ry="0" width="570" height="500" />
        </ContentLoader> )
    else      
        return (<img src={cachedImage}
            alt="Idle"
            className="mw-100 mh-100"
        />)
   }

   return (
      <div onClick={showHome}
      className="position-relative w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center">
              {kioskSettings.idle_video ? (
                  <video key={cachedVideo} autoPlay muted loop playsInline className="position-absolute w-100 h-100">
                      <source src={cachedVideo} type="video/mp4" />
                  </video>
              ) : (
                showImage()
              )}
          </div>
      </div>
  );
}

export default SplashScreen
