import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {getKioskSettings} from '../../services/KioskSettingSlice'

export const useLocationBasedBtnColor = () => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   if (!kioskLocSettings) return ''
   if (
      kioskLocSettings.appearance === 1 &&
      kioskLocSettings?.background_color == '#ffffff' &&
      kioskLocSettings?.primary_color == '#ffffff'
   ) {
      return 'text-black'
   } else if (
      kioskLocSettings.appearance === 0 &&
      kioskLocSettings?.background_color == '#151520' &&
      kioskLocSettings?.primary_color == '#151520'
   ) {
      return 'text-white '
   } else if (kioskLocSettings.appearance === 0 && kioskLocSettings?.primary_color == '#ffffff') {
      return 'text-black'
   } else if (kioskLocSettings.appearance === 0 && kioskLocSettings?.primary_color == '#151520') {
      return 'text-white'
   } else if (kioskLocSettings.appearance === 1 && kioskLocSettings?.primary_color == '#ffffff') {
      return 'text-white'
   } else if (kioskLocSettings.appearance === 1 && kioskLocSettings?.primary_color == '#151520') {
      return 'text-white'
   } else {
      return ''
   }
}

export const useLocationBasedTextColor = () => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   if (!kioskLocSettings) return ''
   if (
      kioskLocSettings.appearance === 1 &&
      kioskLocSettings?.background_color == '#ffffff' &&
      kioskLocSettings?.primary_color == '#ffffff'
   ) {
      return 'text-white'
   } else if (
      kioskLocSettings.appearance === 0 &&
      kioskLocSettings?.background_color == '#151520' &&
      kioskLocSettings?.primary_color == '#151520'
   ) {
      return 'text-black '
   } else if (kioskLocSettings.appearance === 0 && kioskLocSettings?.primary_color == '#ffffff') {
      return 'text-white'
   } else if (kioskLocSettings.appearance === 0 && kioskLocSettings?.primary_color == '#151520') {
      return 'text-black'
   } else if (kioskLocSettings.appearance === 1 && kioskLocSettings?.primary_color == '#ffffff') {
      return 'text-black'
   } else if (kioskLocSettings.appearance === 1 && kioskLocSettings?.primary_color == '#151520') {
      return 'text-black'
   } else {
      return 'text-black'
   }
}
