import React, {useRef} from 'react'
import '../assets/PlusMinusCounterStyle.css'
import {MenuImage} from '../menu_components/MenuImage'

interface MenuModifierProps {
   id: number
   img: string
   name: string
   price?: number
   quantity: number
   onClick: (element: React.MouseEvent<HTMLElement>, action: 'select' | 'unselect') => void
   isSelected: boolean
}

export const MenuModifier = ({img, name, price, quantity, id, onClick, isSelected}: MenuModifierProps) => {
   let liRef = useRef<HTMLLIElement>(null)
   let classes = ` nav-link nav-link-border-solid btn btn-outline p-4`
   if (isSelected) {
      classes += ' active'
   }

   return (
      <li
         role='presentation'
         style={{overflow: 'hidden', wordWrap: 'break-word', marginBottom:'5px'}}
         ref={liRef}
         onClick={(event) => {
            onClick(event, isSelected ? 'unselect' : 'select')
         }}
      >
         <div className={classes} style={{width: '100%'}}>
            <div className='d-flex flex-row'>
               <div className='d-flex justify-content-between align-items-start w-100'>
                      <span className='text-gray-800 fw-bold fs-3 d-block'>{name}
                     </span>
                     {
                     (price && price>0) ?  <span className='priceContent justify-content-end text-primary fs-5 fw-bold m-0'>{`$${(price / 100).toFixed(2)}`}</span>:''
                  }
                </div>
            </div>
            <div className='row w-100 justify-content-center'>
               <div className='justify-content-center d-flex w-150px counterWrapper'>
                  <button
                     className='plusminus p-1'
                     onClick={(event) => {
                        onClick(event, 'unselect')
                     }}
                  >
                     -
                  </button>
                  <span className='num mx-2 h-100 w-30px p-1'>{quantity}</span>
                  <button
                     className='plusminus p-1'
                     onClick={(event) => {
                        onClick(event, 'select')
                     }}
                  >
                     +
                  </button>
               </div>
            </div>
         </div>
      </li>
   )
}
