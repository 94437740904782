import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import './SettingsStyle.css'
import {useSelector} from 'react-redux'
import {GetApiUrl, getAuth} from '../../auth'
import axios from 'axios'
import {RootState} from '../../../store'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {FormattedMessage, useIntl} from 'react-intl'

interface ConnectionModalProps {
   toggleModal: () => void
}

export const ConnectionDetailsModal: FC<ConnectionModalProps> = ({toggleModal}) => {
   const terminalData = useSelector((state: RootState) => state.selected_terminal.value)
   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
   }
   const closeBtnStyle = {
      position: 'absolute',
      top: '1rem',
      right: 0,
   } as React.CSSProperties
   const intl = useIntl()
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   const networkStatus = tizenSystem.network.available
      ? intl.formatMessage({id: 'setting.connection.network_status.online'})
      : intl.formatMessage({id: 'setting.connection.network_status.offline'})
   const networkType = tizenSystem.network.type ?? intl.formatMessage({id: 'setting.connection.n_a'})
   const networkSSID = tizenSystem.network.wifi_ssid ?? intl.formatMessage({id: 'setting.connection.n_a'})

   const terminal_data = terminalData != null ? terminalData : JSON.parse(localStorage.getItem('terminal_data') as any)
   const [TerminalSetting, setTerminalSetting]: any = useState()
   const BtnClrClass = useLocationBasedBtnColor()

   const [serverPinged, setServerPinged] = useState<boolean>(false)

   const pingServer = (server: any) => {
      let xhr = new XMLHttpRequest()
      xhr.onload = function () {
         console.log('Server ping successful')
         setServerPinged(true)
      }
      xhr.onerror = function () {
         console.log('Server ping failed')
         setServerPinged(false)
      }
      xhr.open('GET', server, true)
      xhr.send()
   }

   useEffect(() => {
      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/terminals/${terminal_data.id}/network_settings?global_on_id=${
         process.env.REACT_APP_KIOSK_GLOBAL_ON
      }&user_id=${user_id}`
      const data: any = {
         token: user?.api_token,
         url: url,
      }
      axios
         .get(`${data.url}`, {
            headers: {
               Authorization: `Basic ${data.token}`,
            },
         })
         .then((response) => {
            console.log(response.data)
            setTerminalSetting(response.data)
         })
      pingServer(url) // comment --> Settings - Apr 14 - CJ (1).png; suggests we use better up time API.
   }, [])

   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog-centered h-auto mw-50 px-10'
         show={true}
         onHide={toggleModal}
      >
         <div className='container-xxl px-10 py-5'>
            <div className='modal-dialog mw-100 p-0 m-0'>
               <div className='modal-content'>
                  <div className='text-end'>
                     <div
                        aria-label='Close'
                        data-bs-dismiss='modal'
                        onClick={toggleModal}
                        className='btn btn-icon btn-lg btn-active-light-primary'
                        style={closeBtnStyle}
                     >
                        <i className='bi bi-x-lg fs-2x svg-icon' />
                     </div>
                  </div>
                  {/* START::MODAL-BODY */}
                  <div className='tab-content'>
                     <div className=' h-xl-100 pb-10 pt-5'>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.connection.connectivity' defaultMessage='Connectivity' />
                              </h1>
                              <p className='fs-3'></p>
                           </div>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.connection.services' defaultMessage='Services' />
                              </h1>
                              <p className='fs-3'></p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage
                                    id='setting.connection.network_status'
                                    defaultMessage='Network Status'
                                 />
                              </h1>
                              <p className='fs-3'>{networkStatus}</p>
                           </div>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.connection.v2' defaultMessage='v2' />
                              </h1>
                              {/* <p className="fs-3">{TerminalSetting?.profile?.servers[0]?.operational_status==1?'Online':'Offline'}</p> */}
                              <p className='fs-3'>
                                 {serverPinged
                                    ? intl.formatMessage({id: 'setting.connection.network_status.online'})
                                    : intl.formatMessage({id: 'setting.connection.network_status.offline'})}
                              </p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage id='setting.connection.network_type' defaultMessage='Network Type' />
                              </h1>
                              <p className='fs-3'>{networkType}</p>
                           </div>
                        </div>
                        <div className='row theme-background py-3'>
                           <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
                              <h1 className='fs-1'>
                                 <FormattedMessage
                                    id='setting.connection.networkname'
                                    defaultMessage='Network Name (SSID)'
                                 />
                              </h1>
                              <p className='fs-3'>{networkSSID}</p>
                           </div>
                        </div>
                        <div className='d-flex row justify-content-center'>
                           <button
                              type='button'
                              style={buttonStyle}
                              onClick={toggleModal}
                              className={`btn btn-primary ms-2 w-100 ${BtnClrClass}`}
                           >
                              <FormattedMessage id='alert.ok_got_it' defaultMessage='OK, got it!' />
                           </button>
                        </div>
                     </div>
                  </div>
                  {/* END::MODAL-BODY */}
               </div>
            </div>
         </div>
      </Modal>
   )
}
