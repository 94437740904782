import {MouseEventHandler} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {getSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {constants} from '../../../config/constants'

export type CardModalBodyProps = {
   imgPath: string
   onCancelPayment: MouseEventHandler<HTMLButtonElement>
}

const MODAL_BTN_STYLE = {
   padding: '15px 0px',
   fontSize: '25px',
   borderRadius: '10px',
   height: '85px',
}

export const CardModalBody = ({imgPath, onCancelPayment}: CardModalBodyProps) => {
   const TERMINAL = useSelector(getSelectedTerminal) as Terminal
   const CLOVER_DEVICES = constants.global_ids.manufacturer_terminals.clover
   const PAX_DEVICES = constants.global_ids.manufacturer_terminals.pax
   const UNSUPPORTED_CANCEL_DEVICES = [CLOVER_DEVICES, PAX_DEVICES].reduce((accum, deviceGroup) => {
      return accum.concat(deviceGroup)
   }, [])
   const pairedTerminalSupportsCancel = !UNSUPPORTED_CANCEL_DEVICES.includes(
      TERMINAL.paired_terminal_manufacturer_id ?? 0
   )
   return (
      <div className='modal-body text-center'>
         <div>
            <h2 style={{position: 'relative', top: '40px'}} className='mb-3'>
               Please check the order amount on the payment terminal below and pay according to the displayed
               instructions.
            </h2>

            <div className='mx-auto d-flex'>
               <img src={toAbsoluteUrl(imgPath)} className='mx-auto' />
            </div>

            <h2 style={{position: 'relative', bottom: '60px'}}>
               Once payment is made, your order will be sent to the kitchen.
            </h2>
         </div>

         {pairedTerminalSupportsCancel && (
            <div>
               <button
                  type='button'
                  style={MODAL_BTN_STYLE}
                  className='btn btn-bg-secondary me-2 fs-1 btn-block w-100 mt-10'
                  onClick={onCancelPayment}
               >
                  Cancel
               </button>
            </div>
         )}
      </div>
   )
}
