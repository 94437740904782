import {number, object} from 'yup'

export const PriceCheckSchema = object({
   discounts: number().required(),
   due: number().required(),
   exclusive_tax: number().nullable(),
   inclusive_tax: number().nullable(),
   items: number().required(),
   other_charges: number().required(),
   paid: number().required(),
   service_charges: number().required(),
   sub_total: number().required(),
   tax: number().required(),
   tips: number().required(),
   total: number().required(),
})
