import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {GetApiUrl, getAuth} from '../modules/auth'
import {KIOSK_GLOBAL_ON} from '../config/constants'
import axios from 'axios'
import {AppDispatch, RootState} from '../store'
import {IStandardError, RequestStatus} from '../common/models/SoftPointAPIs'
import {parseRequestError} from '../common/utilities'
import {BasisPoints} from './hooks/useCart'

export interface ISelfOrderingCartItemModifier {
   id: number
   id_external: string
   name: string
   price: number
   price_per_unit: number
   modgroup_id: number
   quantity: number
   parent_modifier_id?: null | number 
   parent_modifier_group_id?: null | number 
}

export interface CheckOutRouteState {
   menu_id: number
   id: number
   quantity: number
   weakUuid?: number
}

export interface ISelfOrderingCartItem {
   weakUuid: number
   category_id: number
   comment: string
   similarity_score: string
   id: number
   id_external: string
   item_order_type?: string
   item_order_mode_id: number
   item_order_mode_id_external: number
   modifiers: ISelfOrderingCartItemModifier[]
   removed_included_modifiers: ISelfOrderingCartItemModifier[]
   name: string
   open: number
   price: number
   price_per_unit: number
   quantity: number
   seat: string
   tax: number
   order_type: string
   image: string
}
export interface ISelfOrderingCartTotalObj {
   sub_total: number
   tax: number
   inclusive_tax: number
   total: number
   due: number
   service_charges: number
   tip: number // Stored as basis points
   order_type: string
}

interface ISaleSession {
   request_status: RequestStatus
   error: IStandardError | null
   data: null | {id: number}
}

interface ITipValue {
   type: 'none' | 'custom' | 'preset'
   uid: null | number
   tip_points?: any
   tip_in_cents?: null | number
   tipAmount: number
}

export interface ISelfOrderingCart {
   sale_session_thunk: ISaleSession
   items: ISelfOrderingCartItem[]
   totals: ISelfOrderingCartTotalObj
   isLoading: boolean
}

const initialState: ISelfOrderingCart = {
   sale_session_thunk: {
      data: null,
      error: null,
      request_status: 'idle',
   },
   items: [],
   totals: {
      sub_total: 0,
      tax: 0,
      inclusive_tax: 0,
      total: 0,
      due: 0,
      service_charges: 0,
      tip: 0,
      order_type: '',
   },
   isLoading: false,
}

export const SelfOrderingCartSlice = createSlice({
   name: 'selfOrderingCart',
   initialState,
   reducers: {
      setLoading: (state, action) => {
         state.isLoading = action.payload
      },
      addItem: (state, action: PayloadAction<ISelfOrderingCartItem>) => {
         state.items.push(action.payload)
      },
      updateCartItem: (state, action: PayloadAction<ISelfOrderingCartItem>) => {
         const itemIdToUpdate = action.payload.weakUuid
         state.items = state.items.filter((cartItem) => {
            if (cartItem.weakUuid == itemIdToUpdate) {
               cartItem.tax = action.payload.tax
               cartItem.quantity = action.payload.quantity
               cartItem.modifiers = action.payload.modifiers
               cartItem.removed_included_modifiers = action.payload.removed_included_modifiers
            }
            return cartItem
         })
      },
      removeItemFromCart: (state, action: PayloadAction<ISelfOrderingCartItem>) => {
         const itemIdToRemove = action.payload.weakUuid
         state.items = state.items.filter((cartItem) => {
            return cartItem.weakUuid != itemIdToRemove
         })
      },
      resetCart: (state) => {
         state.items = []
         state.totals = {
            sub_total: 0,
            tax: 0,
            inclusive_tax: 0,
            service_charges: 0,
            total: 0,
            due: 0,
            tip: 0,
            order_type: '',
         }
         state.sale_session_thunk = initialState.sale_session_thunk
      },
      setTotal: (state, action: PayloadAction<ISelfOrderingCartTotalObj>) => {
         const totals = action.payload
         totals.due = Math.round(totals.due)
         totals.tax = Math.round(totals.tax)
         totals.inclusive_tax = Math.round(totals.inclusive_tax)
         totals.service_charges = Math.round(totals.service_charges)
         totals.total = Math.round(totals.total)
         totals.tip = Math.round(totals.tip)
         state.totals = totals
      },
      updateTotalsWithTip: (state, action: PayloadAction<number>) => {
         console.log('[updateTotalsWithTip]', action.payload)
         state.totals.tip = action.payload
         state.totals.total = state.totals.due + action.payload
      },
      updateTotalsWithNotTip: (state) => {
         if (state.totals.tip > 0) {
            state.totals.total = state.totals.total - state.totals.tip
            state.totals.tip = 0
         }
      },
      setSaleSessionId: (state, action: PayloadAction<number>) => {
         state.sale_session_thunk.data = {id: action.payload}
      },
      setSaleSessionStatus: (state, action: PayloadAction<RequestStatus>) => {
         state.sale_session_thunk.request_status = action.payload
      },
      setSaleSessionError: (state, action: PayloadAction<IStandardError | null>) => {
         state.sale_session_thunk.error = action.payload
      },
   },
})

// Action creators are generated for each case reducer function
export const {
   addItem,
   updateCartItem,
   removeItemFromCart,
   resetCart,
   setTotal,
   setLoading,
   updateTotalsWithTip,
   updateTotalsWithNotTip,
   setSaleSessionId,
   setSaleSessionError,
   setSaleSessionStatus,
} = SelfOrderingCartSlice.actions

export default SelfOrderingCartSlice.reducer

// SELECTORS
export const getSaleSession = (state: RootState): ISaleSession => state.self_ordering_cart.sale_session_thunk

// THUNKS
export const GetNewSaleSessionID = (locationId: number | string, terminalId: number | string) => {
   const URL = `${GetApiUrl()}/locations/${locationId}/webhook`
   const auth = getAuth()
   const queryParams = {
      global_on_id: KIOSK_GLOBAL_ON,
      user_id: auth?.user_id ?? 0,
   }
   const body = {
      terminal_id: terminalId,
   }
   return async (dispatch: AppDispatch) => {
      try {
         dispatch(setSaleSessionStatus('in_flight'))
         const response = await axios.post(URL, body, {params: queryParams})
         if (response.data.id === undefined) {
            throw new Error('[GetNewSaleSessionID] failed, no ID in response.')
         }
         dispatch(setSaleSessionId(Number(response.data.id)))
         dispatch(setSaleSessionStatus('done'))
      } catch (e) {
         dispatch(setSaleSessionError(parseRequestError(e)))
         dispatch(setSaleSessionStatus('done'))
      }
   }
}
