import axios from 'axios'
import {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {GetApiUrl, getAuth} from '../../modules/auth'
import {AppDispatch, RootState} from '../../store'
import {
   addItem,
   ISelfOrderingCartItem,
   updateCartItem,
   removeItemFromCart,
   setTotal,
   resetCart,
   ISelfOrderingCartItemModifier,
   setLoading,
} from '../SelfOrderingCartSlice'
import {iItems_taxrate_int} from '../SelfOrderingDataSlice'
import {PriceCheckSchema} from '../../common/schemas/PriceCheckSchema'
import {ValidationError} from 'yup'
import {KIOSK_GLOBAL_ON} from '../../config/constants'
import {KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {CategoryItem} from '../../common/schemas/SyncDataSchema'
import {Terminal} from '../../common/schemas/TerminalSchema'
import {getDisplayName} from '../../common/utilities'

interface extraItemData {
   category_id: number
   modifiers: ISelfOrderingCartItemModifier[]
   removed_included_modifiers: ISelfOrderingCartItemModifier[]
}

export type CartOperations = {
   AddItem: (item: CategoryItem, quantity: number, extraData: extraItemData) => void
   RemoveItem: (item: ISelfOrderingCartItem) => void
   UpdateQtyToItem: (item: ISelfOrderingCartItem) => void
   GetItems: () => ISelfOrderingCartItem[]
   ResetCart: () => void
   ShowAddToCartSuccessModal: boolean
}

const CartContext = createContext<CartOperations | undefined>(undefined)

export function CartProvider({children}: {children: ReactNode}) {
   const dispatch = useDispatch<AppDispatch>()
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const items = useSelector((state: RootState) => state.self_ordering_cart.items)
   const self_order_selections = useSelector((state: RootState) => state.self_ordering_selections)
   const order_type = self_order_selections.order_type
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const SelectedTerminal = useSelector((state: RootState) => state.selected_terminal.value) as Terminal
   const selectedTipPoints = useSelector((state: RootState) => state.self_ordering_selections.tip_points)

   // Toggling this state will force update the cart totals
   const [updateTotalsSignal, runUpdateTotals] = useState<boolean>(false)
   const [ShowAddToCartSuccessModal, setShowAddToCartSuccessModal] = useState(false)

   // HANDLES CART TOTALS UPDATES
   useEffect(() => {
      if (items.length > 0) {
         calculateTotals()
      } else {
         dispatch(
            setTotal({
               sub_total: 0,
               tax: 0,
               inclusive_tax: 0,
               service_charges: 0,
               due: 0,
               total: 0,
               tip: 0,
               order_type,
            })
         )
         dispatch(setLoading(false))
      }
   }, [items.length, updateTotalsSignal, selectedTipPoints])

   //TODO: Add a limit to how many items can be added to the cart
   const AddItem = (item: CategoryItem, quantity: number, extraData: extraItemData) => {
      let tax_rate_id = item._embedded.tax_rate?.id
      let tax = 0
      if (tax_rate_id) {
         tax = selfOrderData.Data_TaxRates.find((taxRate: iItems_taxrate_int) => taxRate.id === tax_rate_id)?.rate ?? 0
         //((quantity * item.price_per_unit)/100) * (rate/100);
      }
      // let total = Number(item?.price_per_unit) * Number(quantity);

      extraData.modifiers = extraData.modifiers.map((mod) => {
         let clonedMod = {...mod}
         clonedMod.quantity *= quantity
         return clonedMod
      })

      let info: ISelfOrderingCartItem = {
         weakUuid: item.weakUuid ?? Date.now() + Math.random(),
         category_id: extraData.category_id,
         comment: '',
         similarity_score: '',
         id: item?.id,
         id_external: item.id_external ?? '',
         item_order_type: order_type,
         item_order_mode_id: 0,
         item_order_mode_id_external: 0,
         modifiers: extraData.modifiers,
         removed_included_modifiers: extraData.removed_included_modifiers,
         name: getDisplayName(item),
         open: item?.open,
         price: item.price_per_unit ?? 0,
         price_per_unit: item.price_per_unit ?? 0,
         quantity: quantity,
         seat: '1',
         tax: tax,
         order_type: order_type,
         image: item.image ?? '',
      }
      let existItem = info.weakUuid && GetItems().find((e) => e.weakUuid == item.weakUuid)
      if (existItem) UpdateItemQty(info)
      else {
         setTimeout(() => {
            dispatch(addItem(info))
            runUpdateTotals(!updateTotalsSignal)
         }, 100)
         OpenAddToCartSuccessModal()
      }
   }

   const RemoveItem = (item: ISelfOrderingCartItem) => {
      dispatch(removeItemFromCart(item))
      dispatch(setLoading(true))
   }

   const UpdateItemQty = (item: ISelfOrderingCartItem) => {
      setTimeout(() => {
         dispatch(updateCartItem(item))
         runUpdateTotals(!updateTotalsSignal)
      }, 100)
   }

   const calculateTotals = () => {
      checkPrice()
         .then((data) => {
            // const tip = data.sub_total * new BasisPoints(selectedTipPoints, 'basis_points').toRate()
            const tip = 0
            const totalObj = {
               sub_total: data.sub_total,
               tax: data.tax,
               inclusive_tax: 0,
               service_charges: data.service_charges,
               due: data.due,
               total: data.total,
               tip,
               order_type,
            }
            dispatch(setTotal(totalObj))
         })
         .catch((error) => {
            const sub_total = GetItems().reduce((total, item) => {
               return total + getItemSubTotal(item)
            }, 0)

            const tax = GetItems().reduce((total, item) => {
               const subTotal = getItemSubTotal(item)
               const taxRate = item.tax / 100
               return total + subTotal * taxRate
            }, 0)

            const inclusive_tax = 0
            // const tip = sub_total * new BasisPoints(selectedTipPoints, 'basis_points').toRate()
            const tip = 0
            const total = sub_total + tax + tip
            const due = sub_total + tax + tip
            const service_charges = 0
            const totalObj = {sub_total, tax, inclusive_tax, service_charges, due, total, order_type, tip}
            dispatch(setTotal(totalObj))
         })
   }

   const GetItems = () => {
      return items.filter((item) => item.order_type == order_type)
   }

   const ResetCart = () => {
      dispatch(resetCart())
   }

   const checkPrice = async () => {
      let user = getAuth()
      const user_id = user?.user_id

      const employee_id = kiosk_settings?.ordering_employee ? kiosk_settings.ordering_employee : ''
      const employee_external_id = kiosk_settings?.ordering_employee_external_id
         ? kiosk_settings.ordering_employee_external_id
         : ''

      let order_type_id = kiosk_settings.order_type_id ?? ''
      let order_type_id_external = kiosk_settings.order_type_external_id ?? ''
      if (order_type == 'dinein') {
         order_type_id = kiosk_settings.dine_in ?? ''
         order_type_id_external = kiosk_settings.order_type_dine_in_external_id ?? ''
      }

      const revenue_center_id = kiosk_settings?.revenue_center ? kiosk_settings.revenue_center : ''
      const revenue_center_id_external = kiosk_settings?.revenue_center_external_id
         ? kiosk_settings.revenue_center_external_id
         : ''
      const terminal_id = SelectedTerminal.id ? SelectedTerminal.id : ''

      const endPointPayload = {
         cart: {
            employee_id: employee_id,
            employee_id_external: employee_external_id,
            order_type_id: order_type_id,
            order_type_id_external: order_type_id_external,
            revenue_center_id: revenue_center_id,
            revenue_center_id_external: revenue_center_id_external,
            items: GetItems(),
         },
      }

      const url = `${GetApiUrl()}/locations/${kiosk_settings?.location_id}/price_check`
      const queryParams = {
         global_on_id: KIOSK_GLOBAL_ON,
         terminal_id: terminal_id,
         user_id: user_id,
      }
      try {
         const response = await axios.post(url, endPointPayload, {params: queryParams})
         const dirtyData = response.data
         dispatch(setLoading(false))
         return await PriceCheckSchema.validate(dirtyData)
      } catch (error) {
         if (error instanceof ValidationError) {
            console.log('[checkPrice ValidationError]', error.errors)
         }
         dispatch(setLoading(false))

         throw error
      }
   }

   const OpenAddToCartSuccessModal = () => {
      setShowAddToCartSuccessModal(true)
      setTimeout(() => {
         CloseAddToCartSuccessModal()
      }, 4000)
   }

   const CloseAddToCartSuccessModal = () => {
      setShowAddToCartSuccessModal(false)
   }

   return (
      <CartContext.Provider
         value={{GetItems, AddItem, RemoveItem, UpdateQtyToItem: UpdateItemQty, ResetCart, ShowAddToCartSuccessModal}}
      >
         {children}
      </CartContext.Provider>
   )
}

export const useCart = () => {
   const context = useContext(CartContext)

   if (!context) {
      throw new Error('[useCart] must be used in a child component of [CartProvider]')
   }

   return context
}

export function getItemSubTotal(item: ISelfOrderingCartItem) {
   const modTotal = item.modifiers.reduce((total: number, mod: ISelfOrderingCartItemModifier) => {
      return total + mod.price * mod.quantity
   }, 0)
   return item.price_per_unit * item.quantity + modTotal
}

export class BasisPoints {
   private basisPoints: number
   private originalUnit: 'percent' | 'basis_points'
   public constructor(value: number, unitType: 'percent' | 'basis_points') {
      this.originalUnit = unitType
      switch (unitType) {
         case 'percent':
            this.basisPoints = value * 100
            break
         default:
            this.basisPoints = Math.floor(value)
      }
   }

   toRate() {
      return this.basisPoints / 10000
   }

   toPercent() {
      return this.basisPoints / 100
   }

   getBasisPoints() {
      return this.basisPoints
   }
}
