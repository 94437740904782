// export interface IHorizontalRuler {
//    color?: string
//    margin?: string
//    borderType?: 'solid' | 'dotted'
// }
//
// export const HorizontalRuler = ({color = '#2b2b40', borderType = 'solid', margin = '0'}: IHorizontalRuler) => {
//    let rulerStyle = {
//       borderBottom: `1px ${borderType} ${color}`,
//       opacity: borderType == 'solid' ? 0.4 : 1,
//       margin: margin,
//    }
//
//    return <div style={rulerStyle} />
// }

export const HorizontalRuler = ({prompt}: {prompt: string}) => {
   return (
      <div className='d-flex align-items-center w-100'>
         <div className='mw-50 w-100' style={{borderBottom: 'solid 1px gray'}} />
         <span className='fw-bold text-gray-400 fs-1 mx-2'>{prompt}</span>
         <div className=' mw-50 w-100' style={{borderBottom: 'solid 1px gray'}} />
      </div>
   )
}
