import React, {CSSProperties} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {KioskSettings} from '../schemas/KioskSettingsSchema'

interface Props {
   id?: string
   title: string
   disabled?: boolean
   extraClass?: string
   onClick?: () => void
   type?: 'submit' | 'button'
   style?: React.CSSProperties
}

export const TransparentButton: React.FC<Props> = ({id, title, disabled, extraClass, onClick, type, style}) => {
   const buttonStyles: CSSProperties = {
      border: '3px solid #3398fa',
      fontSize: '40px',
      ...style,
   }
   const locSettings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const textColor = locSettings.appearance === 1 ? 'text-black' : 'text-white'
   return (
      <button
         id={id ?? ''}
         type={type ?? 'button'}
         onClick={onClick}
         disabled={disabled ?? false}
         className={`btn btn-lg w-100 h-100 ${extraClass ?? ''}`}
         style={buttonStyles}
      >
         <span className={`fw-bold ${textColor}`}>{title}</span>
      </button>
   )
}
