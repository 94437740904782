import {LocationSchema} from '../../../common/schemas/LocationSchema'
import {TerminalValidationSchema} from '../../../common/schemas/TerminalSchema'
import {InferType, object, string} from 'yup'

export const autoLoginSchema = object({
   terminal_info: object({
      terminal: TerminalValidationSchema,
      location: LocationSchema,
   }).required(),
   access_token: string().required(),
})

export type AutoLoginResponse = InferType<typeof autoLoginSchema>
