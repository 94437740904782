import { MenuItemPlaceholderDark, MenuItemPlaceholderLight } from '../../../common/images'
import { useCacheImg } from '../../../common/hooks/useCacheImg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { constants } from '../../../config/constants'
import ContentLoader from 'react-content-loader'

interface MenuImageProps {
   src: string
   alt?: string
   className?: string
}
export const MenuImage = (props: MenuImageProps) => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const APPEARANCE_STATUS = constants.status.location.appearance
   const placeholder = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ?  MenuItemPlaceholderLight : MenuItemPlaceholderDark;

   const { cachedImage, isLoading } = useCacheImg({ imageUrl: props.src, placeholderUrl: placeholder })

   return (
      <>
         {isLoading ? (
             <ContentLoader
             animateBegin="0.1s"                
             height={150}
             backgroundColor="#f5f5f5"
             foregroundColor="#dbdbdb"
             style={{ width: '100%' }}
             foregroundOpacity={0.5}
         >
             <rect x="25" y="0" rx="0" ry="0" width="150" height="150" />
           </ContentLoader> 
         ) : (
            <img src={cachedImage} loading='lazy' className={props.className} alt={props.alt} />
         )}
      </>
   );
}
