import {TizenDebug} from '../../modules/hotel/FaceAuth/TizenIntegration'

export class Tizen {
   public static updateApplication() {
      const appEnv = process.env.REACT_APP_ENVIRONMENT as string

      let envUrlSegment: string
      switch (appEnv.toLowerCase()) {
         case 'live':
            envUrlSegment = 'Live'
            break
         case 'sandbox':
            envUrlSegment = 'Sandbox'
            break
         default:
            envUrlSegment = 'Dev'
      }

      try {
         const url = `/v/${envUrlSegment}/KioskPoint.zip`
         // @ts-ignore
         b2bapis.paymentdownload.update(url, true)
      } catch (error: any) {
         TizenDebug(' error code = ' + error.code)
         TizenDebug(' error = ' + JSON.stringify(error))
      }
   }
}
