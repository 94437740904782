import {createContext, ReactNode, useContext, useEffect} from 'react'

type QuickPointServices = {
   requestPayment: () => void
}

const QuickPointContext = createContext<QuickPointServices | undefined>(undefined)

export const QuickPointProvider = ({children}: {children: ReactNode}) => {
   const requestPayment = () => {}

   // useEffect(() => {
   //    const socket = new WebSocket('ws://192.168.1.10:25534')
   //    socket.addEventListener('open', () => {
   //       const payload = {
   //          action: 'hellosdf',
   //       }
   //       socket.send(JSON.stringify(payload))
   //    })
   //    socket.addEventListener('message', (event) => {
   //       console.log('[SOCKET] ', event.data)
   //    })
   //    socket.addEventListener('error', (event) => {
   //       console.log('[SOCKET] ', event)
   //    })
   // }, [])

   return <QuickPointContext.Provider value={{requestPayment}}>{children}</QuickPointContext.Provider>
}

export const useQuickPoint = () => {
   const context = useContext(QuickPointContext)
   if (!context) {
      throw new Error('[useQuickPoint] must be used within [QuickPointProvider]')
   }
   return context
}
