import {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {useSelector} from 'react-redux'
import {constants, KIOSK_GLOBAL_ON} from '../../../config/constants'
import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import moment from 'moment'
import {getAuth, GetApiUrl} from '../../auth/core/AuthHelpers'
import {useErrorAlert, useTimedSuccessAlert} from '../../utilities/alerts'
import Swal from 'sweetalert2'
import {RootState} from '../../../store'
import {generateReservationReceipt} from '../../../common/printing/receipt_generators'
import {PrinterService} from '../../../common/printing/printing_service'
import {IGuestReservation, INextReservationResponse} from '../_requests/reservations'
import {PRINTER_STATUSES} from '../../../services/TizenSystemSlice'
import {TizenDebug} from '../FaceAuth/TizenIntegration'
import {FormattedMessage, useIntl} from 'react-intl'
import axios from 'axios'
import {parseRequestError} from '../../../common/utilities'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {useInternationalization} from '../../i18n/i18nProvider'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {getKioskSettings} from '../../../services/KioskSettingSlice'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {OpenTicketArgs, useTicketService} from '../../self_ordering/hooks/useTicketService'
import {getGuestReservationStore} from '../../../services/GuestReservationSlice'
import {getLocationProductStatus, getSelectedLocation} from '../../../services/SelectedLocationSlice'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'

export const ConfirmationScreen = () => {
   const {selectedLang} = useInternationalization()
   const intl = useIntl()
   const navigateTo = useNavigate()
   const fireErrorAlert = useErrorAlert()
   const fireTimedSuccess = useTimedSuccessAlert()
   const {OpenTicket} = useTicketService([])
   const guest_reservation_store = useSelector(getGuestReservationStore)
   const tizenSystem = useSelector((state: RootState) => state.tizen_system)
   const [ShowPrivateKeyModal, setShowPrivateKeyModal] = useState(false)
   const handleClosePrivateKey = () => setShowPrivateKeyModal(false)
   const handleShowPrivateKey = () => setShowPrivateKeyModal(true)

   let bannerImage = toAbsoluteUrl('../media/images/banner-bg.jpg')
   const checkInBtnRef = useRef<null | HTMLButtonElement>(null)
   const [IsChkinDisabled, setIsChkinDisabled] = useState(true)
   const guestId = guest_reservation_store.requested_guest_id

   let user = getAuth()
   const user_id = user?.user_id
   const globalReservationTypes = constants.global_ids.reservation_types

   useTranslatedPageTitle(constants.staticPageTitle.confirmation)
   useEffect(() => {
      const reservation_errors = localStorage.getItem('reservation_errors')
      //Need to check if there are any business errors with the reservation
      console.log(reservation_errors)
      if (reservation_errors !== null) {
         const reservationErrors = JSON.parse(reservation_errors)
         fireErrorAlert(reservationErrors[0].message ?? 'Error')
         localStorage.removeItem('reservation_errors')
      } else {
         setIsChkinDisabled(false)
      }
   }, [])

   let kiosk_settings = useSelector(getKioskSettings) as KioskSettings
   let location_data = useSelector(getSelectedLocation) as SoftPointLocation
   const derivedLocationData = useSelector(getLocationProductStatus)

   const reservation_data = guest_reservation_store.data as INextReservationResponse
   let reservationGuests = reservation_data._embedded?.guests
   let reservation_room_type_data = reservation_data._embedded?.room_type
   let reservation_revenue_center_data = reservation_data._embedded?.revenue_center

   if (kiosk_settings._embedded.locations_config_contactless?.reservation_banner) {
      bannerImage =
         kiosk_settings._embedded.locations_config_contactless?.reservation_banner != ''
            ? `${process.env.REACT_APP_API_URL}/download-file?path=${
                 kiosk_settings._embedded.locations_config_contactless?.reservation_banner
              }&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`
            : toAbsoluteUrl('../media/images/banner-bg.jpg')
   }

   let PrimaryGuest = guest_reservation_store.derived_values.primary_guest
   const NonPrimaryGuests = guest_reservation_store.derived_values.non_primary_guests
   const CheckInGuest = guest_reservation_store.derived_values.current_guest

   /**
    * Sends the guest a confirmation text message and calls processSuccessCheckIn
    */
   const sendConfirmationMessage = async () => {
      const send_confirmation_url = `${GetApiUrl()}/locations/${location_data.location_id}/reservations/${
         reservation_data.id
      }/send_confirmation?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`

      try {
         const response = await axios.post(send_confirmation_url)
         processSuccessCheckIn()
      } catch (e) {
         const standardError = parseRequestError(e)
         fireErrorAlert(standardError.message)
         setIsChkinDisabled(false)
      }
   }

   /**
    * Prints a reservation receipt and displays a success alert
    */
   const processSuccessCheckIn = async () => {
      setIsChkinDisabled(true)
      TizenDebug('[processSuccessCheckIn]: start')
      const printerHasPaper = tizenSystem.printer.paper_status != PRINTER_STATUSES.OUT
      if (kiosk_settings?.print_confirmation == 1 && reservation_data != null && guestId !== null && printerHasPaper) {
         const reservationPlus: IGuestReservation = {
            guest_id: +guestId,
            ...reservation_data,
         }
         const receiptData = generateReservationReceipt(location_data.name, reservationPlus, selectedLang)
         const printerManager: PrinterService = new PrinterService()
         printerManager.openSerialPort()
         printerManager.print(receiptData)
         TizenDebug('[processSuccessCheckIn]: receipt printed')
      }

      let alert_msg = `${intl.formatMessage({
         id: 'checkin.reservation.confirmation.thank_you',
      })} <br> <span style='font-size: 18px'>${intl.formatMessage({
         id: 'checkin.reservation.confirmation.successfully_checked_in',
      })}</span>`

      if (kiosk_settings?.print_confirmation == 1)
         alert_msg += `<p class="fs-2 lh-sm mt-10">${intl.formatMessage({
            id: 'checkin.reservation.confirmation.take_your_receipt',
         })}</p>`

      fireTimedSuccess(alert_msg, 10, intl.formatMessage({id: 'checkin.reservation.confirmation.return_to_home'})).then(
         (userInput) => {
            if (userInput.isConfirmed || userInput.isDismissed) {
               if (derivedLocationData.checkInIsEnabled) {
                  setIsChkinDisabled(false)
                  navigateTo(MODULE_ROUTE_PATHS.checkIn.check_in_home)
               } else {
                  setIsChkinDisabled(false)
                  navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
               }
            }
         }
      )
   }

   const isRestaurantReservation = (reservationTypeID: number) =>
      globalReservationTypes.RESTAURANT === reservationTypeID

   /**
    * Updates the guest's check in status and calls sendConfirmationMessage
    */
   const UpdateReservation = async () => {
      setIsChkinDisabled(true)
      const url = `${GetApiUrl()}/locations/${location_data.location_id}/reservations/${reservation_data.id}/guests/${
         guest_reservation_store.requested_guest_id
      }?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`

      try {
         const response = await axios.put(url, {
            status_checkin: constants.status.complete,
            biometrics_sync_id: guest_reservation_store.biometrics_sync_id,
         })
         let processedData = response.data
         if (CheckInGuest && CheckInGuest.status_checkin == 0) {
            if (
               CheckInGuest.guest != null &&
               isRestaurantReservation(guest_reservation_store.data?.global_reservation_type_id ?? -1) &&
               kiosk_settings._embedded.locations_config_contactless.bartab == constants.status.ON &&
               CheckInGuest.guest_id == (PrimaryGuest?.guest_id ?? -1)
            ) {
               TizenDebug('[OpenTicket]: opening bar tab')
               const openTicketData: OpenTicketArgs = {
                  ticketName: CheckInGuest.guest,
                  guestCount: guest_reservation_store.data!.guest_count,
                  guestPhone: CheckInGuest.phone,
                  context: {
                     flow: 'checkin',
                     guest_id: CheckInGuest.guest_id,
                  },
               }

               try {
                  await OpenTicket(openTicketData)
               } catch (error) {
                  console.log(error)
                  const parsedError = parseRequestError(error)
                  TizenDebug(parsedError.message)
                  const translatedMessage = intl.formatMessage({
                     id: 'create_ticket.failed',
                     defaultMessage: 'We were unable to open your ticket, please seek a staff member.',
                  })
                  const userInput = await fireErrorAlert(translatedMessage)
               }
            }

            console.log('Sending confirmation')
            sendConfirmationMessage()
         } else {
            processSuccessCheckIn()
         }
      } catch (e) {
         const standardError = parseRequestError(e)
         let translatedMessage = standardError.message
         if (standardError.code < 0) {
            translatedMessage = intl.formatMessage({id: translatedMessage})
         }
         fireErrorAlert(translatedMessage).then((result) => {
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.backdrop) {
               setIsChkinDisabled(false)
               navigateTo(MODULE_ROUTE_PATHS.checkIn.check_in_home)
            }
         })
      }
   }

   const handleCheckIn = () => {
      if (CheckInGuest && CheckInGuest.status_checkin === constants.status.complete) {
         processSuccessCheckIn()
      } else {
         UpdateReservation()
      }
   }

   return (
      <div className='container' style={{padding: '0 30px'}}>
         <div className='tab-content image-text-container'>
            <div
               className='card flex-grow-1 bgi-no-repeat bgi-size-auto bgi-position-x-end h-xl-100 pb-20 pt-20'
               style={{backgroundColor: '#020202', backgroundImage: `url(${bannerImage})`}}
            >
               <div className='card-body d-flex justify-content-between flex-column ps-xl-18'>
                  <div className='mb-20 pt-xl-13' style={{marginLeft: '2%'}}>
                     <h3 className='fw-bold text-white fs-4x mb-5 ms-n1'>
                        <FormattedMessage id='checkin.reservation.confirmation.welcome' defaultMessage='Welcome' />
                     </h3>
                     <span className='fw-bold text-white fs-2x mb-8 d-block lh-0'>
                        <FormattedMessage id='checkin.reservation.confirmation.to' defaultMessage='to' />{' '}
                        {location_data.name}
                     </span>

                     <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center me-6 pb-20 pt-20'></div>
                        <div className='d-flex align-items-center'></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='w-100 mt-5'>
            <div className='fv-row'>
               <div className='row'>
                  <div>
                     <div className='card h-xl-100'>
                        <div className='border-0 pt-5'>
                           <h3 className='card-title align-items-start flex-column'>
                              <span className='fs-1 px-5 '>
                                 <FormattedMessage
                                    id='checkin.reservation.confirmation.confirmation'
                                    defaultMessage='Confirmation'
                                 />
                              </span>
                           </h3>
                           <div className='card-toolbar'></div>
                        </div>
                        <div className='px-10 py-5'>
                           <div>
                              <div className='stepper-item' data-kt-stepper-element='nav'>
                                 <div className='stepper-line w-40px'></div>
                                 <div className='d-flex flex-stack'>
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                       <div className='flex-grow-1 me-2'>
                                          <span className=' fs-3'>
                                             <FormattedMessage
                                                id='checkin.reservation.confirmation.guest_name'
                                                defaultMessage='Guest Name'
                                             />
                                          </span>
                                       </div>
                                       <span className=' fs-3'>{CheckInGuest?.guest ?? ''}</span>
                                    </div>
                                 </div>
                                 <div className='separator separator-dashed my-4'></div>
                              </div>
                              <div className='stepper-item' data-kt-stepper-element='nav'>
                                 <div className='stepper-line w-40px'></div>
                                 <div className='d-flex flex-stack'>
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                       <div className='flex-grow-1 me-2'>
                                          <span className=' fs-3'>
                                             <FormattedMessage
                                                id='checkin.reservation.confirmation.reservation_code'
                                                defaultMessage='Reservation Code'
                                             />{' '}
                                          </span>
                                       </div>
                                       <span className=' fs-3'>#{reservation_data.confirmation_number}</span>
                                    </div>
                                 </div>
                                 <div className='separator separator-dashed my-4'></div>
                              </div>
                              <div className='stepper-item' data-kt-stepper-element='nav'>
                                 <div className='stepper-line w-40px'></div>
                                 <div className='d-flex flex-stack'>
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                       <div className='flex-grow-1 me-2'>
                                          <span className=' fs-3'>
                                             <FormattedMessage
                                                id='checkin.reservation.confirmation.time'
                                                defaultMessage='Reservation Time'
                                             />
                                          </span>
                                       </div>
                                       {/* <span className=" fs-3">{moment(reservation_data.check_in).format('MM/DD/YYYY hh:mm A')}</span> */}
                                       <span className=' fs-3'>
                                          {moment(reservation_data.local_check_in).format('ddd, MMM DD [at] LT')}
                                       </span>
                                    </div>
                                 </div>
                                 <div className='separator separator-dashed my-4'></div>
                              </div>
                              {reservation_data.global_reservation_type_id == 2 ? (
                                 <>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                       <div className='stepper-line w-40px'></div>
                                       <div className='d-flex flex-stack'>
                                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                             <div className='flex-grow-1 me-2'>
                                                <span className=' fs-3'>
                                                   <FormattedMessage
                                                      id='checkin.reservation.confirmation.room_type'
                                                      defaultMessage='Room Type'
                                                   />
                                                </span>
                                             </div>
                                             <span className=' fs-3'>{reservation_room_type_data?.name ?? ''}</span>
                                          </div>
                                       </div>
                                       <div className='separator separator-dashed my-4'></div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                       <div className='stepper-line w-40px'></div>
                                       <div className='d-flex flex-stack'>
                                          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                             <div className='flex-grow-1 me-2'>
                                                <span className=' fs-3'>
                                                   <FormattedMessage
                                                      id='checkin.reservation.confirmation.room_number'
                                                      defaultMessage='Room Number'
                                                   />
                                                </span>
                                             </div>
                                             <span className=' fs-3'>{reservation_data.room_id}</span>
                                          </div>
                                       </div>
                                       <div className='separator separator-dashed my-4'></div>
                                    </div>
                                 </>
                              ) : (
                                 <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <div className='stepper-line w-40px'></div>
                                    <div className='d-flex flex-stack'>
                                       <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                          <div className='flex-grow-1 me-2'>
                                             <span className=' fs-3'>
                                                <FormattedMessage
                                                   id='checkin.reservation.confirmation.table_type'
                                                   defaultMessage='Table Type'
                                                />
                                             </span>
                                          </div>
                                          {reservation_revenue_center_data != null ? (
                                             <span className=' fs-3'>{reservation_revenue_center_data.name}</span>
                                          ) : null}
                                       </div>
                                    </div>
                                    <div className='separator separator-dashed my-4'></div>
                                 </div>
                              )}

                              <div className='stepper-item' data-kt-stepper-element='nav'>
                                 <div className='stepper-line w-40px'></div>
                                 <div className='d-flex flex-stack'>
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                       <div className='flex-grow-1 me-2'>
                                          <span className=' fs-3'>
                                             <FormattedMessage
                                                id='checkin.reservation.confirmation.guest'
                                                defaultMessage='Guest'
                                             />
                                          </span>
                                       </div>
                                       {reservation_data.guest_count > 1 ? (
                                          <>
                                             <span className=' fs-3'>
                                                {PrimaryGuest?.guest} + {reservation_data.guest_count - 1}{' '}
                                                <FormattedMessage
                                                   id='checkin.reservation.confirmation.additional_guest'
                                                   defaultMessage='Additional
                                                Guest'
                                                />
                                                {NonPrimaryGuests &&
                                                   NonPrimaryGuests.map((guest, i: number) => {
                                                      return (
                                                         <>
                                                            <br />
                                                            <span className=' fs-3'>{guest.guest}</span>
                                                         </>
                                                      )
                                                   })}
                                             </span>
                                          </>
                                       ) : (
                                          <span className=' fs-3'>{PrimaryGuest?.guest}</span>
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12 ml-5 fs-3 fs-xl-1 p-10'>
                           {reservation_data.rooms_type_id &&
                           reservation_data.rooms_type_id > 0 &&
                           reservation_room_type_data?.description &&
                           reservation_room_type_data?.description != ''
                              ? reservation_room_type_data.description
                              : ''}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-10'>
            <div className='clear'></div>
            <div className='col-xl-12 col-md-12 col-sm-12'>
               <div className='row'>
                  <button
                     ref={checkInBtnRef}
                     type='button'
                     onClick={handleCheckIn}
                     className='btn btn-primary btn-lg btn-block'
                     disabled={IsChkinDisabled}
                     style={{padding: '15px 0px', fontSize: '30px', borderRadius: '10px'}}
                  >
                     <FormattedMessage id='checkin.reservation.confirmation.check_in' defaultMessage='Check In' />
                  </button>
               </div>
            </div>
         </div>
         <PrivateKeyModal show={ShowPrivateKeyModal} handleClose={handleClosePrivateKey} />
      </div>
   )
}

const PrivateKeyModal = (props: any) => {
   return (
      <Modal
         size='lg'
         aria-labelledby='contained-modal-title-vcenter'
         contentClassName='custom-rounded-modal'
         style={{borderRadius: '8% !imporatent'}}
         centered
         show={props.show}
         onHide={props.handleClose}
      >
         <Modal.Header closeButton>
            <Modal.Title className='display-4 text-center w-100'>Printed Key</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className='row'>
               <div className='col-xl-3 col-md-2 col-sm-1'></div>
               <div className='col-xl-3 col-md-4 col-sm-5'>
                  <h1 className='text-center'>Floor</h1>
                  <h2 className='text-center'>2</h2>
               </div>
               <div className='col-xl-3 col-md-4 col-sm-5'>
                  <h1 className='text-center'>Room</h1>
                  <h2 className='text-center'>202</h2>
               </div>
               <div className='col-xl-3 col-md-2 col-sm-1'></div>
               <div className='clear'></div>
               <div className='fs-1 text-center col-xl-12 col-md-12 mt-10 col-sm-12'>Please Collect Your Room keys</div>
               <div className='fs-2 text-center col-xl-12 col-md-12 mt-10 col-sm-12'>
                  Processing 2 keys, How many keys would you like ?
               </div>
            </div>
            <div className='row mx-auto'>
               <div className='fs-2 custom-btn-right col-md-6 mt-10 col-sm-6'>
                  <button className='btn btn-md btn-success fw-bold '>Additional Keys</button>
               </div>
               <div className='fs-2 custom-btn-left col-md-6 mt-10 col-sm-6'>
                  <button className='btn btn-md btn-primary fw-bold '>Enjoy your Stay</button>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}
