import {createContext, ReactNode, useContext, useState} from 'react'

export type OverlayType = {
   setTimerCallback: (callback: () => void) => void
   callback: () => void
   onTimerEnd: () => void
   timerIsOn: boolean
   toggleTimer: (status: boolean) => void
}

const OverlayContext = createContext<undefined | OverlayType>(undefined)

export const OverlayProvider = ({children}: {children: ReactNode}) => {
   const [timerIsOn, toggleTimer] = useState(false)
   const state: OverlayType = {
      callback: () => {},
      onTimerEnd: () => {
         state.callback()
         toggleTimer(false)
      },
      setTimerCallback: (callback) => (state.callback = callback),
      timerIsOn,
      toggleTimer,
   }

   return <OverlayContext.Provider value={state}>{children}</OverlayContext.Provider>
}

export const useOverlayContext = () => {
   const context = useContext(OverlayContext)
   if (!context) {
      throw new Error('[OverlayContext] is not available in this scope, make sure you use [OverlayProvider].')
   }

   return context
}
