import {createContext, ReactNode, useContext, useState} from 'react'
import {IStandardError} from '../../common/models/SoftPointAPIs'
import {AutoLoginResponse} from './requests/auto_login_models'
import {PreAuthResponse} from './requests/pre_auth_token_request'

type AutoLogin = {
   isLoading: boolean
   updateLoadingStatus: (status: boolean) => void
   error: null | IStandardError
   setError: (error: IStandardError) => void
   loadingText: string
   setLoadingText: (text: string) => void
   data: AutoLoginResponse | null
   setAutoLoginData: (data: AutoLoginResponse) => void
   resetState: () => void
}

const AutoLoginContext = createContext<AutoLogin | undefined>(undefined)

export const AutoLoginProvider = ({children}: {children: ReactNode}) => {
   const [isLoading, updateLoadingStatus] = useState<boolean>(false)
   const [error, setError] = useState<IStandardError | null>(null)
   const [loadingText, setLoadingText] = useState<string>('')
   const [data, setAutoLoginData] = useState<AutoLoginResponse | null>(null)

   const resetState = () => {
      updateLoadingStatus(false)
      setError(null)
      setLoadingText('')
      setAutoLoginData(null)
   }

   return (
      <AutoLoginContext.Provider
         value={{
            isLoading,
            updateLoadingStatus,
            error,
            setError,
            loadingText,
            setLoadingText,
            data,
            setAutoLoginData,
            resetState,
         }}
      >
         {children}
      </AutoLoginContext.Provider>
   )
}

export const useAutoLoginContext = () => {
   const context = useContext(AutoLoginContext)
   if (!context) {
      throw new Error('The [AutoLoginContext] is only accessible under the [AutoLoginProvider]')
   }
   return context
}
