import {useEffect, useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {StoreHeaderPageTitle} from '../../services/HeaderPageTitleSlice'
import {useCart} from '../../services/hooks/useCart'
import {CheckoutTop} from './checkout_components/CheckoutTop'
import {OrderDetailsFooter} from './checkout_components/OrderDetailsFooter'
import {ModifierGroupAccordion} from './checkout_components/ModifierGroupAccordion'
import {CheckOutRouteState, ISelfOrderingCartItemModifier} from '../../services/SelfOrderingCartSlice'
import {CategoryItem, ItemModifier, MenuCategory} from '../../common/schemas/SyncDataSchema'
import {ModGroupsUI, useSelectedModifiers} from './checkout_components/ItemModifiersProvider'
import {KTSVG} from '../../../_metronic/helpers'
import {RootState} from '../../store'
import {useMenuSearch} from './menu_components/MenuSearchProvider'
import ModifierPlaceHolder from './checkout_components/ModifirePlaceHolder'
import {getMenuCategories, getModDictionary, getModGroupDictionary} from '../../services/SelfOrderingDataSlice'
import {useKeyboard} from '../../services/hooks/useKeyboard'
import { NestedModifierModal } from './checkout_components/NestedModifierModal'

export type ExtendedCategoryItem = CategoryItem & {quantity: number} & {weakUuid?: number}

const instanceOfCheckOutState = (routeState: any): routeState is CheckOutRouteState => {
   return 'menu_id' in routeState && 'id' in routeState && 'quantity' in routeState
}

export const CheckoutDetailsView = () => {
   const location = useLocation()
   const _keyboard = useSelector((state: RootState) => state.keyboard)
   const {initModGroups, mod_groups} = useSelectedModifiers()
   const dispatch = useDispatch()
   const cartItemInfo: any = location.state
   const [itemInfo, setItemInfo] = useState<ExtendedCategoryItem>()
   const categories: MenuCategory[] = useSelector(getMenuCategories)
   const modDictionary = useSelector(getModDictionary)
   const modGroupDictionary = useSelector(getModGroupDictionary)
   const {GetItems} = useCart()
   const {queryValue, updateQuery} = useMenuSearch()
   const {getInputValue, onChangeInput, setActiveInput, clearInput} = useKeyboard()
   // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
   //    updateQuery(event.target.value)
   // }
   const [ToShowNestedModifier, setToShowNestedModifier] = useState<ItemModifier>()
   const [ToShowNestedModifierGroupId, setToShowNestedModifierGroupId] = useState<number>()

   const removeSearchSelection = () => {
      updateQuery('')
      clearInput()
   }

   const getDisplayName = (item: {name_info: string | null; name: string | null}) => {
      if (item.name_info && item.name_info != '') {
         return item.name_info
      }
      if (item.name && item.name != '') {
         return item.name
      }
      return ''
   }

   const fetchItem = () => {
      let menuItems: CategoryItem[] = []
      let menuItemDb
      if (categories && instanceOfCheckOutState(cartItemInfo)) {
         let menu = categories.filter((e) => e.id == cartItemInfo.menu_id)[0]
         menuItems = menu ? menu._embedded.items : []
         menuItemDb = menuItems?.find((item) => item.id == cartItemInfo.id)
      }

      if (menuItemDb && modDictionary && modGroupDictionary) {
         let menuItem = Object.assign({}, menuItemDb) as ExtendedCategoryItem
         if (instanceOfCheckOutState(cartItemInfo)) {
            menuItem.quantity = cartItemInfo.quantity
            menuItem.weakUuid = cartItemInfo.weakUuid
         }
         setItemInfo(menuItem)
         dispatch(StoreHeaderPageTitle(menuItem.name))

         const selectedModGroups: ModGroupsUI[] = []
         const cartItem = GetItems().find((cartItem) => cartItem.id == menuItem.id)
         menuItem?._embedded?.mod_groups?.forEach((group) => {
            const modGroupDB = modGroupDictionary[group.modifier_group_id]
            if (!modGroupDB) return
            if (!modGroupDB._embedded.mods) return
            const modifiers: ItemModifier[] = []
            modGroupDB._embedded.mods.forEach((mod) => {
               modifiers.push(modDictionary[mod.id])
            })

            let prevSelectedMods: ISelfOrderingCartItemModifier[] = []
            let prevRemovedMods: ISelfOrderingCartItemModifier[] = []
            if (cartItem) {
               prevSelectedMods = cartItem.modifiers.filter((e) => e.modgroup_id == group.modifier_group_id)
               prevSelectedMods = prevSelectedMods.map((mod) => {
                  let clonedMod = {...mod}
                  clonedMod.quantity = clonedMod.quantity / cartItem.quantity
                  return clonedMod
               })

               prevRemovedMods = cartItem.removed_included_modifiers.filter(
                  (e) => e.modgroup_id == group.modifier_group_id
               )
            } else {
               if (group?.included_modifier?.length) {
                  group?.included_modifier?.map((r, i) => {
                     let mod = modifiers.filter((e) => e.id == r.modifier_id)[0]
                     if (mod) {
                        prevSelectedMods.push({
                           id: mod.id,
                           id_external: mod.id_external ?? '',
                           name: mod.name ?? '',
                           price: mod.price,
                           price_per_unit: mod.price,
                           modgroup_id: group.modifier_group_id,
                           quantity: 1,
                        })
                     }
                  })
               }
            }
            if (modifiers.length) {
               let required = group.required == 1 ? group.required : group.option
               const defaultMods = group.included_modifier?.map((includedMod) => Number(includedMod.modifier_id))
               selectedModGroups.push({
                  id_external: group.id_external ?? '',
                  name: getDisplayName({name_info: modGroupDB?.name_info, name: modGroupDB?.name}),
                  modifiers: modifiers,
                  min: group.min ?? 0,
                  max: group.max ?? 0,
                  modifier_group_id: group.modifier_group_id ?? 0,
                  required: required ?? 0,
                  selected_modifiers: prevSelectedMods,
                  removed_included_modifiers: prevRemovedMods,
                  included_modifiers: defaultMods ?? [],
               })
            } 
         })

         cartItem?.modifiers.map(function(modifier) {
            if(modifier.parent_modifier_group_id){
               const modGroupDB = modGroupDictionary[modifier.modgroup_id]
               if (!modGroupDB) return 
               if (!modGroupDB._embedded.mods) return
               const modifiers: ItemModifier[] = []
               modGroupDB._embedded.mods.forEach((mod) => {
                  modifiers.push(modDictionary[mod.id])
               })
               const dbModifier: ItemModifier = modDictionary[modifier.parent_modifier_id!]
               const existingModGroupIndex = selectedModGroups.findIndex((group) => group.parent_modifier_id == modifier.parent_modifier_id && group.parent_modifier_group_id == modifier.parent_modifier_group_id && group.modifier_group_id == modifier.modgroup_id)
               if(existingModGroupIndex > -1){
                  selectedModGroups[existingModGroupIndex].selected_modifiers.push(modifier)
               }else{
                  const group = dbModifier._embedded.mod_groups?.find(g => g.id == modGroupDB.id)
                  if (modifiers.length && group) {
                     let required = group?.required == 1 ? group.required : 0
                     selectedModGroups.push({
                        id_external: group.id_external ?? '',
                        name: getDisplayName({name_info: modGroupDB?.name_info, name: modGroupDB?.name}),
                        modifiers: modifiers,
                        min: group.min ?? 0,
                        max: group.max ?? 0,
                        modifier_group_id: group.id ?? 0,
                        required: required ?? 0,
                        selected_modifiers: [modifier],
                        removed_included_modifiers: [],
                        included_modifiers: [],
                        parent_modifier_id: modifier.parent_modifier_id,
                        parent_modifier_group_id: modifier.parent_modifier_group_id
                     })
                  }
               }
            }
         });
         initModGroups(selectedModGroups)
      }
   }

   useEffect(() => {
      fetchItem()
   }, [])

   useEffect(() => {
      if (getInputValue('searchMenuDetail') !== '') {
         updateQuery(getInputValue('searchMenuDetail'))
      }
   }, [getInputValue('searchMenuDetail')])

   function getModeGroups(){
      return mod_groups.filter((group) => !group.parent_modifier_id);
   }
   
   return (
      <>
         {(itemInfo == undefined || getModeGroups().length == 0) && (
            <>
               <ModifierPlaceHolder />
            </>
         )}

         {itemInfo != undefined && <CheckoutTop itemInfo={itemInfo} />}
         {itemInfo && getModeGroups().length > 0 && (
            <div
               className={`input-group input-group-lg keyboard-input ${
                  _keyboard.inputName == 'searchMenuDetail' && _keyboard.open ? 'keyboard-active' : ''
               } `}
            >
               <span className='input-group-text' id='inputGroup-sizing-lg'>
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-4x svg-icon d-block py-2' />
               </span>
               <input
                  style={{border: '1px solid var(--kt-input-group-addon-border-color)', borderLeft: 'none'}}
                  type='text'
                  name='searchMenuDetail'
                  id='searchMenuDetail'
                  placeholder='Search'
                  value={queryValue}
                  className='form-control form-control-solid ps-9 fs-1 search-input keyboard-input'
                  aria-describedby='inputGroup-sizing-lg'
                  onFocus={() => setActiveInput('searchMenuDetail')}
                  onChange={onChangeInput}
                  readOnly={true}
               />

               {queryValue && (
                  <span
                     style={{border: 'none', top: '35%', right: '48px', zIndex: 10}}
                     className='position-absolute'
                     id='inputGroup-sizing-lg'
                  >
                     <i className='bi bi-x-lg fs-2x svg-icon cursor-pointer' onClick={removeSearchSelection}></i>
                  </span>
               )}
            </div>
         )}

         {ToShowNestedModifier && (
            <NestedModifierModal toggleModal={()=>{setToShowNestedModifier(undefined)}} modifier={ToShowNestedModifier} modifier_group_id={ToShowNestedModifierGroupId}/>
         )}

         {getModeGroups().length > 0 && (
            <div className='w-100' style={{marginBottom: '300px'}}>
               {getModeGroups().map((mod, index: number) => (
                  <ModifierGroupAccordion title={mod.name} modInfo={mod} key={index} setToShowNestedModifier={(modifier)=>{
                     setToShowNestedModifier(modifier);
                     setToShowNestedModifierGroupId(mod.modifier_group_id)
                  }} />
               ))}
            </div>
         )}
         {itemInfo != undefined && <OrderDetailsFooter itemInfo={itemInfo} />}
      </>
   )
}
