import React, {FC, useEffect} from 'react'
import Keyboard, {KeyboardInput} from 'react-simple-keyboard'
import {useDispatch, useSelector} from 'react-redux'
import 'react-simple-keyboard/build/css/index.css'
import {RootState} from '../../store'
import {closeKeyboard, setInput, setLayoutName} from '../../services/KeyboardSlice'
import {useKeyboardRef} from '../../common/KeyboardProvider'

const KeyboardWrapper: FC = () => {
   const _keyboard = useSelector((state: RootState) => state.keyboard)
   const dispatch = useDispatch()
   const {keyboardRef} = useKeyboardRef()

   const layout = {
      default: [
         '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
         '{tab} q w e r t y u i o p [ ] \\',
         "{lock} a s d f g h j k l ; ' {enter}",
         '{shift} z x c v b n m , . / {shift}',
         '.com @ {space}',
      ],
      shift: [
         '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
         '{tab} Q W E R T Y U I O P { } |',
         '{lock} A S D F G H J K L : " {enter}',
         '{shift} Z X C V B N M < > ? {shift}',
         '.com @ {space}',
      ],
      phone: ['1 2 3', '4 5 6', '7 8 9', ' 0 _', '{bksp}'],
      money: ['1 2 3', '4 5 6', '7 8 9', '. 0 {clear}', '{bksp}'],
   }

   const display = {
      '{bksp}': 'delete',
      '{enter}': '< enter',
      '{shift}': 'shift',
      '{s}': 'shift',
      '{tab}': 'tab',
      '{lock}': 'caps',
      '{accept}': 'Submit',
      '{space}': ' ',
      '{//}': ' ',
      '{clear}': 'C',
   }

   const onKeyPress = (button: string) => {
      if (button === '{shift}' || button === '{lock}') handleShift()
      if (button === '{clear}') clearScreen()
   }

   const handleShift = () => {
      const layoutName = _keyboard.layoutName
      dispatch(setLayoutName(layoutName === 'default' ? 'shift' : 'default'))
   }

   const onChangeAll = (inputObj: KeyboardInput) => {
      console.log('inputObj', inputObj)
      dispatch(setInput(inputObj))
   }

   const clearScreen = () => {
      let inputs = {..._keyboard.inputs}
      let inputName = _keyboard.inputName
      inputs[inputName] = ''

      dispatch(setInput(inputs))
      if (keyboardRef.current) {
         keyboardRef.current.clearInput(inputName)
      }
   }

   useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(e: MouseEvent) {
         if (_keyboard.open) {
            let inp = e.target as HTMLElement

            if (
               !inp.classList.contains('react-simple-keyboard') &&
               !inp.classList.contains('keyboard-input') &&
               !inp.classList.contains('hg-button') &&
               !inp.classList.contains('hg-row')
            ) {
               dispatch(closeKeyboard())
               console.log('keyboard closed')
            }
         }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
         // Unbind the event listener on clean up
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [_keyboard.open])

   return (
      <div hidden={_keyboard.open === false}>
         <Keyboard
            keyboardRef={(r) => (keyboardRef.current = r)}
            inputName={_keyboard.inputName}
            layoutName={_keyboard.layoutName}
            onChangeAll={(inputObj) => onChangeAll(inputObj)}
            onKeyPress={(button) => onKeyPress(button)}
            layout={layout}
            display={display}
            theme='hg-theme-default hg-theme-dark'
         />
      </div>
   )
}

export default KeyboardWrapper
