export enum BiometricDataType {
   FingerPrint = 1,
   Face = 2,
   ID = 3,
}

export enum BiometricLogResult {
   Failed = 0,
   Passed = 1,
}
