import {BasisPoints} from '../../../services/hooks/useCart'
import {updateTotalsWithTip} from '../../../services/SelfOrderingCartSlice'
import {useReducer} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import {useKeyboard} from '../../../services/hooks/useKeyboard'

type TipTypes = 'none' | 'custom' | 'preset'
type TipUnits = {TipBasisPoints?: BasisPoints; tip_in_cents?: number}
export type ActiveTip = {
   type: TipTypes
} & TipUnits

export type TipReducerAction = {type: TipTypes; payload: TipUnits}

export const useTipSelectorMachine = (customTipName: Readonly<string>) => {
   const dispatch = useDispatch<AppDispatch>()
   const {setActiveInput, clearInput} = useKeyboard()
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)

   const tipReducer = (currentState: ActiveTip, action: TipReducerAction): ActiveTip => {
      const DEFAULT_STATE: ActiveTip = {
         type: 'none',
      }
      switch (action.type) {
         case 'custom':
            console.log('[tipReducer]', 'custom')
            if (action.payload.tip_in_cents !== undefined) {
               console.log('[tipReducer]', 'action.payload.tip_in_cents')
               console.log('[tipReducer]', action.payload.tip_in_cents)
               dispatch(updateTotalsWithTip(action.payload.tip_in_cents))
               return {
                  type: action.type,
                  tip_in_cents: action.payload.tip_in_cents,
               }
            }
            dispatch(updateTotalsWithTip(0))
            return DEFAULT_STATE
         case 'preset':
            if (action.payload.TipBasisPoints !== undefined) {
               if (currentState.type === 'custom') {
                  setActiveInput('')
                  clearInput(customTipName, true)
               }
               const tipAmount = cartTotals.sub_total * action.payload.TipBasisPoints.toRate()
               dispatch(updateTotalsWithTip(Number(tipAmount.toFixed(0))))
               return {
                  type: action.type,
                  TipBasisPoints: action.payload.TipBasisPoints,
               }
            }
            dispatch(updateTotalsWithTip(0))
            return DEFAULT_STATE
         default:
            dispatch(updateTotalsWithTip(0))
            return DEFAULT_STATE
      }
   }

   return useReducer(tipReducer, {
      type: 'none',
   })
}

export const setCustomTip = (tipInCents: number): TipReducerAction => {
   return {
      type: 'custom',
      payload: {tip_in_cents: tipInCents},
   }
}

export const setPresetTip = (tipBasisPoints: BasisPoints): TipReducerAction => {
   return {
      type: 'preset',
      payload: {TipBasisPoints: tipBasisPoints},
   }
}

export const setDefaultTip = (): TipReducerAction => {
   return {
      type: 'none',
      payload: {},
   }
}
