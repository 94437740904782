import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {OrderTypeView} from '../modules/self_ordering/OrderTypeView'
import {MainMenuView} from '../modules/self_ordering/MainMenuView'
import {CheckoutDetailsView} from '../modules/self_ordering/CheckoutDetailsView'
import {CheckoutView} from '../modules/self_ordering/CheckoutView'
import {RouteSegments} from './RoutePaths'
import {MenuSearchProvider} from '../modules/self_ordering/menu_components/MenuSearchProvider'
import {ItemModifiersProvider} from '../modules/self_ordering/checkout_components/ItemModifiersProvider'
import {MenuViewProvider} from '../modules/self_ordering/menu_components/MenuViewProvider'

export const SelfOrderingRoutes = () => {
   return (
      <Routes>
         <Route element={<MasterLayout />}>
            {/* Pages */}

            <Route path='order-type' element={<OrderTypeView />} />
            <Route
               path='menu'
               element={
                  <MenuSearchProvider>
                     <MenuViewProvider>
                        <MainMenuView />
                     </MenuViewProvider>
                  </MenuSearchProvider>
               }
            ></Route>
            <Route path={RouteSegments.selfOrdering.ORDER_CHECKOUT} element={<CheckoutView />} />
            <Route
               path={RouteSegments.selfOrdering.ITEMS}
               element={
                  <MenuSearchProvider>
                     <ItemModifiersProvider>
                        <CheckoutDetailsView />
                     </ItemModifiersProvider>
                  </MenuSearchProvider>
               }
            />

            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
         </Route>
      </Routes>
   )
}
