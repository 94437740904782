import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {constants, KIOSK_GLOBAL_ON, MOBILE_URL} from '../../../config/constants'
import QRCode from 'react-qr-code'
import {useErrorAlert, useSuccessAlert} from '../../utilities/alerts'
import {getAuth, GetApiUrl} from '../../auth/core/AuthHelpers'
import {fetchNextReservation, INextReservationResponse, INextReservationRequest} from '../_requests/reservations'
import Swal from 'sweetalert2'
import {inDebugEnv, parseRequestError} from '../../../common/utilities'
import {setBiometricsSyncId, setGuestReservation} from '../../../services/GuestReservationSlice'
import {AppDispatch, RootState} from '../../../store'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {CHECK_IN_TYPES, CHECK_INS} from '../types'
import {generateAdmissionReceipt} from '../../../common/printing/receipt_generators'
import {PrinterService} from '../../../common/printing/printing_service'
import {TizenDebug} from '../FaceAuth/TizenIntegration'
import axios from 'axios'
import moment from 'moment'
import {FormattedMessage, useIntl} from 'react-intl'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {useInternationalization} from '../../i18n/i18nProvider'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'

export const QrAuthScreen = () => {
   const {selectedLang} = useInternationalization()
   const intl = useIntl()
   const location = useLocation()
   let navigateTo = useNavigate()
   const fireSuccessAlert = useSuccessAlert()
   const dispatch = useDispatch<AppDispatch>()
   const location_data = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const terminalData = useSelector((state: RootState) => state.selected_terminal)
   const terminal_id: any = terminalData.value?.id ? terminalData.value.id : localStorage.getItem('terminal_id')
   const mobileActivateURL = `${MOBILE_URL}${kioskLocSettings.location_id}/activate`
   let checkInType: CHECK_IN_TYPES = 'age_gate'
   const routeState = location.state as any
   if ('checkin_type' in routeState) {
      checkInType = routeState.checkin_type
   }
   const INITIAL_TIMER_VALUE = 30
   const INIT_QR_CODE = `${randAlphabet(4)}-${randAlphabet(4)}`
   const [codeTimer, setCodeTimer] = useState(INITIAL_TIMER_VALUE)
   const [QrCode, setQrCode] = useState(INIT_QR_CODE)
   const fireErrorAlert = useErrorAlert()
   const timerID = useRef<number>()
   const apiAbortSwitch = useRef<'OFF' | 'ON'>('OFF')
   const currentQRCode = useRef(INIT_QR_CODE)
   const checkInPaths = MODULE_ROUTE_PATHS.checkIn
   const updateQrCode = (code: string) => {
      setQrCode(code)
      currentQRCode.current = code
   }

   const SendKioskAuthCode = async () => {
      setCodeTimer(INITIAL_TIMER_VALUE)
      let user = getAuth()
      const user_id = user!.user_id
      const url = `${GetApiUrl()}/locations/${
         location_data.location_id
      }/kiosk/create-auth-code?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`

      const newQrCode = randAlphabet(4) + '-' + randAlphabet(4)
      updateQrCode(newQrCode)
      const codeCreatedAt = moment().utc().format()

      //Decrements timer
      const localTimerID = window.setInterval(() => {
         if (codeTimer > 0 && currentQRCode.current === newQrCode) {
            setCodeTimer((preTimerCount) => preTimerCount - 1)
         }
      }, 1000)

      timerID.current = localTimerID

      try {
         const response = await axios.post(url, {
            terminal_id: terminal_id,
            auth_code: newQrCode,
            age_gate: checkInType == CHECK_INS.AGE_GATE,
            code_created_at: codeCreatedAt,
         })
         let processedData = response.data as {
            auth_code: string
            guest_id: string | number
            biometrics_sync_id: string | undefined
            first_name: string | undefined
            last_name: string | undefined
         }

         const guestId = processedData.guest_id ?? null
         console.log('[CURRENT QRCODE]: ', currentQRCode.current)
         if (guestId != null && apiAbortSwitch.current == 'OFF' && currentQRCode.current === newQrCode) {
            if (checkInType == CHECK_INS.RESERVATION) {
               const payload: INextReservationRequest = {
                  user_id: user_id,
                  global_on_id: KIOSK_GLOBAL_ON ?? 3,
                  location_id: location_data.location_id,
                  with: 'guests,room,room_type,revenue_center',
                  guest_id: guestId,
               }
               const reservationRes = await fetchNextReservation(payload)
               Swal.close()
               const nextReservation = reservationRes.data as INextReservationResponse
               dispatch(
                  setGuestReservation({
                     reservation: nextReservation,
                     guestID: typeof guestId === 'string' ? parseInt(guestId) : guestId,
                  })
               )

               if (processedData?.biometrics_sync_id != undefined && processedData.biometrics_sync_id.trim() != '') {
                  TizenDebug(`[SendKioskAuthCode]: biometrics_sync_id=${processedData.biometrics_sync_id}`)
                  dispatch(setBiometricsSyncId(processedData.biometrics_sync_id))
               }
               navigateTo(checkInPaths.reservation_confirmation)
            } else {
               const guestName = `${processedData.first_name} ${processedData.last_name}`
               if (kioskLocSettings.print_confirmation == constants.status.YES && location_data.name) {
                  const receiptData = generateAdmissionReceipt(location_data.name, guestName, selectedLang)
                  const printerManager: PrinterService = new PrinterService()
                  printerManager.openSerialPort()
                  printerManager.print(receiptData)
               }
               fireSuccessAlert(
                  `${processedData.first_name} ${processedData.last_name}.<br/> ${intl.formatMessage({
                     id: 'common.welcome_to',
                  })} ${location_data.name}!`
               ).then((result) => {
                  if (result.isConfirmed || result.isDismissed) {
                     navigateTo(checkInPaths.check_in_home)
                  }
               })
            }
         }
      } catch (error) {
         const standardError = parseRequestError(error)
         if (apiAbortSwitch.current == 'OFF' && currentQRCode.current === newQrCode) {
            if (standardError.code != constants.errors.common.external_api_error) {
               let errorMessage = standardError.message
               if (standardError.code == constants.errors.biometrics.underage && checkInType == CHECK_INS.AGE_GATE) {
                  const minAge = kioskLocSettings._embedded.locations_config_contactless?.registration_minimum_age
                  if (minAge) {
                     errorMessage = standardError.message.replace('{}', minAge.toString())
                  }
               }
               if (standardError.code == constants.errors.create_auth_code.verification_code_error) {
                  if (inDebugEnv()) {
                     const userInput = await fireErrorAlert(standardError.message)
                  }
               } else {
                  const userInput = await fireErrorAlert(errorMessage)
               }
            }
            SendKioskAuthCode()
         }
      } finally {
         clearInterval(localTimerID)
      }
   }

   useTranslatedPageTitle(constants.staticPageTitle.signin)

   useEffect(() => {
      SendKioskAuthCode()
      return () => {
         apiAbortSwitch.current = 'ON'
         clearInterval(timerID.current)
      }
   }, [])

   function GetNewCode() {
      if (codeTimer < 28) {
         SendKioskAuthCode()
      } else {
         fireErrorAlert(intl.formatMessage({id: 'checkin.qrauth.alert.code_has_been_generated'}))
      }
   }

   return (
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-30'>
         <div className='col-xl-12 col-md-12 col-sm-12 pt-20'>
            <h3 className='fw-bold fs-4x mb-5 ms-n1 text-center'>
               <FormattedMessage id='checkin.qrauth.alert.two_way_sign_in' defaultMessage='Two ways to sign in?' />
            </h3>
         </div>
         <div className='d-flex flex-row flex-column-fluid h-xl-100'>
            <div className='d-flex flex-row-fluid flex-center pt-lg-20'>
               <div className='row w-800px pt-lg-20 mt-lg-20'>
                  <div className='col-md-12 col-sm-12 border-bigw mx-auto'>
                     <div className='text-center pb-6'>
                        <div className='mb-6'>
                           <div className='text-center'>
                              <span className='fw-bold text-gray-800 cursor-pointer fs-2 fs-lg-3x'>
                                 <FormattedMessage
                                    id='checkin.qrauth.alert.qr_code'
                                    defaultMessage='Scan QR Code to Check in'
                                 />
                              </span>
                           </div>
                        </div>
                        <div
                           className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px'
                           style={{
                              backgroundColor: '#ecedf2',
                              display: 'inline-block',
                              padding: '20px',
                           }}
                        >
                           <QRCode
                              size={210}
                              bgColor={'#ecedf2'}
                              fgColor={'#000'}
                              style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                              value={mobileActivateURL + '?code=' + QrCode}
                           />
                        </div>
                     </div>
                  </div>
                  <div className=' col-md-12 col-sm-12 mt-6'>
                     <div className='text-center'>
                        <div className='mb-6'>
                           <div className='text-center'>
                              <span className='fw-bold text-gray-800 cursor-pointer fs-2 fs-lg-3x'>
                                 <FormattedMessage id='checkin.qrauth.visit_website' defaultMessage='Visit Website' />
                              </span>
                           </div>
                           <div className='mt-10'>
                              <span className='fw-bold text-gray-800 cursor-pointer fs-4 fs-lg-3x'>
                                 <FormattedMessage id='checkin.qrauth.sigin_at' defaultMessage='Sign In at' />
                              </span>
                           </div>
                           <div className='mt-2 d-flex flex-center'>
                              <span className='fw-bold text-white-800 cursor-pointer text-hover-primary fs-4 fs-lg-3x'>
                                 {mobileActivateURL}
                              </span>
                           </div>
                           <div className='mt-2'>
                              <span className='fw-bold text-gray-800 cursor-pointer fs-4 fs-lg-3x'>
                                 <FormattedMessage
                                    id='checkin.qrauth.enter_this_code_when_prompted'
                                    defaultMessage='and enter this code when prompted'
                                 />
                              </span>
                           </div>
                           <div className='mt-10'>
                              <span className='fw-bold text-white-800 cursor-pointer display-1'>{QrCode}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='col-xl-12 col-md-12 col-sm-12 text-center'>
            <h3 className='fw-bold text-gray-800 fs-4 mb-5 ms-n1 text-center fs-lg-3x'>
               <FormattedMessage id='checkin.qrauth.code_expire_in' defaultMessage='Code expires in' />{' '}
               {toStopWatch(codeTimer)}
            </h3>
            <button
               onClick={() => {
                  GetNewCode()
               }}
               className='btn btn-sm btn-dark fw-bold fs-lg-3x'
               style={{minWidth: '450px'}}
            >
               <FormattedMessage id='checkin.qrauth.get_new_code' defaultMessage='Get New Code' />
            </button>
         </div>
         <div className='col-xl-12 col-md-12 col-sm-12 text-center'>
            <div className='mt-3 d-flex flex-center'>
               <span className='fw-bold text-white-800 cursor-pointer text-hover-primary fs-4 fs-lg-2x'>
                  <FormattedMessage
                     id='checkin.qrauth.already_registered'
                     defaultMessage='If not already registered, please continue by scanning the QR Code and create your profile. Then, come back to the kiosk to validate your identity.'
                  />
               </span>
            </div>
         </div>
      </div>
   )
}

/**
 * Creates a random string of alphabetical characters
 * @param length
 */
function randAlphabet(length: number) {
   let result = ''
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
   const charactersLength = characters.length
   for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
   }
   return result
}

/**
 * Creates a formatted timestamp, mm:ss
 * @param secs
 */
function toStopWatch(secs: number) {
   const minutes = Math.floor(secs / 60)
   const seconds = secs % 60
   const minuteString = minutes.toString()
   const secondString = seconds.toString()
   const paddedMinutes = minuteString.length === 1 ? `0${minuteString}` : minuteString
   const paddedSeconds = secondString.length === 1 ? `0${secondString}` : secondString
   return `${paddedMinutes}:${paddedSeconds}`
}
