import React from 'react'

type KeyPadButtonProps = {
   onNumberClick: (number: number) => void
   onDeleteClick: () => void
}

export const NumberKeyPad = ({onNumberClick, onDeleteClick}: KeyPadButtonProps) => {
   return (
      <div>
         <div className='keypad' id='keypad'>
            <div className='keypad-btn'>
               <div id='buttons'>
                  {[1, 2, 3].map((number: number, i: number) => (
                     <button className={`btn btn-bg-secondary fs-1 btn-block`} onClick={() => onNumberClick(number)} key={i}>
                        {number}
                     </button>
                  ))}

                  {[4, 5, 6].map((number: number, i: number) => (
                     <button className={`btn btn-bg-secondary fs-1 btn-block`} onClick={() => onNumberClick(number)} key={i}>
                        {number}
                     </button>
                  ))}

                  {[7, 8, 9].map((number: number, i: number) => (
                     <button className={`btn btn-bg-secondary fs-1 btn-block`} onClick={() => onNumberClick(number)} key={i}>
                        {number}
                     </button>
                  ))}
               </div>
            </div>
            <div className='keypad_footer'>
               <button className={`btn btn-bg-secondary fs-1 btn-block`} onClick={() => onNumberClick(0)}>0</button>
               <button className={`btn btn-bg-secondary fs-lg-4x btn-block`} onClick={onDeleteClick}>
                  <i className="bi bi-backspace fs-lg-2x"></i>
               </button>
            </div>
         </div>
      </div>
   )
}
