import {FC, useEffect, useState} from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import {GetApiUrl, getAuth} from '../../auth/core/AuthHelpers'
import {useDispatch, useSelector} from 'react-redux'
import {SelfOrderingValues, StoreSelfOrderingData} from '../../../services/SelfOrderingDataSlice'
import {
   MenuCategoriesSchema,
   Sync_ItemOrderModes_Schema,
   ItemModifierGroupsSchema,
   ModifiersSchema,
   RevenueCentersSchema,
   TaxRatesSchema,
} from '../../../common/schemas/SyncDataSchema'
import {ValidationError} from 'yup'
import {parseRequestError} from '../../../common/utilities'
import {TizenDebug} from '../../hotel/FaceAuth/TizenIntegration'
import {constants, KIOSK_GLOBAL_ON} from '../../../config/constants'
import axios from 'axios'
import {AppDispatch} from '../../../store'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {getSelectedLocation} from '../../../services/SelectedLocationSlice'
import {getSelectedTerminal, fetchTerminal} from '../../../services/SelectedTerminalSlice'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {useErrorAlert} from '../../utilities/alerts'
import {useNavigate} from 'react-router-dom'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'

interface DownloadSettingsProps {
   onSettingsDownloaded: () => void
   CallSync: boolean
   fullRefresh: boolean
}

export const DownloadSettingsStep: FC<DownloadSettingsProps> = ({onSettingsDownloaded, CallSync, fullRefresh}) => {
   const dispatch = useDispatch<AppDispatch>()
   const fireAlert = useErrorAlert()
   const navigateTo = useNavigate()
   const selectedLocation = useSelector(getSelectedLocation) as SoftPointLocation
   const selectedTerminal = useSelector(getSelectedTerminal) as Terminal
   let Apis = [
      {name: 'Categories', url: 'app/menu/categories', sateName: 'Data_Categories'},
      {name: 'Revenue centers', url: 'revenue_center', sateName: 'Data_RevenueCenters'},
      {name: 'Tax rates', url: 'tax_rates', sateName: 'Data_TaxRates'},
      {name: 'Modifier groups', url: 'app/menu/modifier_groups', sateName: 'Data_ModifierGroups'},
      {name: 'Modifiers', url: 'app/menu/modifiers', sateName: 'Data_Modifiers'},
      {name: 'Item Order modes', url: 'item_order_modes', sateName: 'Data_ItemOrderModes'},
   ]
   let OvSyncApis = [
      {name: 'menu', url: 'sync/menu'},
      {name: 'itemmodes', url: 'sync/item_order_modes'},
      {name: 'revenuecenter', url: 'sync/revenue_centers'},
   ]
   const [TotalSteps, setTotalSteps] = useState(Apis.length)
   const [FullSyncData, setFullSyncData] = useState<SelfOrderingValues>({
      Data_Categories: [],
      Data_RevenueCenters: [],
      Data_TaxRates: [],
      Data_ModifierGroups: [],
      Data_Modifiers: [],
      Data_ItemOrderModes: [],
   })
   const [CurrPer, setCurrPer] = useState(0)
   const [SyncCompleted, setSyncCompleted] = useState(false)
   const GLOBAL_ON_ID = process.env.REACT_APP_KIOSK_GLOBAL_ON
   useTranslatedPageTitle(constants.staticPageTitle.sync)
   const fetchData = async (LocationId: number, terminal_id: any, Callurl: any, sateName: any, page?: number) => {
      let user = getAuth()
      const token = user?.api_token
      const user_id = user?.user_id
      let url = `${GetApiUrl()}/locations/${LocationId}/${Callurl}?global_on_id=${GLOBAL_ON_ID}&user_id=${user_id}&terminal_id=${terminal_id}&sync_ov=${fullRefresh}`
      if (page) url = `${url}&page=${page}`
      try {
         const response = await axios.get(url)
         let responseContent = await response.data
         let parsedData: any = undefined
         if (sateName) {
            const data = sateName == 'Data_RevenueCenters' ? responseContent.revenue_center.data : responseContent.data
            switch (sateName) {
               case 'Data_RevenueCenters':
                  parsedData = await RevenueCentersSchema.validate(data)
                  break
               case 'Data_Categories':
                  parsedData = await MenuCategoriesSchema.validate(data)
                  break
               case 'Data_TaxRates':
                  parsedData = await TaxRatesSchema.validate(data)
                  break
               case 'Data_ModifierGroups':
                  parsedData = await ItemModifierGroupsSchema.validate(data)
                  break
               case 'Data_Modifiers':
                  parsedData = await ModifiersSchema.validate(data)
                  break
               case 'Data_ItemOrderModes':
                  parsedData = await Sync_ItemOrderModes_Schema.validate(data)
                  break
               default:
                  parsedData = undefined
                  break
            }

            const responsePage = responseContent.page
            const responsePageCount = responseContent.page_count
            const responseIsPaginated = typeof responsePage == 'number' && typeof responsePageCount == 'number'
            if (responseIsPaginated && responsePage < responsePageCount) {
               let nextPageData = await fetchData(LocationId, terminal_id, Callurl, sateName, responsePage + 1)
               if (parsedData && Array.isArray(parsedData)) parsedData = parsedData.concat(nextPageData)
            }

            setFullSyncData((prevState) => {
               return {
                  ...prevState,
                  [sateName]: parsedData,
               }
            })
         }
         return parsedData
      } catch (error: any | ValidationError) {
         if (error instanceof ValidationError) {
            console.log(`[ValidationError] ${Callurl}`, error.errors)
            TizenDebug(`[ValidationError] ${Callurl} ${JSON.stringify(error.errors)}`)
            fireAlert(constants.errors.static.vital_parse_error).then((result) => {
               navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
            })
            return parseRequestError(error.errors)
         }
         return parseRequestError(error)
      }
   }

   const SetTerminalVersion = async () => {
      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${selectedLocation.location_id}/terminals/${
         selectedTerminal.id
      }?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
      try {
         const response = await axios.put(url, {
            kp_version: process.env.REACT_APP_VERSION,
         })
      } catch (e) {}
   }

   const callSyncFunc = (step: number) => {
      if (Apis[step]) {
         try {
            fetchData(selectedLocation.location_id, selectedTerminal.id, Apis[step].url, Apis[step].sateName).then(
               (data) => {
                  step++
                  let per = Math.ceil((step * 100) / TotalSteps)
                  setCurrPer(per)
                  if (Apis[step]) {
                     callSyncFunc(step)
                     setSyncCompleted(false)
                  } else {
                     setSyncCompleted(true)
                  }
               }
            )
         } catch (e) {
            console.log('All errors', e)
         }
      }
   }

   const CallOvSyncFirst = (step: number) => {
      if (OvSyncApis[step]) {
         try {
            fetchData(selectedLocation.location_id, selectedTerminal.id, OvSyncApis[step].url, null).then((data) => {
               step++
               let per = Math.ceil((step * 100) / TotalSteps)
               setCurrPer(per)
               if (OvSyncApis[step]) {
                  CallOvSyncFirst(step)
               } else {
                  callSyncFunc(0)
               }
            })
         } catch (e) {
            console.log('All errors', e)
         }
      }
   }

   useEffect(() => {
      if (CallSync) {
         SetTerminalVersion()
         if (fullRefresh) {
            dispatch(fetchTerminal(selectedLocation.location_id, selectedTerminal.id))
            setTotalSteps(Apis.length + OvSyncApis.length)
            CallOvSyncFirst(0)
         } else {
            dispatch(fetchTerminal(selectedLocation.location_id, selectedTerminal.id))
            callSyncFunc(0)
         }
      }
   }, [CallSync])

   useEffect(() => {
      if (SyncCompleted) {
         dispatch(StoreSelfOrderingData(FullSyncData))
         onSettingsDownloaded()
      }
   }, [SyncCompleted])

   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softpoint_small_black.svg'
         : '../media/logos/softpoint_small.svg'

   return (
      <div className='d-flex justify-content-center p-8'>
         <div className='w-100 pt-5'>
            <div className='card card-stretch card-bordered px-8 flex-height-kiosk'>
               <div className='py-6'>
                  <h1 className='card-title fs-2tx'>
                     <FormattedMessage id='kiosk.step4.pos_sync' defaultMessage='POS Sync' />
                  </h1>
               </div>
               <div className='col'>
                  <div className='text-center'>
                     <img
                        src={toAbsoluteUrl(logoPath)}
                        className='rounded-3 mb-4 w-150px h-150px w-xxl-150px h-xxl-150px'
                        alt=''
                     />
                  </div>
                  <div className='mt-6'>
                     <div className='text-center d-flex justify-content-center align-items-center'>
                        <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6 fs-xl-4 pt-1'>
                           <FormattedMessage id='common.please_wait' defaultMessage='Please Wait' />
                        </span>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                     </div>
                     <div className='text-center'>
                        <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6 fs-xl-4'>
                           <FormattedMessage id='common.getting_categories' defaultMessage='Getting Categories...' />
                        </span>
                     </div>
                     <ProgressBar className='mt-5' variant='success' now={CurrPer || 5} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
