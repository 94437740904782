import {Routes, Route} from 'react-router-dom'
import {CheckinMethodScreen} from '../modules/hotel/CheckinMethodScreen'
import {FacialAuthScreen} from '../modules/hotel/FaceAuth/FacialAuthScreen'
import {QrAuthScreen} from '../modules/hotel/qr_auth/QrAuthScreen'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {EmpLoginScreen} from '../modules/hotel/EmpLoginScreen'
import {ConfirmationScreen} from '../modules/hotel/reservation/ConfirmationScreen'
import {OverlayProvider} from '../modules/hotel/FaceAuth/OverlayProvider'
import {getCheckInPaths, getCheckInSegments} from './RoutePaths'

export const HotelRoutes = () => {
   const paths = getCheckInSegments()
   return (
      <Routes>
         <Route element={<MasterLayout />}>
            <Route path={paths.check_in_home} element={<CheckinMethodScreen />} />
            <Route index element={<CheckinMethodScreen />} />
            <Route path={paths.employee_login} element={<EmpLoginScreen />} />
            <Route path={paths.reservation_confirmation} element={<ConfirmationScreen />} />
            <Route
               path={paths.biometric_auth}
               element={
                  <OverlayProvider>
                     <FacialAuthScreen />
                  </OverlayProvider>
               }
            />
            <Route path={paths.qr_auth} element={<QrAuthScreen />} />
         </Route>
      </Routes>
   )
}
