import {FormattedMessage} from 'react-intl'
import {CounterOverlay} from './CounterOverlay'

export const CamFeedOverlay = () => {
   return (
      <>
         <div
            className='d-flex justify-content-center'
            style={{position: 'absolute', width: '100%', fontWeight: 'bolder'}}
         >
            <h1 className='centerFaceText'>
               <FormattedMessage
                  id='checkin.faceauth.position_face_within_frame'
                  defaultMessage='Position your face within the frame'
               />{' '}
               <br />{' '}
               <FormattedMessage id='checkin.faceauth.remove_hat_and_glasses' defaultMessage='REMOVE HAT AND GLASSES' />
            </h1>
         </div>
         <div className='top left' />
         <div className='top right' />
         <div className='bottom right' />
         <div className='bottom left' />
         <div id='overlayContainer' style={{position: 'absolute', width: '98%', height: '98.5%'}}>
            <svg width='100%' height='100%'>
               <mask id='mask'>
                  <rect fill='white' width='100%' height='100vh' opacity='1' />
                  <ellipse id='oval_window' cx='48vw' cy='34vh' rx='26vw' ry='20vh' />
               </mask>
               <rect mask='url(#mask)' width='100%' height='100%' opacity='0.5' />
            </svg>
         </div>
         <CounterOverlay />
      </>
   )
}
