import {useEffect, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {RootState, AppDispatch} from '../../store'
import {useTimerAlert} from '../../modules/utilities/alerts'
import {useIntl} from 'react-intl'
import {useKioskProducts} from '../../../app/common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {resetCart} from '../SelfOrderingCartSlice'

const useRedirectToDashboard = () => {
   const AppEnv = process.env.REACT_APP_ENVIRONMENT
   const dispatch = useDispatch<AppDispatch>()
   const navigateTo = useNavigate()
   const terminal_data = useSelector((state: RootState) => state.selected_terminal.value)
   const kioskSettings = useSelector((state: RootState) => state.kiosk_settings)
   const fireTimedAlert = useTimerAlert()
   const timer = useRef<number | null>(null)
   const routerLocation = useLocation()
   const intl = useIntl()

   const excludedRoutes = [
      '/index.html',
      '/',
      MODULE_ROUTE_PATHS.app_auth.index,
      '/kiosk_terminal',
      MODULE_ROUTE_PATHS.checkIn.check_in_home,
      MODULE_ROUTE_PATHS.offline.index,
      MODULE_ROUTE_PATHS.idle.index,
   ]
   let totalTime = 180000
   const {orderingEnabled, checkInEnabled} = useKioskProducts()
   const homePaths = {
      dashboard: MODULE_ROUTE_PATHS.dashboard.index,
      ordering: MODULE_ROUTE_PATHS.ordering.ORDER_HOME,
      checkIn: MODULE_ROUTE_PATHS.checkIn.check_in_home,
      idle: MODULE_ROUTE_PATHS.idle.index,
   }

   const idleMediaAvailable = () => {
      return kioskSettings.value?.idle_image || kioskSettings.value?.idle_video
   }

   if (!idleMediaAvailable()){
      excludedRoutes.push(MODULE_ROUTE_PATHS.dashboard.index)
   }
 
   if (orderingEnabled && !checkInEnabled && !idleMediaAvailable()) {
      excludedRoutes.push(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
   }

   if (terminal_data?.screen_timeout != undefined && terminal_data.screen_timeout > 0) {
      totalTime = terminal_data.screen_timeout * 1000
   }

   const warningTime = 30000
   const idleTimeout = totalTime - warningTime

   const askForTimeoutConfirmation = () => {
      const translatedMessage = intl.formatMessage({id: 'idle.alert.do_you_need_more_time'})
      fireTimedAlert(
         translatedMessage,
         warningTime,
         intl.formatMessage({id: 'common.yes'}),
         intl.formatMessage({id: 'alert.no'})
      ).then((result) => {
         if (result.isConfirmed) {
            resetTimer()
         } else {
            dispatch(resetCart())
            if (window.location.pathname == MODULE_ROUTE_PATHS.ordering.ORDER_HOME && orderingEnabled && checkInEnabled){
               navigateTo(homePaths.dashboard)
            }else if (idleMediaAvailable()) {
               navigateTo(homePaths.idle)
            }else if (orderingEnabled && checkInEnabled) {
               navigateTo(homePaths.dashboard)
            } else if (checkInEnabled) {
               navigateTo(homePaths.checkIn)
            } else {
               navigateTo(homePaths.ordering)
            }
         }
      })
   }

   const resetTimer = () => {
      clearTimer()
      // Set timer to show warning alert first
      timer.current = window.setTimeout(askForTimeoutConfirmation, idleTimeout > 0 ? idleTimeout : totalTime)
   }

   const clearTimer = () => {
      if (timer.current) {
         clearTimeout(timer.current)
      }
   }

   useEffect(() => {
      if (AppEnv && AppEnv !== 'local') {
         if (!excludedRoutes.includes(window.location.pathname)) {
            document.addEventListener('click', resetTimer)
            resetTimer();
         }
      }
      return () => {
         clearTimer()
         document.removeEventListener('click', resetTimer)
      }
   }, [routerLocation.pathname])

   return null
}

export default useRedirectToDashboard
