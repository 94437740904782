import {useEffect, useState} from 'react'
import {getAuth} from '../../modules/auth'

interface UseCacheImgHookProps {
   imageUrl: string | null
   placeholderUrl: string
}

const cacheName = 'MenuImageCache'
export function useCacheImg(props: UseCacheImgHookProps) {
   const [cachedImage, setCachedImage] = useState<any>(props.placeholderUrl)
   const [isLoading, setIsLoading] = useState<boolean>(true);

   useEffect(() => {
      async function fetchImage() {
         if (props.imageUrl && props.imageUrl.length > 0) {
            try {
               const imageUrl = `${process.env.REACT_APP_API_URL}/download-file?path=${
                  props.imageUrl 
               }&cache=true&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`
               const cache = await caches.open(cacheName)
               const cachedResponse = await cache.match(imageUrl)
               if (cachedResponse) {
                  const blob = await cachedResponse.blob()
                  const objectUrl = URL.createObjectURL(blob)
                  setCachedImage(objectUrl)
               } else {
                  const response = await fetch(imageUrl)
                  await cache.put(imageUrl, response.clone())
                  const blob = await response.blob()
                  const objectUrl = URL.createObjectURL(blob)
                  setCachedImage(objectUrl)
               }
            } catch (error) {

            } finally {
               setIsLoading(false);
            }
         }else{
            setIsLoading(false);
         }
      }

      fetchImage()
   }, [props.imageUrl])

   return { cachedImage, isLoading }
}
