import {configureStore} from '@reduxjs/toolkit'
import HeaderPageTitleSlice from './services/HeaderPageTitleSlice'
import kioskSettingsReducer from './services/KioskSettingSlice'
import selectedLocationReducer from './services/SelectedLocationSlice'
import SelectedTerminalReducer from './services/SelectedTerminalSlice'
import SelfOrderingDataReducer from './services/SelfOrderingDataSlice'
import FlowReducer from './services/FlowSlice'
import TizenSystemReducer from './services/TizenSystemSlice'
import GuestReservationReducer from './services/GuestReservationSlice'
import SelfOrderingCartReducer from './services/SelfOrderingCartSlice'
import SelfOrderingUserReducer from './services/SelfOrderingUserSlice'
import FacialRecognitionServiceReducer from './services/FacialRecognitionService'
import AuthLocationsReducer from './services/AuthLocationsSlice'
import KeyboardReducer from './services/KeyboardSlice'

export const store = configureStore({
   reducer: {
      kiosk_settings: kioskSettingsReducer,
      location_data: selectedLocationReducer,
      header_page_title: HeaderPageTitleSlice,
      selected_terminal: SelectedTerminalReducer,
      location_menu_data: SelfOrderingDataReducer,
      flow: FlowReducer,
      self_ordering_selections: SelfOrderingUserReducer,
      tizen_system: TizenSystemReducer,
      guest_reservation: GuestReservationReducer,
      self_ordering_cart: SelfOrderingCartReducer,
      faceRecognitionServices: FacialRecognitionServiceReducer,
      auth_locations: AuthLocationsReducer,
      keyboard: KeyboardReducer,
   },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
