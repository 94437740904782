import axios from 'axios'
import {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {GetApiUrl, getAuth} from '../../auth'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import Swal from 'sweetalert2'
import Inputmask from 'inputmask'
import {SaveLocalEchoIP, StoreSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {FormattedMessage, useIntl} from 'react-intl'
import {TerminalValidationSchema} from '../../../common/schemas/TerminalSchema'
import {ValidationError} from 'yup'

interface DeviceIpChangeProps {
   toggleModal: () => void
   forcePosFullRefresh: () => void
}

export const DeviceIpChangeModal: FC<DeviceIpChangeProps> = ({toggleModal, forcePosFullRefresh}) => {
   const intl = useIntl()
   let kiosk_settings = useSelector((state: any) => state.kiosk_settings.value)
   const terminalInfo = useSelector((state: RootState) => state.selected_terminal)
   if (!kiosk_settings) {
      kiosk_settings = localStorage.getItem('kiosk_setting')
         ? JSON.parse(localStorage.getItem('kiosk_setting') as any)
         : null
   }
   const terminal_id: any = terminalInfo.value?.id ? terminalInfo.value.id : localStorage.getItem('terminal_id')
   const [pinValue, setPinValue] = useState('')
   const [focusPinField, setFocusPinField] = useState(true)
   const dispatch = useDispatch<AppDispatch>()
   const BtnClrClass = useLocationBasedBtnColor()

   const setVal = (val: any) => {
      let cloneState = pinValue
      if (val == 'X') {
         cloneState = cloneState.substr(0, cloneState.length - 1)
      } else {
         let current_value_splited = cloneState.split('.')
         if (val == '.' && cloneState == '') {
            return false
         }

         if (val != '.' && current_value_splited[current_value_splited.length - 1].length == 3) {
            val = `.${val}`
         }

         cloneState = cloneState + '' + val
      }
      if (focusPinField) {
         setPinValue(cloneState)
      }
   }

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
   }
   const closeBtnStyle = {
      position: 'absolute',
      top: '1rem',
      right: 0,
   } as React.CSSProperties

   useEffect(() => {
      // Phone Number
      Inputmask({
         alias: 'ip',
         greedy: true,
         oncomplete: function () {
            return
         },
      }).mask('#manager_pin')
   })

   function ValidateIPAddress() {
      var ipformat = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/
      if (!pinValue.match(ipformat)) {
         Swal.fire({
            icon: 'error',
            title: intl.formatMessage({id: 'setting.device_ip_change.invalid_ip'}),
            confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
            customClass: {
               confirmButton: `btn btn-primary ${BtnClrClass}`,
            },
            buttonsStyling: false,
         })
         return false
      }

      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/terminals/${terminal_id}?global_on_id=${
         process.env.REACT_APP_KIOSK_GLOBAL_ON
      }&user_id=${user_id}`

      const data: any = {
         token: user?.api_token,
         url: url,
      }
      axios
         .put(
            `${data.url}`,
            {local_echo_ip: pinValue},
            {
               headers: {
                  Authorization: `Basic ${data.token}`,
               },
            }
         )
         .then(async (response) => {
            console.log('response', response)
            if (response.data.error) {
               Swal.fire({
                  icon: 'error',
                  title: response.data.error.message ?? intl.formatMessage({id: 'alert.you_dont_have_permission'}),
                  confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
                  customClass: {
                     confirmButton: `btn btn-primary ${BtnClrClass}`,
                  },
                  buttonsStyling: false,
               })
            } else {
               let terminalRow = response?.data?.terminal
               localStorage.setItem('terminal_data', JSON.stringify(terminalRow))
               dispatch(SaveLocalEchoIP(pinValue))
               localStorage.setItem('terminal_id', response?.data?.terminal?.id)
               toggleModal()
               forcePosFullRefresh()
               //navigateTo('/settings');
               setPinValue('')
            }
         })
         .catch((error: any | ValidationError) => {
            if (error instanceof ValidationError) {
               console.log(error.errors)
               return error.errors
            }
            let message = `${intl.formatMessage({id: 'setting.device_ip_change.invalid_ip'})}.`
            if (error.response.data.error.message) {
               message = error.response.data.error.message
            }
            Swal.fire({
               icon: 'error',
               title: message,
               confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
               customClass: {
                  confirmButton: `btn btn-primary ${BtnClrClass}`,
               },
               buttonsStyling: false,
            })
            setPinValue('')
         })
   }
   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog-centered set-manager-login-modal h-auto px-10'
         show={true}
         onHide={toggleModal}
      >
         <div className='container-xxl px-10 py-5'>
            <div className='modal-dialog mw-100 p-0 m-0'>
               <div className='modal-content'>
                  <div className='text-end'>
                     <div
                        aria-label='Close'
                        data-bs-dismiss='modal'
                        onClick={toggleModal}
                        className='btn btn-icon btn-lg btn-active-light-primary'
                        style={closeBtnStyle}
                     >
                        <i className='bi bi-x-lg fs-2x svg-icon'></i>
                     </div>
                  </div>
                  {/* START::MODAL-BODY */}
                  <div className='tab-content'>
                     <div className='text-center'>
                        <h1 className='modal-title py-5'>
                           <FormattedMessage
                              id='setting.device_ip_change.change_terminal_ip'
                              defaultMessage='Change Terminal IP'
                           />
                        </h1>
                     </div>
                     <div className='d-flex flex-row flex-column-fluid h-xl-100 pb-10 pt-5'>
                        <div className='d-flex flex-row-fluid flex-center'>
                           <div className='row w-400px theme-background py-3'>
                              {
                                 <>
                                    <div className='position-relative col-xl-12 col-md-12 col-sm-12 flex-center mb-5 '>
                                       <input
                                          onClick={() => {
                                             setFocusPinField(true)
                                          }}
                                          type='text'
                                          id='manager_pin'
                                          value={pinValue}
                                          onKeyDown={(e) => {
                                             if (e.key >= '0' && e.key <= '9') {
                                                setPinValue(pinValue + e.key)
                                             }
                                             if (e.key == 'Backspace') {
                                                setPinValue(pinValue.substr(0, pinValue.length - 1))
                                             }
                                          }}
                                          name='pin'
                                          autoComplete='off'
                                          placeholder={intl.formatMessage({
                                             id: 'setting.device_ip_change.enter_terminal_ip',
                                          })}
                                          style={{height: '85px', fontSize: '25px'}}
                                          className='form-control bg-transparent text-center'
                                       />
                                    </div>
                                 </>
                              }
                              <div className='clear'></div>
                              <div className='w-33 fs-2 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(1)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    1
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(2)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    2
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(3)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    3
                                 </button>
                              </div>
                              <div className='clear mb-3'></div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(4)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    4
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(5)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    5
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(6)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    6
                                 </button>
                              </div>
                              <div className='clear mb-3'></div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(7)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    7
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(8)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    8
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(9)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    9
                                 </button>
                              </div>
                              <div className='clear mb-3'></div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal('.')}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    .
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal(0)}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    0
                                 </button>
                              </div>
                              <div className='w-33 text-center symbol'>
                                 <button
                                    style={{height: '85px', width: '95%', fontSize: '25px'}}
                                    onClick={(e) => setVal('X')}
                                    className='btn symbol-label bg-light mx-auto'
                                 >
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       width='50'
                                       height='30'
                                       fill='currentColor'
                                       className='bi bi-backspace'
                                       viewBox='0 0 16 16'
                                    >
                                       <path d='M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z' />
                                       <path d='M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z' />
                                    </svg>
                                 </button>
                              </div>
                              <div className='col-xl-12 col-md-12 col-sm-12 text-center mt-5'>
                                 <button
                                    type='button'
                                    style={buttonStyle}
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_1'
                                    className={`btn btn-sm btn-primary w-100 ${BtnClrClass}`}
                                    onClick={() => {
                                       ValidateIPAddress()
                                    }}
                                 >
                                    <FormattedMessage id='alert.confirm' defaultMessage='Confirm' />
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* END::MODAL-BODY */}
               </div>
            </div>
         </div>
      </Modal>
   )
}
