import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {SupportedLanguages, useInternationalization} from '../../../../app/modules/i18n/i18nProvider'
import {RootState} from '../../../../app/store'

export const useDate = () => {
   const {selectedLang} = useInternationalization()

   let kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value)
   const [today, setDate] = useState(new Date())
   useEffect(() => {
      if (kiosk_settings?.location_current_datetime && kiosk_settings?.location_current_datetime != '') {
         setDate(new Date(kiosk_settings.location_current_datetime))
      }
   }, [])

   useEffect(() => {
      const timer = setInterval(() => {
         today.setMinutes(today.getMinutes() + 1)
         setDate(new Date(today))
      }, 60 * 1000)
      return () => {
         clearInterval(timer)
      }
   }, [])

   const date = `${today.toLocaleDateString(selectedLang, {month: 'long', day: 'numeric', year: 'numeric'})}`
   let time = today.toLocaleTimeString(selectedLang, {hour: 'numeric', hour12: true, minute: 'numeric'})
   time = toEngMeridiem(time, selectedLang)
   return {
      date,
      time,
   }
}

/**
 * Formats the meridiem (pm or am) value of a timestamp by capitalizing the meridiem value.
 * If no meridiem value is detected or the supported language is not es the method will return the timeStamp given.
 * @param timeStamp
 * @param language
 */
function toEngMeridiem(timeStamp: string, language: SupportedLanguages) {
   if (language != 'es') return timeStamp

   let meridiem = ''
   const timeStampTokens = timeStamp.split(' ')
   for (let char of timeStamp) {
      if (char.toLowerCase() === 'a') {
         meridiem = 'AM'
         break
      }
      if (char.toLowerCase() === 'p') {
         meridiem = 'PM'
         break
      }
   }

   if (meridiem === '') return timeStamp

   return `${timeStampTokens[0]} ${meridiem}`
}
