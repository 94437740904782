import {InferType, number, object, string} from 'yup'

export const TerminalValidationSchema = object({
   id: number().required(),
   id_external: string().nullable().defined(),
   location_id: number().required(),
   status: number(),
   serial_number: string().nullable().defined(),
   tid: string().nullable().defined(),
   name: string().nullable().defined(),
   support_device: number().required(),
   global_manufacturer_terminal_id: number(),
   global_manufacturer_terminal: string().nullable().defined(),
   global_terminal_mode: string(),
   global_terminal_mode_id: number(),
   global_tip_id: number(),
   local_echo_ip: string().nullable().defined(),
   local_echo_port: string().nullable().defined(),
   app_version: string().nullable().defined(),
   revenue_center: number()
      .transform((value, originalValue) => {
         if (typeof originalValue === 'number' && originalValue === 0) {
            return null
         }
         return originalValue
      })
      .nullable()
      .defined(),
   created_datetime: string().nullable().defined(),
   last_datetime: string().nullable().defined(),
   screen_timeout: number().nullable().defined(),
   created_datetime_local: string().nullable(),
   last_datetime_local: string().nullable(),
   receipt_printer: number().nullable(),
   always_print_receipt: number().nullable(),
   allow_cash_payments: number().nullable(),
   cash_receipt_options: string().nullable(),
   kp_version: string().nullable(),
   primary_service_area: string().nullable(),
   paired_terminal_id: number().nullable().defined(),
   paired_terminal_sn: string().nullable().defined(),
   paired_terminal_ip: string().nullable().defined(),
   paired_terminal_port: string().nullable().defined(),
   paired_terminal_manufacturer_id: number().nullable().defined(),
})

export type Terminal = InferType<typeof TerminalValidationSchema>
