export const getAxisSystemStatus = (IP:any) => {
    // const requestOptions = {
    //     method: 'POST',
    //     mode:'no-cors'
    //     body: JSON.stringify({
    //         "apiVersion": "1.1",
    //         "context": "kiosk",
    //         "method": "systemready",
    //         "params": {
    //             "timeout": 20
    //         }
    //     })
    // }
    // const req = new Request(`http://${IP}/axis-cgi/systemready.cgi`)
    return fetch(`http://${IP}/axis-cgi/systemready.cgi`, {
        method: 'POST',
        mode:'no-cors',
        body: JSON.stringify({
            "apiVersion": "1.1",
            "context": "kiosk",
            "method": "systemready",
            "params": {
                "timeout": 20
            }
        })
    })
}
