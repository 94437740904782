import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {ISelfOrderingCartItemModifier} from '../../../services/SelfOrderingCartSlice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {useCart} from '../../../services/hooks/useCart'
import {DerivedItemModGrp} from '../../../services/SelfOrderingDataSlice'
import {useWarningAlert,useErrorAlert} from '../../utilities/alerts'
import {ExtendedCategoryItem} from '../CheckoutDetailsView'
import {useSelectedModifiers} from './ItemModifiersProvider'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import moment from 'moment'


interface OrderDetailsFooterProps {
   itemInfo: ExtendedCategoryItem
}

const buttonStyle = {
   padding: '15px 0px',
   fontSize: '25px',
   borderRadius: '10px',
   maxWidth: '300px',
   minWidth: '300px',
   height: '70px',
}

export const OrderDetailsFooter: FC<OrderDetailsFooterProps> = ({itemInfo}) => {
   const orderingPaths = MODULE_ROUTE_PATHS.ordering
   const fireWarningAlert = useWarningAlert()
   const {mod_groups} = useSelectedModifiers()
   const groupedModifiers = mod_groups.map((modGroup) => modGroup.selected_modifiers)
   const groupedRemovedModifiers = mod_groups.map((modGroup) => modGroup.removed_included_modifiers)
   const selfOrder = useSelector((state: RootState) => state.location_menu_data.values)   
   const modifiers = groupedModifiers.reduce((accum, mods) => {
      return accum.concat(mods)
   }, [])
   const categories = selfOrder.Data_Categories
   const removedIncludedModifiers = groupedRemovedModifiers.reduce((accum, mods) => {
      return accum.concat(mods)
   }, [])
   const fireErrorAlert = useErrorAlert()

   
   const navigateTo = useNavigate()
   let category_id = useSelector((state: RootState) => state.self_ordering_selections.selected_category_section_id)

   const currentCategory = categories.filter((cat)=>cat.id==category_id)[0];   
   const [total, setTotal] = useState<number>(0)
   const [qty, setQty] = useState(1)

   const {AddItem, UpdateQtyToItem} = useCart()

   const checkInTime = (startTime:String|null, endTime:String|null) => {
      if(!startTime || !endTime || startTime == endTime)  return true
      function timeToMinutes(time:String) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      }
      const currentTime = moment.utc().format('HH:mm')
      const minutesStartTime = timeToMinutes(startTime);
      const minutesEndTime = timeToMinutes(endTime);
      const minutesCurrentTime = timeToMinutes(currentTime);
      if(minutesStartTime>minutesEndTime){
         return minutesCurrentTime >= minutesStartTime || minutesCurrentTime <= minutesEndTime; 
      }else{
         return minutesCurrentTime >= minutesStartTime && minutesCurrentTime <= minutesEndTime; 
      }      
   } 

   const BtnClrClass = useLocationBasedBtnColor()

   useEffect(() => {
      if (itemInfo !== undefined) {
         setQty(itemInfo.quantity)
      }
   }, [itemInfo])

   const AddToCartClickHandler = () => {
      if(!checkInTime(currentCategory.start_time,currentCategory.end_time)){
         fireErrorAlert('This item currently not available');
         return;
      }
      let validated = true
      mod_groups.some((modgroup, index: number) => {         
         let min = modgroup.min ? modgroup.min : 0
         let max = modgroup.max ? modgroup.max : 0
         const modCount = modifiers.filter((e) => e.modgroup_id == modgroup.modifier_group_id).reduce((total: number, mod: ISelfOrderingCartItemModifier) => {
            return total + mod.quantity
         }, 0) 

         //let modCount = modifiers.hasOwnProperty(modgroup.id) ? Object.keys(modifiers[modgroup.id]).length : 0
         if (modgroup.required == 1 && min > 0 && modCount == 0) {
            fireWarningAlert('Please select minimum required modifiers from each required Group.')
            validated = false
            return true
         } else if (modgroup.required == 1 && min > 0 && modCount < min) {
            fireWarningAlert(`${modgroup.name} requires ${min} modifiers, please select them to proceed.`)
            validated = false
            return true
         } else if (modCount > 0 && min > 0 && modCount < min) {
            fireWarningAlert(`${modgroup.name} requires ${min} modifiers, please select them to proceed.`)
            validated = false
            return true
         }
      })
      if (!validated) return
      let arrModifiers: Array<ISelfOrderingCartItemModifier> = modifiers ?? []
      let arr_removed_def_modifiers: Array<ISelfOrderingCartItemModifier> = removedIncludedModifiers ?? []

      let extraData = {
         category_id: category_id,
         modifiers: arrModifiers,
         removed_included_modifiers: arr_removed_def_modifiers,
      }
      
      AddItem(itemInfo, qty, extraData)            

      setTimeout(() => {
         navigateTo(orderingPaths.MENU)
      }, 10)
   }

   const handleDecrement = () => {
      const qtyDec = qty === 1 ? 1 : qty - 1
      setQty(qtyDec)

      const sum = (Number(qtyDec) * +(itemInfo.price_per_unit ?? 0)) / 100
      setTotal(sum)
   }

   const handleIncrement = () => {
      const qtyInc = qty + 1
      setQty(qtyInc)
      const sum = (Number(qtyInc) * +(itemInfo.price_per_unit ?? 0)) / 100

      setTotal(sum)
   }

   const getTotalWithMods = () => {
      if (itemInfo === undefined) return 0
      let total = (itemInfo.price_per_unit ?? 0) * qty
      let totalAllMods = 0
      modifiers.map((mod) => {
         totalAllMods += (mod.quantity * mod.price)
      })
      return (total + totalAllMods * qty) / 100
   }
   return (
      <>
         <div
            className='container-fluid d-flex align-items-stretch justify-content-between fixed-bottom bg-light border-top footer-bg-color'
            style={{height: '100px', zIndex: '1'}}
         >
            <div className='col-5 my-auto'>
               <button
                  type='button'
                  className='btn btn-bg-secondary me-2'
                  style={buttonStyle}
                  onClick={() => {
                     navigateTo(-1)
                  }}
               >
                  Cancel
               </button>
            </div>
            <div className='col-7 my-auto text-right d-flex justify-content-end '>
               <h1 className='me-10 my-auto'>Quantity </h1>
               <div
                  className='position-relative d-flex align-items-center me-10'
                  data-kt-dialer='true'
                  data-kt-dialer-min='1'
                  data-kt-dialer-max='10'
                  data-kt-dialer-step='1'
                  data-kt-dialer-decimals='0'
               >
                  <button
                     type='button'
                     className={`btn btn-icon btn-sm btn-light w-70px h-70px`}
                     data-kt-dialer-control='decrease'
                     onClick={handleDecrement}
                  >
                     <span className={`svg-icon-3x`}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                           <rect x='6' y='11' width='12' height='2' rx='1' fill='currentColor'></rect>
                        </svg>
                     </span>
                  </button>

                  <input
                     type='text'
                     className='form-control border-0 text-center px-0 fs-3 text-gray-800 w-70px h-70px'
                     data-kt-dialer-control='button'
                     style={{pointerEvents: 'none'}}
                     placeholder='Amount'
                     name='manageBudget'
                     value={qty}
                     onChange={() => {}}
                  />

                  <button
                     type='button'
                     className='btn btn-icon btn-sm btn-light w-70px h-70px'
                     data-kt-dialer-control='increase'
                     onClick={handleIncrement}
                  >
                     <span className={`svg-icon-3x`}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                           <rect
                              opacity='0.5'
                              x='11'
                              y='18'
                              width='12'
                              height='2'
                              rx='1'
                              transform='rotate(-90 11 18)'
                              fill='currentColor'
                           ></rect>
                           <rect x='6' y='11' width='12' height='2' rx='1' fill='currentColor'></rect>
                        </svg>
                     </span>
                  </button>
               </div>
               <button
                  type='button'
                  onClick={AddToCartClickHandler}
                  className={`btn  btn-light btn-primary btn-lg me-5 ${BtnClrClass != '' ? BtnClrClass : 'text-white'}`}
                  style={buttonStyle}
               >
                  Add to Cart ${getTotalWithMods().toFixed(2)}
               </button>
            </div>
         </div>
      </>
   )
}
