import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getAuth} from '../auth'

interface LocationTypes {
   name: string
   value: number
   POS: string
   logo: string
   selectedTerminalLocation: number
   HandleRowClick: (value: any, name: string) => void
}
let logoPath =
   localStorage.getItem('kt_theme_mode_value') === 'light'
      ? '../media/logos/softpoint_small_black.svg'
      : '../media/logos/softpoint_small.svg'

//handleTerminalLocation
export const KioskTerminalLocation: React.FC<LocationTypes> = ({
   name,
   value,
   selectedTerminalLocation,
   logo,
   POS,
   HandleRowClick,
}) => {
   return (
      <div
         onClick={(e) => {
            HandleRowClick(value, name)
         }}
         className='d-flex flex-stack flex-wrap py-3'
      >
         <img
            alt='Logo'
            src={
               logo
                  ? `${process.env.REACT_APP_API_URL}/download-file?path=${logo}&global_on_id=${
                       process.env.REACT_APP_KIOSK_GLOBAL_ON
                    }&user_id=${getAuth()?.user_id}`
                  : toAbsoluteUrl(logoPath)
            }
            onError={({currentTarget}) => {
               //broken image
               currentTarget.onerror = null // prevents looping
               currentTarget.src = toAbsoluteUrl(logoPath)
            }}
            className='h-150px w-150px mx-5 rounded-3'
         />
         <div className='d-flex flex-wrap col'>
            <div className='col-8'>
               <h6 className='fs-2tx'> {name}</h6>
               <span className='text-gray-700 fs-2tx'>{POS}</span>
            </div>
         </div>
         <label htmlFor={'terminalSelection'} className='form-check-lg form-check-solid form-check-custom'>
            <input
               className='form-check-input'
               type='radio'
               name={name}
               value={value}
               onChange={() => {}}
               checked={selectedTerminalLocation === value}
            />
         </label>
      </div>
   )
}
