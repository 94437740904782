import {useCart} from '../../../services/hooks/useCart'
import axios, {AxiosResponse} from 'axios'
import {GetApiUrl, getAuth} from '../../auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import moment from 'moment'
import {
   CreateTicketAndPayResponse,
   CreateTicketAndPaySchema,
   IGuestData,
   TicketRequest,
   WebhookSaleResponse,
   WebhookSaleSchema,
} from '../models/use_pay_models'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {getSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {getSaleSession} from '../../../services/SelfOrderingCartSlice'
import {constants, KIOSK_GLOBAL_ON, PAYMENT_TIMEOUT} from '../../../config/constants'
import {generateUniqueId} from '../../../common/utilities'

export interface ILoyaltyData {
   card_number: string
   amount_to_redeem: number
}

export function usePay() {
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const SelectedTerminal = useSelector(getSelectedTerminal) as Terminal
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)
   const selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   const order_type = useSelector((state: RootState) => state.self_ordering_selections.order_type)
   const saleSession = useSelector(getSaleSession)
   const {GetItems} = useCart()

   type LoyaltyRequest = {
      card_number: string
      amount: number
   }

   type OloData = {
      contact_info:{
         first_name:string,
         last_name:string,
         email:string|null,
         phone:string,
         phone_global_country:string,
      },
      order_type:string,
      pickup_datetime:string|null,
      vehicle:string|null
   }
   

   type PayAndCreateTicketRequest = {
      sale_session_id: null | number
      user_id: number
      employee_id: number
      global_on_id: number
      terminal_id: number
      payment_terminal_id?: number
      payment_type: string
      ticket: TicketRequest
      close_ticket?: boolean
      loyalty?: LoyaltyRequest,
      olo_info:OloData,
   }

   type WebhookSaleRequest = {
      sale_session_id: null | number
      user_id: number
      ordering_employee_id: number
      global_on_id: number
      terminal_id: number
      payment_terminal_id?: number
      payment_request: {
         base_amount: number
         total_amount: number
         tip_amount?: number
      }
      loyalty?: Omit<LoyaltyRequest, 'amount'>
   }

   type CreateTicketRequest = {
      user_id: number
      employee_id: number
      global_on_id: number
      ticket: TicketRequest
      terminal_id: number
      payment_id: number
      close_ticket: boolean
   }

   const PAY_TYPES = {
      InStore: 'pay_in_store',
      CreditCard: 'cp',
      GiftCard: 'gc',
      Loyalty: 'loyalty',
   } as const

   type PayType = typeof PAY_TYPES[keyof typeof PAY_TYPES]

   const getOrderMetaData = () => {
      let employee_id = kiosk_settings.ordering_employee ?? 0
      let employee_external_id = kiosk_settings.ordering_employee_external_id
         ? kiosk_settings.ordering_employee_external_id
         : ''

      let order_type_id = kiosk_settings.order_type_id ?? 0
      let order_type_id_external = kiosk_settings.order_type_external_id ? kiosk_settings.order_type_external_id : ''
      if (order_type == 'dinein') {
         order_type_id = kiosk_settings.dine_in ?? 0
         order_type_id_external = kiosk_settings.order_type_dine_in_external_id
            ? kiosk_settings.order_type_dine_in_external_id
            : ''
      }
      let revenue_center_id = SelectedTerminal.revenue_center ?? 0
      let db_revenue_center = selfOrderData.Data_RevenueCenters.find(
         (revenueCenter) => revenueCenter.id == revenue_center_id
      )
      let revenue_center_id_external = db_revenue_center?.id_external ?? ''
      return {
         employee_id,
         employee_external_id,
         order_type_id,
         order_type_id_external,
         revenue_center_id,
         revenue_center_id_external,
      }
   }

   const paymentRequest = async (payment_type: PayType, user_data: IGuestData, loyalty_data?: ILoyaltyData) => {
      let user = getAuth()
      const user_id = user!.user_id

      const orderMetaData = getOrderMetaData()

      if (payment_type === PAY_TYPES.CreditCard) {
         let saleRequest: WebhookSaleRequest = {
            user_id: user_id,
            ordering_employee_id: orderMetaData.employee_id,
            global_on_id: parseInt(KIOSK_GLOBAL_ON),
            terminal_id: SelectedTerminal.id,
            payment_terminal_id: SelectedTerminal.paired_terminal_id ?? 0,
            sale_session_id: saleSession.data?.id ?? null,
            payment_request: {
               base_amount: cartTotals.total - cartTotals.tip,
               total_amount: cartTotals.total,
               tip_amount: cartTotals.tip,
            },
         }

         if (loyalty_data) {
            saleRequest.loyalty = {
               card_number: loyalty_data.card_number,
            }
         }
         const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/webhook/sale`
         return axios.post(url, saleRequest)
      }
      console.log('user_data',user_data);

      let create_ticket_and_pay_payload: PayAndCreateTicketRequest = {
         user_id: user_id,
         employee_id: orderMetaData.employee_id,
         global_on_id: parseInt(KIOSK_GLOBAL_ON),
         terminal_id: SelectedTerminal.id,
         payment_type: payment_type,
         sale_session_id: saleSession.data?.id ?? null,
         olo_info:{
            contact_info:{
               first_name:user_data.name?user_data.name.split(' ').slice(-1)[0]:'',
               last_name:user_data.name?user_data.name.substring(0, user_data.name.lastIndexOf(" ") > 0 ? user_data.name.lastIndexOf(" ") : user_data.name.length):'',
               email:null,
               phone:user_data.phone?user_data.phone:'',
               phone_global_country:"",
            },
            order_type:order_type,
            pickup_datetime:moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            vehicle:"",
         },
         ticket: {
            terminal_id: SelectedTerminal.id,
            employee_id: orderMetaData.employee_id,
            employee_id_external: orderMetaData.employee_external_id,
            guest_count: 1,
            inclusive_tax: 0,
            name: user_data.name,
            phone: user_data.phone,
            order_type_id: orderMetaData.order_type_id,
            order_type_id_external: orderMetaData.order_type_id_external,
            revenue_center_id: orderMetaData.revenue_center_id,
            revenue_center_id_external: orderMetaData.revenue_center_id_external,
            ticket_number: generateUniqueId(),
            items: GetItems(),
            sub_total: cartTotals.sub_total,
            tax: cartTotals.tax,
            total: cartTotals.total,
         },
      }
      if (kiosk_settings.global_tips_types_id === constants.global_tip_types.tip_before) {
         create_ticket_and_pay_payload.ticket.tip = cartTotals.tip
         create_ticket_and_pay_payload.ticket.total = create_ticket_and_pay_payload.ticket.total - cartTotals.tip //Total should not to include tip
      }

      if (loyalty_data) {
         create_ticket_and_pay_payload.loyalty = {
            card_number: loyalty_data.card_number,
            amount: loyalty_data.amount_to_redeem,
         }
      }

      const url = `${GetApiUrl()}/locations/${kiosk_settings.location_id}/pay_and_create_ticket`
      return axios.post(url, create_ticket_and_pay_payload)
   }

   const payInStore = async (user_data: IGuestData): Promise<CreateTicketAndPayResponse> => {
      try {
         const response = await paymentRequest(PAY_TYPES.Loyalty, user_data)
         return CreateTicketAndPaySchema.validateSync(response.data)
      } catch (error) {
         throw error
      }
   }

   const payWithLoyalty = async (
      loyalty_data: ILoyaltyData,
      user_data: IGuestData
   ): Promise<CreateTicketAndPayResponse> => {
      try {
         const response = await paymentRequest(PAY_TYPES.Loyalty, user_data, loyalty_data)
         return CreateTicketAndPaySchema.validateSync(response.data)
      } catch (error) {
         throw error
      }
   }

   const payWithCC = async (user_data: IGuestData, loyalty_data?: ILoyaltyData) => {
      try {
         const paymentResponse = (await Promise.race([
            paymentRequest(PAY_TYPES.CreditCard, user_data, loyalty_data),
            getPaymentTimeout(),
         ])) as AxiosResponse
         const payment = WebhookSaleSchema.validateSync(paymentResponse.data)
         return payment as WebhookSaleResponse
      } catch (error) {
         throw error
      }
   }

   const payWithGC = () => {
      return {
         ticket_id: 568,
         payment_id: 6678,
      }
   }

   return {
      payInStore,
      payWithCC,
      payWithGC,
      payWithLoyalty,
   }
}

export class PaymentTimeoutError extends Error {
   constructor(message = 'The payment service is not responding, please try again later') {
      super(message)
      this.name = 'PaymentTimeoutError'
      this.message = message
   }
}

function getPaymentTimeout() {
   return new Promise((resolve, reject) => {
      window.setTimeout(() => reject(new PaymentTimeoutError()), PAYMENT_TIMEOUT * 1000)
   })
}
