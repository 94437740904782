import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {integrationHasKiosk, integrationHasCheckin} from '../utilities'

export const useKioskProducts = () => {
   const LocData = useSelector((state: RootState) => state.location_data)
   const settings = useSelector((state: RootState) => state.kiosk_settings)
   const locationProducts = LocData.derived_values.product_statuses
   return {
      checkInEnabled:
         LocData.derived_values.product_statuses.checkInIsEnabled &&
         integrationHasCheckin(settings.derived_values.checkInIntegration) &&
         (locationProducts.reservationsEnabled || settings.value?.check_in_walk_up == 1),
      orderingEnabled:
         locationProducts.selfOrderingEnabled &&
         (Number.isInteger(settings.value?.dine_in) || Number.isInteger(settings.value?.order_type_id)) &&
         integrationHasKiosk(settings.derived_values.selfOrderingIntegration),
      DineinAndTakeOutBothEnable: settings.value?.dine_in && settings.value?.order_type_id,
      loyaltyEnabled:
         locationProducts.loyaltyEnabled &&
         integrationHasKiosk(settings.derived_values.loyaltyIntegration) &&
         settings.value?.pay_by_loyalty == 1,
   }
}
