import {useEffect, useState} from 'react'
import {useAutoLoginContext} from './AutoLoginProvider'
import {setAuth} from './core/AuthHelpers'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useErrorAlert} from '../utilities/alerts'
import {DownloadSettingsStep as SettingsSyncView} from '../KioskSettings/components/DownloadSettingsStep'
import {StoreSelectedTerminal} from '../../services/SelectedTerminalSlice'
import {useDispatch, useSelector} from 'react-redux'
import {fetchKioskSettings} from '../../common/apis/kiosk_settings_request'
import {integrationHasKiosk, parseRequestError} from '../../common/utilities'
import {StoreKioskData} from '../../services/KioskSettingSlice'
import {StoreSelectedLocation} from '../../services/SelectedLocationSlice'
import {LoadingEllipses} from './components/LoadingEllipses'
import {RootState} from '../../store'
import {useAutoLogin} from './requests/auto_login_request'
import {setFlow} from '../../services/FlowSlice'
import {constants} from '../../config/constants'
import {ValidationError} from 'yup'
import {useIntl} from 'react-intl'
import {useInternationalization} from '../i18n/i18nProvider'
import {useKioskProducts} from '../../common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'

export const AutoLoginController = () => {
   const intl = useIntl()
   const loadingSerialNumberText = intl.formatMessage({id: 'auth.autologin.retreiving_serial_number'})
   const settingsLoadingText = intl.formatMessage({id: 'auth.autologin.retreiving_settings'})
   const fullLogoPath = '../media/logos/softpoint_logo_app_white.svg'
   const fireAlert = useErrorAlert()
   const navigateTo = useNavigate()
   const tizenSettings = useSelector((state: RootState) => state.tizen_system)
   const autoLoginQuery = useAutoLoginContext()
   const dispatch = useDispatch()
   const {setLanguage} = useInternationalization()
   const FetchAutoLogin = useAutoLogin()
   const locationData = useSelector((state: RootState) => state.location_data)
   const kioskSettings = useSelector((state: RootState) => state.kiosk_settings)
   const [renderSettingsView, toggleSettingsView] = useState(false)
   const [settingsAreLoading, setSettingsQueryLoading] = useState(false)
   const {orderingEnabled, checkInEnabled} = useKioskProducts()

   useEffect(() => {
      autoLoginQuery.setLoadingText(loadingSerialNumberText)
      localStorage.setItem('kt_theme_mode_value', 'dark')

      //Need to set the default app mode to Check In.
      // Check In is now the preferred home page
      dispatch(setFlow(constants.flows.checkin))

      return () => {
         autoLoginQuery.resetState()
      }
   }, [])

   // REDIRECTS IF APP IS NOT RUNNING ON TIZEN OS
   useEffect(() => {
      if (!tizenSettings.isLoading && (tizenSettings.serial_number == null || tizenSettings.serial_number == '')) {
         fireAlert(intl.formatMessage({id: 'auth.autologin.unable_to_retreive_serial_number'})).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
               navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
            }
         })
      } else {
         if (tizenSettings.serial_number !== null) {
            FetchAutoLogin(tizenSettings.serial_number)
         }
      }
   }, [tizenSettings.isLoading, tizenSettings.serial_number])

   // HANDLES REQUEST ERRORS
   useEffect(() => {
      if (autoLoginQuery.error) {
         const buttonText =
            autoLoginQuery.error.code == -2 ? intl.formatMessage({id: 'alert.ok_got_it'}) : 'Try Manual Login'
         fireAlert(autoLoginQuery.error.message, false, buttonText).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
               navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
            }
         })
      }
   }, [autoLoginQuery.error])

   //ORCHESTRATES DATA LOADING
   useEffect(() => {
      if (autoLoginQuery.data) {
         const locationID = autoLoginQuery.data.terminal_info.location.location_id
         const userID = autoLoginQuery.data.terminal_info.location.owner!

         setAuth({
            api_token: autoLoginQuery.data.access_token,
            user_id: autoLoginQuery.data.terminal_info.location.owner!,
         })
         dispatch(StoreSelectedTerminal(autoLoginQuery.data.terminal_info.terminal))
         dispatch(StoreSelectedLocation(autoLoginQuery.data.terminal_info.location))

         //FIXME: Only one storage solution should be used application wide
         // Need to remove this local storage usage
         localStorage.setItem('terminal_data', JSON.stringify(autoLoginQuery.data.terminal_info.terminal))

         setSettingsQueryLoading(true)
         autoLoginQuery.setLoadingText(settingsLoadingText)
         fetchKioskSettings(locationID, userID)
            .then((response) => {
               setLanguage(response._embedded.location_info.locale)
               dispatch(StoreKioskData(response))
               localStorage.setItem('kiosk_setting', JSON.stringify(response))
               toggleSettingsView(true)
            })
            .catch((error) => {
               if (error instanceof ValidationError) {
                  console.log('[KioskSettingsSchema] errors', error.errors)
               }
               const standardError = parseRequestError(error)
               let translatedMessage = standardError.message
               if (standardError.code < 0) {
                  translatedMessage = intl.formatMessage({id: standardError.message})
               }
               const buttonText =
                  standardError.code == -2 ? intl.formatMessage({id: 'alert.ok_got_it'}) : 'Login Manually'
               fireAlert(translatedMessage, false, buttonText).then((result) => {
                  if (result.isConfirmed || result.isDismissed) {
                     navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
                  }
               })
            })
      }
   }, [autoLoginQuery.data])

   const navigationReducer = () => {
      if (checkInEnabled && orderingEnabled) {
         navigateTo(MODULE_ROUTE_PATHS.dashboard.index)
      } else if (checkInEnabled) {
         navigateTo(MODULE_ROUTE_PATHS.checkIn.check_in_home)
      } else if (orderingEnabled) {
         navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
      } else {
         fireAlert(intl.formatMessage({id: 'kiosk.terminal.location_not_configured'})).then((input) =>
            navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
         )
      }
   }

   return (
      <div className='vh-100'>
         <div className='h-100 d-flex flex-column pt-5'>
            {renderSettingsView ? (
               autoLoginQuery.data && <SettingsSyncView onSettingsDownloaded={navigationReducer} CallSync={true} fullRefresh={false} />
            ) : (
               <div className='flex-grow-1 d-flex flex-column justify-content-center p-8 pt-20 mt-20'>
                  <div className='card container-fluid h-100 justify-content-center'>
                     <div className='d-flex flex-column text-center'>
                        <div className='mb-6'>
                           <img alt='Logo' src={toAbsoluteUrl(fullLogoPath)} className='h-150px mt-5' />
                        </div>
                        <div className='d-flex justify-content-center'>
                           <h2 className='fs-2tx py-10'>
                              {(autoLoginQuery.isLoading || settingsAreLoading) && autoLoginQuery.loadingText}
                           </h2>
                           {(autoLoginQuery.isLoading || settingsAreLoading) && <LoadingEllipses />}
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </div>
      </div>
   )
}
