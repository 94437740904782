import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import './SettingsStyle.css'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {FormattedMessage} from 'react-intl'
interface HelpModalProps {
   toggleModal: () => void
}

export const HelpModal: FC<HelpModalProps> = ({toggleModal}) => {
   const locationData = useSelector((state: RootState) => state.location_data)

   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
   }
   const closeBtnStyle = {
      position: 'absolute',
      top: '1rem',
      right: 0,
   } as React.CSSProperties
   const location_data = locationData.value
      ? locationData.value
      : JSON.parse(localStorage.getItem('location_data') as any)
   const BtnClrClass = useLocationBasedBtnColor()

   return (
      <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog-centered h-auto px-10'
         show={true}
         onHide={toggleModal}
      >
         <div className='container-xxl px-10 py-5'>
            <div className='modal-dialog mw-100 p-0 m-0'>
               <div className='modal-content'>
                  <div className='text-end'>
                     <div
                        aria-label='Close'
                        data-bs-dismiss='modal'
                        onClick={toggleModal}
                        style={closeBtnStyle}
                        className='btn btn-icon btn-lg btn-active-light-primary'
                     >
                        <i className='bi bi-x-lg fs-2x svg-icon'></i>
                     </div>
                  </div>
                  {/* START::MODAL-BODY */}
                  <div className='tab-content'>
                     <div className='d-flex flex-row flex-column-fluid h-xl-100 pb-10'>
                        <div className='d-flex flex-row-fluid flex-center'>
                           <div className='row theme-background py-3 text-center'>
                              {
                                 <>
                                    <h1 className='fs-1 py-5'>
                                       <FormattedMessage id='setting.help.need_help' defaultMessage='Need Help?' />
                                    </h1>
                                    <p className='fs-1'>
                                       <FormattedMessage
                                          id='setting.help.if_you_need_assistance'
                                          defaultMessage='If you are in need of assistance'
                                       />
                                       ,
                                       <br />
                                       <FormattedMessage
                                          id='setting.help.contact_softpoint'
                                          defaultMessage='please contact SoftPoint Support by phone at'
                                       />
                                    </p>
                                    <h1 className='fs-1 mt-5'> (480) 745.3049 x2</h1>
                                    <p className='fs-1 mt-5'>
                                       {' '}
                                       <FormattedMessage
                                          id='setting.help.or_by_email'
                                          defaultMessage='or by email at'
                                       />
                                       :
                                    </p>

                                    <h1 className='fs-1'>support@softpoint.io</h1>
                                    <p className='fs-1 mt-5'>
                                       <FormattedMessage
                                          id='setting.help.provide_location_id'
                                          defaultMessage='Please provide your Location ID below'
                                       />
                                       :
                                    </p>
                                    <h1 className='fs-1 my-5'>{location_data.location_id}</h1>
                                 </>
                              }
                              <div className='d-flex row justify-content-center'>
                                 <button
                                    type='button'
                                    style={buttonStyle}
                                    onClick={toggleModal}
                                    className={`btn btn-primary ms-2 w-100 ${BtnClrClass}`}
                                 >
                                    <FormattedMessage id='alert.ok_got_it' defaultMessage='OK, got it!' />
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* END::MODAL-BODY */}
               </div>
            </div>
         </div>
      </Modal>
   )
}
