import {IntlProvider} from 'react-intl'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import {WithChildren} from '../../../_metronic/helpers'
import {createContext, useContext, useState} from 'react'

/*
      THESE PACKAGES BREAK THE APP

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
*/
export const ES_COUNTRIES = [5, 12, 26, 38, 54, 69, 72, 76, 79, 85, 87, 89, 112, 118, 173, 184, 185, 182, 237];

export const LANGUAGE_CODES = ['en','es'] as const // ISO 639-1

type LanguageAsset = {
   [key in typeof LANGUAGE_CODES[number]]: string
}

export const LANGUAGE_NAMES: LanguageAsset = {
   es: 'Espa\xF1ol',
   en: 'English',
} as const

export const LANGUAGE_FLAGS: LanguageAsset = {
   en: '/media/flags/united-states.svg',
   es: '/media/flags/mexico.svg',
} as const

export type SupportedLanguages = typeof LANGUAGE_CODES[number]

type i18nProps = {
   selectedLang: SupportedLanguages
   setLanguage: (language: SupportedLanguages) => void
}

const I18nContext = createContext<i18nProps | undefined>(undefined)

const allMessages = {
   en: enMessages,
   es: esMessages,
}

export const useInternationalization = () => {
   const i18nContext = useContext(I18nContext)
   if (!i18nContext) {
      throw new Error('[] can not be used without I18nProvider')
   }
   return i18nContext
}

const I18nProvider = ({children}: WithChildren) => {
   const [locale, setLocale] = useState<SupportedLanguages>('en')
   const messages = allMessages[locale]
   const setLanguage = (newLocale: SupportedLanguages) => {
      if (locale != newLocale) {
         setLocale(newLocale)
      }
   }
   return (
      <I18nContext.Provider value={{selectedLang: locale, setLanguage}}>
         <IntlProvider locale={locale} messages={messages}>
            {children}
         </IntlProvider>
      </I18nContext.Provider>
   )
}

export {I18nProvider}
