import {SoftPointLocation} from '../common/schemas/LocationSchema'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type AuthLocations = {
   value: SoftPointLocation[]
}

const initialState: AuthLocations = {value: []}

export const AuthLocationsSlice = createSlice({
   name: 'AuthLocations',
   initialState,
   reducers: {
      setAuthLocations: (state, action: PayloadAction<SoftPointLocation[]>) => {
         state.value = action.payload
      },
   },
})

export const {setAuthLocations} = AuthLocationsSlice.actions

export default AuthLocationsSlice.reducer
