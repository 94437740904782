import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useEffect, ReactNode, useState} from 'react'
import {getBaseRequestPayload} from '../../common/utilities'
import axios from 'axios'
import {GetApiUrl} from '../auth'
import {setFaceRecognitionStatus} from '../../services/FacialRecognitionService'
import {TizenDebug} from '../hotel/FaceAuth/TizenIntegration'

type monitorProps = {
   children: ReactNode
}

export const FaceRecognitionMonitor = (props: monitorProps) => {
   const ENV_POLL_TIMER = process.env.REACT_APP_BIOMETRIC_POLL_TIMER
   const faceRecognitionService = useSelector((state: RootState) => state.faceRecognitionServices)
   const appStoreDispatch = useDispatch()
   const intervalTimeInSeconds = ENV_POLL_TIMER != undefined ? parseInt(ENV_POLL_TIMER) : 60

   // console.log(`[BIOMETRIC_POLL_TIMER]: ${parseFloat(process.env.REACT_APP_BIOMETRIC_POLL_TIMER ?? 'ENV VARIABLE UNDEFINED')}`)
   const fetchHeartBeat = () => {
      TizenDebug(`[API Request]: /biometrics/heart_beat`)
      const queryPayload = getBaseRequestPayload()
      axios
         .get(`${GetApiUrl()}/biometrics/heart_beat`, {params: queryPayload})
         .then((response) => {
            if (response.status == 200) {
               TizenDebug(`[API Heart Beat Response]: 200`)
               appStoreDispatch(setFaceRecognitionStatus(true))
            }
         })
         .catch((error) => {
            TizenDebug(`[API Heart Beat Response]: 400-599`)
            TizenDebug(`[API Heart Beat Response]: ${error.response.data}`)
            console.log(error.response)
            setTimeout(fetchHeartBeat, intervalTimeInSeconds * 1000)
         })
   }

   useEffect(() => {
      console.log(`[FaceRecognitionMonitor]: Facial Biometrics is online = ${faceRecognitionService.is_online}`)

      if (!faceRecognitionService.is_online) {
         setTimeout(fetchHeartBeat, intervalTimeInSeconds * 1000)
      }
   }, [faceRecognitionService.is_online])

   return <>{props.children}</>
}
