import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {constants} from '../../../config/constants'
import {useCart} from '../../../services/hooks/useCart'
import {useLocationBasedTextColor} from '../../../common/hooks/buttons'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {PaymentType} from './PaymentModalController'
import {useSelector} from 'react-redux'
import {getKioskSettings} from '../../../services/KioskSettingSlice'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'

interface OrderCompleteModalProps {
   orderNumber?: string
   paymentType: PaymentType
   toggleReceiptModal: () => void
}

export const OrderCompletedModalBody: FC<OrderCompleteModalProps> = ({
   orderNumber,
   paymentType,
   toggleReceiptModal,
}) => {
   const settings = useSelector(getKioskSettings) as KioskSettings
   const navigateTo = useNavigate()
   const {ResetCart} = useCart()

   const BtntextClrClass = useLocationBasedTextColor()

   useTranslatedPageTitle(constants.staticPageTitle.confirm)

   return (
      <>
         <div className=' row d-flex justify-content-center h-100'>
            <div className='w-100'>
               <div className='row d-flex justify-content-center'>
                  <div className='col-md-12 d-flex justify-content-center'>
                     <div className='d-flex align-items-center'>
                        <h1 className='mt-5 fw-bold order_complete text-center'>ORDER COMPLETE</h1>
                     </div>
                  </div>
                  <div className='row d-flex justify-content-center' data-kt-buttons='true'>
                     <div className='col d-flex justify-content-center'>
                        <div
                           className={`btn btn-outline btn-outline btn-outline-primary ${BtntextClrClass}`}
                           style={{
                              borderRadius: '6px',
                              minWidth: '200px',
                              minHeight: '200px',
                              maxWidth: '200px',
                              maxHeight: '200px',
                           }}
                        >
                           <div className=' my-auto mx-auto'>
                              <div className='d-block fs-2 fw-bold h-100px py-10'>Order Number</div>
                              <div className='d-block fs-lg-2x text-primary fw-bold h-100px pl-10'>{orderNumber}</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='col-9'>
                     <div className='d-flex align-items-center'>
                        <div className='my-5 fw-bold fs-2 d-flex align-items-center'>
                           <div
                              className={`order_complete_second btn btn-outline btn-outline btn-outline-primary ${BtntextClrClass}`}
                           >
                              1
                           </div>
                           <div style={{width: '90%'}} className='text-start'>
                              GRAB YOUR RECEIPT
                           </div>
                        </div>
                     </div>
                  </div>
                  {paymentType === 'cash' && (
                     <div className='col-9'>
                        <div className='d-flex align-items-center'>
                           <div className='my-5 fw-bold fs-2 d-flex align-items-center'>
                              <div
                                 className={`order_complete_second btn btn-outline btn-outline btn-outline-primary ${BtntextClrClass}`}
                              >
                                 2
                              </div>
                              <div style={{width: '90%'}} className='text-start'>
                                 PLEASE GO TO THE NEAREST COUNTER TO PAY AND PICKUP YOUR ORDER
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
                  {paymentType !== 'cash' && (
                     <div className='col-9'>
                        <div className='d-flex align-items-center'>
                           <div className='my-5 fw-bold fs-2 d-flex align-items-center'>
                              <div
                                 className={`order_complete_second btn btn-outline btn-outline btn-outline-primary ${BtntextClrClass}`}
                              >
                                 2
                              </div>
                              <div style={{width: '90%'}} className='text-start'>
                                 GRAB YOUR ORDER AT THE PICKUP COUNTER
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </div>

               <div
                  className='d-flex flex-equal gap-5 px-0 row align-items-center'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
               >
                  <div className='col d-flex justify-content-center'>
                     {settings.auto_print_receipt === constants.status.NO && (settings.receipt_email || settings.receipt_text || settings.receipt_print) && (
                        <button
                           className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center'
                           style={{
                              padding: '0px',
                              margin: '5px',
                              minWidth: '180px',
                              minHeight: '180px',
                              maxWidth: '180px',
                              maxHeight: '180px',
                           }}
                           onClick={() => {
                              toggleReceiptModal()
                           }}
                        >
                           <div className=' my-auto mx-auto'>
                              <input className='btn-check' type='radio' name='method' value={1} />
                              <i className='bi bi-receipt fs-2hx my-1 pe-0'></i>
                              <span className='d-block fs-3 fw-bold'>Receipt</span>
                           </div>
                        </button>
                     )}
                     {paymentType !== 'cash' && (
                        <button
                           data-kt-button='true'
                           type='submit'
                           form='checkout-form'
                           className='btn bg-light btn-color-gray-800 btn-active-text-gray-900 d-flex justify-content-center align-items-center'
                           style={{
                              padding: '0px',
                              margin: '5px',
                              minWidth: '180px',
                              minHeight: '180px',
                              maxWidth: '180px',
                              maxHeight: '180px',
                           }}
                           onClick={() => {
                              ResetCart()
                              navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
                           }}
                        >
                           <div className=' my-auto mx-auto'>
                              <input className='btn-check' type='radio' name='method' value={0} />
                              <i className='bi bi-rewind-circle-fill fs-2hx my-1 pe-0'></i>
                              <span className='d-block fs-3 fw-bold'>Start New Order</span>
                           </div>
                        </button>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
