import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {constants} from '../../config/constants'
import {useErrorAlert, useLoadingAlert} from '../utilities/alerts'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {PaymentModalController} from './checkout_components/PaymentModalController'
import {MenuImage} from './menu_components/MenuImage'
import {useLocationBasedBtnColor} from '../../common/hooks/buttons'
import {getItemSubTotal, useCart} from '../../services/hooks/useCart'
import {
   GetNewSaleSessionID,
   getSaleSession,
   ISelfOrderingCartItem,
   ISelfOrderingCartItemModifier,
} from '../../services/SelfOrderingCartSlice'
import {AppDispatch, RootState} from '../../store'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import Swal from 'sweetalert2'
import {getKioskSettings} from '../../services/KioskSettingSlice'
import {KioskSettings} from '../../common/schemas/KioskSettingsSchema'
import {getPosCheck, getPosStatus, updatePosCheckRequest} from '../../services/SelfOrderingDataSlice'
import {getSelectedTerminal} from '../../services/SelectedTerminalSlice'
import {Terminal} from '../../common/schemas/TerminalSchema'

export const CheckoutView = () => {
   const orderingPaths = MODULE_ROUTE_PATHS.ordering
   const navigateTo = useNavigate()
   const dispatch = useDispatch<AppDispatch>()
   const posSettings = useSelector(getPosCheck)
   const fireErrorAlert = useErrorAlert()
   const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)
   const kioskSettings = useSelector(getKioskSettings) as KioskSettings
   const saleSession = useSelector(getSaleSession)
   const terminal = useSelector(getSelectedTerminal) as Terminal
   const togglePaymentModal = () => setShowPaymentModal(!showPaymentModal)

   const handlePayButton = () => {
      togglePaymentModal()
   }

   interface IPayForm {
      firstName: string
      lastName: string
      phoneNumber: string
   }

   const [orderForm, setOrderForm] = useState<IPayForm>({
      firstName: '',
      lastName: '',
      phoneNumber: '',
   })

   const fireLoadingAlert = useLoadingAlert()

   const BtnClrClass = useLocationBasedBtnColor()
   const {GetItems, UpdateQtyToItem, RemoveItem} = useCart()
   const cartTotals = useSelector((state: RootState) => state.self_ordering_cart.totals)
   const isLoading = useSelector((state: RootState) => state.self_ordering_cart.isLoading)
   const [cartItems, setCartItems] = useState<Array<ISelfOrderingCartItem>>([])

   const [PayButtonDisabled, setPayButtonDisabled] = useState<boolean>(false)

   const buttonStyleFooter = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      maxWidth: '300px',
      minWidth: '300px',
      height: '70px',
   }
   const buttonStyle = {
      marginTop: '10px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
      minWidth: '150px',
   }

   useTranslatedPageTitle(constants.staticPageTitle.cart)

   useEffect(() => {
      if (saleSession.data?.id === undefined && saleSession.request_status != 'in_flight') {
         dispatch(GetNewSaleSessionID(kioskSettings.location_id, terminal.id))
      }
   }, [saleSession.data?.id, saleSession.request_status])

   useEffect(() => {
      dispatch(getPosStatus(kioskSettings.location_id))
   }, [])

   // COUPLED HOOKS START
   useEffect(() => {
      if (posSettings.request_status === 'done') {
         if (posSettings.data?.status === 'offline') {
            fireErrorAlert('Sorry the network is currently offline, please try again later.').then((result) => {
               navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
            })
         }
         dispatch(updatePosCheckRequest('idle'))
      }
   }, [posSettings.request_status])

   useEffect(() => {
      if (cartItems.length == 0) {
         setCartItems(GetItems())
         setPayButtonDisabled(true)
      } else if (posSettings.request_status === 'in_flight') {
         setPayButtonDisabled(true)
      } else {
         setPayButtonDisabled(false)
      }
   }, [cartItems.length, posSettings.request_status])
   // COUPLED HOOKS END

   const changeQuantity = (index: number, change: number) => {
      const currentItems = [...cartItems]
      const currentItem = cartItems[index]
      let clonedItem = {...currentItem}
      if (clonedItem.quantity == 1 && change == -1) return
      clonedItem.quantity += change
      clonedItem.modifiers = clonedItem.modifiers.map((mod) => {
         let clonedMod = {...mod}
         let originalQty = clonedMod.quantity / currentItem.quantity
         clonedMod.quantity += originalQty * change
         return clonedMod
      })
      currentItems[index] = clonedItem
      UpdateQtyToItem(clonedItem)
      setCartItems(currentItems)
   }

   const removeItem = (index: number) => {
      const currentItem = cartItems[index]
      RemoveItem(currentItem)
      setCartItems((pre) => {
         return pre.filter((e) => e.id !== currentItem.id)
      })
   }

   const editItem = (index: number) => {
      const currentItem = cartItems[index]
      const state = {
         id: currentItem.id,
         menu_id: currentItem.category_id,
         quantity: currentItem.quantity,
         weakUuid: currentItem.weakUuid,
      }
      navigateTo(orderingPaths.ITEMS, {state: state})
   }

   const getFormattedSubTotal = (index: number) => {
      const item: ISelfOrderingCartItem = cartItems[index]
      const itemSubTotal = getItemSubTotal(item)
      return ((itemSubTotal ?? 0) / 100).toFixed(2)
   }

   const getDisplayValue = (cents?: number) => {
      return cents ? (cents / 100).toFixed(2) : '0.00'
   }

   useEffect(() => {
      if (isLoading) {
         fireLoadingAlert('Processing...')
      } else {
         Swal.close()
      }
   }, [isLoading])

   return (
      <>
         <div className='px-5 h-100' style={{marginBottom: 200}}>
            <div className='flex-row-auto '>
               <div className='card card-flush bg-body' id='kt_pos_form'>
                  <div className='card-body pt-0'>
                     <div className='table-responsive mb-8' style={{overflowX: 'auto'}}>
                        <table className='table align-middle gs-0 gy-4 my-0'>
                           <thead className=''>
                              <tr>
                                 <th className='min-w-30'>
                                    {' '}
                                    <span className='fs-3 text-gray-800 fw-bold'>ITEM</span>
                                 </th>
                                 <th className='w-10 text-left fs-3 text-gray-800 fw-bold'> QTY</th>
                                 <th className='min-w-30'>
                                    {' '}
                                    <span className='fs-3 text-gray-800 fw-bold'>NAME</span>
                                 </th>
                                 <th className='w-20 text-right fs-3 text-gray-800 fw-bold'>TOTAL PRICE</th>
                                 <th className='w-20 text-right fs-3 text-gray-800 fw-bold'>ACTION</th>
                              </tr>
                           </thead>
                           <tbody>
                              {/* start main product details */}
                              {cartItems?.length > 0 ? (
                                 <>
                                    {cartItems.map((item: ISelfOrderingCartItem, i) => (
                                       <tr
                                          className='border-top'
                                          data-kt-pos-element='item'
                                          data-kt-pos-item-price='33'
                                          key={i}
                                       >
                                          <td className='pe-0 '>
                                             <div className='d-flex align-items-center'>
                                                <MenuImage
                                                   src={item.image}
                                                   className='w-80px h-80px rounded-3 me-3'
                                                   alt=''
                                                />
                                             </div>
                                          </td>
                                          <td className='pe-0' style={{paddingRight: 0}}>
                                             <div
                                                className='position-relative d-flex align-items-center'
                                                data-kt-dialer='true'
                                                data-kt-dialer-min='1'
                                                data-kt-dialer-max='10'
                                                data-kt-dialer-step='1'
                                                data-kt-dialer-decimals='0'
                                             >
                                                <button
                                                   type='button'
                                                   onClick={() => changeQuantity(i, -1)}
                                                   className='btn btn-icon btn-sm btn-light w-80px h-80px'
                                                   data-kt-dialer-control='decrease'
                                                >
                                                   <span className='svg-icon-3x'>
                                                      <svg
                                                         width='24'
                                                         height='24'
                                                         viewBox='0 0 24 24'
                                                         fill='none'
                                                         xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                         <rect
                                                            x='6'
                                                            y='11'
                                                            width='12'
                                                            height='2'
                                                            rx='1'
                                                            fill='currentColor'
                                                         ></rect>
                                                      </svg>
                                                   </span>
                                                </button>

                                                <input
                                                   type='text'
                                                   className='form-control border-0 text-center px-0 fs-3 text-gray-800 w-80px h-80px'
                                                   data-kt-dialer-control='input'
                                                   readOnly={true}
                                                   placeholder='Amount'
                                                   name='manageBudget'
                                                   value={item.quantity}
                                                />

                                                <button
                                                   type='button'
                                                   onClick={() => changeQuantity(i, +1)}
                                                   className='btn btn-icon btn-sm btn-light w-80px h-80px'
                                                   data-kt-dialer-control='increase'
                                                >
                                                   <span className='svg-icon-3x'>
                                                      <svg
                                                         width='24'
                                                         height='24'
                                                         viewBox='0 0 24 24'
                                                         fill='none'
                                                         xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                         <rect
                                                            opacity='0.5'
                                                            x='11'
                                                            y='18'
                                                            width='12'
                                                            height='2'
                                                            rx='1'
                                                            transform='rotate(-90 11 18)'
                                                            fill='currentColor'
                                                         ></rect>
                                                         <rect
                                                            x='6'
                                                            y='11'
                                                            width='12'
                                                            height='2'
                                                            rx='1'
                                                            fill='currentColor'
                                                         ></rect>
                                                      </svg>
                                                   </span>
                                                </button>
                                             </div>
                                          </td>
                                          <td className='pe-0'>
                                             <div
                                                className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-2 me-1'
                                                style={{display: 'block'}}
                                             >
                                                {' '}
                                                {item.name}
                                             </div>
                                             <div>
                                                {item.modifiers.map((modifier: ISelfOrderingCartItemModifier, k) => (
                                                   <div key={k}>
                                                      {modifier.name} x {modifier.quantity / item.quantity}
                                                   </div>
                                                ))}
                                                {item.removed_included_modifiers.map(
                                                   (modifier: ISelfOrderingCartItemModifier, k) => (
                                                      <div key={k}>No {modifier.name}</div>
                                                   )
                                                )}
                                             </div>
                                          </td>

                                          <td className='text-end' style={{paddingRight: 0}}>
                                             <span className='fw-bold text-gray-800 fs-2'>
                                                ${getFormattedSubTotal(i)}
                                             </span>
                                          </td>

                                          <td className='text-right'>
                                             {item.modifiers.length !== 0 && (
                                                <div>
                                                   <button
                                                      type='button'
                                                      className={`btn btn-primary ${
                                                         BtnClrClass != '' ? BtnClrClass : 'text-white'
                                                      }`}
                                                      style={buttonStyle}
                                                      onClick={() => {
                                                         editItem(i)
                                                      }}
                                                   >
                                                      Edit
                                                   </button>
                                                </div>
                                             )}
                                             <div>
                                                <button
                                                   type='button'
                                                   className='btn btn-bg-secondary btn-danger'
                                                   style={buttonStyle}
                                                   onClick={() => {
                                                      removeItem(i)
                                                   }}
                                                >
                                                   Remove
                                                </button>
                                             </div>
                                          </td>
                                       </tr>
                                    ))}
                                 </>
                              ) : (
                                 <tr className='border-top' data-kt-pos-element='item' data-kt-pos-item-price='33'>
                                    <td className='pe-0 fs-2 text-center' colSpan={5}>
                                       No items added
                                    </td>
                                 </tr>
                              )}
                              {/* end main product details */}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className='fixed-bottom' style={{zIndex: '1'}}>
            <div className='px-10'>
               <div className='d-flex flex-stack bg-success rounded-3 p-6 mb-11 '>
                  <div className='fs-6 fw-bold text-white'>
                     <span className='d-block lh-1 mb-2 fs-2'>Subtotal</span>
                     {cartTotals.service_charges > 0 && <span className='d-block mb-2 fs-2'>Service Charges</span>}
                     <span className='d-block mb-2 fs-2'>Tax (Estimated)</span>
                     {kioskSettings.global_tips_types_id === 2 && <span className='d-block mb-2 fs-2'>Tip</span>}
                     <span className='d-block fs-2qx lh-1'>Total</span>
                  </div>
                  <div className='fs-6 fw-bold text-white text-end'>
                     <span className='d-block lh-1 mb-2 fs-2' data-kt-pos-element='total'>
                        ${getDisplayValue(cartTotals.sub_total)}
                     </span>
                     {cartTotals.service_charges > 0 && (
                        <span className='d-block mb-2 fs-2' data-kt-pos-element='tax'>
                           ${getDisplayValue(cartTotals.service_charges)}
                        </span>
                     )}
                     <span className='d-block mb-2 fs-2' data-kt-pos-element='tax'>
                        ${getDisplayValue(cartTotals.tax)}
                     </span>

                     {kioskSettings.global_tips_types_id === 2 && (
                        <span className='d-block mb-2 fs-2' data-kt-pos-element='tax'>
                           ${getDisplayValue(cartTotals.tip)}
                        </span>
                     )}

                     <span className='d-block fs-2qx lh-1' data-kt-pos-element='grant-total'>
                        ${getDisplayValue(cartTotals?.total)}
                     </span>
                  </div>
               </div>
            </div>
            <div
               className='container-fluid d-flex align-items-stretch justify-content-between bg-light border-top footer-bg-color'
               style={{height: '100px', zIndex: '2000'}}
            >
               <div className='col-5 my-auto'>
                  <button
                     type='button'
                     className='btn btn-bg-body me-2 btn-color-gray-500'
                     style={buttonStyleFooter}
                     onClick={() => {
                        navigateTo(orderingPaths.MENU)
                     }}
                  >
                     <i className='bi bi-arrow-return-left fs-2x svg-icon /*bi-arrow-repeat*/'></i>
                     Start Over
                  </button>
               </div>
               <div className='col-7 my-auto text-right d-flex justify-content-end '>
                  <button
                     disabled={PayButtonDisabled}
                     type='button'
                     data-bs-toggle='modal'
                     data-bs-target='#kt_modal_1'
                     className={`btn  btn-light btn-primary btn-lg me-5 btn-no-right-margin ${BtnClrClass}`}
                     onClick={handlePayButton}
                     style={buttonStyleFooter}
                  >
                     Pay
                  </button>
               </div>
            </div>
         </div>
         {showPaymentModal && (
            <PaymentModalController
               toggleModal={togglePaymentModal}
               orderForm={orderForm}
               setOrderForm={setOrderForm}
            />
         )}
      </>
   )
}
