import {PAPER_QUERY_RESULTS, PORT_EVENT_TYPES, PortEvent} from '../printing/printing_service'
import {TizenDebug} from '../../modules/hotel/FaceAuth/TizenIntegration'
import {setPrinterPaperStatus} from '../../services/TizenSystemSlice'
import {useDispatch} from 'react-redux'

export const usePrinterPaperCallback = () => {
   const dispatch = useDispatch()

   return (event: PortEvent) => {
      if (event.type == PORT_EVENT_TYPES.PAPER_CHECK) {
         TizenDebug('[checkPaperCallback] data: ' + event.data)
         switch (event.data) {
            case PAPER_QUERY_RESULTS.NEARLY_OUT:
               dispatch(setPrinterPaperStatus('LOW'))
               break
            case PAPER_QUERY_RESULTS.NO_ROLL:
               dispatch(setPrinterPaperStatus('OUT'))
               break
            case PAPER_QUERY_RESULTS.OUT_OF_PAPER:
               dispatch(setPrinterPaperStatus('OUT'))
               break
            default:
               dispatch(setPrinterPaperStatus('OK'))
         }
      }
   }
}
