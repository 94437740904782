import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';

export interface ContectlessData {
  flow: string|null
}

const initialState: ContectlessData = {
  flow: null,
}

export const FlowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setFlow: (state, action: PayloadAction<any>) => {
      state.flow = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFlow } = FlowSlice.actions

export default FlowSlice.reducer
