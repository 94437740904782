import {createRoot} from 'react-dom/client'
import axios from 'axios'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './global.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {setupAxios} from './app/modules/auth'
import {store} from './app/store'
import {Provider} from 'react-redux'
import {Debugger} from './app/common/tizen/Debugger'
import {inDebugEnv} from './app/common/utilities'
import {I18nProvider} from './app/modules/i18n/i18nProvider'
import {AxiosConfigProvider} from './app/common/AxiosConfigProvider'
import {KeyboardProvider} from './app/common/KeyboardProvider'

setupAxios(axios)

// const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
   createRoot(container).render(
      <Provider store={store}>
         <I18nProvider>
            <AxiosConfigProvider>
               <KeyboardProvider>
                  {inDebugEnv() && <Debugger />}
                  <AppRoutes />
               </KeyboardProvider>
            </AxiosConfigProvider>
         </I18nProvider>
      </Provider>
   )
}
