import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import Inputmask from 'inputmask'
import {StoreHeaderPageTitle} from '../../services/HeaderPageTitleSlice'
import {useDispatch, useSelector} from 'react-redux'
import {constants} from '../../config/constants'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getAuth} from '../auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {useTranslatedPageTitle} from '../../common/hooks/useTranslatedPageTitle'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
export const EmpLoginScreen = () => {
   let navigateTo = useNavigate()
   const kiosk_settings = useSelector((state: any) => state.kiosk_settings.value)
   const checkInPaths = MODULE_ROUTE_PATHS.checkIn
   const [EmpPin, setEmpPin] = useState('')
   const SetPinVal = (val: any) => {
      let cloneState = EmpPin
      if (val == 'X') {
         cloneState = cloneState.substr(0, cloneState.length - 1)
      } else {
         cloneState = cloneState + '' + val
      }
      setEmpPin(cloneState)
   }

   useTranslatedPageTitle(constants.staticPageTitle.emp_login)

   useEffect(() => {
      Inputmask({
         mask: '(999) 999-9999',
      }).mask('#EmpPin')
   }, [EmpPin])
   let logoPath =
      localStorage.getItem('kt_theme_mode_value') === 'light'
         ? '../media/logos/softPointLoginLogo.svg'
         : '../media/logos/softpoint_logo_app-white.svg'

   return (
      <div className='tab-content'>
         <div className='row  d-flex justify-content-center mt-10'>
            {kiosk_settings?._embedded?.location_info?.logo ? (
               <img
                  src={`${process.env.REACT_APP_API_URL}/download-file?path=${
                     kiosk_settings?._embedded?.location_info?.logo
                  }&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`}
                  className='rounded-3 mb-4 h-125px w-125px symbol w-xxl-125px h-xxl-125px'
                  onError={({currentTarget}) => {
                     //broken image
                     currentTarget.onerror = null // prevents looping
                     currentTarget.src = toAbsoluteUrl(logoPath)
                  }}
               />
            ) : (
               <img src={logoPath} className='rounded-3 mb-4 h-125px w-125px symbol w-xxl-125px h-xxl-125px' alt='' />
            )}
         </div>
         <div className='d-flex flex-row flex-column-fluid h-xl-100 pb-10 pt-5'>
            <div className='d-flex flex-row-fluid flex-center'>
               <div className='row w-400px theme-background py-3'>
                  <div className='col-xl-12 col-md-12 col-sm-12 flex-center mb-5'>
                     <input
                        id='EmpPin'
                        type='text'
                        style={{height: '85px', fontSize: '25px'}}
                        readOnly={true}
                        value={EmpPin}
                        placeholder='Enter Phone'
                        name='EmpPin'
                        autoComplete='off'
                        className='form-control bg-transparent text-center'
                     />
                  </div>
                  <div className='clear'></div>
                  <div className='w-33 fs-200 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(1)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        1
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(2)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        2
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(3)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        3
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(4)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        4
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(5)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        5
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(6)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        6
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(7)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        7
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(8)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        8
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(9)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        9
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal(0)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        0
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => SetPinVal('X')}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        X
                     </button>
                  </div>
                  <div className='col-xl-12 col-md-12 col-sm-12 text-center mt-5'>
                     <button
                        style={{height: '85px', fontSize: '25px'}}
                        onClick={() => {
                           navigateTo(checkInPaths.reservation_confirmation)
                        }}
                        className='btn btn-sm btn-light-primary w-100 fw-bold'
                     >
                        <FormattedMessage
                           id='checkin.reservation.emp_login.find_reservation'
                           defaultMessage='Find Reservation'
                        />
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
