import {ChangeEvent, Dispatch, useEffect} from 'react'
import {Field, useFormikContext} from 'formik'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useKeyboard} from '../../services/hooks/useKeyboard'

export type IKeyboardField = {
   id: string
   readonly name: string
   placeholder: string
   setActiveInput: any
}

export const KeyboardField = ({id, name, placeholder, setActiveInput}: IKeyboardField) => {
   const _keyboard = useSelector((state: RootState) => state.keyboard)
   const formik = useFormikContext()
   const KeyboardOps = useKeyboard()
   let fieldValue = KeyboardOps.getInputValue(name)

   useEffect(() => {
      if (name == 'phoneNumber') {
         const x: any = fieldValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
         fieldValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
         let val = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
         console.log('change event', val)
         formik.setFieldValue(name, val)
      } else {
         formik.setFieldValue(name, fieldValue)
      }
   }, [fieldValue])

   return (
      <Field
         key={name}
         id={id}
         readOnly={true}
         type='text'
         className={`form-control form-control-lg keyboard-input ${
            _keyboard.inputName == name && _keyboard.open ? 'keyboard-active' : ''
         }`}
         name={name}
         placeholder={placeholder}
         style={{height: '88px', fontSize: '2rem'}}
         value={KeyboardOps.getInputValue(name)}
         onFocus={() => setActiveInput(name, name == 'phoneNumber' ? 'phone' : 'default')}
         // onChange={onChangeInput}
         autoComplete='off'
      />
   )
}
