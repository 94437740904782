import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import type {RootState} from '../../store'
import Fuse from 'fuse.js'
import {MODULE_ROUTE_PATHS, RouteSegments} from '../../routing/RoutePaths'
import {useCart} from '../../services/hooks/useCart'
import {setActiveMenuCategory} from '../../services/SelfOrderingUserSlice'
import {useMenuSearch} from './menu_components/MenuSearchProvider'
import {MenuItem} from './menu_components/MenuItem'
import {CategoryItem, MenuCategory} from '../../common/schemas/SyncDataSchema'
import {getDisplayName} from '../../common/utilities'
import {Waypoint} from 'react-waypoint'
import {useMenuView} from './menu_components/MenuViewProvider'
import moment from 'moment'
import {useErrorAlert} from '../utilities/alerts'

export const MenuItems = () => {
   const dispatch = useDispatch()
   const {AddItem, GetItems, UpdateQtyToItem} = useCart()
   const {setActiveCategory, activeCategory} = useMenuView()
   let navigateTo = useNavigate()
   const selfOrder = useSelector((state: RootState) => state.location_menu_data.values)
   const {queryValue, updateFilteredCatIds, updateQuery} = useMenuSearch()
   const [menuCategories, setMenuCategories] = useState<MenuCategory[] | null>(null)
   const baseClasses = ''
   const orderingPaths = MODULE_ROUTE_PATHS.ordering
   const fireErrorAlert = useErrorAlert()

   const checkInTime = (startTime: String | null, endTime: String | null) => {
      if (!startTime || !endTime || startTime == endTime) return true
      function timeToMinutes(time: String) {
         const [hours, minutes] = time.split(':').map(Number)
         return hours * 60 + minutes
      }
      const currentTime = moment.utc().format('HH:mm')
      const minutesStartTime = timeToMinutes(startTime)
      const minutesEndTime = timeToMinutes(endTime)
      const minutesCurrentTime = timeToMinutes(currentTime)
      if (minutesStartTime > minutesEndTime) {
         return minutesCurrentTime >= minutesStartTime || minutesCurrentTime <= minutesEndTime
      } else {
         return minutesCurrentTime >= minutesStartTime && minutesCurrentTime <= minutesEndTime
      }
   }

   let categories = selfOrder.Data_Categories
   const dayName = moment().format('dddd')
   categories = categories.filter((menu) => {
      return (menu.available_dow && menu.available_dow.indexOf(dayName) > -1) || Object.is(menu.available_dow, null)
   })

   categories = categories.filter((menu) => {
      return checkInTime(menu.start_time, menu.end_time)
   })

   const isValidSearchString = () => {
      const trimmedQuery = queryValue.trim()
      return trimmedQuery != '' && trimmedQuery.length > 2
   }

   // SETS MENU ITEMS
   useEffect(() => {
      if (isValidSearchString()) {
         const options = {
            keys: ['name', 'name_info'],
            includeScore: false,
            threshold: 0.3,
            location: 0,
            ignoreLocation: true,
            findAllMatches: true,
         }

         let newCategories: MenuCategory[] = []
         const uniqueIds = new Set<number>()
         categories.map((cat, i) => {
            let cloneCat = {
               id: cat.id,
               id_external: cat.id_external,
               name: cat.name,
               background_color: cat.background_color,
               start_time: cat.start_time,
               end_time: cat.end_time,
               available_dow: cat.available_dow,
               image: cat.image,
               _embedded: {
                  items: cat._embedded.items,
               },
            }

            const fuse = new Fuse(cat._embedded.items, options)
            const results = fuse.search(queryValue.trim())
            if (results.length) {
               let items: CategoryItem[] = []
               results.map((item) => {
                  items = [...items, item.item]
               })
               cloneCat._embedded.items = items
               newCategories = [...newCategories, cloneCat]
               setMenuCategories(newCategories)
               uniqueIds.add(cloneCat.id)
            } else {
               newCategories = [...newCategories]
               setMenuCategories(newCategories)
            }
         })
         updateFilteredCatIds(Array.from(uniqueIds))
      } else {
         setMenuCategories(categories)
         const uniqueIds = new Set<number>()
         categories.forEach((cat) => uniqueIds.add(cat.id))
         updateFilteredCatIds(Array.from(uniqueIds))
      }
   }, [queryValue])

   const onSelect = (itemId: Number, categoryId: Number) => {
      if (!menuCategories) return
      const categories = selfOrder.Data_Categories
      const category = categories.find((cat) => cat.id === categoryId)
      if (!category) return

      const categoryItems = category._embedded.items
      const selectedItem = categoryItems.find((item) => item.id == itemId)
      if (!selectedItem) return

      if (selectedItem._embedded?.mod_groups?.length == 0) {
         let extraData = {
            category_id: category.id,
            modifiers: [],
            removed_included_modifiers: [],
         }
         let existingCartItem = GetItems().find((item) => item.id == itemId)
         if (existingCartItem) {
            let newItem = {...existingCartItem}
            newItem.quantity += 1
            UpdateQtyToItem(newItem)
         } else {
            if (checkInTime(category.start_time, category.end_time)) {
               AddItem(selectedItem, 1, extraData)
            } else {
               fireErrorAlert('This item currently not available')
            }
         }

         navigateTo(orderingPaths.MENU)
      } else {
         dispatch(setActiveMenuCategory(category.id))
         const state = {id: selectedItem.id, menu_id: category.id, quantity: 1}
         navigateTo(orderingPaths.ITEMS, {state})
      }
   }

   return (
      <>
         {menuCategories !== null && (
            <div className={`${baseClasses} col-10`} style={{overflow: 'hidden'}}>
               <div
                  id='ItemScrollBar'
                  className='ps-2 hide-scrollbar'
                  style={{
                     marginBottom: '0px',
                     maxHeight: '82vh',
                     overflowY: 'scroll',
                     width: '100%',
                     scrollBehavior: 'smooth',
                  }}
               >
                  {menuCategories.map((cat, i) => {
                     const height = Math.ceil(cat._embedded.items.length / 3) * 321.5
                     if (cat._embedded.items.length == 0) return null
                     return (
                        <Waypoint
                           fireOnRapidScroll={false}
                           key={`inCat_${cat.id}`}
                           onEnter={(waypointProps) => {
                              console.log('fire')
                              setActiveCategory({
                                 id: cat.id,
                                 name: cat.name ?? '',
                              })
                           }}
                           bottomOffset='98%'
                        >
                           <div
                              key={`inCatDiv_${cat.id}`}
                              style={menuCategories.length - 1 == i ? {marginBottom: `calc(82vh - ${height}px )`} : {}}
                              id={`category_${cat.id}`}
                           >
                              {i > 0 && <hr />}

                              <div
                                 key={`category_${cat.id}`}
                                 className={`section-start row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-6 g-5 justify-content-left`}
                              >
                                 {cat._embedded.items.map((item, ii) => {
                                    return (
                                       <div key={`item_${item.id}`} className='col'>
                                          <MenuItem
                                             key={item.id}
                                             id={item.id}
                                             display_name={getDisplayName(item)}
                                             image={item.image ?? ''}
                                             price_per_unit={item.price_per_unit ?? 0}
                                             onSelect={onSelect}
                                             categoryId={cat.id ?? 0}
                                          ></MenuItem>
                                       </div>
                                    )
                                 })}
                              </div>
                           </div>
                        </Waypoint>
                     )
                  })}
               </div>
            </div>
         )}

         {isValidSearchString() && menuCategories?.length == 0 && (
            <div className='row py-10 text-center'>
               <div className='fs-2x text-danger'>No item found</div>
            </div>
         )}
      </>
   )
}
