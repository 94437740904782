import {useEffect, useState} from 'react'
import {getAuth} from '../../modules/auth'

interface UseCacheVideoHookProps {
   videoUrl: string | null
}

const cacheName = 'MenuVideoCache'

export function useCacheVideo(props: UseCacheVideoHookProps) {
   const [cachedVideo, setCachedVideo] = useState<string | undefined>(undefined)

   useEffect(() => {
      async function fetchVideo() {
         if (props.videoUrl && props.videoUrl.length > 0) {
            try {
               const videoUrl = `${process.env.REACT_APP_API_URL}/download-file?path=${
                  props.videoUrl
               }&cache=true&global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${getAuth()?.user_id}`

               const cache = await caches.open(cacheName)
               const cachedResponse = await cache.match(videoUrl)

               if (cachedResponse) {
                  const blob = await cachedResponse.blob()
                  const objectUrl = URL.createObjectURL(blob)
                  setCachedVideo(objectUrl)
               } else {
                  const response = await fetch(videoUrl)
                  await cache.put(videoUrl, response.clone())
                  const blob = await response.blob()
                  const objectUrl = URL.createObjectURL(blob)
                  setCachedVideo(objectUrl)
               }
            } catch (error) {}
         }
      }

      fetchVideo()
   }, [props.videoUrl])

   return cachedVideo
}
