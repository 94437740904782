import {createContext, MutableRefObject, ReactNode, useContext, useRef} from 'react'
import {KeyboardReactInterface} from 'react-simple-keyboard'
// MutableRefObject must have type any react-simple-keyboard does not provide type
type VirtualKeyboard = {keyboardRef: MutableRefObject<KeyboardReactInterface | null>}
const KeyboardContext = createContext<VirtualKeyboard | null>(null)
export const KeyboardProvider = ({children}: {children: ReactNode}) => {
   const keyboardRef = useRef<any | null>(null)
   return <KeyboardContext.Provider value={{keyboardRef: keyboardRef}}>{children}</KeyboardContext.Provider>
}
export const useKeyboardRef = () => {
   const context = useContext(KeyboardContext)
   if (!context) {
      throw new Error('[useKeyboardRef] must be used within [KeyboardProvider]')
   }
   return context
}
