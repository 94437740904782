import {useLocation, useNavigate} from 'react-router-dom'
import {SUPPORTED_VALUES} from './AxisIntegration'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useErrorAlert, useReverseConfirmAlert, useSuccessAlert} from '../../utilities/alerts'
import {getAxisSystemStatus} from './_requests/Axis'
import {buttonStylesFull} from './components/styles'
import {CamFeed, ICamFeed} from './components/CamFeed'
import {CamFeedPlaceholder} from './components/CamFeedPlaceholder'
import React, {SyntheticEvent, useEffect, useRef, useState} from 'react'
import {captureNSend, TizenDebug} from './TizenIntegration'
import {IApiError, instanceOfError, IUploadFile} from '../../../common/models/SoftPointAPIs'
import {BiometricAuthRequest, BiometricTimeoutError, loginBySelfie, postBiometricLog} from './_requests/Biometrics'
import './components/CameraPageStyles.scss'
import {CamFeedOverlay} from './components/CamFeedOverlay'
import Swal from 'sweetalert2'
import axios from 'axios'
import {GetApiUrl, getAuth} from '../../auth'
import {constants, KIOSK_GLOBAL_ON, MOBILE_URL} from '../../../config/constants'
import {INextReservationResponse} from '../_requests/reservations'
import QRCode from 'react-qr-code'
import {renderToString} from 'react-dom/server'
import {setBiometricsSyncId, setGuestReservation} from '../../../services/GuestReservationSlice'
import {EnterPhoneNumberModal} from './components/EnterPhoneNumberModal'
import {clientIsTizen} from '../../../common/tizen/helpers'
import {setFaceRecognitionStatus} from '../../../services/FacialRecognitionService'
import {useOverlayContext} from './OverlayProvider'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {CHECK_IN_TYPES, CHECK_INS} from '../types'
import {generateAdmissionReceipt} from '../../../common/printing/receipt_generators'
import {PrinterService} from '../../../common/printing/printing_service'
import {FormattedMessage, useIntl} from 'react-intl'
import {useInternationalization} from '../../i18n/i18nProvider'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {HorizontalRuler} from '../../../common/components/HorizontalRuler'
import {TransparentButton} from '../../../common/components/TransparentButton'
import {BiometricDataType, BiometricLogResult} from '../../../common/models/biometrics'

interface IAuthCandidate {
   similarity_measure: number
   match_probability: number
   identity: string
   UUID: string
}

interface IMultiMatchError {
   candidates: IAuthCandidate[]
   image: string
   threshold: number
   sync_id: string
}

export const FacialAuthScreen = () => {
   const intl = useIntl()
   const appDispatch = useDispatch()
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const fireError = useErrorAlert()
   const fireSuccessAlert = useSuccessAlert()
   const fireConfirmAlert = useReverseConfirmAlert()
   const navigateTo = useNavigate()
   const terminal = useSelector((state: RootState) => state.selected_terminal.value) as Terminal
   const locationData = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const {setTimerCallback, toggleTimer, timerIsOn} = useOverlayContext()
   const location = useLocation()
   const {selectedLang} = useInternationalization()
   const camIPAddress = terminal.local_echo_ip
   const onKioskDevice = clientIsTizen()
   const selfieBtnRef = useRef<null | HTMLButtonElement>(null)
   const userSession = getAuth()
   const checkInPaths = MODULE_ROUTE_PATHS.checkIn
   const biometricErrors = constants.errors.biometrics
   const staticErrors = constants.errors.static
   const myOptions: ICamFeed = {
      uri_options: {
         camera: 1,
         resolution: SUPPORTED_VALUES.resolutions[6],
         rotation: SUPPORTED_VALUES.rotations.two_seventy,
      },
      ip: camIPAddress ?? '',
   }
   const APPEARANCE_STATUS = constants.status.location.appearance
   let checkInType: CHECK_IN_TYPES = 'age_gate'
   const routeState = location.state as any
   if ('checkin_type' in routeState) {
      checkInType = routeState.checkin_type
   }
   const textColor = kioskLocSettings.appearance === 1 ? 'text-black' : 'text-white'

   const [camOnline, setCamStatus] = useState(false)
   const [cameraPlaceholderText, setCameraPlaceholderText] = useState(
      intl.formatMessage({id: 'checkin.faceauth.loading_feed'})
   )
   const [multiMatchError, setMultiMatchError] = useState<IMultiMatchError | null>(null)
   const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false)

   useEffect(() => {
      if (kioskLocSettings.quick_selfie == 1) {
         setTimeout(() => {
            toggleTimer(true)
         }, 100)
      }
   }, [])

   useEffect(() => {
      console.log('camIPAddress useEffect')
      if (camIPAddress) {
         getAxisSystemStatus(camIPAddress)
            .then((response) => {
               setCamStatus(true)
               // if(response.ok){
               //     setCamStatus(true)
               // }
            })
            .catch((err) => {
               // console.log('cam error')
               setCameraPlaceholderText(intl.formatMessage({id: 'checkin.faceauth.no_feed_available'}))
            })
      }
   }, [camIPAddress])

   const togglePhoneModal = () => {
      setShowPhoneModal(!showPhoneModal)
   }

   const showGuestReservation = (guest_id: number | string, biometrics_log_sync_id: string | undefined = undefined) => {
      Swal.fire({
         icon: 'warning',
         html: `<h1 class='swal2-html-container fs-2x mx-0'>${intl.formatMessage({
            id: 'checkin.faceauth.retrieving_your_reservations',
         })}</h1>`,
         didOpen(popup: HTMLElement) {
            Swal.showLoading()
         },
         // allowOutsideClick: () => !Swal.isLoading()
      })

      const payload = {
         user_id: userSession?.user_id,
         global_on_id: KIOSK_GLOBAL_ON,
         with: 'guests,room,room_type,revenue_center',
      }
      TizenDebug(`${GetApiUrl()}/locations/${locationData.location_id}/guests/${guest_id}/next_reservation`)
      axios
         .get(`${GetApiUrl()}/locations/${locationData.location_id}/guests/${guest_id}/next_reservation`, {
            params: payload,
         })
         .then((reservationRes) => {
            Swal.close()
            const nextReservation = reservationRes.data as INextReservationResponse
            appDispatch(
               setGuestReservation({
                  reservation: nextReservation,
                  guestID: typeof guest_id === 'string' ? parseInt(guest_id) : guest_id,
               })
            )

            if (biometrics_log_sync_id !== undefined && biometrics_log_sync_id.trim() !== '') {
               appDispatch(setBiometricsSyncId(biometrics_log_sync_id))
            }

            const reservationGuests = nextReservation._embedded?.guests ?? null
            if (reservationGuests == null) {
               TizenDebug("Reservation's guests was null")
            }

            const reservationRoomType = nextReservation._embedded?.room_type ?? null
            if (reservationRoomType == null) {
               TizenDebug("Reservation's room type was null")
            }

            const reservationRevenueCenter = nextReservation._embedded?.revenue_center
            if (reservationRevenueCenter == null) {
               TizenDebug("Reservation's room type was null")
            }

            navigateTo(checkInPaths.reservation_confirmation)
         })
         .catch((error) => {
            const reservation_pending_precheckin_errors = [constants.errors.reservations.guest_without_pre_check_in]

            Swal.close()
            TizenDebug('Error with GET next reservation')
            const spError = error.response.data as IApiError | any
            let message = staticErrors.unexpected_contact_support
            if (instanceOfError(spError)) {
               message = spError.error.message
            }
            fireError(message).then((result) => {
               if (result.isConfirmed || result.isDismissed) {
                  navigateTo(checkInPaths.check_in_home)
               }
            })
         })
   }

   const handleSelfieUploadResponse = (event: SyntheticEvent) => {
      const hiddenInput = event.target as HTMLInputElement
      TizenDebug(`${hiddenInput.value}`)

      if (hiddenInput) {
         const fileUploadResponse: IUploadFile | IApiError = JSON.parse(hiddenInput.value)
         if (instanceOfError(fileUploadResponse)) {
            //IF there is an error it will most likely be a misuse of the upload API or a server side issue.
            fireError(intl.formatMessage({id: 'checkin.faceauth.alert.error_processing_selfie'})).then()
         } else {
            let payload: BiometricAuthRequest = {
               location_id: locationData.location_id,
               selfie_file_name: fileUploadResponse.file_name,
               terminal_id: terminal.id,
               i18n: selectedLang,
            }
            if (checkInType == CHECK_INS.AGE_GATE) payload.flow = CHECK_INS.AGE_GATE
            loginBySelfie(payload)
               .then((response: any) => {
                  Swal.close()
                  TizenDebug(`Login Response: ${response.ok}`)
                  return response.json()
               })
               .then((guestRes) => {
                  guestRes = guestRes as IApiError | any
                  if (instanceOfError(guestRes)) {
                     TizenDebug(`Biometric Auth Error Code: ${guestRes.error.code}`)
                     const message = guestRes.error.message
                     const terminalErrors = [biometricErrors.guest_biometrics_out_of_sync]
                     const errorCodesToAddQRRegister = [
                        biometricErrors.guest_registration_not_found,
                        biometricErrors.missing_biometric_registration_step,
                        biometricErrors.missing_id_verification_registration_step,
                        biometricErrors.missing_cof_registration_step,
                     ]
                     const redirectToQRAuthCodes = [
                        biometricErrors.false_positive_threshold_guard,
                        biometricErrors.no_face_match_redirect_qr,
                     ]
                     if (terminalErrors.includes(guestRes.error.code)) {
                        fireError(message).then((result) => {
                           if (result.isConfirmed || result.isDismissed) {
                              navigateTo(checkInPaths.check_in_home)
                           }
                        })
                     } else if (errorCodesToAddQRRegister.includes(guestRes.error.code)) {
                        const alertTextColor =
                           kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
                        let customHtmlMessage = `<h1 class='fs-2x ${alertTextColor}'>${message}</h1>`

                        customHtmlMessage += generateRegisterQR()

                        fireError(customHtmlMessage).then((result) => {
                           if (result.isConfirmed || result.isDismissed) {
                              navigateTo(checkInPaths.check_in_home)
                           }
                        })
                     } else if (guestRes?.error?.code == biometricErrors.biometrics_many_guests_found) {
                        let error_details: any = guestRes.error.details
                        setMultiMatchError({
                           candidates: error_details.biometrics_identity_lists,
                           image: error_details.image,
                           threshold: error_details.threshold,
                           sync_id: error_details.sync_id,
                        })
                        setShowPhoneModal(true)
                     } else if (guestRes?.error?.code == biometricErrors.biometrics_offline_error) {
                        const biometricsOfflineMessage = constants.errors.static.biometric_auth_timeout
                        fireError(biometricsOfflineMessage)
                        appDispatch(setFaceRecognitionStatus(false))
                        navigateTo(checkInPaths.qr_auth, {state: {checkin_type: checkInType}})
                     } else if (guestRes?.error.code == biometricErrors.underage) {
                        const minAge = kioskLocSettings._embedded.locations_config_contactless.registration_minimum_age
                        let formattedMessage = guestRes.error.message
                        if (minAge) {
                           formattedMessage = guestRes.error.message.replace('{}', minAge.toString())
                        }
                        fireError(formattedMessage).then((result) => {
                           navigateTo(checkInPaths.check_in_home)
                        })
                     } else if (redirectToQRAuthCodes.includes(guestRes.error.code)) {
                        let logIDMessage = ''
                        if (guestRes.error.details && 'biometric_log_id' in guestRes.error.details) {
                           logIDMessage = `Error: ${guestRes.error.details.biometric_log_id}`
                        }
                        fireConfirmAlert(
                           message,
                           intl.formatMessage({id: 'alert.try_again'}),
                           intl.formatMessage({id: 'checkin.faceauth.alert.try_qr_code_auth'}),
                           logIDMessage
                        ).then((result) => {
                           if (result.isConfirmed) {
                              navigateTo(checkInPaths.qr_auth, {state: {checkin_type: checkInType}})
                           }
                        })
                     } else {
                        let logIDMessage = ''
                        if (guestRes.error.details && 'biometric_log_id' in guestRes.error.details) {
                           logIDMessage = `Error: ${guestRes.error.details.biometric_log_id}`
                        }
                        fireConfirmAlert(
                           message,
                           intl.formatMessage({id: 'alert.try_again'}),
                           intl.formatMessage({id: 'checkin.faceauth.alert.try_qr_code_auth'}),
                           logIDMessage
                        ).then((result) => {
                           TizenDebug(`${JSON.stringify(guestRes.error)}`)
                           if (result.isConfirmed) {
                              navigateTo(checkInPaths.qr_auth, {state: {checkin_type: checkInType}})
                           }
                        })
                     }
                  } else {
                     if (checkInType == CHECK_INS.AGE_GATE) {
                        const guestName = `${guestRes.first_name} ${guestRes.last_name}`
                        if (kioskLocSettings.print_confirmation == constants.status.YES && locationData.name) {
                           const receiptData = generateAdmissionReceipt(locationData.name, guestName, selectedLang)
                           const printerManager: PrinterService = new PrinterService()
                           printerManager.openSerialPort()
                           printerManager.print(receiptData)
                        }
                        fireSuccessAlert(
                           `${guestName}.<br/> ${intl.formatMessage({id: 'common.welcome_to'})} ${locationData.name}!`
                        ).then((result) => {
                           if (result.isConfirmed || result.isDismissed) {
                              navigateTo(checkInPaths.check_in_home)
                           }
                        })
                     } else {
                        showGuestReservation(guestRes.id, guestRes?.biometrics_log_sync_id)
                     }
                  }
               })
               .catch((error) => {
                  Swal.close()
                  let errorMessage = constants.errors.static.unexpected_contact_support
                  if (error instanceof BiometricTimeoutError) {
                     errorMessage = constants.errors.static.biometric_auth_timeout
                  }
                  fireError(errorMessage).then((result) => {
                     appDispatch(setFaceRecognitionStatus(false))
                     navigateTo(checkInPaths.qr_auth, {state: {checkin_type: checkInType}})
                  })
               })
         }
      }
   }

   const generateRegisterQR = () => {
      let mobile_register_url = `${MOBILE_URL}${locationData.location_id}/register`
      return renderToString(
         <div
            className='rounded-3 mb-4 w-250px h-250px w-xxl-250px h-xxl-250px'
            style={{backgroundColor: '#ecedf2', display: 'inline-block', padding: '20px'}}
         >
            <QRCode
               size={210}
               bgColor={'#ecedf2'}
               style={{height: 'auto', maxWidth: '100%', width: '100%'}}
               fgColor={'#000'}
               value={mobile_register_url}
            />
         </div>
      )
   }

   const showNonRegisteredFEAlert = () => {
      const alertTextColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
      let customHtmlMessage = `<h1 class='fs-2x ${alertTextColor}'>${intl.formatMessage({
         id: 'checkin.faceauth.alert.we_could_not_find_your_profile',
      })}.</h1>`

      customHtmlMessage += generateRegisterQR()

      fireError(customHtmlMessage).then((result) => {
         if (result.isConfirmed || result.isDismissed) {
            navigateTo(checkInPaths.check_in_home)
         }
      })
   }

   /**
    * The method uses /biometric_logs endpoint to convert a failed biometric authentication to a phone fallback authentication
    * @param biometrics_guest_found
    * @param guest_id
    */
   const updateBiometricLog = (biometrics_guest_found: any, guest_id: number) => {
      const url = `${GetApiUrl()}/biometric_logs?global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${
         userSession?.user_id
      }`

      const data: any = {
         token: userSession?.api_token,
         url: url,
      }

      const payload = {
         location_id: locationData.location_id,
         global_biometric_service_type: constants.global_biometrics.service_type.phone_callback.type,
         result: 1,
         sync_id: multiMatchError?.sync_id,
         score: biometrics_guest_found.similarity_measure,
         guest_id: guest_id,
      }
      // TizenDebug(`[updateBiometricLog] ${JSON.stringify(payload)}`)

      axios
         .put(`${data.url}`, payload, {
            headers: {
               Authorization: `Basic ${data.token}`,
            },
         })
         .then((response) => {})
         .catch((error) => {
            // const errorResponse = JSON.stringify(error.response.data)
            // TizenDebug(errorResponse)
         })
   }

   const submitPhoneNumberHandle = (phone_number: string) => {
      if (phone_number.trim() == '' || phone_number.length < 10) {
         setShowPhoneModal(false)
         fireError(intl.formatMessage({id: 'checkin.faceauth.alert.please_enter_valid_phone'})).then((res) => {
            if (res.isDismissed || res.isConfirmed) {
               setShowPhoneModal(true)
            }
         })
      } else {
         const url = `${GetApiUrl()}/locations/${locationData.location_id}/guests?global_on_id=${
            process.env.REACT_APP_KIOSK_GLOBAL_ON
         }&user_id=${userSession?.user_id}&phone=${phone_number}`

         const data: any = {
            token: userSession?.api_token,
            url: url,
         }

         axios
            .get(`${data.url}`, {
               headers: {
                  Authorization: `Basic ${data.token}`,
               },
            })
            .then((response) => {
               setShowPhoneModal(false)
               const matched_guest_id = Number(response?.data?.id)
               if (multiMatchError?.candidates && matched_guest_id !== 0 && !isNaN(matched_guest_id)) {
                  if (multiMatchError!.candidates.length > 0) {
                     let guest_found = multiMatchError.candidates.find(
                        (candidate) => candidate.identity == response?.data?.id
                     )
                     if (guest_found == undefined) {
                        showNonRegisteredFEAlert()
                     } else {
                        postBiometricLog({
                           global_on_id: KIOSK_GLOBAL_ON,
                           location_id: locationData.location_id,
                           result: BiometricLogResult.Passed,
                           score: guest_found.similarity_measure,
                           sync_id: multiMatchError?.sync_id,
                           global_biometric_service_type: 'PHONE_FALLBACK',
                           global_biometric_data_type: BiometricDataType.Face,
                           guest_id: matched_guest_id,
                        })
                           .then()
                           .catch()
                        if (checkInType == CHECK_INS.RESERVATION) {
                           showGuestReservation(response?.data?.id)
                        } else {
                           fireSuccessAlert(
                              `${intl.formatMessage({
                                 id: 'checkin.faceauth.alert.you_are_all_set',
                              })}.<br/> ${intl.formatMessage({id: 'common.welcome_to'})} ${locationData.name}!`
                           ).then((result) => {
                              if (result.isConfirmed || result.isDismissed) {
                                 navigateTo(checkInPaths.check_in_home)
                              }
                           })
                        }
                     }
                  } else {
                     showNonRegisteredFEAlert()
                  }
               } else {
                  TizenDebug('[submitPhoneNumberHandle] multiMatchError was null')
               }
            })
            .catch((error) => {
               setShowPhoneModal(false)
               showNonRegisteredFEAlert()
            })
      }
   }

   const submitSelfie = () => {
      if (!camOnline || camIPAddress === null) {
         fireError(intl.formatMessage({id: 'checkin.faceauth.alert.camera_not_properly_set'}))
         return
      }
      if (onKioskDevice) {
         Swal.fire({
            icon: 'warning',
            html: `<h1 class='swal2-html-container fs-2x mx-0'>${intl.formatMessage({
               id: 'checkin.faceauth.alert.wait_while_we_identify_you',
            })}.</h1>`,
            didOpen(popup: HTMLElement) {
               Swal.showLoading()
            },
            // allowOutsideClick: () => !Swal.isLoading()
         })
         captureNSend(camIPAddress, 'uploadedSelfieFile', userSession?.user_id)
      } else {
         fireError(intl.formatMessage({id: 'checkin.faceauth.alert.feature_only_available_on_samsung_kiosk'}))

         //UNCOMMENT TO USE FAKE DATA ON DESKTOP
         // localStorage.setItem('reservation_data', JSON.stringify(fakeReservation))
         // localStorage.setItem('reservation_guest_data', JSON.stringify([fakeGuest]))
         // localStorage.setItem('reservation_room_type_data', JSON.stringify(fakeRoomType))
         // localStorage.setItem('reservation_errors', JSON.stringify(fakeReservationErrors))
         // navigateTo('/checkin/reservation-confirmation')
      }
   }

   setTimerCallback(submitSelfie)

   return (
      <>
         {showPhoneModal && (
            <EnterPhoneNumberModal toggleSettingModal={togglePhoneModal} onSubmit={submitPhoneNumberHandle} />
         )}

         <div
            id='CameraPage'
            className='d-flex flex-column justify-content-center align-items-center'
            style={{height: '95%'}}
         >
            <div id='feed' className='w-100 h-100'>
               {camOnline && <CamFeedOverlay />}
               {camOnline ? (
                  <CamFeed uri_options={myOptions.uri_options} ip={myOptions.ip} />
               ) : (
                  <CamFeedPlaceholder message={cameraPlaceholderText} />
               )}
            </div>

            <div className='pt-4 w-100 h-150px text-center' style={{height: '8%'}}>
               <button
                  id='selfie_btn'
                  ref={selfieBtnRef}
                  className={`btn btn-lg ${textColor}`}
                  style={buttonStylesFull}
                  onClick={() => toggleTimer(true)}
               >
                  <FormattedMessage id='checkin.faceauth.take_selfie' defaultMessage='Take Selfie' />
               </button>
            </div>
            <div className={'pt-5'} style={{width: '95%'}}>
               <HorizontalRuler prompt={'or'} />
            </div>
            <div className='pt-5 h-150px text-center' style={{height: '8%', width: '95%'}}>
               <TransparentButton
                  onClick={() => {
                     navigateTo(checkInPaths.qr_auth, {state: {checkin_type: checkInType}})
                  }}
                  title={intl.formatMessage({id: 'checkin.faceauth.skip_to_qr'})}
                  disabled={timerIsOn}
               />
            </div>
            <input
               id='uploadedSelfieFile'
               onInput={handleSelfieUploadResponse}
               style={{display: 'none'}}
               type='text'
               value='default'
            />
         </div>
      </>
   )
}
