import {FC, useEffect} from 'react'
import {ExtendedCategoryItem} from '../CheckoutDetailsView'
import {MenuItemPlaceholderDark, MenuItemPlaceholderLight} from '../../../common/images'
import {useCacheImg} from '../../../common/hooks/useCacheImg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { constants } from '../../../config/constants'
import {getDisplayName} from '../../../common/utilities'
import ContentLoader from 'react-content-loader'

interface ProductCheckoutSliderProps {
   itemInfo: ExtendedCategoryItem
}

export const CheckoutTop: React.FC<ProductCheckoutSliderProps> = ({itemInfo}) => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const APPEARANCE_STATUS = constants.status.location.appearance

   const {cachedImage, isLoading} = useCacheImg({imageUrl: itemInfo?.image, placeholderUrl: kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ?  MenuItemPlaceholderLight : MenuItemPlaceholderDark});
      
   return (
      <>
         <div className='w-100 g-5 g-xl-10 mb-5'>
            <div className='card card-flush h-xl-100'>
               <div className='card-body py-9'>
                  <div className='row gx-9 h-100'>
                     <div className='col-sm-6 mb-10 mb-sm-0'>
                        {isLoading && (
                           <ContentLoader
                             animateBegin="0.1s"                
                             height={400}
                             backgroundColor="#f5f5f5"
                             foregroundColor="#dbdbdb"
                             style={{ width: '98%', borderRadius: "10px" }}
                             foregroundOpacity={0.5}
                         >
                             <rect x="0" y="0" rx="0" ry="0" width="470" height="400" />
                           </ContentLoader>       
                        )}
                        {!isLoading && (
                           <div
                              className={`overlay-wrapper bgi-no-repeat bgi-position-center ${
                                 itemInfo?.image ? 'bgi-size-cover' : 'bgi-size-contain'
                              }  card-rounded min-h-md-400px min-h-200px h-100`}
                              style={{
                                 backgroundImage: `url('${cachedImage}')`,
                              }}
                           ></div>
                        )}
                     </div>
                     <div className='col-sm-6'>
                        <div className='d-flex flex-column h-100'>
                           <div className='mb-5'>
                              <div className=''>
                                 <h1 className='me-2 d-block lh-1 fs-2tx pb-2'>{getDisplayName(itemInfo)}</h1>
                              </div>
                           </div>
                           <h2 className='fs-2x text-gray-800'>{itemInfo.description ?? ''} </h2>
                           <div className='d-flex flex-stack mt-auto bd-highlight align-items-center'>
                              <h1 className='m-0 pt-9 fs-2tx'>
                                 ${itemInfo ? ((itemInfo.price_per_unit ?? 0) / 100).toFixed(2) : 0.0}
                              </h1>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
