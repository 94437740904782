import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {StoreHeaderPageTitle} from '../../services/HeaderPageTitleSlice'
import {useIntl} from 'react-intl'
import {useInternationalization} from '../../modules/i18n/i18nProvider'
import {PageTitles} from '../../config/constants'

export const useTranslatedPageTitle = (pageTitle: PageTitles) => {
   const {selectedLang} = useInternationalization()
   const dispatch = useDispatch()
   const intl = useIntl()
   useEffect(() => {
      dispatch(StoreHeaderPageTitle(intl.formatMessage({id: pageTitle})))
   }, [selectedLang])
}
