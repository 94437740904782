import {ReactNode} from 'react'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../../store'
import {useMenuView} from './MenuViewProvider'
import {setActiveMenuCategory} from '../../../services/SelfOrderingUserSlice'
import {MenuImage} from './MenuImage'

interface MenuCategoryProps {
   id: number
   name: string
   itemCount: number
   children?: ReactNode
   image?: string
   disabled?: boolean
}
const SetScrollPosition = (id: number) => {
   let ItemScrollBar = document.getElementById('ItemScrollBar')
   let itemDiv = document.getElementById('category_' + id)
   if (ItemScrollBar && itemDiv) {
      itemDiv.scrollIntoView()
   }
}
export const MenuCategory = ({name, itemCount, id, image, disabled = false}: MenuCategoryProps) => {
   const {activeCategory, setActiveCategory} = useMenuView()
   const isActiveId = id === activeCategory?.id
   const dispatch = useDispatch<AppDispatch>()

   const baseClasses = `nav-link nav-link-border-solid btn btn-outline btn-flex flex-column flex-stack pt-${
      image ? '1' : '10'
   } pb-7 my-1 menu-categories`
   let classes = isActiveId ? `${baseClasses} active` : baseClasses
   return (
      <li
         value={id}
         style={{overflow: 'hidden', wordWrap: 'break-word', listStyleType: 'none'}}
         onClick={() => {
            if (disabled) return
            SetScrollPosition(id)
            setActiveCategory({id, name})
            dispatch(setActiveMenuCategory(id))
         }}
      >
         <div
            className={classes}
            style={{width: '100%', height: '12rem'}}
            aria-selected={isActiveId ? 'true' : 'false'}
         >
            <div className='mb-2 w-100'>
               {image && <MenuImage src={image} className='rounded-3 w-100px h-90px mx-auto' />}
               <div className='menu-cat-text justify-content-center  d-flex align-items-center'>
                  <span className='text-primary fw-bold fs-1 d-block w-100'>{`${name}`}</span>
               </div>
               {!image && <span className='text-gray-400 fw-semibold fs-2'>{`${itemCount} Options`}</span>}
            </div>
         </div>
      </li>
   )
}
