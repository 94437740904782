import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ITizenNetworkStatus {
   available: boolean
   type: string | null
   ip: string | null
   wifi_ssid: string | null
   alertDisplayed: boolean
}

export const PRINTER_STATUSES = {
   LOW: 'LOW',
   OK: 'OK',
   OUT: 'OUT',
} as const

type PaperStatuses = typeof PRINTER_STATUSES[keyof typeof PRINTER_STATUSES]

export interface ITizenPrinter {
   paper_status: typeof PRINTER_STATUSES[keyof typeof PRINTER_STATUSES]
}

export interface ITizenSystemStatus {
   isLoading: boolean
   network: ITizenNetworkStatus
   device_uid: string | null
   serial_number: string | null
   printer: ITizenPrinter
}

const initialState: ITizenSystemStatus = {
   isLoading: true,
   network: {
      available: true,
      type: null,
      ip: null,
      wifi_ssid: null,
      alertDisplayed: false,
   },
   device_uid: null,
   serial_number: null,
   printer: {
      paper_status: 'OK',
   },
}

export const TizenSystemSlice = createSlice({
   name: 'TizenSystem',
   initialState,
   reducers: {
      setSystemSettings: (state: ITizenSystemStatus, action: PayloadAction<ITizenSystemStatus>) => {
         state.serial_number = action.payload.serial_number
         state.device_uid = action.payload.device_uid
         state.network = action.payload.network
         state.isLoading = false
      },
      updateNetworkStatus: (state: ITizenSystemStatus, action: PayloadAction<ITizenNetworkStatus>) => {
         state.network = action.payload
      },
      setAlertDisplayed: (state: ITizenSystemStatus, action: PayloadAction<boolean>) => {
         state.network.alertDisplayed = action.payload
      },
      toggleLoadingStatus: (state, action: PayloadAction<boolean>) => {
         state.isLoading = action.payload
      },
      setPrinterPaperStatus: (state, action: PayloadAction<PaperStatuses>) => {
         state.printer.paper_status = action.payload
      },
   },
})

export const {setSystemSettings, updateNetworkStatus, setAlertDisplayed, toggleLoadingStatus, setPrinterPaperStatus} =
   TizenSystemSlice.actions

export default TizenSystemSlice.reducer
