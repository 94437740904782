import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface HeaderPageTitle {
  value: any
}

const initialState: HeaderPageTitle = {
  value: '',
}

export const HeaderPageTitleSlice = createSlice({
  name: 'HeaderPageTitle',
  initialState,
  reducers: {    
    StoreHeaderPageTitle: (state, action: PayloadAction<any>) => {        
        state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { StoreHeaderPageTitle } = HeaderPageTitleSlice.actions

export default HeaderPageTitleSlice.reducer