import {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { KioskSettings } from '../../../../common/schemas/KioskSettingsSchema'
import { RootState } from '../../../../store'
import {useOverlayContext} from '../OverlayProvider'

type CounterOverlay = {
   onTimerEnd: () => void
}

export const CounterOverlay = () => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings

   const counts = createArray(kioskLocSettings.selfie_timer)
   const MAX_INDEX = counts.length - 1
   const {onTimerEnd, timerIsOn} = useOverlayContext()
   const [countIndex, updateCountIndex] = useState(0)
   const counterCallback = () => {
      updateCountIndex((prevCount) => prevCount + 1)
   }

   function createArray(n:number) {
      var arrayN = [];
      for (var i = n; i >= 1; i--) {
          arrayN.push(i);
      }
      arrayN.push("Ready");
      return arrayN;
  }

   useEffect(() => {
      let intervalID: number
      if (timerIsOn) {
         if (countIndex <= MAX_INDEX) {
            intervalID = window.setInterval(counterCallback, 1000)
         } else {
            onTimerEnd()
            updateCountIndex(0)
         }
      }
      return () => {
         clearInterval(intervalID)
      }
   }, [countIndex, timerIsOn])

   return (
      <div className='overlay d-flex justify-content-center align-items-center'>
         <span className='ps-2 pb-6 text-white opacity-50' style={{fontSize: '20rem'}}>
            {timerIsOn && counts[countIndex]}
         </span>
      </div>
   )
}
