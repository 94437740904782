import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'

export interface ITipsButton {
   id: string
   tipPercentage: string | number
   activeBtn: number
   handleActiveBtnCallback: (num: number) => void
}

//MAX 3 options
export const TipsButton = ({id, tipPercentage, activeBtn, handleActiveBtnCallback}: ITipsButton) => {
   const dynamicBtnColor = useLocationBasedBtnColor()

   // Converting string to number and using the number to display the percentage
   const convertStrToNumTip: number = +tipPercentage / 100

   // Handles selected tip percentage
   const handleSelectActiveBtn = () => {
      return handleActiveBtnCallback(convertStrToNumTip)
   }

   // Active and non-active styles for the middle buttons
   const activeMiddleButton = `btn active-tip-btn ${dynamicBtnColor} btn-no-hover no-border-radius border-start border-top border-bottom border-end col p-4 fs-3`
   const nonActiveMiddleButton = `btn btn-no-hover no-border-radius border-start border-top border-bottom border-end text-gray-700 fw-semibold col p-4 fs-3`

   // Styles for the buttons
   const buttonStyles = activeBtn === convertStrToNumTip ? activeMiddleButton : nonActiveMiddleButton

   return (
      <>
         <button id={id} className={buttonStyles} onClick={handleSelectActiveBtn}>
            {convertStrToNumTip}%
         </button>
      </>
   )
}
