import {array, InferType, number, object, string} from 'yup'

export const LocationProductSchema = object({
   id: number().nullable(),
   status: number(),
   global_product_id: number().required(),
   global_product_status: number().required(),
   global_integrations_types_id: number().nullable().defined(),
   code: string(),
   name: string().required(),
   description: string(),
})
export type LocationProduct = InferType<typeof LocationProductSchema>

export const LocationIntegrationsSchema = object({
   account_id: string().nullable(),
   status: string(),
   code: string(),
})
export type LocationIntegrations = InferType<typeof LocationIntegrationsSchema>

export const EmbeddedSchema = object({
   missing_configuration: array().optional(),
   location_products: array(LocationProductSchema).required(),
   location_integrations: LocationIntegrationsSchema,
})
export type Embedded = InferType<typeof EmbeddedSchema>

export const LocationSchema = object({
   location_id: number().required(),
   location_id_external: string().nullable(),
   name: string(),
   pos_name: string().required(),
   revenue_center: number(),
   item_order_modes: number(),
   ticket_auto_close: number(),
   global_business_types_id: number().nullable(),
   city: string().nullable().defined(),
   state_code: string(),
   calling_code: string(),
   global_country_id: number().required(),
   owner: number().nullable().defined(),
   phone: string(),
   logo: string().nullable(),
   _embedded: EmbeddedSchema.required(),
   state: string().nullable(),
   owner_name: string().nullable(),
   address:string().nullable(),
   address2:string().nullable(),
   zip:string().nullable(),
})

export type SoftPointLocation = InferType<typeof LocationSchema>
