import {GetApiUrl, getAuth} from '../../auth'
import {KIOSK_GLOBAL_ON} from '../../../config/constants'
import moment from 'moment/moment'
import axios from 'axios'
import {array, object} from 'yup'
import {Terminal, TerminalValidationSchema} from '../../../common/schemas/TerminalSchema'
import {parseRequestError} from '../../../common/utilities'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getSelectedLocation} from '../../../services/SelectedLocationSlice'
import {useErrorAlert} from '../../utilities/alerts'
import {useNavigate} from 'react-router-dom'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'

export type TerminalUI = Terminal & {dateDisp: string}

export const useFetchTerminals = () => {
   const [terminals, setTerminals] = useState<TerminalUI[]>([])
   const selectedLocation = useSelector(getSelectedLocation)
   const fireErrorAlert = useErrorAlert()
   const navigateTo = useNavigate()

   const fetchTerminals = async (LocationId: number) => {
      let user = getAuth()
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${LocationId}/terminals?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`

      let dateFrom = moment().subtract(3, 'd').format('YYYY-MM-DD 00:00:00')
      let newDataBefore: TerminalUI[] = []
      let newDataAfter: TerminalUI[] = []

      try {
         const response = await axios.get(url)
         const GetTerminalsSchema = object({
            terminals: object({
               data: array(TerminalValidationSchema).required(),
            }).required(),
         })
         const cleanData = GetTerminalsSchema.validateSync(response.data)
         cleanData.terminals.data
            .filter(function (terminal) {
               return terminal.global_terminal_mode === 'Kiosk' && terminal.status === 1
            })
            .map(function (terminal, i: number) {
               const extendedTerminal = {
                  ...terminal,
                  dateDisp: moment(terminal.last_datetime_local).format('MM/DD/YYYY'),
               }
               if (extendedTerminal.last_datetime_local) {
                  if (dateFrom > extendedTerminal.last_datetime_local) {
                     newDataBefore.push(extendedTerminal)
                  } else {
                     newDataAfter.push(extendedTerminal)
                  }
               } else {
                  newDataBefore.push(extendedTerminal)
               }
            })
         if (newDataBefore.length > 0) {
            newDataBefore = [...newDataBefore].sort((a, b) => {
               if (a.name && b.name) {
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
               } else {
                  return 1
               }
            })
         }
         if (newDataAfter.length > 0) {
            newDataAfter = [...newDataAfter].sort((a, b) => {
               if (a.name && b.name) {
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
               } else {
                  return 1
               }
            })
            newDataAfter.map(function (terminal) {
               newDataBefore.push(terminal)
            })
         }
      } catch (e) {
         const standardError = parseRequestError(e)
         fireErrorAlert(standardError.message).then((userInput) => {
            navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
         })
      }

      return newDataBefore
   }

   useEffect(() => {
      if (selectedLocation?.location_id) {
         fetchTerminals(selectedLocation.location_id).then((terminals) => {
            setTerminals(terminals)
         })
      }
   }, [selectedLocation?.location_id])

   return {
      terminals,
   }
}
