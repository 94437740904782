import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {Integration, KioskSettings} from '../common/schemas/KioskSettingsSchema'
import {constants} from '../config/constants'
import {RootState} from '../store'

export interface KioskData {
   value: null | KioskSettings
   derived_values: {
      qrIntegration: Integration | undefined
      biometricIntegration: Integration | undefined
      selfOrderingIntegration: Integration | undefined
      checkInIntegration: Integration | undefined
      loyaltyIntegration: Integration | undefined
   }
}

const initialState: KioskData = {
   value: null,
   derived_values: {
      qrIntegration: undefined,
      biometricIntegration: undefined,
      selfOrderingIntegration: undefined,
      checkInIntegration: undefined,
      loyaltyIntegration: undefined,
   },
}

const globalIntegrations = constants.integrations

export const KioskSettingSlice = createSlice({
   name: 'KioskData',
   initialState,
   reducers: {
      StoreKioskData: (state, action: PayloadAction<null | KioskSettings>) => {
         const checkInGlobalIds = Object.values(constants.integrations.check_in)
         state.value = action.payload
         if (action.payload?._embedded) {
            state.derived_values.qrIntegration = action.payload._embedded.integrations.find((integration) => {
               return integration.global_integrations_id == globalIntegrations.qr_code.global_integration_id
            })

            state.derived_values.biometricIntegration = action.payload._embedded.integrations.find((integration) => {
               return integration.global_integrations_id == globalIntegrations.biometrics.global_integration_id
            })

            state.derived_values.selfOrderingIntegration = action.payload._embedded.integrations.find((integration) => {
               return integration.global_integrations_id == globalIntegrations.self_ordering.global_integration_id
            })
            state.derived_values.checkInIntegration = action.payload._embedded.integrations.find((integration) => {
               return checkInGlobalIds.includes(integration.global_integrations_id ?? -1)
            })
            state.derived_values.loyaltyIntegration = action.payload._embedded.integrations.find((integration) => {
               return integration.global_integrations_types_id == globalIntegrations.loyalty.global_integration_type_id
            })
         } else {
            state.derived_values.qrIntegration = undefined
            state.derived_values.biometricIntegration = undefined
            state.derived_values.selfOrderingIntegration = undefined
            state.derived_values.checkInIntegration = undefined
            state.derived_values.loyaltyIntegration = undefined
         }
      },
   },
})

// Action creators are generated for each case reducer function
export const {StoreKioskData} = KioskSettingSlice.actions

export default KioskSettingSlice.reducer

export const getKioskSettings = (state: RootState): null | KioskSettings => state.kiosk_settings.value
