import {typeParameter} from '@babel/types'
import {AuthModel} from './_models'
import {json} from 'body-parser'
import {AxiosRequestConfig} from 'axios'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'

const AUTH_LOCAL_STORAGE_KEY = 'user_auth'
const LOCAL_SYNC_DATA = 'location_sync_data'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const GetApiUrl = () => {
  let apiURL = process.env.REACT_APP_API_URL
  var themeMode = localStorage.getItem('IsSSLNotAvaiable')
  if (themeMode == 'true') {
    apiURL = process.env.REACT_APP_API_URL_NON_SSL
  }
  return apiURL
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const SetSyncData = (auth: any) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(LOCAL_SYNC_DATA, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

type RequestHeaders = {
  Authorization: string
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config.headers) {
        config.headers = {
          Accept: 'application/json',
        }
      }

      if (config.headers['X-Skip-Interceptor'] === 'true') {
        return config
      }

      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {
  getAuth,
  setAuth,
  GetApiUrl,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  SetSyncData,
}
