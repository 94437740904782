import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {
   CategoryItem,
   ItemModifier,
   ItemModifierGroup,
   ItemTaxRate,
   MenuCategory,
   RevenueCenter,
} from '../common/schemas/SyncDataSchema'
import {AppDispatch, RootState} from '../store'
import {GetApiUrl, getAuth} from '../modules/auth'
import {constants, KIOSK_GLOBAL_ON} from '../config/constants'
import axios from 'axios'
import {IStandardError} from '../common/models/SoftPointAPIs'
import {parseRequestError} from '../common/utilities'

export type DerivedItemModGrp = {
   id_external: string
   name: string
   max: number
   min: number
   modifier_group_id: number
   required: number | null
   modifiers: null | ItemModifier[]
   included_modifiers: null | number[]
   parent_modifier_id?: null | number 
   parent_modifier_group_id?: null | number 
}

export interface iItems_taxrate_int {
   id: number
   name: string | null
}

export interface MenuItemUI {
   id: number
   display_name: string
   image: string
   price_per_unit: number
   categoryId: number
}

interface Data_ItemOrderModes_int {
   id: number
   id_external: string
   name: string
   available: number
   default: number
}

export type SelfOrderingValues = {
   Data_Categories: MenuCategory[]
   Data_RevenueCenters: RevenueCenter[]
   Data_TaxRates: ItemTaxRate[]
   Data_ModifierGroups: ItemModifierGroup[]
   Data_Modifiers: ItemModifier[]
   Data_ItemOrderModes: Data_ItemOrderModes_int[]
}

type PosSettings = {
   type: 'external' | 'sppos'
   status: 'online' | 'offline'
}

type RequestStatus = 'idle' | 'in_flight' | 'done'
type PosCheck = {error: IStandardError | null; request_status: RequestStatus; data: PosSettings | null}
export interface SelfOrderingData {
   values: SelfOrderingValues
   derived_values: {
      modifiers_dict: {[key: number]: ItemModifier} | null
      mod_groups_dict: {[key: number]: ItemModifierGroup} | null
   }
   cat_sync: boolean
   pos: PosCheck
}

const initialState: SelfOrderingData = {
   values: {
      Data_Categories: [],
      Data_RevenueCenters: [],
      Data_TaxRates: [],
      Data_ModifierGroups: [],
      Data_Modifiers: [],
      Data_ItemOrderModes: [],
   },
   derived_values: {
      modifiers_dict: null,
      mod_groups_dict: null,
   },
   cat_sync: false,
   pos: {
      data: null,
      error: null,
      request_status: 'idle',
   },
}

export const SelfOrderingDataSlice = createSlice({
   name: 'SelfOrderingDataSlice',
   initialState,
   reducers: {
      StoreSelfOrderingData: (state, action: PayloadAction<SelfOrderingValues>) => {
         state.values = action.payload

         if (action.payload.Data_Modifiers.length > 0) {
            state.derived_values.modifiers_dict = action.payload.Data_Modifiers.reduce((dictionary, modifier) => {
               dictionary[modifier.id] = modifier
               return dictionary
            }, {} as {[key: number]: ItemModifier})
         }
         if (action.payload.Data_ModifierGroups.length > 0) {
            state.derived_values.mod_groups_dict = action.payload.Data_ModifierGroups.reduce((dictionary, modGroup) => {
               dictionary[modGroup.id] = modGroup
               return dictionary
            }, {} as {[key: number]: ItemModifierGroup})
         }
      },
      StoreCatSync: (state, action: PayloadAction<boolean>) => {
         state.cat_sync = action.payload
      },
      setPosCheckResponse: (state, action: PayloadAction<PosSettings>) => {
         state.pos.data = action.payload
      },
      setPosCheckError: (state, action: PayloadAction<IStandardError | null>) => {
         state.pos.error = action.payload
      },
      updatePosCheckRequest: (state, action: PayloadAction<RequestStatus>) => {
         state.pos.request_status = action.payload
      },
   },
})

// Action creators are generated for each case reducer function
export const {StoreSelfOrderingData, StoreCatSync, setPosCheckResponse, setPosCheckError, updatePosCheckRequest} =
   SelfOrderingDataSlice.actions

export default SelfOrderingDataSlice.reducer

// Selectors
export const getModDictionary = (state: RootState) => state.location_menu_data.derived_values.modifiers_dict

export const getModGroupDictionary = (state: RootState) => state.location_menu_data.derived_values.mod_groups_dict

export const getMenuCategories = (state: RootState): MenuCategory[] => state.location_menu_data.values.Data_Categories

export const getPosCheck = (state: RootState): PosCheck => state.location_menu_data.pos

// THUNKS
export const getPosStatus = (locationId: number | string) => {
   let user = getAuth()
   const user_id = user?.user_id
   const url = `${GetApiUrl()}/locations/${locationId}/pos_check?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
   return async (dispatch: AppDispatch) => {
      try {
         dispatch(updatePosCheckRequest('in_flight'))
         // Endpoint returns no content
         await axios.get(url)
         dispatch(
            setPosCheckResponse({
               status: 'online',
               type: 'external',
            })
         )
         dispatch(updatePosCheckRequest('done'))
      } catch (e) {
         const standardError = parseRequestError(e)
         dispatch(setPosCheckError(standardError))
         dispatch(updatePosCheckRequest('done'))
         if (standardError.code === constants.errors.pos_check.not_external_pos) {
            dispatch(
               setPosCheckResponse({
                  status: 'online',
                  type: 'sppos',
               })
            )
         } else {
            dispatch(
               setPosCheckResponse({
                  status: 'offline',
                  type: 'external',
               })
            )
         }
      }
   }
}
