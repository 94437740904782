import {useCallback} from 'react'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {API_KEY, KIOSK_GLOBAL_ON} from '../../../config/constants'
import {GetApiUrl} from '../core/AuthHelpers'
import {parseRequestError} from '../../../common/utilities'
import {TizenDebug} from '../../hotel/FaceAuth/TizenIntegration'
import {PreAuthResponse} from './pre_auth_token_request'
import {useAutoLoginContext} from '../AutoLoginProvider'
import {autoLoginSchema} from './auto_login_models'
import {ValidationError} from 'yup'
import {useIntl} from 'react-intl'

export const useAutoLogin = () => {
   const intl = useIntl()
   const authenticationLoadingText = intl.formatMessage({id: 'auth.autologin.authenticating_kiosk'})

   const {updateLoadingStatus, setError, setAutoLoginData, setLoadingText} = useAutoLoginContext()

   return useCallback(async (serialNumber: string) => {
      const preAuthConfigs: AxiosRequestConfig = {
         headers: {
            apiKey: API_KEY,
            'X-Skip-Interceptor': 'true',
         },
      }

      const data = {global_on_id: KIOSK_GLOBAL_ON}

      updateLoadingStatus(true)
      setLoadingText(authenticationLoadingText)
      try {
         const preAuthResponse = await axios.post(`${GetApiUrl()}/auth/app-pre-token`, data, preAuthConfigs)
         const responseContent = preAuthResponse.data as PreAuthResponse
         updateLoadingStatus(false)

         const requestConfigs: AxiosRequestConfig = {
            headers: {
               'X-Skip-Interceptor': 'true',
               Authorization: `Bearer ${responseContent.temp_access_token}`,
            },
         }

         const payload = {
            serial_number: serialNumber,
            global_on_id: KIOSK_GLOBAL_ON,
         }

         const autoLoginResponse = await axios.post(`${GetApiUrl()}/auth/auto-login`, payload, requestConfigs)

         const dirtyData = autoLoginResponse.data

         const cleanData = await autoLoginSchema.validate(dirtyData)

         updateLoadingStatus(false)
         setAutoLoginData(cleanData)
      } catch (error: any | AxiosError | ValidationError) {
         if (error instanceof ValidationError) {
            console.log('Data Errors', error.errors)
            TizenDebug(`[FetchAutoLogin] Data Errors: ${JSON.stringify(error.errors)}`)
         }
         const standardError = parseRequestError(error)
         // TizenDebug(`[FetchAutoLogin] Payload: ${JSON.stringify(data)}`)
         TizenDebug(`[FetchAutoLogin] Response: ${JSON.stringify(standardError)}`)
         updateLoadingStatus(false)
         setError(standardError)
      }
   }, [])
}
