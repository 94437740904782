type SweetAlertSpinnerProps = {
   size: 'small' | 'med' | 'large'
}
export const SweetAlertSpinner = ({size}: SweetAlertSpinnerProps) => {
   let fontSize = '2rem'
   if (size == 'med') {
      fontSize = '4rem'
   }
   if (size == 'large') {
      fontSize = '6rem'
   }

   return (
      <div
         className='swal2-loader'
         data-button-to-replace='swal2-confirm swal2-styled'
         style={{display: 'flex', fontSize}}
      ></div>
   )
}
