import {useEffect, useState} from "react";

export const LoadingEllipses = () => {

    const ellipses = ['', '.', '..', '...']
    const [ellipsesIndex, updateIndex] = useState(0)

    useEffect(()=>{
        const intervalID = setInterval(()=>{
            updateIndex((prevIndex)=> {
                switch (prevIndex){
                    case 0:
                        return 1
                    case 1:
                        return 2
                    case 2:
                        return 3
                    default:
                        return 0
                }

            })
        }, 500)

        return () => {
            clearInterval(intervalID)
        }

    },[])

    return(
        <h2 className='text-start fs-2tx py-10' style={{width:'3rem'}}>{ellipses[ellipsesIndex]}</h2>
    )
}