import {useEffect, useState} from 'react'
import {MenuCategory as MenuCategoryComp} from './MenuCategory'
import {useSelector} from 'react-redux'
import type {RootState} from '../../../store'
import moment from 'moment'
import '../assets/MenuCategories.css'
import {useMenuSearch} from './MenuSearchProvider'
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

interface MenuCategoryUI {
   id: number
   pane_id: string
   name: string
   itemCount: number
   image: string
}

export const MenuCategories = () => {
   const {queryValue, filteredCatIds} = useMenuSearch()
   let selfOrderData = useSelector((state: RootState) => state.location_menu_data.values)
   // const [CurrentSlide, setCurrentSlide] = useState(0)

   const checkInTime = (startTime: String | null, endTime: String | null) => {
      if (!startTime || !endTime || startTime == endTime) return true
      function timeToMinutes(time: String) {
         const [hours, minutes] = time.split(':').map(Number)
         return hours * 60 + minutes
      }
      const currentTime = moment.utc().format('HH:mm')
      let minutesStartTime = timeToMinutes(startTime)
      let minutesEndTime = timeToMinutes(endTime)
      let minutesCurrentTime = timeToMinutes(currentTime)
      if (minutesStartTime > minutesEndTime) {
         return minutesCurrentTime >= minutesStartTime || minutesCurrentTime <= minutesEndTime
      } else {
         return minutesCurrentTime >= minutesStartTime && minutesCurrentTime <= minutesEndTime
      }
   }
   let menuCats: MenuCategoryUI[] = []
   let categories = selfOrderData.Data_Categories
   const dayName = moment().format('dddd')
   categories = categories.filter((menu) => {
      return (menu.available_dow && menu.available_dow.indexOf(dayName) > -1) || Object.is(menu.available_dow, null)
   })

   categories = categories.filter((menu) => {
      return checkInTime(menu.start_time, menu.end_time)
   })
   const [filteredMenuCats, setFilteredMenuCats] = useState<MenuCategoryUI[]>(menuCats)

   for (let category of categories) {
      menuCats.push({
         id: category.id,
         image: category.image ?? '',
         pane_id: `menu${category.id}`,
         name: category.name ?? '',
         itemCount: category._embedded.items.length,
      })
   }

   useEffect(() => {
      let trimmedQuery = queryValue.trim()
      if (trimmedQuery !== '' && trimmedQuery.length > 2) {
         setFilteredMenuCats(menuCats.filter((cat) => filteredCatIds.includes(cat.id)))
      } else {
         setFilteredMenuCats(menuCats)
      }
   }, [filteredCatIds.length, queryValue])
   return (
      <div className='p-2'>
         <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1.2}
            totalSlides={filteredMenuCats.length}
            orientation='vertical'
            infinite={false}
            visibleSlides={filteredMenuCats.length > 9 ? 9.44 : filteredMenuCats.length + 0.02}
            currentSlide={0} //filteredMenuCats.findIndex(obj=>obj.id==activeCategory?.id)
         >
            <Slider>
               {filteredMenuCats.filter(cat => cat.itemCount > 0).map((cat, index: number) => (
                  <Slide key={`slide_${cat.id}`} index={index}>
                     <MenuCategoryComp
                        key={`Cat_${cat.id}`}
                        id={cat.id}
                        name={cat.name}
                        itemCount={cat.itemCount}
                        image={cat.image}
                        disabled={cat.itemCount == 0}
                     ></MenuCategoryComp>
                  </Slide>
               ))}
            </Slider>
         </CarouselProvider>
      </div>
   )
}
