import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {constants} from '../../config/constants'

export type PrinterSVGProps = {
   width?: string
   height?: string
   fill?: string
   onClick?: () => void
}

const defaultAttributes: PrinterSVGProps = {
   width: '48',
   height: '48',
}

export const PrinterSVG = (attributes: PrinterSVGProps = defaultAttributes) => {
   const kioskLocSettings = useSelector((state: RootState) => state.kiosk_settings.value)

   const {status, theme} = constants
   const APPEARANCE_STATUS = status.location.appearance
   const fillColor = kioskLocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'black' : 'white'
   return (
      <div onClick={attributes.onClick ?? (() => {})}>
         <svg
            xmlns='http://www.w3.org/2000/svg'
            width={attributes?.width ?? defaultAttributes.width}
            height={attributes?.height ?? defaultAttributes.height}
            fill={attributes?.fill ?? fillColor}
            className='bi bi-printer'
            viewBox='0 0 16 16'
         >
            <path d='M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z' />
            <path d='M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z' />
         </svg>
      </div>
   )
}
