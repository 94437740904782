import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

type OrderTypes = 'dinein' | 'takeout'

export type SelfOrderingUserConfigs = {
   order_type: OrderTypes | ''
   selected_category_section_id: number
   tip_points: number // This is Basis Points units
}

const initialState: SelfOrderingUserConfigs = {
   order_type: '',
   selected_category_section_id: 0,
   tip_points: 0,
}

export const SelfOrderingUserSlice = createSlice({
   name: 'self_ordering_selections',
   initialState,
   reducers: {
      setOrderType: (state, action: PayloadAction<OrderTypes>) => {
         state.order_type = action.payload
      },
      setActiveMenuCategory: (state, action: PayloadAction<number>) => {
         state.selected_category_section_id = action.payload
      },
      setOrderTipPoints: (state, action: PayloadAction<number>) => {
         state.tip_points = action.payload
      },
   },
})

// Action creators are generated for each case reducer function
export const {setOrderType, setActiveMenuCategory, setOrderTipPoints} = SelfOrderingUserSlice.actions

export default SelfOrderingUserSlice.reducer
