export interface IUploadFile {
   file_name: string
   sftp_response?: boolean
}

export interface IStandardError {
   code: number
   message: string
   details: {[key: string | number]: any} | null
}

export interface IApiError {
   error: IStandardError
}

export const instanceOfError = (object: any): object is IApiError => {
   return 'error' in object
}

export type RequestStatus = 'idle' | 'in_flight' | 'done'
