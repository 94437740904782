import { useEffect, useState, FC } from 'react'
import ContentLoader from "react-content-loader"

const ModifirePlaceHolder = () => {
    return (
        <>
            <ContentLoader
                animateBegin="0.1s"                
                height={400}
                backgroundColor="#f5f5f5"
                foregroundColor="#dbdbdb"
                style={{ width: '100%' }}
                foregroundOpacity={0.5}
            >
                <rect x="4" y="8" rx="3" ry="3" width="7" height="388" />
                <rect x="6" y="389" rx="3" ry="3" style={{ width: '98.4%' }} height="8" />
                <rect x="5" y="8" rx="3" ry="3" style={{ width: '98.4%' }} height="7" />
                <rect x="98.4%" y="9" rx="3" ry="3" width="6" height="388" />

                <rect x="40" y="42" rx="16" ry="16" width="400" height="320" />
                <rect x="476" y="42" rx="3" ry="3" width="300" height="60" />
                <rect x="476" y="139" rx="3" ry="3" width="178" height="20" />
                <rect x="476" y="300" rx="14" ry="14" width="150" height="52" />
            </ContentLoader>
            <ContentLoader
                animateBegin="0.1s"
                interval={0.8}
                height={100}
                backgroundColor="#f5f5f5"
                foregroundColor="#dbdbdb"
                style={{ width: '100%' }}
                foregroundOpacity={0.5}
            >
                <rect x="4" y="8" rx="3" ry="3" width="7" height="94" />
                <rect x="6" y="94" rx="3" ry="3" style={{ width: '98.4%' }} height="8" />
                <rect x="5" y="8" rx="3" ry="3" style={{ width: '98.4%' }} height="7" />
                <rect x="98.4%" y="9" rx="3" ry="3" width="6" height="94" />
                <rect x="40" y="25" rx="16" ry="16" style={{ width: '92.4%' }} height="60" />
            </ContentLoader>
            <ContentLoader
                animateBegin="0.1s"
                interval={0.8}
                height={1180}
                backgroundColor="#f5f5f5"
                foregroundColor="#dbdbdb"
                style={{ marginTop: '20px', width: '100%' }}
                foregroundOpacity={0.5}
            >
                <rect x="4" y="8" rx="3" ry="3" width="7" height="1160" />
                <rect x="5" y="8" rx="3" ry="3" style={{ width: '98.4%' }} height="7" />
                <rect x="98.4%" y="8" rx="3" ry="3" width="7" height="1160" />
                <rect x="6" y="1160" rx="3" ry="3" style={{ width: '98.4%' }} height="8" />

                <GetModLinePlaceHolder top={50} />
                <GetModLinePlaceHolder top={150} />
                <GetModLinePlaceHolder top={250} />
                <GetModLinePlaceHolder top={350} />
                <GetModLinePlaceHolder top={450} />
                <GetModLinePlaceHolder top={550} />
                <GetModLinePlaceHolder top={650} />
                <GetModLinePlaceHolder top={750} />
                <GetModLinePlaceHolder top={850} />
                <GetModLinePlaceHolder top={950} />
                <GetModLinePlaceHolder top={1050} />

            </ContentLoader>
        </>
    )
}
export default  ModifirePlaceHolder;

interface IGetModLinePlaceHolder {
    top: number
}

export const GetModLinePlaceHolder: FC<IGetModLinePlaceHolder> = ({ top }) => {
    return (
        <>
            <rect x="30" y={top} rx="16" ry="16" style={{ width: '30%' }} height="60" />
            <rect x="35%" y={top} rx="16" ry="16" style={{ width: '30%' }} height="60" />
            <rect x="67%" y={top} rx="16" ry="16" style={{ width: '30%' }} height="60" />
        </>
    )
}