import {TizenDebug} from '../TizenIntegration'
import {SupportedLanguages} from '../../../i18n/i18nProvider'
import {GetApiUrl, getAuth} from '../../../auth'
import {constants, KIOSK_GLOBAL_ON} from '../../../../config/constants'
import axios from 'axios'
import {array, InferType, number, object, string} from 'yup'
import {BiometricDataType, BiometricLogResult} from '../../../../common/models/biometrics'

const BIOMETRIC_TIMEOUT_SECONDS: number =
   process.env.REACT_APP_BIOMETRIC_SERVICE_TIMEOUT == undefined
      ? 30
      : parseFloat(process.env.REACT_APP_BIOMETRIC_SERVICE_TIMEOUT)

export class BiometricTimeoutError extends Error {
   constructor(message = 'The biometric server is offline, please try again later') {
      super(message)
      this.name = 'BiometricTimeoutError'
      this.message = message
   }
}

export const biometricTimeout = () => {
   console.log(
      `[BIOMETRIC_SERVICE_TIMEOUT]: ${parseFloat(
         process.env.REACT_APP_BIOMETRIC_SERVICE_TIMEOUT ?? 'ENV VARIABLE UNDEFINED'
      )}`
   )
   TizenDebug(
      `[BIOMETRIC_SERVICE_TIMEOUT]: ${parseFloat(
         process.env.REACT_APP_BIOMETRIC_SERVICE_TIMEOUT ?? 'ENV VARIABLE UNDEFINED'
      )}`
   )
   return new Promise((resolve, reject) => {
      window.setTimeout(() => reject(new BiometricTimeoutError()), BIOMETRIC_TIMEOUT_SECONDS * 1000)
   })
}

export type BiometricAuthRequest = {
   location_id: number
   selfie_file_name: string
   terminal_id: number
   flow?: string
   i18n: SupportedLanguages
}

export const loginBySelfie = (request: BiometricAuthRequest) => {
   const auth = getAuth()
   const kioskGlobalOn: string = process.env.REACT_APP_KIOSK_GLOBAL_ON ?? '3'
   const baseAPIURL = process.env.REACT_APP_API_URL
   let reqPayload: any = {
      image_type: 'selfie',
      image_name: request.selfie_file_name,
      global_on_id: parseInt(kioskGlobalOn),
      location_id: request.location_id,
      terminal_id: request.terminal_id,
      i18n: request.i18n,
      app_version: process.env.REACT_APP_VERSION,
   }
   if (request.flow) {
      reqPayload['flow'] = request.flow
   }

   const reqOptions = {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${auth?.api_token ?? ''}`,
      },
      body: JSON.stringify(reqPayload),
   }

   TizenDebug(`URI: ${baseAPIURL}/trueface/template/match`)
   TizenDebug(
      `Req Body: ${JSON.stringify({
         image_type: 'selfie',
         image_name: request.selfie_file_name,
         global_on_id: kioskGlobalOn,
         location_id: request.location_id,
      })}`
   )

   return Promise.race([fetch(`${baseAPIURL}/trueface/template/match`, reqOptions), biometricTimeout()])
}

const POSTBioLogResponseSchema = object({
   id: number().required(),
   images: array().of(string()).required(),
})

type POSTBiometricLogResponse = InferType<typeof POSTBioLogResponseSchema>

type POSTBiometricLogRequest = {
   global_biometric_service_type:
      | 'AUTH'
      | 'COMPARE'
      | 'PHONE_FALLBACK'
      | 'TICKET_PAYMENT'
      | 'PRE_CHECKIN'
      | 'CHECK_IN'
      | 'MANUAL_REVIEW'
      | 'UPDATE_PRIMARY_SELFIE'
      | 'AGE_GATE_AUTH'
      | 'BIO_AUTH'
   global_biometric_data_type: BiometricDataType
   sync_id?: string
   parent_id?: number
   result: BiometricLogResult
   offline_id?: number | null
   threshold?: number | null
   score: number | null
   auth_image?: string
   guest_id: string | number | null
   location_id: number | string
   terminal_id?: string | number | null
   payment_id?: string | number | null
   tickets_items_id?: string | number | null
   global_error_name?: string | null
   global_on_id: string | number
   reviewed_by?: number | null
   reviewer_type?: number | null
   reviewed_on?: number | null
   created_datetime?: string
}

/**
 * The method uses /biometric_logs endpoint to record a phone fallback authentication log
 * @param payload
 */
export const postBiometricLog = async (payload: POSTBiometricLogRequest): Promise<POSTBiometricLogResponse> => {
   // TizenDebug(`[updateBiometricLog] ${JSON.stringify(payload)}`)
   try {
      const response = await axios.post(`${GetApiUrl()}/biometric_logs`, payload)
      return POSTBioLogResponseSchema.validate(response.data)
   } catch (error) {
      TizenDebug(JSON.stringify(error))
      throw error
   }
}
