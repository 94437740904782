import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import {ISelfOrderingCartItemModifier} from '../../../services/SelfOrderingCartSlice'
import {DerivedItemModGrp} from '../../../services/SelfOrderingDataSlice'
import {ItemModifier} from '../../../common/schemas/SyncDataSchema'

export type ModGroupsUI = DerivedItemModGrp & {
   selected_modifiers: ISelfOrderingCartItemModifier[]
   removed_included_modifiers: ISelfOrderingCartItemModifier[]
}

type SelectedModifiersUI = {
   mod_groups: ModGroupsUI[]
   addSelectedModifier: (modGroupId: number, modifier: ISelfOrderingCartItemModifier | ItemModifier, parentModifierId?: null | number, parentModGroupId?: null | number) => void
   removeSelectedModifier: (modGroupId: number, modifierId: number, parentModifierId?: null | number, parentModGroupId?: null | number) => void
   initModGroups: (groups: ModGroupsUI[]) => void
   addModGroups: (groups: ModGroupsUI[]) => void
   removeAllNestedModifier: (modGroupId: number, parent_modifier_group_id: number) => void
}

const SelectedModifiersContext = createContext<SelectedModifiersUI | undefined>(undefined)

export const useSelectedModifiers = () => {
   const context = useContext(SelectedModifiersContext)
   if (!context) {
      throw new Error('[useSelectedModifiers] can only be used under the [SelectedModifiersProvider]')
   }
   return context
}

export const ItemModifiersProvider = ({children}: {children: ReactNode}) => {
   const [itemModGroups, setModGroups] = useState<ModGroupsUI[]>([])
   const [nestedItemModGroups, setNestedItemModGroups] = useState<ModGroupsUI[]>([])

   const initModGroups = (modGroups: ModGroupsUI[]) => {
      setModGroups(modGroups)
   }

   const addModGroups = (modGroups: ModGroupsUI[]) => {
      setModGroups((pre) => {
         return [...pre, ...modGroups]
      })
   }

   const addSelectedModifier = (modGroupId: number, modifier: ISelfOrderingCartItemModifier | ItemModifier, parentModifierId?: null | number, parentModGroupId?: null | number) => {
      setModGroups((prevState) => {
         const clonedModGroups = [...prevState] //Need to return new reference or React will not trigger re-render
         const modGroupIndex = clonedModGroups.findIndex((modGroup) => modGroup.modifier_group_id == modGroupId && modGroup.parent_modifier_group_id == parentModGroupId && modGroup.parent_modifier_id == parentModifierId) 
         clonedModGroups[modGroupIndex].removed_included_modifiers = clonedModGroups[
            modGroupIndex
         ].removed_included_modifiers?.filter((mod) => mod.id != modifier.id)
         if (modGroupIndex != -1) {
            const selectedModGroup = clonedModGroups[modGroupIndex]
            const existingModifier = selectedModGroup.selected_modifiers.find((mod) => mod.id == modifier.id )
            const existingModifierIndex = selectedModGroup.selected_modifiers.findIndex((mod) => mod.id == modifier.id)
            if (!existingModifier) {
               if (instanceOfItemModifier(modifier)) {
                  clonedModGroups[modGroupIndex].selected_modifiers.push({
                     id: modifier.id,
                     id_external: modifier.id_external ?? '',
                     name: modifier.name ?? '',
                     price: modifier.price,
                     price_per_unit: modifier.price,
                     modgroup_id: modGroupId,
                     quantity: 1,
                     parent_modifier_group_id: parentModGroupId,
                     parent_modifier_id: clonedModGroups[modGroupIndex].parent_modifier_id,
                  })
               } else {
                  clonedModGroups[modGroupIndex].selected_modifiers.push(modifier)
               }

               return clonedModGroups
            } else {
               var cloneExistingModifier = Object.assign({}, existingModifier)
               cloneExistingModifier.quantity += 1
               const selected_modifiers = clonedModGroups[modGroupIndex].selected_modifiers
               selected_modifiers[existingModifierIndex] = cloneExistingModifier
               clonedModGroups[modGroupIndex].selected_modifiers = selected_modifiers
               return clonedModGroups
            }
         }
         return prevState
      })
   }

   const removeSelectedModifier = (modGroupId: number, modifierId: number, parentModifierId?: null | number, parentModGroupId?: null | number) => {
      setModGroups((prevState) => {
         const clonedModGroups = [...prevState] //Need to return new reference or React will not trigger re-render

         const modGroupIndex = clonedModGroups.findIndex((modGroup) => modGroup.modifier_group_id == modGroupId && modGroup.parent_modifier_group_id == parentModGroupId && modGroup.parent_modifier_id == parentModifierId)
         if (modGroupIndex != -1) {
            if (clonedModGroups[modGroupIndex].included_modifiers?.includes(modifierId)) {
               const defaultMod = clonedModGroups[modGroupIndex].selected_modifiers.find((mod) => mod.id == modifierId)
               if (defaultMod) {
                  clonedModGroups[modGroupIndex].removed_included_modifiers.push(defaultMod)
               }
            }
            const existingModifier = clonedModGroups[modGroupIndex].selected_modifiers.find(
               (mod) => mod.id == modifierId
            )
            if (existingModifier) {
               const existingModifierIndex = clonedModGroups[modGroupIndex].selected_modifiers.findIndex(
                  (mod) => mod.id == modifierId
               )
               if (existingModifier?.quantity == 1) {
                  clonedModGroups[modGroupIndex].selected_modifiers = clonedModGroups[
                     modGroupIndex
                  ].selected_modifiers.filter((mod) => mod.id != modifierId)
                  return clonedModGroups
               } else {
                  const cloneExistingModifier = Object.assign({}, existingModifier)
                  cloneExistingModifier.quantity -= 1
                  clonedModGroups[modGroupIndex].selected_modifiers[existingModifierIndex] = cloneExistingModifier
                  return clonedModGroups
               }
            }
         }
         return prevState
      })
   }

   const removeAllNestedModifier = (modGroupId: number, parent_modifier_group_id: number) => {
      setModGroups((prevState) => {
         const clonedModGroups = [...prevState] 
         return clonedModGroups.filter((group) => !(group.parent_modifier_id == modGroupId && group.parent_modifier_group_id == parent_modifier_group_id))
      })
   }

   return (
      <SelectedModifiersContext.Provider
         value={{mod_groups: itemModGroups, addSelectedModifier, removeSelectedModifier, initModGroups, addModGroups, removeAllNestedModifier}}
      >
         {children}
      </SelectedModifiersContext.Provider>
   )
}

const instanceOfItemModifier = (modifier: ISelfOrderingCartItemModifier | ItemModifier): modifier is ItemModifier => {
   return '_embedded' in modifier
}
