import {createContext, ReactNode, useContext, useState} from 'react'

export type SearchContext = {
   queryValue: string
   updateQuery: (value: string) => void
   updateFilteredCatIds: (value: number[]) => void
   filteredCatIds: number[]
   
}

const MenuSearchContext = createContext<SearchContext | undefined>(undefined)

export const MenuSearchProvider = ({children}: {children: ReactNode}) => {
   const [queryValue, updateQuery] = useState<string>('')
   const [filteredCatIds, updateFilteredCatIds] = useState<number[]>([])
   

   return (
      <MenuSearchContext.Provider value={{queryValue, updateQuery, updateFilteredCatIds, filteredCatIds}}>
         {children}
      </MenuSearchContext.Provider>
   )
}

export const useMenuSearch = () => {
   const context = useContext(MenuSearchContext)
   if (!context) {
      throw new Error('[useMenuSearch] needs to be used inside the [MenuSearchProvider] component')
   }
   return context
}
