import {useState} from 'react'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'

interface GiftCardModalProps {
   cartTotal: number
   toggleGiftCardModal: () => void
}

export const GiftCardModalBody = ({cartTotal, toggleGiftCardModal}: GiftCardModalProps) => {
   const [pinValue, setPinValue] = useState('')
   const [cardValue, setCardValue] = useState('')
   const [focusPinField, setFocusPinField] = useState(false)
   const [confirmGiftCard, setConfirmGiftCard] = useState(false)
   const [giftCardBalance, setGiftCardBalance] = useState('73.61')
   const [amountToPayWithGiftCard, setAmountToPayWithGiftCard] = useState<string | number>(0.0)
   const BtnClrClass = useLocationBasedBtnColor()

   const insertAmountToPayWithGiftCard = (val: any) => {
      let cloneState = amountToPayWithGiftCard
      if (val == 'X') {
         cloneState = cloneState.toString().substr(0, cloneState.toString.length - 1)
      } else {
         cloneState = cloneState + '' + val
      }
      setAmountToPayWithGiftCard(cloneState)
   }
   const deleteCardVal = (val: any) => {
      let cloneState = cardValue
      if (cardValue.length > 15 && pinValue.length === 0 && val == 'X') {
         cloneState = cloneState.substr(0, cloneState.length - 1)
         setCardValue(cloneState)
      } else {
         cloneState = cloneState + '' + val
      }
   }
   const setVal = (val: any) => {
      let cloneState
      focusPinField ? (cloneState = pinValue) : (cloneState = cardValue)
      if (cardValue.length >= 16) {
         cloneState = pinValue
      }
      if (val == 'X') {
         cloneState = cloneState.substr(0, cloneState.length - 1)
         deleteCardVal(val)
      } else {
         cloneState = cloneState + '' + val
      }
      if (cardValue.length === 16 || focusPinField) {
         setPinValue(cloneState.slice(0, 4))
      }
      if (cardValue.length < 16 && focusPinField === false) {
         setCardValue(cloneState)
      }
      if (confirmGiftCard === true) {
         insertAmountToPayWithGiftCard(val)
      }
   }
   let payWithGCBalance =
      parseFloat(amountToPayWithGiftCard.toString()).toFixed(2) == 'NaN'
         ? '0.00'
         : parseFloat(amountToPayWithGiftCard.toString().slice(0, 5)).toFixed(2)
   const buttonStyle = {
      padding: '15px 0px',
      fontSize: '25px',
      borderRadius: '10px',
      height: '70px',
   }
   return (
      <div className='tab-content'>
         <div className='text-center'>
            <h1 className='modal-title pb-8'>
               {confirmGiftCard ? `Gift Card Balance: $${giftCardBalance}` : 'Gift Card Payment'}
            </h1>
         </div>
         <div className='d-flex flex-row flex-column-fluid h-xl-100 pt-5'>
            <div className='d-flex flex-row-fluid flex-center'>
               <div className='row w-400px theme-background py-3'>
                  {confirmGiftCard ? (
                     <>
                        <div className='col-xl-12 col-md-12 col-sm-12 flex-center mb-5'>
                           <input
                              id='GC_Number'
                              type='text'
                              style={{border: 'none', height: '85px', fontSize: '25px'}}
                              readOnly={true}
                              value={`Balance Due: $${cartTotal.toFixed(2)}`}
                              placeholder={`Balance Due: $${cartTotal.toFixed(2)}`}
                              name='Balance'
                              autoComplete='off'
                              className='form-control bg-transparent text-center'
                           />
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12 flex-center mb-5'>
                           <input
                              id='GC_PinNumber'
                              type='text'
                              style={{border: 'none', height: '85px', fontSize: '25px'}}
                              value={`Pay $${payWithGCBalance} with Gift Card`}
                              placeholder=''
                              name='Balance'
                              autoComplete='off'
                              className='form-control bg-transparent text-center'
                           />
                        </div>
                     </>
                  ) : (
                     <>
                        <div className='col-xl-12 col-md-12 col-sm-12 flex-center mb-5'>
                           <input
                              onClick={() => {
                                 setFocusPinField(false)
                              }}
                              id='GC_Number'
                              type='text'
                              style={{height: '85px', fontSize: '25px'}}
                              value={cardValue.toString().replace(/\d{4}(?=.)/g, '$& ')}
                              placeholder='Enter Gift Card Number'
                              name='GiftCard'
                              autoComplete='off'
                              className='form-control bg-transparent text-center'
                           />
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12 flex-center mb-5'>
                           <input
                              onClick={() => {
                                 setFocusPinField(true)
                              }}
                              type='text'
                              id='GC_PinNumber'
                              value={pinValue}
                              name='GiftCardPin'
                              autoComplete='off'
                              placeholder='Enter Pin Number'
                              style={{height: '85px', fontSize: '25px'}}
                              className='form-control bg-transparent text-center'
                           />
                        </div>
                     </>
                  )}
                  <div className='clear'></div>
                  <div className='w-33 fs-2 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(1)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        1
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(2)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        2
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(3)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        3
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(4)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        4
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(5)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        5
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(6)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        6
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(7)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        7
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(8)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        8
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(9)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        9
                     </button>
                  </div>
                  <div className='clear mb-3'></div>
                  <div className='w-33 text-center symbol'></div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal(0)}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        0
                     </button>
                  </div>
                  <div className='w-33 text-center symbol'>
                     <button
                        style={{height: '85px', width: '95%', fontSize: '25px'}}
                        onClick={(e) => setVal('X')}
                        className='btn symbol-label bg-light mx-auto'
                     >
                        <svg
                           xmlns='http://www.w3.org/2000/svg'
                           width='50'
                           height='30'
                           fill='currentColor'
                           className='bi bi-backspace'
                           viewBox='0 0 16 16'
                        >
                           <path d='M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z' />
                           <path d='M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z' />
                        </svg>
                     </button>
                  </div>
                  <div className='col-xl-12 col-md-12 col-sm-12 text-center mt-5'>
                     <button
                        type='button'
                        style={buttonStyle}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        className={`btn btn-sm btn-bg-primary w-100 ${BtnClrClass}`}
                        onClick={() => {
                           if (pinValue.length === 4 && cardValue.length === 16) {
                              setConfirmGiftCard(true)
                           }
                        }}
                     >
                        {confirmGiftCard ? 'Pay' : 'Confirm'}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
