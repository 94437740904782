import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {Helmet} from 'react-helmet'
import useRedirectToDashboard from './services/hooks/useRedirectToDashboard'
import useTerminalStatusChecker from './services/hooks/useTerminalStatusChecker'
import KeyboardWrapper from './modules/utilities/KeyboardWrapper'

const App = () => {
   useRedirectToDashboard()
   useTerminalStatusChecker()

   return (
      <Suspense fallback={<LayoutSplashScreen />}>
         <LayoutProvider>
            <Helmet>
               <meta
                  name='viewport'
                  content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
               />
            </Helmet>
            <Outlet />
            <MasterInit />
            {/* <KeyboardWrapper keyboardRef={keyboard} onChange={setInput} /> */}
            <KeyboardWrapper/>
         </LayoutProvider>
      </Suspense>
   )
}

export {App}
