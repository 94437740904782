import {TizenDebug} from '../../modules/hotel/FaceAuth/TizenIntegration'
import EventEmitter from 'events'

export const PORT_EVENT_TYPES = {
   PAPER_CHECK: 'PAPER_CHECK',
   PRINT_RECEIPT: 'PRINT_RECEIPT',
   RESET: 'RESET',
} as const

export const PAPER_QUERY_RESULTS = {
   OUT_OF_PAPER: '0c',
   NEARLY_OUT: '03',
   OK: '00',
   NO_ROLL: '0f',
} as const

export type PortEventTypes = typeof PORT_EVENT_TYPES[keyof typeof PORT_EVENT_TYPES]

type PrinterPorts = 'PRINTERPORT0' | 'PRINTERPORT1' | 'PRINTERPORT2'
type DataBitOptions = 'BITS5' | 'BITS6' | 'BITS7' | 'BITS8'
type StopBitsOptions = '1' | '1.5' | '2'
type ParityOptions = 'NONE' | 'ODD' | 'EVEN'
type TextSizes = '00' | '11' | '22' | '33' | '44' | '55' | '66'

export type PortEvent = {
   type: PortEventTypes
   data: any
}
export type Printer = {
   onPortPrint: (event: PortEvent) => void
}

export class PrinterService {
   private PRINT_PORT: PrinterPorts = 'PRINTERPORT1'
   private DATA_BITS_OPTION: DataBitOptions = 'BITS8'
   private STOP_BITS_OPTION: StopBitsOptions = '1'
   private PARITY_OPTION: ParityOptions = 'NONE'
   private BAUDRATE: number = 115200

   private Text1: TextSizes = '00'
   private Text2: TextSizes = '11'
   private Text3: TextSizes = '22'
   private Text4: TextSizes = '33'
   private Text5: TextSizes = '44'
   private Text6: TextSizes = '55'
   private Text7: TextSizes = '66'

   private COMMANDS = {
      CHECK_PAPER_CMD: '1B76',
      PRINT_BUFFER_AND_NEW_LINE: '0A0A0A0A1B69',
      RESET_PRINTER: '1B40',
   }

   private onPortPrint: ((event: PortEvent) => void) | undefined
   private currentCommand: PortEventTypes = 'PRINT_RECEIPT'
   public paperCheckEvent = new EventEmitter()

   private portIsOpen = false

   constructor(initParams?: Printer) {
      TizenDebug('[SerialPrint API][close port] function call')
      const printPort = this.PRINT_PORT
      try {
         // @ts-ignore
         const didClose: boolean = window.b2bapis.serialprint.close(printPort)
         if (!didClose) {
            TizenDebug('[SerialPrint API]Failed to close print serial port')
         }
      } catch (e: any) {
         TizenDebug(
            '[SerialPrint API][close] call syncFunction exception ' + e.code + ' ' + e.errorName + ' ' + e.errorMessage
         )
      }
      if (initParams) {
         this.onPortPrint = initParams.onPortPrint
      }
   }

   /**
    * This method opens the printers port which is required to be open when printing.
    * @return True if the printer's port was successfully opened
    */
   openSerialPort() {
      const options = {
         baudRate: this.BAUDRATE,
         parity: this.PARITY_OPTION,
         dataBits: this.DATA_BITS_OPTION,
         stopBits: this.STOP_BITS_OPTION,
      }
      const printPort = this.PRINT_PORT

      const onlistener = (printSerialData: any) => {
         TizenDebug(
            '[SerialPrint Callback] Print result code is ' +
               printSerialData.data +
               ', Print serial Port is === ' +
               printSerialData.result
         )
         const printerEvent: PortEvent = {
            type: this.currentCommand,
            data: printSerialData.data,
         }
         this.paperCheckEvent.emit(PORT_EVENT_TYPES.PAPER_CHECK, printerEvent)
         if (this.onPortPrint) {
            this.onPortPrint(printerEvent)
         }
         return
      }

      try {
         // @ts-ignore
         const result: boolean = window.b2bapis.serialprint.open(printPort, options, onlistener)
         if (result) {
            TizenDebug('[SerialPrint API] Serial port opened')
            this.portIsOpen = true
         }
         return true
      } catch (e: any) {
         TizenDebug(
            '[SerialPrint API][open] call syncFunction exception ' + e.code + ' ' + e.errorName + ' ' + e.errorMessage
         )
         return false
      }
   }

   /**
    * Commands the Tizen printer to perform a paper check.
    * @return Promise<PortEvent>
    */
   checkPaperStatus() {
      return new Promise<PortEvent>((resolve, reject) => {
         if (!this.portIsOpen) {
            return reject(new NoPortOpenError())
         }
         const handlePaperCheck = (data: PortEvent) => {
            this.paperCheckEvent.removeListener(PORT_EVENT_TYPES.PAPER_CHECK, handlePaperCheck)
            resolve(data)
         }
         this.paperCheckEvent.on(PORT_EVENT_TYPES.PAPER_CHECK, handlePaperCheck)

         TizenDebug('[SerialPrint API][write] function call')
         this.currentCommand = 'PAPER_CHECK'
         const data = this.COMMANDS.CHECK_PAPER_CMD
         try {
            // @ts-ignore
            const result = window.b2bapis.serialprint.writeData(this.PRINT_PORT, data, data.length)
            if (result == 0) {
               TizenDebug('[SerialPrint API][writeData] no data written')
            }
            // return resolve('success')
         } catch (e: any) {
            TizenDebug(
               '[SerialPrint API][writeData] call syncFunction exception ' +
                  e.code +
                  ' ' +
                  e.errorName +
                  ' ' +
                  e.errorMessage
            )
            return reject(e)
         }
      })
   }

   resetPrinter() {
      if (!this.portIsOpen) throw new NoPortOpenError()
      TizenDebug('[SerialPrint API][write] function call')
      this.currentCommand = 'RESET'
      const data = this.COMMANDS.RESET_PRINTER
      try {
         // @ts-ignore
         const result = window.b2bapis.serialprint.writeData(this.PRINT_PORT, data, data.length)
         if (result == 0) {
            TizenDebug('[SerialPrint API][writeData] no data written')
         }
      } catch (e: any) {
         TizenDebug(
            '[SerialPrint API][writeData] call syncFunction exception ' +
               e.code +
               ' ' +
               e.errorName +
               ' ' +
               e.errorMessage
         )
      }
   }

   /**
    *
    * @param receiptData
    * @exception NoPortOpenError
    */
   print(receiptData: string) {
      if (!this.portIsOpen) throw new NoPortOpenError()
      TizenDebug('[SerialPrint API][writeData] function call')
      const data = this.stringToHex(receiptData)
      try {
         // @ts-ignore
         const result = window.b2bapis.serialprint.writeData(this.PRINT_PORT, data, data.length)
         if (result == 0) {
            TizenDebug('[SerialPrint API][writeData] no data written')
         }
      } catch (e: any) {
         TizenDebug(
            '[SerialPrint API][writeData] call syncFunction exception ' +
               e.code +
               ' ' +
               e.errorName +
               ' ' +
               e.errorMessage
         )
      }
   }

   private static generateBarcode(barcode: string) {
      let oddNumber = 0
      let evenNumber = 0
      for (let i = 0; i < barcode.length; i++) {
         i % 2 ? (evenNumber += parseInt(barcode[i])) : (oddNumber += parseInt(barcode[i]))
      }
      let keyNumber = 10 - ((oddNumber * 3 + evenNumber) % 10)
      if (keyNumber === 10) keyNumber = 0
      return barcode + keyNumber
   }

   /**
    *
    * @param tmp
    * @param barStr Barcode String. 11 Digits.
    */
   private stringToHex(tmp: string, barStr: string | null = null) {
      let str = ''
      let tmp_len = tmp.length
      let formatCode
      let tag

      for (let i = 0; i < tmp_len; i++) {
         if (tmp[i] === '<') {
            tag = true
         } else if (tmp[i] === '>') {
            tag = false
         } else {
            if (tag) {
               switch (tmp[i]) {
                  // --Text Align Left
                  case 'l':
                     formatCode = '1B6100'
                     break
                  // --Text Align Center
                  case 'c':
                     formatCode = '1B6101'
                     break
                  // --Text Align Right
                  case 'r':
                     formatCode = '1B6102'
                     break
                  // --Underline Text
                  case 'u':
                     formatCode = '1B2D01'
                     break
                  // --Bold Text
                  case 'b':
                     formatCode = '1B4701'
                     break
                  // --Text Size 1
                  case '1':
                     //width/height
                     formatCode = '1D21' + this.Text1
                     break
                  // --Text Size 2
                  case '2':
                     formatCode = '1D21' + this.Text2
                     break
                  // --Text Size 3
                  case '3':
                     formatCode = '1D21' + this.Text3
                     break
                  // --Text Size 4
                  case '4':
                     formatCode = '1D21' + this.Text4
                     break
                  // --Text Size 5
                  case '5':
                     formatCode = '1D21' + this.Text5
                     break
                  // --Text Size 6
                  case '6':
                     formatCode = '1D21' + this.Text6
                     break
                  // --Text Size 7
                  case '7':
                     formatCode = '1D21' + this.Text7
                     break
                  // --Barcode
                  case 'w':
                     //-- Setting Barcode Size. Max size 08
                     formatCode = '1D77' + this.Text1
                     if (barStr !== null) {
                        const barcodeString = PrinterService.generateBarcode(barStr)
                        const hexString = this.stringToHex(barcodeString)
                        formatCode = formatCode + '1D6B00' + hexString + '0A0A'
                     } else {
                        formatCode = undefined
                     }
                     break
                  // --Enter
                  case 'e':
                     formatCode = '0A'
                     break
                  // --Set Default Values
                  //FIXME: Need to throw error if forward slash does not come after a open tag or text
                  case '/':
                     formatCode = '1B61001B2D001B47001D2100'
                     break
                  default:
                     break
               }
            } else {
               formatCode = tmp.charCodeAt(i).toString(16)
            }
            if (formatCode === undefined) throw new Error('Invalid token')
            str += formatCode.toString()
         }

         //If at end of str add termination cmd
         if (i == tmp_len - 1) str += '0A0A0A0A1B69'
      }
      return str
   }
}

export class NoPortOpenError extends Error {
   constructor(message = "The printer's port must be open to print to it.") {
      super(message)
      this.name = 'NoPortOpenError'
      this.message = message
   }
}

export const instanceOfPortEvent = (object: any): object is PortEvent => {
   return 'type' in object && 'data' in object
}
