import {useEffect, useReducer, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {HeaderWrapper} from '../../../_metronic/layout/components/header/HeaderWrapper'
import {useErrorAlert} from '../utilities/alerts'
import {LocationSelectionStep} from './components/LocationSelectionStep'
import {TerminalSelectionStep} from './components/TerminalSelectionStep'
import {CreateTerminalStep} from './components/CreateTerminalStep'
import {DownloadSettingsStep} from './components/DownloadSettingsStep'
import {getAuth} from '../auth/core/AuthHelpers'
import {useDispatch, useSelector} from 'react-redux'
import type {AppDispatch, RootState} from '../../store'
import {getSelectedLocation} from '../../services/SelectedLocationSlice'
import {constants} from '../../config/constants'
import {setFlow} from '../../services/FlowSlice'
import {SoftPointLocation} from '../../common/schemas/LocationSchema'
import {useIntl} from 'react-intl'
import {getSoftPointLogo, parseRequestError} from '../../common/utilities'
import {getSelectedTerminal} from '../../services/SelectedTerminalSlice'
import {useFetchKioskSettings} from '../../common/apis/kiosk_settings_request'
import {useKioskProducts} from '../../common/hooks/useKioskProducts'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'
import {setOrderType} from '../../services/SelfOrderingUserSlice'

export type StepConfigs = {create_new_terminal?: boolean} | null

type stepName = 'SELECT_LOCATION' | 'SELECT_TERMINAL' | 'CREATE_TERMINAL' | 'DOWNLOAD_CONFIGS'
type stepActions = {
   type: 'NEXT' | 'PREV'
   payload: StepConfigs
}

type StepState = {
   prev: stepName
   current: stepName
   next: stepName
}

const STEP_STATES: StepState[] = [
   {
      prev: 'SELECT_LOCATION',
      current: 'SELECT_LOCATION',
      next: 'SELECT_TERMINAL',
   },
   {
      prev: 'SELECT_LOCATION',
      current: 'SELECT_TERMINAL',
      next: 'DOWNLOAD_CONFIGS',
   },
   {
      prev: 'SELECT_TERMINAL',
      current: 'CREATE_TERMINAL',
      next: 'DOWNLOAD_CONFIGS',
   },
]

export const TerminalConfigurationScreen = () => {
   const intl = useIntl()
   const dispatch = useDispatch<AppDispatch>()
   const kioskSettings = useSelector((state: RootState) => state.kiosk_settings.value)
   const locationDerivedData = useSelector((state: RootState) => state.location_data.derived_values)
   const selectedLocation = useSelector(getSelectedLocation)
   const selectedTerminal = useSelector(getSelectedTerminal)
   const fetchKioskSettings = useFetchKioskSettings()
   const navigateTo = useNavigate()
   const location = useLocation()
   const [CallSync, setCallSync] = useState<boolean>(false)
   const [redirectToLoginTimeOutId, setRedirectToLoginTimeOutId] = useState<any>(null) //used to help trigger the useEffect in each step with the backButton from the header
   const fireError = useErrorAlert()
   const logoPath = getSoftPointLogo()
   const {orderingEnabled, checkInEnabled,DineinAndTakeOutBothEnable} = useKioskProducts()
   const paths = MODULE_ROUTE_PATHS
   if (kioskSettings) {
      if (kioskSettings.primary_color) {
         const root = document.documentElement
         root.style.setProperty('--kt-primary-active', kioskSettings.primary_color)
         root.style.setProperty('--kt-primary', kioskSettings.primary_color)
         root.style.setProperty('--kt-text-primary', kioskSettings.primary_color)
      }
   }

   const stepDictionary = STEP_STATES.reduce((dictionary, advanceStep) => {
      dictionary[advanceStep.current] = advanceStep
      return dictionary
   }, {} as {[key: string]: StepState})

   const stepReducer = (currentStep: stepName, action: stepActions) => {
      switch (action.type) {
         case 'NEXT':
            switch (currentStep) {
               case 'SELECT_LOCATION':
                  if (selectedLocation) {
                     if (locationIsNotConfigured(selectedLocation)) {
                        fireError(intl.formatMessage({id: 'kiosk.terminal.location_not_configured'}))
                        return currentStep
                     } else {
                        // TODO: Consider removing this api call as it happens on location selection on row click
                        GetKioskSettings(selectedLocation.location_id)
                        return stepDictionary[currentStep].next
                     }
                  } else {
                     return currentStep
                  }
               case 'SELECT_TERMINAL':
                  if (action.payload?.create_new_terminal === true) {
                     let timeoutId = setTimeout(() => {
                        navigateTo(MODULE_ROUTE_PATHS.app_auth.index)
                     }, 180000)
                     setRedirectToLoginTimeOutId(timeoutId)
                     return 'CREATE_TERMINAL'
                  }
                  if (selectedTerminal) {
                     setCallSync(true)
                     // TODO: Consider removing this api call as it happens on location selection on row click
                     GetKioskSettings(selectedTerminal.location_id)
                     return stepDictionary[currentStep].next
                  } else {
                     fireError(intl.formatMessage({id: 'kiosk.terminal.select_terminal'}))
                     return currentStep
                  }
               case 'CREATE_TERMINAL':
                  setCallSync(true)
                  return stepDictionary[currentStep].next
               default:
                  return currentStep
            }
         case 'PREV':
            return stepDictionary[currentStep].prev
         default:
            return currentStep
      }
   }
   const [currentStep, dispatchStep] = useReducer(stepReducer, 'SELECT_LOCATION')

   const handleNextStep = (stepData: StepConfigs) => dispatchStep({type: 'NEXT', payload: stepData})
   const handlePrevStep = () => dispatchStep({type: 'PREV', payload: {}})

   function locationIsNotConfigured(location: SoftPointLocation) {
      const KioskLen = location._embedded.location_products.filter((e) => {
         return e.name == 'Self Ordering Kiosk' && e.status == 1
      }).length
      const CheckInLen = location._embedded.location_products.filter((e) => {
         return e.name == 'Check In' && e.status == 1
      }).length
      return KioskLen <= 0 && CheckInLen <= 0
   }

   function NavigationCheck(path: string) {
      const checkInProductsDisabled =
         !locationDerivedData.product_statuses.reservationsEnabled && kioskSettings!.check_in_walk_up === 0
      const orderingProductsDisabled = kioskSettings!.dine_in === null && kioskSettings!.order_type_id === null

      if (path == paths.checkIn.check_in_home && (kioskSettings!.status == 0 || checkInProductsDisabled)) {
         fireError(intl.formatMessage({id: 'kiosk.terminal.checkin_service_unavailable'})).then((input) =>
            navigateTo(paths.app_auth.index)
         )
      } else if (path == paths.ordering.ORDER_HOME && (kioskSettings!.status === 0 || orderingProductsDisabled)) {
         fireError(intl.formatMessage({id: 'kiosk.terminal.order_service_unavailable'})).then((input) =>
            navigateTo(paths.app_auth.index)
         )
      } else {
         navigateTo(path)
      }
   }
   function navigateHome() {
      if (checkInEnabled && orderingEnabled) {
         navigateTo(paths.dashboard.index)
      } else if (checkInEnabled) {
         dispatch(setFlow(constants.flows.checkin))
         NavigationCheck(paths.checkIn.check_in_home)
      } else if (orderingEnabled) {
         dispatch(setFlow(constants.flows.ordering))
         NavigationCheck(paths.ordering.ORDER_HOME)
      } else {
         fireError(intl.formatMessage({id: 'kiosk.terminal.location_not_configured'})).then((input) =>
            navigateTo(paths.app_auth.index)
         )
      }
   }

   function GetKioskSettings(locationId: number) {
      let user = getAuth()
      const user_id = user?.user_id
      if (user_id) {
         fetchKioskSettings(locationId, user_id)
            .then()
            .catch((error) => {
               const standardError = parseRequestError(error)
               let translatedMessage = standardError.message
               if (standardError.code < 0) {
                  translatedMessage = intl.formatMessage({id: standardError.message})
               }
               fireError(translatedMessage)
            })
      }
   }

   useEffect(() => {
      return () => {
         if (redirectToLoginTimeOutId != null) {
            clearTimeout(redirectToLoginTimeOutId)
         }
      }
   }, [])

   return (
      <div>
         <HeaderWrapper
            to={paths.terminal_configuration.index}
            useSVG={true}
            viewDateTime={false}
            styles={{height: 'auto'}}
            imgClassName={'svg-icon-2tx'}
            pathname={location.pathname}
            LogoFullPath={false}
            imgURL={logoPath}
            onBackButton={handlePrevStep}
         />
         <div className='stepper stepper-pills' style={{marginTop: '7.7rem'}} id='kt_stepper_example_basic'>
            <form>
               {currentStep === 'SELECT_LOCATION' && (
                  <div className='flex-column current'>
                     <LocationSelectionStep handleNextStep={handleNextStep} />
                  </div>
               )}

               {currentStep === 'SELECT_TERMINAL' && (
                  <div className='flex-column'>
                     <TerminalSelectionStep handleNextStep={handleNextStep} />
                  </div>
               )}

               {currentStep === 'CREATE_TERMINAL' && (
                  <div className='flex-column'>
                     <CreateTerminalStep handleNextStep={handleNextStep} />
                  </div>
               )}
               {currentStep === 'DOWNLOAD_CONFIGS' && (
                  <div className='flex-column'>
                     <DownloadSettingsStep CallSync={CallSync} onSettingsDownloaded={navigateHome} fullRefresh={false} />
                  </div>
               )}
            </form>
         </div>
      </div>
   )
}
