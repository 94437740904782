import {ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../store'
import {LayoutType, displayKeyboard, setInput, setInputName} from '../KeyboardSlice'
import {useKeyboardRef} from '../../common/KeyboardProvider'

export const useKeyboard = () => {
   const dispatch = useDispatch<AppDispatch>()
   const {keyboardRef} = useKeyboardRef()
   const _keyboard = useSelector((state: RootState) => state.keyboard)

   return {
      getInputValue: (inputName: string) => {
         return _keyboard.inputs[inputName] ?? ''
      },
      setActiveInput: (inputName: string, layoutName: LayoutType = 'default') => {
         dispatch(setInputName(inputName))
         if (inputName !== '') {
            dispatch(displayKeyboard(layoutName))
         }
         console.log('Active input', inputName)
      },
      onChangeInput: (event: ChangeEvent<HTMLInputElement>) => {
         let inputVal = event.target.value

         dispatch(
            setInput({
               ..._keyboard.inputs,
               [_keyboard.inputName]: inputVal,
            })
         )

         if (keyboardRef.current) {
            keyboardRef.current.setInput(inputVal)
         }
      },
      clearInput: (inputName: string | undefined = undefined, destroy: boolean = false) => {
         console.log('[useKeyboard::clearInput]', _keyboard.inputs)
         let clonedInputs = {..._keyboard.inputs}
         if (!inputName) {
            inputName = _keyboard.inputName
         }
         if (destroy) {
            let filteredObject: {[key: string]: string} = {}
            for (const pair of Object.entries(clonedInputs)) {
               if (pair[0] !== inputName) {
                  filteredObject[pair[0]] = pair[1]
               }
            }
            console.log('[useKeyboard::filteredObject]', filteredObject)
            dispatch(setInput(filteredObject))
            if (keyboardRef.current) {
               keyboardRef.current.replaceInput({...filteredObject, default: ''})
            }
         } else {
            clonedInputs[inputName] = ''
            dispatch(setInput(clonedInputs))

            if (keyboardRef.current) {
               keyboardRef.current.clearInput(inputName)
            }
         }
      },
      clearInputs: (inputNames: string[]) => {
         console.log('[useKeyboard::clearInputs]', _keyboard.inputs)
         let clonedInputs = {..._keyboard.inputs}

         inputNames.forEach((inputName) => {
            clonedInputs[inputName] = ''
         })

         dispatch(setInput(clonedInputs))
         if (keyboardRef.current) {
            for (const inputName of inputNames) {
               keyboardRef.current.clearInput(inputName)
            }
         }
      },
      setSingleInputValue: (inputName: string, value: string) => {
         dispatch(
            setInput({
               ..._keyboard.inputs,
               [_keyboard.inputName]: value,
            })
         )

         if (keyboardRef.current) {
            keyboardRef.current.setInput(value, inputName)
         }
      },
   }
}
