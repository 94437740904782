import {FC, SetStateAction, useEffect, useState, Dispatch, ChangeEvent} from 'react'
import {useSelector} from 'react-redux'

import {getAuth, GetApiUrl} from '../../auth'

import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {Field, Formik, ErrorMessage, Form, useFormikContext} from 'formik'
import Inputmask from 'inputmask'
import axios from 'axios'
import {RootState} from '../../../store'
import Swal from 'sweetalert2'

import * as Yup from 'yup'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {useErrorAlert, useLoadingAlert} from '../../utilities/alerts'
import {parseRequestError} from '../../../common/utilities'
import {useNavigate} from 'react-router-dom'
import {MODULE_ROUTE_PATHS} from '../../../routing/RoutePaths'
import {KIOSK_GLOBAL_ON} from '../../../config/constants'
import {getSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {Terminal} from '../../../common/schemas/TerminalSchema'
import {useQuickPointPrint} from '../requests/receipts'
import {useCart} from '../../../services/hooks/useCart'
import {useKeyboard} from '../../../services/hooks/useKeyboard'
import {KeyboardField} from '../../../common/components/KeyboardField'
import {SoftPointLocation} from '../../../common/schemas/LocationSchema'

interface ReceiptPrintModalProps {
   TicketId: number | null
   phonePreview: string
   paymentId: number | null
   setReceiptType: Dispatch<SetStateAction<'' | 'Phone' | 'Email'>>
}
const loginSchema = Yup.object().shape({
   email: Yup.string().email('Wrong email format').required(),
})

const loginSchemaPhone = Yup.object().shape({
   phoneNumber: Yup.string().required().label('Phone Number'),
})

type ModalView = 'DEFAULT_VIEW' | 'PHONE_VIEW' | 'EMAIL_VIEW'

const rowButtonStyle = {
   padding: '15px 0px',
   fontSize: '25px',
   borderRadius: '10px',
   height: '70px',
}

const squareButtonStyle = {
   padding: '0px',
   margin: '5px',
   fontSize: '25px',
   minWidth: '220px',
   minHeight: '220px',
   maxWidth: '220px',
   maxHeight: '220px',
}

export const ReceiptsModalBody: FC<ReceiptPrintModalProps> = ({TicketId, phonePreview, paymentId, setReceiptType}) => {
   const user = getAuth()
   const location_data = useSelector((state: RootState) => state.location_data.value) as SoftPointLocation
   const kiosk_settings = useSelector((state: RootState) => state.kiosk_settings.value) as KioskSettings
   const terminal = useSelector(getSelectedTerminal) as Terminal
   const fireError = useErrorAlert()
   const fireLoadingAlert = useLoadingAlert()
   const PrintPaperReceipt = useQuickPointPrint()
   const {ResetCart} = useCart()
   const navigateTo = useNavigate()
   const [currentView, updateView] = useState<ModalView>('DEFAULT_VIEW')
   const BtnClrClass = useLocationBasedBtnColor()
   const [phoneNumber, setPhoneNumber] = useState<string>('')
   const {getInputValue, onChangeInput, setActiveInput} = useKeyboard()

   const initialValues = {
      email: '',
   }
   interface EmailFormInt {
      email: string
   }
   const initialValuesPhone = {
      phoneNumber: phoneNumber,
   }
   interface PhoneFromInt {
      phoneNumber: string
   }
   const hideLoader = () => {
      Swal.close()
   }
   useEffect(() => {
      setPhoneNumber(phonePreview)
   }, [phonePreview])

   const handleSubmitPhone = (Values: PhoneFromInt) => {
      let formattedPhoneNumber = Values.phoneNumber.replace(/[^\d]/g, '')
      if (location_data.calling_code) {
         formattedPhoneNumber = location_data.calling_code.replace('+', '') + formattedPhoneNumber
      }
      fireLoadingAlert('Processing...')
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${
         kiosk_settings.location_id
      }/tickets/${TicketId}/digitalreceipt?global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${user_id}`
      axios
         .post(url, {phone: formattedPhoneNumber})
         .then((response) => {
            hideLoader()
            ResetCart()
            navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
         })
         .catch((error) => {
            hideLoader()
            const standardError = parseRequestError(error)
            fireError(standardError.message)
         })
   }

   const handleSubmitEmail = (Values: EmailFormInt) => {
      fireLoadingAlert('Processing...')
      const user_id = user?.user_id
      const url = `${GetApiUrl()}/locations/${
         kiosk_settings.location_id
      }/tickets/${TicketId}/digitalreceipt?global_on_id=${process.env.REACT_APP_KIOSK_GLOBAL_ON}&user_id=${user_id}`

      axios
         .post(url, {email: Values.email})
         .then((response) => {
            hideLoader()
            ResetCart()
            navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
         })
         .catch((error) => {
            hideLoader()
            const standardError = parseRequestError(error)
            fireError(standardError.message)
         })
   }

   const handlePrintPaperCopy = () => {
      console.log('[handlePrintPaperCopy TicketId]', TicketId)
      console.log('[handlePrintPaperCopy paymentId]', paymentId)
      if (TicketId == null || paymentId == null) {
         return
      }
      if (terminal.paired_terminal_id) {
         const payload = {
            ticket_id: TicketId,
            terminal_id: terminal.id,
            device_id: terminal.paired_terminal_id,
            payment_id: paymentId,
         }
         PrintPaperReceipt(payload, () => {
            ResetCart()
            navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
         })
      }
   }

   const startNewOrder = () => {
      ResetCart()
      navigateTo(MODULE_ROUTE_PATHS.ordering.ORDER_HOME)
   }

   useEffect(() => {
      if (currentView == 'PHONE_VIEW') {
         Inputmask({
            mask: '(999) 999-9999',
         }).mask('#phoneNumber')
         setReceiptType('Phone')
      } else if (currentView == 'EMAIL_VIEW') {
         setReceiptType('Email')
      } else {
         setReceiptType('')
      }
   }, [currentView])

   return (
      <>
         <div className='modal-body row d-flex justify-content-center h-100'>
            {currentView == 'DEFAULT_VIEW' && (
               <div className='w-100'>
                  <div className='text-center'>
                     <h1 className='text-center pb-10'>
                        <i className='bi bi-check-circle text-success fs-4x my-1 pe-0'></i>
                     </h1>
                     <h1 className='text-center pb-10' style={{fontSize: '25px'}}>
                        How would you like your receipt?
                     </h1>
                  </div>
                  <div className='col d-flex justify-content-center'>
                     <button
                        className='btn bg-light '
                        style={squareButtonStyle}
                        onClick={() => updateView('PHONE_VIEW')}
                        hidden={!kiosk_settings.receipt_text}
                     >
                        <div className=' my-auto mx-auto'>
                           <i className='bi bi-phone fs-3hx my-1 pe-0'></i>
                           <span className='d-block fw-bold'>Text Receipt</span>
                        </div>
                     </button>

                     <button
                        className='btn bg-light '
                        style={squareButtonStyle}
                        onClick={() => updateView('EMAIL_VIEW')}
                        hidden={!kiosk_settings.receipt_email}
                     >
                        <div className=' my-auto mx-auto'>
                           <i className='bi bi-envelope fs-3hx my-1 pe-0'></i>
                           <span className='d-block fw-bold'>Email Receipt</span>
                        </div>
                     </button>

                     <button
                        className='btn bg-light '
                        style={squareButtonStyle}
                        hidden={!kiosk_settings.receipt_print}
                        onClick={handlePrintPaperCopy}
                     >
                        <div className=' my-auto mx-auto'>
                           <i className='bi bi-receipt fs-3hx my-1 pe-0'></i>
                           <span className='d-block fw-bold'>Print Receipt</span>
                        </div>
                     </button>
                  </div>
                  <div className='w-100'>
                     <button
                        type='button'
                        className={`btn mt-10 btn-bg-primary ms-2 w-100 ${BtnClrClass}`}
                        style={rowButtonStyle}
                        onClick={startNewOrder}
                     >
                        No, thank you
                     </button>
                  </div>
               </div>
            )}

            {currentView == 'PHONE_VIEW' && (
               <div className='w-100'>
                  <div className='mt-10 w-100'>
                     <Formik
                        validationSchema={loginSchemaPhone}
                        initialValues={initialValuesPhone}
                        onSubmit={(values, error) => {
                           handleSubmitPhone(values)
                        }}
                     >
                        {({errors, touched}) => (
                           <Form className='form w-100' noValidate id='kt_send_to_phone_form'>
                              <div className='col-lg-12 pb-5 fv-row fv-plugins-icon-container'>
                                 <KeyboardField
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    placeholder='(000) 000-0000'
                                    setActiveInput={setActiveInput}
                                 />
                                 <div className='text-danger ps-1 mb-3'>
                                    <ErrorMessage name='phoneNumber' />
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className='col'>
                                    <button
                                       style={rowButtonStyle}
                                       type='button'
                                       className='btn w-100 btn-bg-secondary me-2'
                                       onClick={() => updateView('DEFAULT_VIEW')}
                                    >
                                       Cancel
                                    </button>
                                 </div>
                                 <div className='col'>
                                    <button
                                       style={rowButtonStyle}
                                       type='submit'
                                       className={`btn w-100  btn-light btn-primary btn-lg me-5 ${
                                          BtnClrClass != '' ? BtnClrClass : 'text-white'
                                       }`}
                                    >
                                       Send Receipt
                                    </button>
                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>
                  </div>
               </div>
            )}

            {currentView == 'EMAIL_VIEW' && (
               <div className='w-100'>
                  <div className='mt-10 w-100'>
                     <Formik
                        validationSchema={loginSchema}
                        initialValues={initialValues}
                        onSubmit={(values, error) => {
                           handleSubmitEmail(values)
                        }}
                     >
                        {({errors, touched}) => (
                           <Form className='form w-100' noValidate id='kt_send_to_email_form'>
                              <div className='col-lg-12 pb-5 fv-row fv-plugins-icon-container'>
                                 <KeyboardField
                                    id='email'
                                    name='email'
                                    placeholder='Email'
                                    setActiveInput={setActiveInput}
                                 />
                                 <div className='text-danger ps-1 mb-3'>
                                    <ErrorMessage name='email' />
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className='col'>
                                    <button
                                       style={rowButtonStyle}
                                       type='button'
                                       className='btn w-100 btn-bg-secondary me-2'
                                       onClick={() => updateView('DEFAULT_VIEW')}
                                    >
                                       Cancel
                                    </button>
                                 </div>
                                 <div className='col'>
                                    <button
                                       style={rowButtonStyle}
                                       type='submit'
                                       className={`btn w-100  btn-light btn-primary btn-lg me-5 ${
                                          BtnClrClass != '' ? BtnClrClass : 'text-white'
                                       }`}
                                    >
                                       Send Receipt
                                    </button>
                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>
                  </div>
               </div>
            )}
         </div>
      </>
   )
}
