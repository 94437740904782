import {useRef, useEffect, FC, Dispatch} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import {TipsButton} from './Tipsbutton'
import {useLocationBasedBtnColor} from '../../../common/hooks/buttons'
import {FormattedMessage} from 'react-intl'
import {BasisPoints} from '../../../services/hooks/useCart'
import {getKioskSettings} from '../../../services/KioskSettingSlice'
import {KioskSettings} from '../../../common/schemas/KioskSettingsSchema'
import {updateTotalsWithNotTip} from '../../../services/SelfOrderingCartSlice'
import {useKeyboard} from '../../../services/hooks/useKeyboard'
import {ActiveTip, setCustomTip, setDefaultTip, setPresetTip, TipReducerAction} from '../hooks/useTipSelectorMachine'

interface IProps {
   activeTipOption: ActiveTip
   dispatchTipSelection: Dispatch<TipReducerAction>
   readonly customTipFieldName: string
}
export const TipSelector: FC<IProps> = ({activeTipOption, dispatchTipSelection, customTipFieldName}) => {
   const dispatch = useDispatch<AppDispatch>()
   const dynamicBtnColor = useLocationBasedBtnColor()
   const customTipInputField = useRef<HTMLInputElement>(null)
   const settings = useSelector(getKioskSettings) as KioskSettings
   const csvTipOptions = settings.tip_percentage ?? ''
   const configuredTipOptions: string[] = csvTipOptions == '' ? [] : csvTipOptions.split(',')
   const activeCustomTipBtn = `btn active-tip-btn ${dynamicBtnColor} btn-no-hover no-border-radius border-start border-top border-bottom border-end col`
   const inactiveCustomTipBtn = `btn btn-no-hover no-border-radius border-start border-top border-bottom border-end text-gray-700 fw-semibold col`
   const {getInputValue, setActiveInput, clearInput} = useKeyboard()
   const _keyboard = useSelector((state: RootState) => state.keyboard)

   // Callback for selected percentage buttons in child component
   const handleActiveBtnCallback = (activePercentage: number): void => {
      console.log('[activePercentage]', activePercentage)
      dispatchTipSelection(setPresetTip(new BasisPoints(activePercentage, 'percent')))
      return
   }

   const handleNoneTipSelected = (tipAmount: number): number => {
      if (tipAmount == 0) {
         dispatch(updateTotalsWithNotTip())
      }
      dispatchTipSelection(setDefaultTip())
      return tipAmount
   }

   // Highlights the custom tip amount button
   const handleCustomTipSelected = () => {
      console.log('[handleCustomTipSelected]')
      if (activeTipOption.type !== 'custom') {
         dispatchTipSelection(setCustomTip(0))
      }
   }

   useEffect(() => {
      if (_keyboard.inputName == customTipFieldName) {
         const inputValue = getInputValue(customTipFieldName)
         console.log('[useEffect::getInputValue(custom-tip-input)]', inputValue)

         const inputAsNumber = getCurrencyValue(inputValue)
         const tipInCents = inputAsNumber * 100
         dispatchTipSelection(setCustomTip(tipInCents))
      }
   }, [getInputValue(customTipFieldName)])
   // useEffect(() => console.log('[activeTipOption]', activeTipOption), [activeTipOption])
   return (
      <div className='pb-2'>
         <h1 className='fw-semibold fs-1 form-label m-0'>
            <FormattedMessage id='olo.tips' defaultMessage='Tips' />
         </h1>
         <div className='row d-flex py-4 mx-0'>
            <button
               id='no-tip-button'
               className={
                  activeTipOption.type === 'none'
                     ? `btn ${dynamicBtnColor} active-tip-btn col border-radius-0 border border-end-0 rounded-start fs-3`
                     : `btn border-radius-0 border border-end-0 rounded-start text-gray-700 col fs-3`
               }
               onClick={() => handleNoneTipSelected(0)}
            >
               <FormattedMessage id='profile.receipt.none' defaultMessage='None' />
            </button>
            <>
               {configuredTipOptions.map((tipAmount, index) => {
                  return (
                     <TipsButton
                        key={index}
                        id={index === configuredTipOptions.length - 1 ? 'end-button-style' : ''}
                        tipPercentage={tipAmount}
                        activeBtn={activeTipOption.TipBasisPoints?.toPercent() ?? 0}
                        handleActiveBtnCallback={handleActiveBtnCallback}
                     />
                  )
               })}
            </>
         </div>
         <div className='d-flex row m-0 p-0 pb-10'>
            <button
               onClick={handleCustomTipSelected}
               className={`col-3 p-0 ${
                  activeTipOption.type === 'custom' ? activeCustomTipBtn : inactiveCustomTipBtn
               } rounded-start border-end-0 fs-3`}
            >
               <FormattedMessage id='profile.receipt.custom' defaultMessage='Custom' />
            </button>
            <div className='input-group col m-0 p-0'>
               <span
                  onClick={handleCustomTipSelected}
                  id='tip-input-group-text'
                  className='input-group-text p-0 w-100'
               >
                  <i className='bi bi-currency-dollar fs-5 text-gray-700 fw-semibold px-2'></i>

                  <input
                     ref={customTipInputField}
                     id={customTipFieldName}
                     className={`form-control form-control-lg form-control-solid rounded-end ps-0 fs-3 text-gray-700 fw-semibold lh-1 text-end ${
                        _keyboard.inputName == customTipFieldName && _keyboard.open ? 'keyboard-active' : ''
                     }`}
                     type='number'
                     readOnly={true}
                     value={getInputValue(customTipFieldName)}
                     onFocus={() => setActiveInput(customTipFieldName, 'money')}
                  />
               </span>
            </div>
         </div>
      </div>
   )
}

/**
 * Converts a numeric string value into a USD currency number
 * @param numericValue
 */
function getCurrencyValue(numericValue: string) {
   let inputAsNumber = +numericValue

   const numberParts = numericValue.split('.')
   if (numberParts.length === 2) {
      const truncatedDecimalValue = numberParts[1].slice(0, 2)
      inputAsNumber = +(numberParts[0] + '.' + truncatedDecimalValue)
   }
   return inputAsNumber
}
