import {IApiError, instanceOfError, IStandardError} from './models/SoftPointAPIs'
import {constants} from '../config/constants'
import {getAuth} from '../modules/auth'
import {IBaseRequest} from './api_interfaces/base'
import axios, {AxiosError} from 'axios'
import {ValidationError} from 'yup'
import {Integration} from './schemas/KioskSettingsSchema'
import {CategoryItem} from './schemas/SyncDataSchema'

export const getErrorMsg = (err?: any) => {
   const staticErrors = constants.errors.static
   let msg = staticErrors.default_error
   if (err === undefined) return msg
   const apiError: IApiError | any = err.response.data

   if (instanceOfError(apiError)) {
      msg = apiError.error.message
   }
   return msg
}

/**
 * Returns an object containing a user_id and global_on_id
 */
export const getBaseRequestPayload = (): IBaseRequest => {
   const userSession = getAuth()

   if (userSession === undefined) {
      throw new Error('Authentication error, no user found')
   }

   return {
      global_on_id: process.env.REACT_APP_KIOSK_GLOBAL_ON ?? 3,
      user_id: userSession?.user_id,
   }
}

/**
 * Takes a string and adds new lines after each period
 * @param rawMessage
 */
export const formatMessage = (rawMessage: string) => {
   const messageSentences = rawMessage.split('.')
   return messageSentences.reduce((accum, currentSentence, currentIndex) => {
      return `${accum}<br>${currentSentence}.`
   }, '')
}

/**
 * The method attempts to parse out an API response error returned by an Axios request.
 * @param error
 */
export const parseRequestError = (error: any | AxiosError | ValidationError): IStandardError => {
   if (error instanceof ValidationError) {
      console.log(error.errors)
      return {
         code: -2,
         message: constants.errors.static.vital_parse_error,
         details: null,
      }
   }

   if (axios.isAxiosError(error)) {
      const response = error.response
      if (response && instanceOfError(response.data)) {
         return response.data.error
      }

      return {
         code: -1,
         message: constants.errors.static.unexpected_contact_support,
         details: null,
      }
   } else {
      return {
         code: -1,
         message: constants.errors.static.unexpected_contact_support,
         details: null,
      }
   }
}

export const integrationHasKiosk = (locationIntegration: Integration | undefined) => {
   if (!locationIntegration) return false
   return locationIntegration.kiosk == constants.status.ON
}

export const integrationHasCheckin = (locationIntegration: Integration | undefined) => {
   if (!locationIntegration) return false
   return locationIntegration.checkin == constants.status.ON
}

/**
 * Evaluates whether the application is running in a debug environment
 */
export const inDebugEnv = () => {
   const debugEnvs = ['development', 'sandbox-debug', 'live-debug', 'local']
   const appEnv = process.env.REACT_APP_ENVIRONMENT
   if (!appEnv) return false
   return debugEnvs.includes(appEnv.toLowerCase())
}

/**
 * Return the project path of a theme based SoftPoint logo
 */
export const getSoftPointLogo = () => {
   return localStorage.getItem('kt_theme_mode_value') === 'light'
      ? '../media/logos/softpoint_small_black.svg'
      : '../media/logos/softpoint_small.svg'
}

export const getDisplayName = (item: CategoryItem) => {
   if (item.name_info && item.name_info != '') {
      return item.name_info
   }
   if (item.name && item.name != '') {
      return item.name
   }
   return ''
}

export const generateUniqueId = () => {
   const timestamp = Date.now().toString() // Convert current timestamp to a string
   const random = Math.floor(Math.random() * 10000).toString() // Generate a random number between 0 and 9999 and convert to a string
   const uniqueId = timestamp + random // Concatenate the timestamp and random number
   return parseInt(uniqueId).toString().slice(-9) // Convert the resulting string to a numeric value, then to a string, and extract the first 9 characters
}

export const sleep = (milliSeconds: number) => {
   return new Promise((resolve) => {
      window.setTimeout(resolve, milliSeconds)
   })
}
