import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../store'
import {GetApiUrl, getAuth} from '../../modules/auth'
import {StoreSelectedLocation} from '../SelectedLocationSlice'
import {KIOSK_GLOBAL_ON} from '../../config/constants'
import axios from 'axios'
import {MODULE_ROUTE_PATHS} from '../../routing/RoutePaths'

const useTerminalStatusChecker = () => {
   const navigateTo = useNavigate()
   const dispatch = useDispatch<any>()
   const routerLocation = useLocation()
   const excludedRoutes = ['/index.html', '/', MODULE_ROUTE_PATHS.app_auth.index, '/kiosk_terminal']
   const terminalInfo = useSelector((state: RootState) => state.selected_terminal)
   let kiosk_settings = useSelector((state: RootState) => state.kiosk_settings)

   const checkStatus = async () => {
      let user = getAuth()
      const user_id = user?.user_id
      const terminal_id: any = terminalInfo.value?.id ? terminalInfo.value.id : localStorage.getItem('terminal_id')

      const url = `${GetApiUrl()}/locations/${
         kiosk_settings?.value?.location_id
      }/terminals/${terminal_id}?global_on_id=${KIOSK_GLOBAL_ON}&user_id=${user_id}`
      try {
         const response = await axios.get(url)
         const data = response.data
         if (data?.terminals?.status == 0) {
            localStorage.setItem('location_data', '')
            dispatch(StoreSelectedLocation(null))
            navigateTo(MODULE_ROUTE_PATHS.terminal_configuration.index)
         }
      } catch (e) {}
   }

   useEffect(() => {
      if (!excludedRoutes.includes(window.location.pathname)) {
         checkStatus()
      }
   }, [routerLocation.pathname])

   return null
}

export default useTerminalStatusChecker
