import {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getSelectedTerminal, StoreSelectedTerminal} from '../../../services/SelectedTerminalSlice'
import {FormattedMessage} from 'react-intl'
import {TerminalValidationSchema} from '../../../common/schemas/TerminalSchema'
import {ValidationError} from 'yup'
import {parseRequestError} from '../../../common/utilities'
import {AppDispatch} from '../../../store'
import {TerminalUI, useFetchTerminals} from '../hooks/use_fetch_terminals'
import {useTranslatedPageTitle} from '../../../common/hooks/useTranslatedPageTitle'
import {constants} from '../../../config/constants'
import {StepConfigs} from '../TerminalConfigurationScreen'

interface TerminalStepProps {
   handleNextStep: (stepData: StepConfigs) => void
}

export const TerminalSelectionStep: FC<TerminalStepProps> = ({handleNextStep}) => {
   const dispatch = useDispatch<AppDispatch>()
   const [createNewTerminal, setCreateNewTerminal] = useState(false)
   const selectedTerminal = useSelector(getSelectedTerminal)
   const {terminals} = useFetchTerminals()

   useTranslatedPageTitle(constants.staticPageTitle.select_terminal)

   const HandleRowClick = async (terminalRow: TerminalUI) => {
      try {
         const terminal = await TerminalValidationSchema.validate(terminalRow)
         dispatch(StoreSelectedTerminal(terminal))
      } catch (error: any | ValidationError) {
         if (error instanceof ValidationError) {
            console.log(error.errors)
            parseRequestError(error.errors)
         }
         return parseRequestError(error)
      }
   }

   return (
      <div className='d-flex justify-content-center p-8'>
         <div className='w-100 pt-5'>
            <div className='card card-stretch card-bordered px-8 flex-height-kiosk'>
               <div className='pt-6'>
                  <h1 className='card-title fs-2tx'>
                     <FormattedMessage id='pagetitle.select_terminal' defaultMessage='Select Terminal' />
                  </h1>
                  <span className='text-gray-700 fs-2tx'>
                     <FormattedMessage
                        id='kiosk.step2.select_terminal_setting_up'
                        defaultMessage='Select the terminal setting up.'
                     />
                  </span>
               </div>
               <div style={{overflow: 'auto', maxHeight: '1480px'}} className='col'>
                  {terminals.map((element, i) => (
                     <div
                        onClick={(e) => {
                           HandleRowClick(element)
                        }}
                        key={i}
                        className='d-flex flex-stack align-items-center pt-10'
                     >
                        <p className='fs-2tx m-0'>
                           {' '}
                           {element.name} {element.dateDisp != '' ? `(Last Used ${element.dateDisp})` : ''}{' '}
                        </p>
                        <label
                           htmlFor='terminalSelectionList'
                           className='form-check-lg form-check-solid form-check-custom'
                        >
                           <input
                              className='form-check-input'
                              type='radio'
                              name={element.name ?? ''}
                              value={element.id}
                              onChange={() => {}}
                              checked={selectedTerminal?.id == element.id}
                           />
                        </label>
                     </div>
                  ))}
                  <div
                     onClick={(e) => {
                        setCreateNewTerminal(true)
                     }}
                     className='d-flex flex-stack align-items-center pt-10'
                  >
                     <p className='fs-2tx m-0'>
                        <FormattedMessage id='kiosk.step2.create_terminal' defaultMessage='Create Terminal' />
                     </p>
                     <label
                        htmlFor='terminalSelectionList'
                        className='form-check-lg form-check-solid form-check-custom'
                     >
                        <input
                           className='form-check-input'
                           type='radio'
                           name={'createTerminal'}
                           value={'Create Terminal'}
                           onChange={() => {}}
                           checked={createNewTerminal}
                        />
                     </label>
                  </div>
               </div>
               <div className='my-5 w-100'>
                  <button
                     onClick={() => handleNextStep(createNewTerminal ? {create_new_terminal: true} : null)}
                     type='button'
                     className='btn btn-lg btn-theme-primary w-100 py-8'
                     data-kt-stepper-action='next'
                  >
                     <span className='fs-2tx'>
                        <FormattedMessage id='kiosk.next_step' defaultMessage='Next Step' />
                     </span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   )
}
