import {TizenDebug} from '../../modules/hotel/FaceAuth/TizenIntegration'
import {ITizenNetworkStatus, ITizenSystemStatus} from '../../services/TizenSystemSlice'

// export interface ITizenNetworkStatus{
//     available:boolean,
//     type:string|null,
//     ip:string|null,
//     wifi_ssid:string|null
// }
//
// export interface ITizenSystemStatus{
//     network:ITizenNetworkStatus
//     device_uid:string|null
//     serial_number:string|null
// }

export class TizenSystem {
   public static getNetworkStatus() {
      let netStatus: ITizenNetworkStatus = {
         available: false,
         type: null,
         ip: null,
         wifi_ssid: null,
         alertDisplayed: false,
      }

      //@ts-ignore
      const networkTypeCode: number = webapis.network.getActiveConnectionType() //https://www.samsungdforum.com/B2B/CmsGuide/GuideView/8972
      const networkType = getNetworkType(networkTypeCode)
      if (networkType === 'ERROR') {
         return netStatus
      }
      // TizenDebug(`networkTypeCode = ${networkTypeCode}`)
      //@ts-ignore
      netStatus.ip = webapis.network.getIp() ?? null

      if (networkType !== 'DISCONNECTED') {
         netStatus.available = true
         netStatus.type = networkType
      }
      if (networkType === 'WIFI') {
         //@ts-ignore
         netStatus.wifi_ssid = webapis.network.getWiFiSsid() ?? null
      }
      // TizenDebug('From getNetworkStatus')
      return netStatus
   }

   public static getSystemSettings() {
      let tizenStatus: ITizenSystemStatus = {
         isLoading: false,
         network: {
            available: false,
            type: null,
            ip: null,
            wifi_ssid: null,
            alertDisplayed: false,
         },
         device_uid: null,
         serial_number: null,
         printer: {paper_status: 'OK'},
      }
      try {
         const newStatus = getNetworkStatus()
         if (newStatus === null) {
            throw new TizenNetworkError()
         }
         tizenStatus.network = newStatus

         // @ts-ignore
         tizenStatus.serial_number = b2bapis.b2bcontrol.getSerialNumber() ?? null

         //@ts-ignore
         tizenStatus.device_uid = webapis.productinfo.getDuid() ?? null
         // localStorage.setItem(TIZEN_KEYS.system_status, JSON.stringify(this.tizenStatus))
         TizenDebug(JSON.stringify(tizenStatus))
      } catch (e: any) {
         if (e instanceof ReferenceError) {
            console.log('Application is not running on Tizen OS')
         }
         if (e instanceof TizenNetworkError) {
            TizenDebug(e.message)
         }
         TizenDebug(e.message)
         console.log(e)
         return tizenStatus
         // localStorage.setItem(TIZEN_KEYS.system_status, JSON.stringify(this.tizenStatus))
      }
      return tizenStatus
   }
}

export const clientIsTizen = () => {
   // return true
   let result = false
   try {
      // @ts-ignore
      result = webapis !== undefined
   } catch (e: any) {
      TizenDebug(`[Tizen OS Check] ${e.message}`)
      return false
   }
   return result
}

//TODO: Implement network state monitor
export const initNetworkMonitor = () => {
   // const dispatch = useDispatch()
   // const onChange = function(netState:any) {
   //     TizenDebug(`[NetworkStateChangedCallback]: ${netState}`)
   //     //@ts-ignore
   //     if(webapis.network.NetworkState.GATEWAY_CONNECTED == netState){
   //         try{
   //             const onlineStatus: ITizenNetworkStatus | null = TizenSystem.getNetworkStatus()
   //             TizenDebug(`onlineStatus = ${onlineStatus ? JSON.stringify(onlineStatus) : 'null'}`)
   //             if(onlineStatus !== null){
   //                 dispatch(updateNetworkStatus(onlineStatus))
   //             }
   //         }
   //         catch (e:any) {
   //             TizenDebug('From Tizen OS')
   //             TizenDebug('An error occurred while retrieving the new network status')
   //             TizenDebug(`[Error code] ${e.code}`)
   //             TizenDebug(`[Message] = ${e.message}`)
   //         }
   //     }
   //     //@ts-ignore
   //     if(webapis.network.NetworkState.GATEWAY_DISCONNECTED == netState){
   //         const offlineStatus:ITizenNetworkStatus = {
   //             available:false,
   //             ip:null,
   //             wifi_ssid:null,
   //             type:null
   //         }
   //         dispatch(updateNetworkStatus(offlineStatus))
   //     }
   //
   // }
   // try {
   //     //@ts-ignore
   //     webapis.network.addNetworkStateChangeListener(onChange);
   // } catch (e:any) {
   //     TizenDebug("addNetworkStateChangeListener exception [" + e.code
   //         + "] name: " + e.name + " message: " + e.message);
   // }
   // if (listenerID > -1) {
   //     log("addNetworkStateChangeListener success listener ID ["
   //         + listenerID + "] ");
   // }
}

function getNetworkType(networkTypeCode: number) {
   let connType: string
   switch (networkTypeCode) {
      //@ts-ignore
      case webapis.network.NetworkActiveConnectionType.DISCONNECTED:
         connType = 'DISCONNECTED'
         break
      //@ts-ignore
      case webapis.network.NetworkActiveConnectionType.WIFI:
         connType = 'WIFI'
         break
      //@ts-ignore
      case webapis.network.NetworkActiveConnectionType.CELLULAR:
         connType = 'CELLULAR'
         break
      //@ts-ignore
      case webapis.network.NetworkActiveConnectionType.ETHERNET:
         connType = 'ETHERNET'
         break
      default:
         connType = 'ERROR'
   }
   // TizenDebug('From getNetworkType')
   return connType
}

function getNetworkStatus() {
   let netStatus: ITizenNetworkStatus = {
      available: false,
      type: null,
      ip: null,
      wifi_ssid: null,
      alertDisplayed: false,
   }

   //@ts-ignore
   const networkTypeCode: number = webapis.network.getActiveConnectionType() //https://www.samsungdforum.com/B2B/CmsGuide/GuideView/8972
   const networkType = getNetworkType(networkTypeCode)
   if (networkType === 'ERROR') {
      return null
   }
   // TizenDebug(`networkTypeCode = ${networkTypeCode}`)
   //@ts-ignore
   netStatus.ip = webapis.network.getIp() ?? null

   if (networkType !== 'DISCONNECTED') {
      netStatus.available = true
      netStatus.type = networkType
   }
   if (networkType === 'WIFI') {
      //@ts-ignore
      netStatus.wifi_ssid = webapis.network.getWiFiSsid() ?? null
   }
   // TizenDebug('From getNetworkStatus')
   return netStatus
}

class TizenNetworkError extends Error {
   constructor(message = 'Unable to set network status') {
      super(message)
      this.name = 'TizenNetworkError'
      this.message = message
   }
}
