import axios from 'axios'
import {getAuth, setAuth} from "./AuthHelpers";
import moment from "moment";
import {Buffer} from "buffer";
import {TizenDebug} from "../../hotel/FaceAuth/TizenIntegration";
import {constants} from "../../../config/constants";

const API_URL = process.env.REACT_APP_API_URL
const API_URL_NON_SSL = process.env.REACT_APP_API_URL_NON_SSL
const kiosk_global_id = process.env.REACT_APP_KIOSK_GLOBAL_ON

export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGIN_URL_NON_SSL = `${API_URL_NON_SSL}/auth/login`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh_access_token`;
export function CheckSSLRequest() {
  
  localStorage.setItem("IsSSLNotAvaiable",new Boolean(false).toString());
  axios.post(API_URL as any, '').then(async (res) =>
    console.log(res)
  ).then(() => {

  }).catch(error => {    
    if (error.code === 'CERT_HAS_EXPIRED') {
      localStorage.setItem("IsSSLAvaiable",new Boolean(true).toString());
    }
    if (error.code === 'CERT_INVALID') {
      localStorage.setItem("IsSSLAvaiable",new Boolean(true).toString());
    }
  });
}
export function login(email: string, password: string) {
  let LoginUrl = LOGIN_URL;
  var themeMode = localStorage.getItem("IsSSLNotAvaiable");  
  if(themeMode=="true"){   
    LoginUrl  = LOGIN_URL_NON_SSL as string;
  }
  const loginPayload = {
    email: email,
    password: password,
    global_on_id: kiosk_global_id,
    has_products: [constants.global_ids.products.CHECK_IN, constants.global_ids.products.SELF_ORDERING]
  }
  return axios.post(LoginUrl, loginPayload)
}

const tokenWillExpireSoonOrExpired = () => {
  const auth = getAuth();
  if(auth?.api_token != undefined) {
    const decodedToken = JSON.parse(Buffer.from(auth.api_token.split('.')[1], 'base64').toString('utf-8')); // decode the JWT payload
    const expirationTime = moment.unix(decodedToken.exp).subtract(15, 'minutes');
    const now = moment();
    return now.isAfter(expirationTime);
  }
  return false;
}

export function refreshToken() {
  const auth = getAuth()
  if (auth && auth?.api_token != undefined && tokenWillExpireSoonOrExpired()) {
    return axios.post(REFRESH_TOKEN_URL, { global_on_id: kiosk_global_id })
        .then(function (response) {
          // console.log("TOKEN REFRESHED!!!")
          // TizenDebug("TOKEN REFRESHED!")
          const data = response.data;
          const userToken = data.access_token as string;
          // const decodedToken = parseJWT(userToken)
          // if(decodedToken.jti !== undefined) TizenDebug(`TOKEN ID ${decodedToken.jti}`)
          setAuth({ api_token: userToken,user_id:auth.user_id });
        })
  } else {
    console.log("TOKEN NOT REFRESHED YET")
  }
  return false
}

export const parseJWT = (token:string) => {
  /**
   * exp:number
   * iat:number
   * iss:string
   * jti:string
   * nbf:number
   * prv:string
   * sub:number
   * type:string
   */
  return  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'))
}
